import { call, put } from 'redux-saga/effects';
import AlgoliaActions from '../Redux/AlgoliaRedux';

export function* getAlgoliaOrganisations(api, { queryParams }) {
  const response = yield call(api.getAlgoliaOrganisations, queryParams);
  if (response.hits) {
    yield put(AlgoliaActions.getAlgoliaOrganisationsSuccess(queryParams, response.hits));
  } else {
    yield put(AlgoliaActions.getAlgoliaOrganisationsFailure(queryParams, response?.message));
  }
}

export function* getAlgoliaCourses(api, { queryParams }) {
  const response = yield call(api.getAlgoliaCourses, queryParams);
  if (response.hits) {
    yield put(AlgoliaActions.getAlgoliaCoursesSuccess(queryParams, response.hits));
  } else {
    yield put(AlgoliaActions.getAlgoliaCoursesFailure(queryParams, response?.message));
  }
}
