/* eslint-disable global-require */
import { isClientSide } from '../Config/ServerConfig';
import RequestResponseDecoratorActions from '../Redux/RequestResponseDecoratorRedux';

let store;

export const setStore = pStore => {
  store = pStore;
};

const makeUniqueRequestId = length => {
  const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let text = '';

  // eslint-disable-next-line
  for (let i = 0; i < length; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  return text;
};

const dispatchRequestResponseAction = client => {
  if (isClientSide()) {
    client.addRequestTransform(request => {
      request.uid = makeUniqueRequestId(10);
      const hideDecorator =
        request.data instanceof FormData ? request.data?.get('noDecorator') : null;
      if (hideDecorator === null)
        store.dispatch(RequestResponseDecoratorActions.requestStart(request.method, request.uid));
    });

    client.addResponseTransform(response => {
      store.dispatch(
        RequestResponseDecoratorActions.requestEnd(response.config.method, response.config.uid),
      );
    });
  }
};

export default dispatchRequestResponseAction;
