import React from 'react';
import { connect } from 'react-redux';
import loadable from '@loadable/component';
import OrganisationActions from '../Redux/OrganisationRedux';
import { isFamily } from '../Acl/Controls';
const FullScreenLoader = loadable(() => import('../Components/Layout/FullScreenLoader'));
const NoMatch404 = loadable(() => import('../Pages/NoMatch404'));

type Props = {
  match: Object,
  organisation: Object,
  getOrganisation: Function,
};

const mapStateToProps = state => ({
  organisation: state.organisation.family,
  isFetching: state.organisation.isFetching.getOrganisationByIdentifier,
  errors: state.organisation.errors,
});

const mapDispatchToProps = dispatch => ({
  getOrganisation: (organisationIdentifier, method) =>
    dispatch(
      OrganisationActions.getOrganisationByIdentifierRequest(organisationIdentifier, method),
    ),
});

const withStructure = (WrappedComponent, method) => {
  function fetchOrganisation(props: Props) {
    const { organisation, match, getOrganisation, errors, isFetching } = props;
    const isOrganisationLoaded =
      organisation && isFamily(organisation) && match.params.identifier === organisation.identifier;

    if (!isOrganisationLoaded && errors && errors.status === 404) {
      return <NoMatch404 />;
    }

    if (!isOrganisationLoaded) {
      if (!isFetching) {
        getOrganisation(match.params.identifier, method);
      }
      return <FullScreenLoader />;
    }

    return <WrappedComponent family={organisation} {...props} />;
  }

  return connect(mapStateToProps, mapDispatchToProps)(fetchOrganisation);
};

export default withStructure;
