import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';
import findAndReplace from './ReduxHelpers';

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  getLeagueRequest: ['leagueId', 'includeHoldings', 'includeOrganisations', 'includeCommunities'],
  getLeagueSuccess: ['league'],
  getLeagueFailure: ['errors'],

  getLeaguesRequest: [''],
  getLeaguesSuccess: ['leagues'],
  getLeaguesFailure: ['errors'],

  createCandidateLeagueRequest: ['leagueId', 'candidate', 'cv'],
  createCandidateLeagueSuccess: ['candidate'],
  createCandidateLeagueFailure: ['errors'],

  getLeagueTasksRequest: ['leagueId', 'min', 'onlyCreatedHoldings'],
  getLeagueTasksSuccess: ['tasks'],
  getLeagueTasksFailure: ['errors'],

  addLeagueHolding: ['holding'],

  updateHoldingPremiumStatusRequest: ['leagueId', 'updateAttributes'],
  updateHoldingPremiumStatusSuccess: ['holding'],
  updateHoldingPremiumStatusFailure: ['errors'],

  getLeagueHelpersRequest: ['leagueId', 'page', 'organisation_id'],
  getLeagueHelpersSuccess: ['helpers'],
  getLeagueHelpersFailure: ['errors'],

  getLeagueHelperRequest: ['leagueId', 'helperId'],
  getLeagueHelperSuccess: ['helper'],
  getLeagueHelperFailure: ['errors'],

  createLeagueHelperRequest: ['leagueId', 'helperAttributes'],
  createLeagueHelperSuccess: ['helper'],
  createLeagueHelperFailure: ['errors'],

  updateLeagueHelperRequest: ['leagueId', 'helperId', 'helperLeagueAttributes'],
  updateLeagueHelperSuccess: ['helper'],
  updateLeagueHelperFailure: ['errors'],

  transferLeagueHelperRequest: ['leagueId', 'helperId', 'organisationId'],
  transferLeagueHelperSuccess: ['helper'],
  transferLeagueHelperFailure: ['errors'],

  updateLeagueHelperInfosRequest: ['helperId', 'helperAttributes'],
  updateLeagueHelperInfosSuccess: ['helper'],
  updateLeagueHelperInfosFailure: ['errors'],

  resetLeagues: [],
});

export const LeaguesTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
  league: null,
  candidate: null,
  isFetching: {
    createCandidateLeague: null,
    getLeague: null,
    getLeagues: null,
    getLeagueTasks: null,
    getLeagueHelpers: null,
    getLeagueHelper: null,
    createLeagueHelper: null,
    updateLeagueHelper: null,
    updateLeagueHelperInfos: null,
    transferLeagueHelper: null,
  },
  errors: {
    createCandidateLeague: null,
    getLeague: null,
    getLeagues: null,
    getLeagueTasks: null,
    getLeagueHelpers: null,
    getLeagueHelper: null,
    createLeagueHelper: null,
    updateLeagueHelper: null,
    updateLeagueHelperInfos: null,
    transferLeagueHelper: null,
  },
  leagues: [],
  helpers: null,
});

/* ------------- Reducers ------------- */
// get league
const getLeagueRequest = state => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    getLeague: true,
  },
});

const getLeagueSuccess = (state, { league }) => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    getLeague: false,
  },
  league,
});

const getLeagueFailure = (state, { error }) => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    getLeague: false,
  },
  errors: {
    ...state.errors,
    getLeague: error,
  },
});

//
const getLeaguesRequest = state => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    getLeagues: true,
  },
});

const getLeaguesSuccess = (state, { leagues }) => ({
  ...state,
  leagues,
  isFetching: {
    ...state.isFetching,
    getLeagues: false,
  },
});

const getLeaguesFailure = (state, { errors }) => ({
  ...state,
  errors: {
    ...state.errors,
    getLeagues: errors,
  },
  isFetching: {
    ...state.isFetching,
    getLeagues: false,
  },
});

// create candidate in league
const createCandidateLeagueRequest = state => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    createCandidateLeague: true,
  },
});
const createCandidateLeagueSuccess = (state, { candidate }) => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    createCandidateLeague: false,
  },
  candidate,
});
const createCandidateLeagueFailure = (state, { error }) => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    createCandidateLeague: false,
  },
  errors: {
    ...state.errors,
    createCandidateLeague: error,
  },
});

// getLeagueTasks
const getLeagueTasksRequest = state => ({
  ...state,
  isFetching: { ...state.isFetching, getLeagueTasks: true },
  tasks: INITIAL_STATE.tasks,
  errors: INITIAL_STATE.errors,
});

const getLeagueTasksSuccess = (state, { tasks }) => ({
  ...state,
  isFetching: { ...state.isFetching, getLeagueTasks: false },
  tasks,
});

const getLeagueTasksFailure = (state, { errors }) => ({
  ...state,
  isFetching: { ...state.isFetching, getLeagueTasks: false },
  tasks: [],
  errors: {
    ...state.errors,
    getLeagueTasks: errors || true,
  },
});

const addLeagueHolding = (state, { holding }) => {
  if (!holding || !state.league || holding.league_id !== state.league.id) return state;

  let newHoldingList = state.league.holdings?.slice();
  if (newHoldingList) newHoldingList.push(holding);
  else newHoldingList = [holding];

  return {
    ...state,
    league: { ...state.league, holdings: newHoldingList },
  };
};

// updateHoldingPremiumStatus

const updateHoldingPremiumStatusRequest = state => ({
  ...state,
  isFetching: { ...state.isFetching, updateHoldingPremiumStatus: true },
});

const updateHoldingPremiumStatusSuccess = (state, { holding }) => ({
  ...state,
  isFetching: { ...state.isFetching, updateHoldingPremiumStatus: false },
  league: {
    ...state.league,
    holdings: findAndReplace(h => h.id === holding.id, holding)(state.league.holdings),
  },
});

const updateHoldingPremiumStatusFailure = (state, { errors }) => ({
  ...state,
  isFetching: { ...state.isFetching, updateHoldingPremiumStatus: false },
  errors: {
    ...state.errors,
    updateHoldingPremiumStatus: errors,
  },
});

// getLeagueHelpers

const getLeagueHelpersRequest = state => ({
  ...state,
  isFetching: { ...state.isFetching, getLeagueHelpers: true },
});

const getLeagueHelpersSuccess = (state, { helpers }) => ({
  ...state,
  isFetching: { ...state.isFetching, getLeagueHelpers: false },
  helpers,
});

const getLeagueHelpersFailure = (state, { errors }) => ({
  ...state,
  isFetching: { ...state.isFetching, getLeagueHelpers: false },
  errors: {
    ...state.errors,
    getLeagueHelpers: errors,
  },
});

// getLeagueHelper

const getLeagueHelperRequest = state => ({
  ...state,
  isFetching: { ...state.isFetching, getLeagueHelper: true },
  errors: {
    ...state.errors,
    getLeagueHelper: INITIAL_STATE.errors.getLeagueHelper,
  },
});

const getLeagueHelperSuccess = (state, { helper }) => ({
  ...state,
  isFetching: { ...state.isFetching, getLeagueHelper: false },
  helper,
});

const getLeagueHelperFailure = (state, { errors }) => ({
  ...state,
  isFetching: { ...state.isFetching, getLeagueHelper: false },
  errors: {
    ...state.errors,
    getLeagueHelper: errors,
  },
});

// createLeagueHelper

const createLeagueHelperRequest = state => ({
  ...state,
  isFetching: { ...state.isFetching, createLeagueHelper: true },
});

const createLeagueHelperSuccess = (state, { helper }) => {
  const newHelpers = state.helpers?.slice() || [];
  newHelpers.push(helper);
  return {
    ...state,
    isFetching: { ...state.isFetching, createLeagueHelper: false },
    helpers: newHelpers,
  };
};

const createLeagueHelperFailure = (state, { errors }) => ({
  ...state,
  isFetching: { ...state.isFetching, createLeagueHelper: false },
  errors: {
    ...state.errors,
    createLeagueHelper: errors,
  },
});

// updateLeagueHelper

const updateLeagueHelperRequest = state => ({
  ...state,
  isFetching: { ...state.isFetching, updateLeagueHelper: true },
});

const updateLeagueHelperSuccess = (state, { helper }) => {
  const newHelper = { ...state.helper, ...helper };
  return {
    ...state,
    isFetching: { ...state.isFetching, updateLeagueHelper: false },
    helpers: findAndReplace(h => h.id === helper.id, newHelper)(state.helpers),
    helper: newHelper,
  };
};

const updateLeagueHelperFailure = (state, { errors }) => ({
  ...state,
  isFetching: { ...state.isFetching, updateLeagueHelper: false },
  errors: {
    ...state.errors,
    updateLeagueHelper: errors,
  },
});

// transferLeagueHelper

const transferLeagueHelperRequest = state => ({
  ...state,
  isFetching: { ...state.isFetching, transferLeagueHelper: true },
});

const transferLeagueHelperSuccess = (state, { helper }) => ({
  ...state,
  isFetching: { ...state.isFetching, transferLeagueHelper: false },
  helpers: findAndReplace(h => h.id === helper.id, helper)(state.helpers),
  helper: helper,
});

const transferLeagueHelperFailure = (state, { errors }) => ({
  ...state,
  isFetching: { ...state.isFetching, transferLeagueHelper: false },
  errors: {
    ...state.errors,
    transferLeagueHelper: errors,
  },
});

// updateLeagueHelperInfos

const updateLeagueHelperInfosRequest = state => ({
  ...state,
  isFetching: { ...state.isFetching, updateLeagueHelperInfos: true },
});

const updateLeagueHelperInfosSuccess = (state, { helper }) => {
  const oldHelper = state.helpers?.find(h => h.id === helper.id);
  if (!oldHelper) return state;

  return {
    ...state,
    isFetching: { ...state.isFetching, updateLeagueHelperInfos: false },
    helpers: findAndReplace(h => h.id === helper.id, { ...oldHelper, ...helper })(state.helpers),
  };
};

const updateLeagueHelperInfosFailure = (state, { errors }) => ({
  ...state,
  isFetching: { ...state.isFetching, updateLeagueHelperInfos: false },
  errors: {
    ...state.errors,
    updateLeagueHelperInfos: errors,
  },
});

const resetLeagues = () => INITIAL_STATE;

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_LEAGUE_REQUEST]: getLeagueRequest,
  [Types.GET_LEAGUE_SUCCESS]: getLeagueSuccess,
  [Types.GET_LEAGUE_FAILURE]: getLeagueFailure,
  [Types.GET_LEAGUES_REQUEST]: getLeaguesRequest,
  [Types.GET_LEAGUES_SUCCESS]: getLeaguesSuccess,
  [Types.GET_LEAGUES_FAILURE]: getLeaguesFailure,
  [Types.CREATE_CANDIDATE_LEAGUE_REQUEST]: createCandidateLeagueRequest,
  [Types.CREATE_CANDIDATE_LEAGUE_SUCCESS]: createCandidateLeagueSuccess,
  [Types.CREATE_CANDIDATE_LEAGUE_FAILURE]: createCandidateLeagueFailure,
  [Types.GET_LEAGUE_TASKS_REQUEST]: getLeagueTasksRequest,
  [Types.GET_LEAGUE_TASKS_SUCCESS]: getLeagueTasksSuccess,
  [Types.GET_LEAGUE_TASKS_FAILURE]: getLeagueTasksFailure,
  [Types.ADD_LEAGUE_HOLDING]: addLeagueHolding,
  [Types.UPDATE_HOLDING_PREMIUM_STATUS_REQUEST]: updateHoldingPremiumStatusRequest,
  [Types.UPDATE_HOLDING_PREMIUM_STATUS_SUCCESS]: updateHoldingPremiumStatusSuccess,
  [Types.UPDATE_HOLDING_PREMIUM_STATUS_FAILURE]: updateHoldingPremiumStatusFailure,
  [Types.GET_LEAGUE_HELPERS_REQUEST]: getLeagueHelpersRequest,
  [Types.GET_LEAGUE_HELPERS_SUCCESS]: getLeagueHelpersSuccess,
  [Types.GET_LEAGUE_HELPERS_FAILURE]: getLeagueHelpersFailure,
  [Types.GET_LEAGUE_HELPER_REQUEST]: getLeagueHelperRequest,
  [Types.GET_LEAGUE_HELPER_SUCCESS]: getLeagueHelperSuccess,
  [Types.GET_LEAGUE_HELPER_FAILURE]: getLeagueHelperFailure,
  [Types.CREATE_LEAGUE_HELPER_REQUEST]: createLeagueHelperRequest,
  [Types.CREATE_LEAGUE_HELPER_SUCCESS]: createLeagueHelperSuccess,
  [Types.CREATE_LEAGUE_HELPER_FAILURE]: createLeagueHelperFailure,
  [Types.UPDATE_LEAGUE_HELPER_REQUEST]: updateLeagueHelperRequest,
  [Types.UPDATE_LEAGUE_HELPER_SUCCESS]: updateLeagueHelperSuccess,
  [Types.UPDATE_LEAGUE_HELPER_FAILURE]: updateLeagueHelperFailure,
  [Types.TRANSFER_LEAGUE_HELPER_REQUEST]: transferLeagueHelperRequest,
  [Types.TRANSFER_LEAGUE_HELPER_SUCCESS]: transferLeagueHelperSuccess,
  [Types.TRANSFER_LEAGUE_HELPER_FAILURE]: transferLeagueHelperFailure,
  [Types.UPDATE_LEAGUE_HELPER_INFOS_REQUEST]: updateLeagueHelperInfosRequest,
  [Types.UPDATE_LEAGUE_HELPER_INFOS_SUCCESS]: updateLeagueHelperInfosSuccess,
  [Types.UPDATE_LEAGUE_HELPER_INFOS_FAILURE]: updateLeagueHelperInfosFailure,
  [Types.RESET_LEAGUES]: resetLeagues,
});
