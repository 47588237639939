import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import moment from 'moment';

import { isClientSide } from '../Config/ServerConfig';

const defaultLocale = 'fr';
const availableLocals = ['fr', 'en'];

const htmlLocale = isClientSide() ? document.documentElement.getAttribute('lang') : defaultLocale;
export const locale = availableLocals.includes(htmlLocale) ? htmlLocale : defaultLocale;

moment.locale(defaultLocale);

// Import locales
const resources = {
  fr: {
    // eslint-disable-next-line global-require
    translation: require('./Locales/fr.json'),
  },
  en: {
    // eslint-disable-next-line global-require
    translation: require('./Locales/en.json'),
  },
};
i18n
  .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    // lng: locale,
    fallbackLng: defaultLocale,

    keySeparator: true, // we do not use keys in form messages.welcome
    nsSeparator: false,
    interpolation: {
      escapeValue: false, // react already safes from xss
      prefix: '{',
      suffix: '}',
      transSupportBasicHtmlNodes: true,
    },
    react: {
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'b'],
    },
  });

export default i18n;
