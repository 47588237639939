import React from 'react';

import Routes from './Routes';
import { HOSTS_CONFIG } from './HostConstraint';
// import { hasHolding } from '../Acl/Controls';

class Seo {
  // static metaOrganisation(organisation, uri) {
  //   if (!organisation) return null;
  //   const title = hasHolding(organisation)
  //     ? `${organisation.holding.name}${` - `}${organisation.name}${
  //         messages.fr[`${Routes.currentNamespace()}.SEO.SUFFIX`]
  //       }`
  //     : `${organisation.name}${messages.fr[`${Routes.currentNamespace()}.SEO.SUFFIX`]}`;

  //   return Seo.canonical(organisation, uri)
  //     .concat(Seo.title(title))
  //     .concat(Seo.description(organisation.holding?.short_description || ''));
  // }

  static title(title) {
    return [
      <title key="title">{title}</title>,
      <meta property="og:title" content={title} key="ogtitle" />,
      <meta name="twitter:title" content={title} key="ogtitle" />,
    ];
  }

  static description(description) {
    return [
      <meta name="description" content={description} key="description" />,
      <meta property="og:description" content={description} key="ogdescription" />,
      <meta name="twitter:description" content={description} key="twitterdescription" />,
    ];
  }

  static url(url) {
    return [<meta property="og:url" content={url} key="ogurl" />];
  }

  static image(image) {
    const hostname = Routes.currentHost();
    let currentImage = image;
    if (image && image.indexOf('http://') === -1 && image.indexOf('https://') === -1) {
      currentImage = `https://${hostname}${image}`;
    }
    return [
      <meta property="og:image" content={currentImage} key="ogimage" />,
      <meta name="og:image:secure_url" content={currentImage} key="ogimage" />,
    ];
  }

  static canonical(leagueId, uri) {
    const host = HOSTS_CONFIG?.find(h => `${h.league.id}` === `${leagueId}`);
    const hostname = host ? host.hostname : Routes.currentHost();
    return [<link rel="canonical" href={`https://${hostname}${uri || ''}`} key="canonical" />];
  }

  static noIndex() {
    return [<meta name="robots" content="noindex,nofollow" key="robots" />];
  }
}

export default Seo;
