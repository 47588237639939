import { call, put } from 'redux-saga/effects';
import _ from 'lodash';
import { toastResponseError } from '../Services/ApiErrorHelpers';
import AuthActions from '../Redux/AuthRedux';
import CareNotebooksActions from '../Redux/CareNotebooksRedux';

export function* getCareNotebookPosts(api, { familySlug, page }) {
  const response = yield call(api.getCareNotebookPosts, familySlug, page);
  if (response.ok) {
    yield put(
      CareNotebooksActions.getCareNotebookPostsSuccess(
        familySlug,
        response.data.posts,
        response.data.meta.total_posts,
        response.data.meta.important_posts,
        page,
      ),
    );
  } else {
    yield toastResponseError(response);
    const errors = _.get(response, 'data.errors');
    yield put(CareNotebooksActions.getCareNotebookPostsFailure(errors));
  }
}

export function* getCareNotebookPost(api, { familySlug, postId }) {
  const response = yield call(api.getCareNotebookPost, familySlug, postId);
  if (response.ok) {
    yield put(CareNotebooksActions.getCareNotebookPostSuccess(response.data.post));
  } else {
    yield toastResponseError(response);
    const errors = _.get(response, 'data.errors');
    yield put(CareNotebooksActions.getCareNotebookPostFailure(errors));
  }
}

export function* createCareNotebookPost(
  api,
  { familySlug, familyId, post, attachment, subscription },
) {
  const response = yield call(api.createPost, null, familySlug, familyId, post, attachment);
  if (response.ok) {
    yield put(CareNotebooksActions.createCareNotebookPostSuccess(familySlug, response.data.post));
    if (subscription) {
      yield put(AuthActions.createHelperSubscriptionRequest(subscription.helper_id, subscription));
    }
  } else {
    yield toastResponseError(response);
    const errors = _.get(response, 'data.error');
    yield put(CareNotebooksActions.createCareNotebookPostFailure(errors));
  }
}

export function* updateCareNotebookPost(api, { familySlug, familyId, post, attachment }) {
  const response = yield call(api.updatePost, null, familySlug, familyId, post, attachment);
  if (response.ok) {
    yield put(CareNotebooksActions.updateCareNotebookPostSuccess(familySlug, response.data.post));
  } else {
    yield toastResponseError(response);
    const errors = _.get(response, 'data.error');
    yield put(CareNotebooksActions.updateCareNotebookPostFailure(errors));
  }
}

export function* deleteCareNotebookPost(api, { familySlug, familyId, postId }) {
  const response = yield call(api.destroyPost, null, familySlug, familyId, postId);
  if (response.ok) {
    yield put(CareNotebooksActions.deleteCareNotebookPostSuccess(familySlug, postId));
  } else {
    yield toastResponseError(response);
    const errors = _.get(response, 'data.error');
    yield put(CareNotebooksActions.deleteCareNotebookPostFailure(errors));
  }
}

export function* createCareNotebookPostComment(api, { familySlug, postId, comment }) {
  const response = yield call(api.createPost, null, familySlug, postId, comment);
  if (response.ok) {
    yield put(
      CareNotebooksActions.createCareNotebookPostCommentSuccess(
        familySlug,
        postId,
        response.data.post,
      ),
    );
  } else {
    yield toastResponseError(response);
    yield put(CareNotebooksActions.createCareNotebookPostCommentFailure(response.data));
  }
}

export function* deleteCareNotebookPostComment(api, { familySlug, postId, commentId }) {
  const response = yield call(api.destroyPost, null, familySlug, postId, commentId);
  if (response.ok) {
    yield put(
      CareNotebooksActions.deleteCareNotebookPostCommentSuccess(familySlug, postId, commentId),
    );
  } else {
    yield toastResponseError(response);
    const errors = _.get(response, 'data.error');
    yield put(CareNotebooksActions.deleteCareNotebookPostCommentFailure(errors));
  }
}
