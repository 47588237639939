import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  getAlgoliaOrganisationsRequest: ['queryParams'],
  getAlgoliaOrganisationsSuccess: ['queryParams', 'organisations'],
  getAlgoliaOrganisationsFailure: ['queryParams', 'errors'],

  getAlgoliaCoursesRequest: ['queryParams'],
  getAlgoliaCoursesSuccess: ['queryParams', 'courses'],
  getAlgoliaCoursesFailure: ['queryParams', 'errors'],
});

export const AlgoliaTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  isFetching: {
    getAlgoliaOrganisations: false,
    getAlgoliaCourses: false,
  },
  queryParams: {
    getAlgoliaOrganisations: null,
    getAlgoliaCourses: null,
  },
  lastFetchDate: {
    getAlgoliaOrganisations: null,
    getAlgoliaCourses: null,
  },
  organisations: [],
  courses: [],
  errors: {
    getAlgoliaOrganisations: null,
    getAlgoliaCourses: null,
  },
});

/* ------------- Reducers ------------- */
// -
// getAlgoliaOrganisations
// -

const getAlgoliaOrganisationsRequest = state => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    getAlgoliaOrganisations: true,
  },
});

const getAlgoliaOrganisationsSuccess = (state, { queryParams, organisations }) => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    getAlgoliaOrganisations: false,
  },
  errors: {
    ...state.errors,
    getAlgoliaOrganisations: INITIAL_STATE.errors.getAlgoliaOrganisations,
  },
  organisations,
  queryParams: {
    ...state.queryParams,
    getAlgoliaOrganisations: queryParams,
  },
  lastFetchDate: {
    ...state.lastFetchDate,
    getAlgoliaOrganisations: new Date(),
  },
});

const getAlgoliaOrganisationsFailure = (state, { queryParams, errors }) => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    getAlgoliaOrganisations: false,
  },
  organisations: [],
  queryParams: {
    ...state.queryParams,
    getAlgoliaOrganisations: queryParams,
  },
  errors: {
    ...state.errors,
    getAlgoliaOrganisations: errors,
  },
});

// -
// getAlgoliaOrganisations
// -

const getAlgoliaCoursesRequest = state => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    getAlgoliaCourses: true,
  },
});

const getAlgoliaCoursesSuccess = (state, { queryParams, courses }) => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    getAlgoliaCourses: false,
  },
  errors: {
    ...state.errors,
    getAlgoliaCourses: INITIAL_STATE.errors.getAlgoliaCourses,
  },
  courses,
  queryParams: {
    ...state.queryParams,
    getAlgoliaCourses: queryParams,
  },
  lastFetchDate: {
    ...state.lastFetchDate,
    getAlgoliaCourses: new Date(),
  },
});

const getAlgoliaCoursesFailure = (state, { queryParams, errors }) => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    getAlgoliaCourses: false,
  },
  courses: [],
  queryParams: {
    ...state.queryParams,
    getAlgoliaCourses: queryParams,
  },
  errors: {
    ...state.errors,
    getAlgoliaCourses: errors,
  },
});

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_ALGOLIA_ORGANISATIONS_REQUEST]: getAlgoliaOrganisationsRequest,
  [Types.GET_ALGOLIA_ORGANISATIONS_SUCCESS]: getAlgoliaOrganisationsSuccess,
  [Types.GET_ALGOLIA_ORGANISATIONS_FAILURE]: getAlgoliaOrganisationsFailure,

  [Types.GET_ALGOLIA_COURSES_REQUEST]: getAlgoliaCoursesRequest,
  [Types.GET_ALGOLIA_COURSES_SUCCESS]: getAlgoliaCoursesSuccess,
  [Types.GET_ALGOLIA_COURSES_FAILURE]: getAlgoliaCoursesFailure,
});
