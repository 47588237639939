import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Redirect, withRouter, Link, useRouteMatch } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';

import _ from 'lodash';
import Authentication from './Authentication';
import ToasterActions from '../Redux/ToasterRedux';
import { getUserOrganisation } from '../Services/UserHelper';
import { isLeagueAdmin } from './Controls';
import { CheckRoleRule } from './Rules';
import { isClientSide } from '../Config/ServerConfig';

type Props = {
  showForbidden: Function,
  organisation: Object,
  currentUser: Object,
  showForbiddenToaster: Function,
};

const OrganisationAuthorization = action => WrappedComponent => {
  function WithAuthorization(props: Props) {
    const { showForbiddenToaster, organisation, currentUser } = props;
    const { t } = useTranslation();
    const match = useRouteMatch();
    const { holding_slug: holdingSlug, organisation_slug: orgaSlug } = match.params;
    const userOrga = getUserOrganisation(currentUser, holdingSlug, orgaSlug);
    const reduxOrga =
      organisation?.slug === orgaSlug && organisation?.holding_slug === holdingSlug
        ? organisation
        : null;
    const checkIsAllowed = orga => {
      if (!orga) return true;
      if (isLeagueAdmin(_.get(orga, 'holding.league_slug'), currentUser)) return true;

      if (
        CheckRoleRule(orga.role, 'organisation:read', { organisation: orga }) &&
        (action === '' || CheckRoleRule(orga.role, action, { organisation: orga }))
      )
        return true;
      return false;
    };

    if (!isClientSide() && (!checkIsAllowed(reduxOrga) || !checkIsAllowed(userOrga))) {
      showForbiddenToaster({ id: 'APP.ACL.ORGANISATION.FORBIDDEN' });

      return (
        <>
          <Typography align="center" style={{ paddingTop: '20px' }}>
            <Link to="/signin">{t('APP.ACL.ORGANISATION.FORBIDDEN')}</Link>
          </Typography>
          <Redirect to="/signin" />
        </>
      );
    }

    return <WrappedComponent {...props} />;
  }

  const mapStateToProps = state => ({
    currentUser: state.auth.currentUser,
    organisation: state.organisation.organisation,
  });

  const mapDispatchToProps = dispatch => ({
    showForbiddenToaster: message => dispatch(ToasterActions.showToaster(message, 'error', 5000)),
  });

  return connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withRouter(Authentication(WithAuthorization)));
};

export default OrganisationAuthorization;
