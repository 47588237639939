import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import { grey } from '@material-ui/core/colors';

type Props = {
  tree: Object,
};

const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: grey[200],
    borderRadius: '45px',
    marginLeft: theme.spacing(2),
    height: '45px',
  },
  button: {
    backgroundColor: 'transparent',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  flex: {
    marginLeft: '10px',
    marginTop: '4px',
    marginRight: '10px',
    display: 'flex',
  },
  title: {
    display: '-webkit-box',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: '1',
    wordBreak: 'break-word',
    textTransform: 'capitalize',
  },
}));

const NavBarMenu = ({ tree }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState();
  const [anchorTitle, setAnchorTitle] = React.useState();

  return (
    <>
      {tree?.map(item => (
        <Grid key={item.title} className={classes.container}>
          {item.items && (
            <>
              <Grid className={classes.flex}>
                <Button
                  onClick={event => {
                    setAnchorEl(event.currentTarget);
                    setAnchorTitle(item.title);
                  }}
                  className={classes.button}
                  disableRipple
                >
                  <Typography className={classes.title}>{t(item.title)}</Typography>

                  {/* <ExpandMoreIcon
                    style={{
                      fontSize: '1.1rem',
                      marginLeft: '5px',
                      marginBottom: '-2px',
                      height: 'auto',
                    }}
                  /> */}
                </Button>
                <Hidden only={['sm']}>{item.avatar}</Hidden>
              </Grid>
              <Menu
                id="long-structures"
                anchorEl={anchorEl}
                open={anchorTitle === item.title}
                onClick={() => {
                  setAnchorEl(null);
                  setAnchorTitle(null);
                }}
                PaperProps={{
                  style: {
                    marginTop: 44,
                  },
                }}
                MenuListProps={{
                  style: {
                    paddingTop: 0,
                    paddingBottom: 0,
                  },
                }}
              >
                {item.items.map((subItem, i) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <div key={`${subItem.title}-${i}`}>
                    {subItem.component && subItem.component}
                    {subItem.href && (
                      <>
                        <MenuItem component="a" href={subItem.href}>
                          {t(subItem.title)}
                        </MenuItem>
                        <Divider light />
                      </>
                    )}
                    {subItem.link && (
                      <>
                        <MenuItem component={Link} to={subItem.link}>
                          {t(subItem.title)}
                        </MenuItem>
                        <Divider light />
                      </>
                    )}
                  </div>
                ))}
              </Menu>
            </>
          )}
          {item.link && (
            <Grid className={classes.flex}>
              <Button to={item.link} component={Link} className={classes.button} disableRipple>
                <Typography className={classes.title}>{t(item.title)}</Typography>
              </Button>
              {item.avatar}
            </Grid>
          )}
          {item.href && (
            <Grid className={classes.flex}>
              <Button
                className={classes.button}
                target="_blank"
                component="a"
                href={item.href}
                disableRipple
              >
                <Typography className={classes.title}>{t(item.title)}</Typography>
              </Button>
              {item.avatar}
            </Grid>
          )}
        </Grid>
      ))}
    </>
  );
};

export default NavBarMenu;
