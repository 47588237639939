import React from 'react';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import AuthActions from '../../../Redux/AuthRedux';
import { getNotificationAvatar } from '../../../Services/NotificationHelper';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    padding: 0,
  },
  avatar: {
    marginRight: '-25px',
    height: '25px',
    width: '25px',
  },
  badge: {
    height: '9px',
    width: '9px',
    minWidth: '9px',
    borderRadius: '15px',
  },
  title: {
    padding: theme.spacing(2),
    fontSize: '1.4rem',
  },
  link: {
    padding: theme.spacing(2),
    flexGrow: 1,
    textAlign: 'end',
    alignSelf: 'flex-end',
  },
}));

type Props = {
  notifications: Array,
  hasShowMoreButton: Boolean,
};

const NotificationList = ({ notifications, hasShowMoreButton }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const noNotifications = !notifications || notifications.length === 0;
  const history = useHistory();

  const handleNotifClick = notif => {
    if (!notif?.is_read)
      dispatch(
        AuthActions.updateHelperNotificationRequest(notif?.helper_id, {
          ...notif,
          is_read: true,
        }),
      );
    history.push(notif?.path);
  };

  return (
    <MenuList className={classes.root}>
      <Grid container flexDirection="row">
        <Typography variant="h4" className={classes.title}>
          {t('APP.NOTIFICATIONS.TITLE')}
        </Typography>
        {hasShowMoreButton && (
          <Link to="/notifications" color="inherit" className={classes.link}>
            {t('APP.NOTIFICATIONS.SHOW_ALL')}
          </Link>
        )}
      </Grid>
      {noNotifications && (
        <MenuItem key="no-notif">
          <ListItemText
            primary={t('APP.NOTIFICATIONS.EMPTY')}
            component="span"
            style={{
              textAlign: 'center',
            }}
          />
        </MenuItem>
      )}
      {notifications?.map(notif => (
        <MenuItem key={`notif-${notif.id}`} onClick={() => handleNotifClick(notif)} divider>
          <ListItemIcon>{getNotificationAvatar(notif)}</ListItemIcon>
          <ListItemText
            primary={notif.message}
            secondary={moment(notif.created_at).fromNow()}
            component="span"
            style={{
              paddingLeft: '10px',
              whiteSpace: 'pre-wrap',
            }}
            primaryTypographyProps={{
              style: {
                fontWeight: notif.is_read ? '500' : '800',
                color: notif.is_read ? 'grey' : 'black',
              },
            }}
          />
        </MenuItem>
      ))}
    </MenuList>
  );
};

export default NotificationList;
