import { call, put } from 'redux-saga/effects';

import _ from 'lodash';
import OrganisationMembershipActions from '../Redux/OrganisationMembershipRedux';
import UsersActions from '../Redux/UsersRedux';
import OrganisationActions from '../Redux/OrganisationRedux';
import AuthActions from '../Redux/AuthRedux';
import ClubsActions from '../Redux/ClubsRedux';
import ArticlesActions from '../Redux/ArticlesRedux';
import DealsActions from '../Redux/DealsRedux';
import { toastResponseError } from '../Services/ApiErrorHelpers';
import ToasterActions from '../Redux/ToasterRedux';
import { isPrimary } from '../Acl/Controls';
import { PROROLES } from '../Acl/Roles';
import history from '../Routing/CurrentHistory';

export function* getOrganisationMembership(api, { holdingSlug, organisationSlug, role }) {
  const response = yield call(api.getOrganisationMembership, holdingSlug, organisationSlug, role);

  if (response.ok) {
    yield put(
      OrganisationMembershipActions.getOrganisationMembershipSuccess(
        response.data.helpers_organisations,
      ),
    );
  } else {
    yield toastResponseError(response);
    yield put(OrganisationMembershipActions.getOrganisationMembershipFailure(response.data));
  }
}

export function* updateOrganisationMembership(
  api,
  {
    holdingSlug,
    organisationSlug,
    membershipHelperId,
    updateAttributes,
    currentUserId,
    updateType,
  },
) {
  const response = yield call(
    api.updateOrganisationMembership,
    holdingSlug,
    organisationSlug,
    membershipHelperId,
    updateAttributes,
  );

  if (response.ok) {
    yield put(
      OrganisationMembershipActions.updateOrganisationMembershipSuccess(
        response.data.helper_organisation,
      ),
    );
    if (!holdingSlug) {
      yield put(OrganisationActions.updateFamilyHelper(response.data.helper_organisation.helper));
      yield put(ToasterActions.showToaster({ id: 'UPDATE_SUCCESS' }, 'success', 5000));
    }
    if (currentUserId) yield put(UsersActions.getUserRequest(currentUserId));
    if (updateType === 'notif') {
      yield put(
        OrganisationActions.updateOrganisationNotifications(holdingSlug, organisationSlug, {
          email_alerts: response.data.helper_organisation.email_alerts,
          email_excluded_services: response.data.helper_organisation.email_excluded_services || [],
        }),
      );
      yield put(
        ToasterActions.showToaster(
          { id: 'APP.ORGANISATION.SETTINGS.NOTIFICATIONS.SUCCESS' },
          'success',
          5000,
        ),
      );
    } else if (
      holdingSlug &&
      !updateAttributes.password &&
      !updateAttributes.email &&
      !updateAttributes.validated &&
      updateAttributes.role
    ) {
      yield put(
        OrganisationMembershipActions.getOrganisationMembershipRequest(
          holdingSlug,
          organisationSlug,
          PROROLES,
        ),
      );
      // update organisation.role
      yield put(OrganisationActions.getOrganisationRequest(holdingSlug, organisationSlug, 'full'));
      yield put(
        ToasterActions.showToaster(
          { id: 'APP.ORGANISATION.SETTINGS.MEMBERS.UPDATE_ROLE_SUCCESS' },
          'success',
          5000,
        ),
      );
    }
  } else {
    yield toastResponseError(response);
    yield put(OrganisationMembershipActions.updateOrganisationMembershipFailure(response.data));
  }
}

export function* destroyOrganisationMembership(
  api,
  { holdingSlug, organisationSlug, membershipHelperId, membershipId },
) {
  const response = yield call(
    api.destroyOrganisationMembership,
    holdingSlug,
    organisationSlug,
    membershipHelperId,
    membershipId,
  );
  if (response.ok) {
    yield put(
      OrganisationMembershipActions.destroyOrganisationMembershipSuccess(
        response.data.helper_organisation,
      ),
    );
    if (isPrimary(response.data.helper_organisation?.role)) {
      yield put(
        OrganisationActions.removeOrganisationPrimaryMembership(response.data.helper_organisation),
      );
      yield put(
        AuthActions.removeUserOrganisationPrimaryMembership(response.data.helper_organisation),
      );
    }
    yield put(
      ToasterActions.showToaster(
        { id: 'APP.ORGANISATION.SETTINGS.MEMBERS.DELETE_SUCCESS' },
        'success',
        5000,
      ),
    );
    if (!membershipId) {
      yield put(OrganisationActions.resetOrganisation());
      yield put(ClubsActions.resetClubs());
      yield put(DealsActions.resetDeals());
      yield put(ArticlesActions.resetArticles());
      // Update current user data (quit orga)
      yield put(UsersActions.getUserRequest(membershipHelperId));
      yield call(history.push, `/signin`);
    }
  } else {
    yield toastResponseError(response);
    yield put(OrganisationMembershipActions.destroyOrganisationMembershipFailure(response.data));
  }
}

export function* createOrganisationMembership(
  api,
  { holdingSlug, organisationSlug, membershipAttributes, helperAttributes },
) {
  const response = yield call(
    api.createOrganisationMembership,
    holdingSlug,
    organisationSlug,
    membershipAttributes,
    helperAttributes,
  );
  if (response.ok) {
    yield put(
      OrganisationMembershipActions.createOrganisationMembershipSuccess(
        response.data.helper_organisation,
      ),
    );
    if (
      response.data.helper_organisation?.helper?.email &&
      response.data.helper_organisation?.helper?.validation_status ===
        'waiting_for_email_validation'
    ) {
      yield put(
        ToasterActions.showToaster(
          { id: 'APP.ORGANISATION.SETTINGS.MEMBERS.INVITATION_SUCCESS' },
          'success',
          5000,
        ),
      );
    } else {
      yield put(
        ToasterActions.showToaster(
          { id: 'APP.ORGANISATION.SETTINGS.MEMBERS.CREATE_SUCCESS' },
          'success',
          5000,
        ),
      );
    }
  } else {
    const errors = _.get(response, 'data.errors');
    yield toastResponseError(errors || response);

    yield put(OrganisationMembershipActions.createOrganisationMembershipFailure(errors));
  }
}

export function* resendInvitation(api, { holdingSlug, organisationSlug, helperId }) {
  const response = yield call(api.resendInvitation, holdingSlug, organisationSlug, helperId);
  if (response.ok) {
    yield put(
      OrganisationMembershipActions.resendInvitationSuccess(response.data.helper_organisation),
    );
    yield put(
      ToasterActions.showToaster(
        { id: 'APP.ORGANISATION.SETTINGS.MEMBERS.INVITATION_SUCCESS' },
        'success',
        5000,
      ),
    );
  } else {
    const errors = _.get(response, 'data.errors');
    yield toastResponseError(errors || response);

    yield put(OrganisationMembershipActions.resendInvitationFailure(errors));
  }
}
