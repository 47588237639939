import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Routes from '../../Routing/Routes';
import AuthActions from '../../Redux/AuthRedux';
import OrganisationActions from '../../Redux/OrganisationRedux';
import UsersActions from '../../Redux/UsersRedux';
import { CHANNEL_USER, subscribeChannel, unsubscribeChannel } from '../../Services/WebSocket';
import { findSubscription } from '../../Services/SubscriptionHelper';

export default function WebSocketListener() {
  const token = useSelector(state => state.auth?.currentUser?.token);
  const isLoggedIn = useSelector(state => state.auth.isLoggedIn);
  const currentUser = useSelector(state => state.auth?.currentUser);
  const hasNewObject = useSelector(state => state.auth?.hasNewObject);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (token && isLoggedIn) dispatch(UsersActions.getUserRequest(currentUser?.id));

    const channelSubscription = token
      ? subscribeChannel(
          token,
          { channel: CHANNEL_USER },
          {
            received(data) {
              // data {type: "NEW_OBJECT", holding_slug: club.organisation.holding.slug, organisation_slug: club.organisation.slug, organisation_id: club.organisation_id, subscrible_type: "Club", sub_type: nil, subscrible_id: club.id,}
              console.log('User unread data', data);
              if (data?.type === 'NEW_OBJECT') {
                const subscription = findSubscription(
                  currentUser,
                  data.organisation_id,
                  data.subscription_id,
                );
                if (subscription) {
                  const newSub = { ...subscription, unread_count: subscription.unread_count + 1 };
                  dispatch(AuthActions.updateHelperSubscriptionSuccess(newSub));
                  dispatch(OrganisationActions.updateOrganisationSubscription(newSub));
                }
              } else if (data?.type === 'NEW_NOTIFICATION' && data.notification) {
                dispatch(AuthActions.createHelperNotification(data.notification));
              }
            },
          },
        )
      : null;
    return () => {
      if (channelSubscription) unsubscribeChannel(channelSubscription);
    };
  }, [token, dispatch, currentUser?.Id]);

  React.useEffect(() => {
    const fav = document.getElementById('favicon');
    if (!hasNewObject) {
      fav.href = Routes.currentFavicon();
    } else {
      fav.href = Routes.currentFaviconNotif();
    }
  }, [hasNewObject]);

  return null;
}
