import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';
import _ from 'lodash';
import { addNewElement } from '../Services/DataHelper';
import findAndReplace from './ReduxHelpers';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  getDealsRequest: ['holdingSlug', 'organisationSlug', 'page'],
  getDealsSuccess: ['deals', 'page', 'totalDeals'],
  getDealsFailure: ['errors'],

  getDealRequest: ['holdingSlug', 'organisationSlug', 'dealId'],
  getDealSuccess: ['deal'],
  getDealFailure: ['errors'],

  createDealPostRequest: ['holdingSlug', 'organisationSlug', 'dealId', 'post'],
  createDealPostSuccess: ['dealId', 'post'],
  createDealPostFailure: ['errors'],

  destroyDealPostRequest: ['holdingSlug', 'organisationSlug', 'dealId', 'postId'],
  destroyDealPostSuccess: ['dealId', 'postId'],
  destroyDealPostFailure: ['errors'],

  createDealRequest: ['holdingSlug', 'organisationSlug', 'deal', 'attachments'],
  createDealSuccess: ['deal'],
  createDealFailure: ['errors'],

  updateDealRequest: ['holdingSlug', 'organisationSlug', 'deal', 'attachments'],
  updateDealSuccess: ['deal'],
  updateDealFailure: ['errors'],

  destroyDealRequest: ['holdingSlug', 'organisationSlug', 'dealId', 'organisaionType'],
  destroyDealSuccess: ['deals'],
  destroyDealFailure: ['errors'],

  resetDeals: [],
});

export const DealsTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  isFetching: {
    createDeal: false,
    createDealPost: null,
    updateDeal: false,
    destroyDeal: false,
    getDeals: false,
    getNextDeals: false,
    getDeal: false,
    getTotalDeals: false,
    destroyDealPost: false,
  },
  deals: null,
  deal: null,
  totalDeals: 0,
  errors: {
    createDeal: null,
    createDealPost: null,
    destroyDeal: false,
    updateDeal: null,
    getDeals: null,
    getDeal: null,
    getTotalDeals: null,
    destroyDealPost: false,
  },
});

/* ------------- Reducers ------------- */
// -
// getDeals
// -
const getDealsRequest = (state, { page }) => {
  let fetching = { ...state.isFetching, getDeals: true };
  if (page > 1) {
    fetching = { ...state.isFetching, getNextDeals: true };
  }

  return {
    ...state,
    isFetching: fetching,
  };
};
const getDealsSuccess = (state, { deals, page, totalDeals }) => {
  let { deals: allDeals } = state;
  if (page === 1) allDeals = [];

  deals.forEach(deal => {
    allDeals = addNewElement(allDeals, deal);
  });

  allDeals.reverse();

  let fetching = { ...state.isFetching, getDeals: false };
  let err = {
    ...state.errors,
    getDeals: INITIAL_STATE.errors.getDeals,
  };
  if (page > 1) {
    fetching = { ...state.isFetching, getNextDeals: false };
    err = {
      ...state.errors,
      getNextDeals: INITIAL_STATE.errors.getDeals,
    };
  }

  return {
    ...state,
    isFetching: fetching,
    errors: err,
    deals: allDeals,
    totalDeals,
  };
};

const getDealsFailure = (state, { errors }) => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    getDeals: false,
    getNextDeals: false,
  },
  deals: [],
  errors: {
    ...state.errors,
    getDeals: errors,
  },
});

//
// get one
//
const getDealRequest = state => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    getDeal: true,
  },
});
const getDealSuccess = (state, { deal }) => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    getDeal: false,
  },
  errors: {
    ...state.errors,
    getDeal: INITIAL_STATE.errors.getDeal,
  },
  deal,
});
const getDealFailure = (state, { errors }) => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    getDeal: false,
  },
  errors: {
    ...state.errors,
    getDeal: errors,
  },
});
// -
// updateDeal
// -
const updateDealRequest = state => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    updateDeal: true,
  },
});
const updateDealSuccess = (state, { deal }) => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    updateDeal: false,
  },
  errors: {
    ...state.errors,
    updateDeal: INITIAL_STATE.errors.updateDeal,
  },
  deal,
  deals: findAndReplace(d => deal.id === d.id, deal)(state.deals),
});
const updateDealFailure = (state, { errors }) => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    updateDeal: false,
  },
  errors: {
    ...state.errors,
    updateDeal: errors,
  },
});

//
// destroyDeal
//
const destroyDealRequest = state => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    destroyDeal: true,
  },
  errors: {
    ...state.errors,
    destroyDeal: INITIAL_STATE.errors.destroyDeal,
  },
});
const destroyDealSuccess = (state, { deals }) => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    destroyDeal: false,
  },
  errors: {
    ...state.errors,
    destroyDeal: INITIAL_STATE.errors.destroyDeal,
  },
  deals,
  totalDeals: deals?.length,
});
const destroyDealFailure = (state, { errors }) => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    destroyDeal: false,
  },
  errors: {
    ...state.errors,
    destroyDeal: errors,
  },
});

// -
// createDeal
// -
const createDealRequest = state => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    createDeal: true,
  },
});
const createDealSuccess = (state, { deal }) => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    createDeal: false,
  },
  errors: {
    ...state.errors,
    createDeal: INITIAL_STATE.errors.createDeal,
  },
  deal,
  deals: _.concat([deal], state.deals),
});
const createDealFailure = (state, { errors }) => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    createDeal: false,
  },
  errors: {
    ...state.errors,
    createDeal: errors,
  },
});

// -
// nested, create post
// -
const createDealPostRequest = (state, { dealId }) => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    createDealPost: dealId,
  },
  errors: {
    ...state.errors,
    createDealPost: INITIAL_STATE.errors.createDealPost,
  },
});

const createDealPostSuccess = (state, { dealId, post }) => {
  const deals = state.deals.map(deal => {
    const newDeal = _.assign({}, deal);
    if (newDeal.id === dealId) {
      newDeal.posts = _.concat([], newDeal.posts || [], [post]);
    }
    return newDeal;
  });

  return {
    ...state,
    deals,
    deal:
      dealId.toString() === _.get(state, 'deal.id', '').toString()
        ? _.assign({}, state.deal, {
            posts: _.concat([], state.deal.posts || [], [post]),
          })
        : state.deal,
    isFetching: {
      ...state.isFetching,
      createDealPost: null,
    },
  };
};

const createDealPostFailure = (state, { errors }) => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    createDealPost: null,
  },
  errors: {
    ...state.errors,
    createDealPost: errors,
  },
});

// -
// delete post
// -
const destroyDealPostRequest = state => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    destroyDealPost: true,
  },
  errors: {
    ...state.errors,
    destroyDealPost: INITIAL_STATE.errors.destroyDealPost,
  },
});

const destroyDealPostSuccess = (state, { dealId, postId }) => {
  const deals = state.deals.map(deal => {
    const newDeal = _.assign({}, deal);
    if (newDeal.id === dealId) {
      newDeal.posts = newDeal.posts.filter(p => p.id !== postId);
    }
    return newDeal;
  });
  const newDealPosts = state.deal?.posts?.filter(p => p.id !== postId);
  return {
    ...state,
    deals,
    deal: { ...state.deal, posts: newDealPosts },
    isFetching: {
      ...state.isFetching,
      destroyDealPost: false,
    },
  };
};

const destroyDealPostFailure = (state, { errors }) => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    destroyDealPost: false,
  },
  errors: {
    ...state.errors,
    createDealPost: errors,
  },
});

const resetDeals = () => INITIAL_STATE;

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_DEALS_REQUEST]: getDealsRequest,
  [Types.GET_DEALS_SUCCESS]: getDealsSuccess,
  [Types.GET_DEALS_FAILURE]: getDealsFailure,

  [Types.GET_DEAL_REQUEST]: getDealRequest,
  [Types.GET_DEAL_SUCCESS]: getDealSuccess,
  [Types.GET_DEAL_FAILURE]: getDealFailure,

  [Types.UPDATE_DEAL_REQUEST]: updateDealRequest,
  [Types.UPDATE_DEAL_SUCCESS]: updateDealSuccess,
  [Types.UPDATE_DEAL_FAILURE]: updateDealFailure,

  [Types.DESTROY_DEAL_REQUEST]: destroyDealRequest,
  [Types.DESTROY_DEAL_SUCCESS]: destroyDealSuccess,
  [Types.DESTROY_DEAL_FAILURE]: destroyDealFailure,

  [Types.CREATE_DEAL_REQUEST]: createDealRequest,
  [Types.CREATE_DEAL_SUCCESS]: createDealSuccess,
  [Types.CREATE_DEAL_FAILURE]: createDealFailure,

  [Types.CREATE_DEAL_POST_REQUEST]: createDealPostRequest,
  [Types.CREATE_DEAL_POST_SUCCESS]: createDealPostSuccess,
  [Types.CREATE_DEAL_POST_FAILURE]: createDealPostFailure,

  [Types.DESTROY_DEAL_POST_REQUEST]: destroyDealPostRequest,
  [Types.DESTROY_DEAL_POST_SUCCESS]: destroyDealPostSuccess,
  [Types.DESTROY_DEAL_POST_FAILURE]: destroyDealPostFailure,

  [Types.RESET_DEALS]: resetDeals,
});
