/* eslint-disable global-require */

const images = {
  app: {
    stat: '/images/app/stats.svg',
    chart: '/images/app/chart.png',
    team: '/images/app/team.png',
    no_match: '/images/app/404.svg',
    unit_course_success: '/images/app/unit-course-success.png',
  },
  badge: {
    SOIN: '/icons/badge/badge-soin.svg',
    ERROR: '/icons/badge/badge-error.svg',
  },
  illu: {
    carenotebook_for_me: '/images/illu/illu_carenotebook_for_me.webp',
    carenotebook_for_helped: '/images/illu/illu_carenotebook_for_helped.webp',
    carenotebook_pro: '/images/illu/illu_carenotebook_pro.webp',
  },
  activity: {
    activity1: '/images/leagues/wello/activity1.jpg',
    activity2: '/images/leagues/wello/activity2.jpg',
    activity3: '/images/leagues/wello/activity3.jpg',
    activity4: '/images/leagues/wello/activity4.jpg',
    activity5: '/images/leagues/wello/activity5.jpg',
    activity6: '/images/leagues/wello/activity6.jpg',
    activity7: '/images/leagues/wello/activity7.jpg',
    activity8: '/images/leagues/wello/activity8.jpg',
    activity9: '/images/leagues/wello/activity9.jpg',
    activity10: '/images/leagues/wello/activity10.jpg',
    activity11: '/images/leagues/wello/activity11.jpg',
    activity12: '/images/leagues/wello/activity12.jpg',
    activity13: '/images/leagues/wello/activity13.jpg',
    activity14: '/images/leagues/wello/activity14.webp',
    activity15: '/images/leagues/wello/activity15.jpeg',
    activity16: '/images/leagues/wello/activity16.jpeg',
    activity17: '/images/leagues/wello/activity17.jpg',
    activity19: '/images/leagues/wello/activity19.jpg',
  },
  community: {
    community1: '/images/leagues/wello/community1.jpg',
    community2: '/images/leagues/wello/community2.jpg',
    community3: '/images/leagues/wello/community3.jpg',
    community4: '/images/leagues/wello/community4.jpg',
    community5: '/images/leagues/wello/community5.jpg',
  },
  lead: {
    lead1: '/images/leagues/wello/lead-avatar1@3x.png',
    lead2: '/images/leagues/wello/lead-avatar7@2x.png',
    lead3: '/images/leagues/wello/lead-avatar6@2x.png',
    lead4: '/images/leagues/wello/lead-avatar8@2x.png',
    lead5: '/images/leagues/wello/lead-avatar9@2x.png',
    lead6: '/images/leagues/wello/lead-avatar3@3x.png',
  },
  news: {
    news1: '/images/news/news1.jpg',
    news2: '/images/news/news2.jpg',
    news3: '/images/news/news3.jpg',
    news4: '/images/news/news4.jpg',
    news5: '/images/news/news5.jpg',
    news6: '/images/news/news6.jpg',
    news7: '/images/news/news7.jpg',
  },
  financements: {
    fin1: '/images/leagues/wello/fin1.jpg',
    fin2: '/images/leagues/wello/fin2.jpg',
    fin3: '/images/leagues/wello/fin3.jpg',
    fin4: '/images/leagues/wello/fin4.jpg',
    fin5: '/images/leagues/wello/fin5.jpg',
    fin6: '/images/leagues/wello/fin6.jpg',
    fin7: '/images/leagues/wello/fin7.jpg',
    fin8: '/images/leagues/wello/fin8.jpg',
    fin9: '/images/leagues/wello/fin9.jpg',
    fin10: '/images/leagues/wello/fin10.jpg',
    fin11: '/images/leagues/wello/fin11.jpg',
    fin12: '/images/leagues/wello/fin12.jpg',
    finEmpty: '/images/leagues/wello/finEmpty.jpg',
  },
  info: {
    info1: '/images/info/conge-proche-aidant.jpeg',
    info2: '/images/info/depression-personnes-agees.jpeg',
    info3: '/images/info/apa.jpeg',
    info4: '/images/info/covid-ehpad.png',
  },
  logo: {
    bousole: '/images/logos/bousole.svg',
    wello: '/images/logos/wello-grey.png',
    wello_circle: '/images/logos/wello-circle.png',
  },
  matrice: {
    aideadomicile: '/images/leagues/wello/matrice_aideadomicile.jpeg',
    loisirs: '/images/leagues/wello/matrice_loisirs.jpeg',
    quotidien: '/images/leagues/wello/matrice_quotidien.jpeg',
    santé: '/images/leagues/wello/matrice_santé.jpeg',
    bien_etre: '/images/leagues/wello/bien_etre.jpg',
  },
  wello: {
    home: '/images/leagues/wello/logo_wello.svg',
    partner: '/images/leagues/wello/partner_wello.svg',
    price: '/images/leagues/wello/price_all.png',
    partenaire_tarifs: '/images/leagues/wello/tarifs_partenaires.png',
    vivalab: '/images/leagues/wello/vivalab.png',
    logo_fit: '/images/logos/wello-fit.svg',
    partner_all: '/images/leagues/wello/partenaire_all.png',
    seo: '/images/leagues/wello/seo.jpg',
    jumbo: '/images/leagues/wello/jumbo.jpg',
    oss_jumbo: '/images/leagues/wello/jumbo_oss.svg',
    sub_jumbo: '/images/leagues/wello/hopital_numerique_du_futur.jpg',
    cta_jumbo: '/images/leagues/wello/jumbo_cta.jpg',
    why: '/images/leagues/wello/marguerite_.webp',
    feature1: '/images/leagues/wello/feat_vivez@2x.png',
    feature2: '/images/leagues/wello/feat_organisez@2x.png',
    feature3: '/images/leagues/wello/feat_rencontrez@2x.png',
    sponsor1: '/images/leagues/wello/aphp.png',
    sponsor2: '/images/leagues/wello/bpi.png',
    sponsor3: '/images/leagues/wello/chu.png',
    jumbo_laSanteNaPasDHoraire: '/images/leagues/wello/jumbo_laSanteNaPasDHoraire.jpg',
    jumbo_soignonsHumain: '/images/leagues/wello/jumbo_soignonsHumain.jpg',
    jumbo_confFam: '/images/leagues/wello/jumbo_confFam.jpg',
    jumbo_mobility: '/images/leagues/wello/jumbo_mobility.jpg',
    jumbo_hypocamp: '/images/leagues/wello/jumbo_hypocamp.jpg',
    jumbo_relaisdespetitsmayennais: '/images/leagues/wello/jumbo_relaisdespetitsmayennais.jpg',
    jumbo_carnetdeliaison: '/images/leagues/wello/jumbo_carnetDeLiaison.jpg',
    logo_soignonsHumain: '/images/leagues/wello/logo_soignonsHumain.jpg',
    logo_laSanteNaPasDHoraire: '/images/leagues/wello/logo_laSanteNaPasDHoraire.jpg',
    logo_confFam: '/images/leagues/wello/logo_europe.jpg',
    logo_mobility: '/images/leagues/wello/logo_empty.jpg',
    logo_hypocamp: '/images/leagues/wello/logo_hypocamp.jpg',
    logo_relaisdespetitsmayennais: '/images/leagues/wello/logo_relaisDesPetitMayennais.jpg',
    logo_carnetdeliaison: '/images/leagues/wello/logo_carnetdeliaison.jpg',
    attachment: '/images/leagues/wello/file.png',
    marker_icon_black: '/icons/marker-icon-black.png',
    marker_icon_red: '/icons/marker-icon-red.png',
    marker_icon_blue: '/icons/marker-icon-blue.png',
    marker_icon_gold: '/icons/marker-icon-gold.png',
    marker_icon_green: '/icons/marker-icon-green.png',
    marker_icon_grey: '/icons/marker-icon-grey.png',
    marker_icon_orange: '/icons/marker-icon-orange.png',
    marker_icon_violet: '/icons/marker-icon-violet.png',
    marker_icon_purple: '/icons/marker-icon-purple.png',
    marker_icon_yellow: '/icons/marker-icon-yellow.png',
    marker_icon_shadow: '/icons/marker-shadow.png',
    marker_icon_dot: '/icons/white-dot.svg',
    marker_icon_transp_dot: '/icons/transparent-dot.svg',
    no_timeline: '/images/app/no-timeline.svg',
    care_manager: '/images/leagues/wello/care_manager.svg',
    googlePlayBadge: '/images/leagues/wello/google_play_badge.svg',
    appStoreBadge: '/images/leagues/wello/app_store_badge.svg',
    orga_empty_banner: '/images/leagues/wello/organisation_empty.png',
    habitat: '/images/leagues/wello/habitat.jpeg',
    ehpad: '/images/leagues/wello/ehpad-domicile.jpg',
    care: '/images/leagues/wello/care.jpg',
    retour_domicile: '/images/leagues/wello/retour-domicile.jpg',
    relayage: '/images/leagues/wello/relayage.jpg',
    pair_aidance: '/images/leagues/wello/pair_aidance.jpeg',
    impact: '/images/leagues/wello/impact.jpg',
    data_science: '/images/leagues/wello/data_science.jpg',
    open_source: '/images/leagues/wello/open_source.jpg',
    programme_recherche: '/images/leagues/wello/recherche_.webp',
    recherche_logo: '/images/leagues/wello/recherche_logo.webp',
    illu_aidant: '/images/leagues/wello/illu-aidant.webp',
    illu_pro: '/images/leagues/wello/illu-pro.webp',
    illu_establishment: '/images/leagues/wello/illu_establishment.webp',
    illu_partner: '/images/leagues/wello/illu_partner.webp',
    esus: '/images/leagues/wello/esus.svg',
  },
  una: {
    home: '/images/leagues/una/home.png',
    logo: '/images/leagues/una/logo.svg',
    partner1: '/images/leagues/una/partner1.png',
    partner2: '/images/leagues/una/partner2.png',
    logo_circle: '/images/leagues/una/logo_circle.jpg',
    seo: '/images/leagues/una/seo.jpg',
    jumbo: '/images/leagues/una/besoin_aide_a_domicile.jpg',
    sub_jumbo: '/images/leagues/una/besoin_aide_a_domicile_service_course.jpg',
    benefit: '/images/leagues/una/besoin_aide_a_domicile_service_menage.jpg',
    service: '/images/leagues/una/besoin_aide_a_domicile_service_repassage.jpg',
    benefit_card_1: '/images/leagues/una/valeur_humain.svg',
    benefit_card_2: '/images/leagues/una/valeur_professionnalisme.svg',
    benefit_card_3: '/images/leagues/una/valeur_reactivite.svg',
    portrait_1: '/images/leagues/una/aide_a_domicile_portrait_aurelie.jpg',
    portrait_2: '/images/leagues/una/aide_a_domicile_portrait_rozenn.jpg',
    portrait_3: '/images/leagues/una/aide_a_domicile_portrait_david.jpg',
    portrait_4: '/images/leagues/una/aide_a_domicile_portrait_anne_sophie.jpg',
    value_1: '/images/leagues/una/auxiliaire_de_vie_valeur_un_reseau_humain.svg',
    value_2: '/images/leagues/una/auxiliaire_de_vie_valeur_donner_du_sens.svg',
    value_3: '/images/leagues/una/auxiliaire_de_vie_valeur_etre_autonome.svg',
    value_4: '/images/leagues/una/auxiliaire_de_vie_valeur_se_sentir_bien.svg',
    value_5: '/images/leagues/una/auxiliaire_de_vie_valeur_parcours_pro.svg',
    value_6: '/images/leagues/una/auxiliaire_de_vie_valeur_competances.svg',
    value_7: '/images/leagues/una/auxiliaire_de_vie_valeur_valeurs_associatives.svg',
    value_8: '/images/leagues/una/auxiliaire_de_vie_valeur_reseau_innovant.svg',
    job: '/images/leagues/una/auxiliaire_de_vie_planning.png',
    avatar_pro_1: '/images/leagues/una/aide_a_domicile_circle_veronique.jpg',
    avatar_pro_2: '/images/leagues/una/aide_a_domicile_circle_thomas.jpg',
    avatar_pro_3: '/images/leagues/una/aide_a_domicile_circle_barbara.jpg',
    aide_au_repas: '/images/leagues/una/besoin_aide_a_domicile_service_repas.jpg',
    team: '/images/leagues/una/aide_a_domicile_team.jpg',
  },
  scalaa: {
    home: '/images/leagues/scalaa/logo.png',
    logo: '/images/leagues/scalaa/logo.svg',
    logo_circle: '/images/leagues/scalaa/logo_circle.jpg',
    seo: '/images/leagues/scalaa/seo.jpg',
    jumbo: '/images/leagues/scalaa/jumbo.jpg',
    sub_jumbo: '/images/leagues/scalaa/jumbo_cta.jpg',
    benefit: '/images/leagues/scalaa/homepage_why.jpg',
    service: '/images/leagues/scalaa/homepage_service.jpg',
    benefit_card_1: '/images/leagues/wello/landing_benefit_10.svg',
    benefit_card_2: '/images/leagues/wello/landing_benefit_9.svg',
    benefit_card_3: '/images/leagues/wello/landing_benefit_2.svg',
    benefit_card_4: '/images/leagues/wello/landing_benefit_4.svg',
    activity_1: '/images/leagues/scalaa/activity_restaurant.png',
    activity_2: '/images/leagues/scalaa/activity_pinceau.png',
    activity_3: '/images/leagues/scalaa/activity_violon.png',
    activity_4: '/images/leagues/scalaa/activity_foret.png',
    activity_5: '/images/leagues/scalaa/activity_esthetique.png',
    activity_6: '/images/leagues/scalaa/activity_gouter.png',
    value_1: '/images/leagues/scalaa/pro_ico_1.svg',
    value_2: '/images/leagues/scalaa/pro_ico_2.svg',
    value_3: '/images/leagues/scalaa/pro_ico_3.svg',
    avatar_customer_1: '/images/leagues/scalaa/circle_marie.png',
    avatar_customer_2: '/images/leagues/wello/circle_avatar1.png',
    avatar_customer_3: '/images/leagues/scalaa/circle_charlotte.png',
    avatar_customer_4: '/images/leagues/wello/circle_avatar5.png',
    avatar_customer_5: '/images/leagues/wello/circle_avatar3.png',
    avatar_customer_6: '/images/leagues/wello/circle_avatar4.png',
    avatar_customer_7: '/images/leagues/wello/circle_avatar5.png',
    avatar_customer_8: '/images/leagues/wello/circle_avatar6.png',
    avatar_pro_1: '/images/leagues/scalaa/circle_patrick.png',
    avatar_pro_2: '/images/leagues/wello/circle_avatar2.png',
    avatar_pro_3: '/images/leagues/scalaa/circle_ThiThuThao.png',
    covid: '/images/leagues/scalaa/covid.jpg',
  },
  mormal: {
    home: '/images/leagues/mormal/logo.png',
    logo: '/images/leagues/mormal/logo_circle.png',
    partner: '/images/leagues/mormal/partner.png',
    partner2: '/images/leagues/mormal/partner2.png',
    partner3: '/images/leagues/mormal/partner3.png',
    logo_circle: '/images/leagues/mormal/logo_circle.png',
    seo: '/images/leagues/mormal/seo.jpg',
    jumbo: '/images/leagues/mormal/jumbo.jpg',
    sub_jumbo: '/images/leagues/mormal/jumbo_cta.jpg',
    benefit: '/images/leagues/mormal/homepage_why.jpg',
    service: '/images/leagues/mormal/homepage_service.jpg',
    benefit_card_1: '/images/leagues/mormal/landing_benefit_1.svg',
    benefit_card_2: '/images/leagues/mormal/landing_benefit_2.svg',
    benefit_card_3: '/images/leagues/mormal/landing_benefit_3.svg',
    choice_card_1: '/images/leagues/mormal/landing_choice_1.svg',
    choice_card_2: '/images/leagues/mormal/landing_choice_2.svg',
    choice_card_3: '/images/leagues/mormal/landing_choice_3.svg',
    choice_card_4: '/images/leagues/mormal/landing_choice_4.svg',
    sponsor1: '/images/leagues/mormal/sponsor1.jpg',
    sponsor2: '/images/leagues/mormal/sponsor2.jpg',
    passpass: '/images/leagues/mormal/transport_passpass.png',
    jumbo_activity: '/images/leagues/mormal/jumbo_activity.jpg',
    activity: '/images/leagues/mormal/activity.png',
  },
  sla: {
    home: '/images/leagues/sla/logo-fpfr.png',
    logo: '/images/leagues/sla/logo_fond_blanc.svg',
    banner: '/images/leagues/sla/banner.jpg',
    seo: '/images/leagues/sla/seo.jpg',
    jumbo: '/images/leagues/sla/jumbo_.jpg',
    jumboSearchResults: '/images/leagues/sla/jumbo_search_results.svg',
    infographie: '/images/leagues/sla/infographie_sla.png',
    jumbo_information: '/images/leagues/sla/info_jumbo.svg',
    info: '/images/leagues/sla/info.webp',
    jumbo_repit: '/images/leagues/sla/jumbo_repit.svg',
    repit: '/images/leagues/sla/repit_.webp',
    avatar_user_1: '/images/leagues/wello/avatar12.jpg',
    avatar_user_2: '/images/leagues/wello/lissoune_toure.jpg',
    avatar_user_3: '/images/leagues/wello/avatar10.jpg',
    solution_repit: '/images/leagues/sla/sejour_enfants_repit.jpeg',
    bulle_air: '/images/leagues/sla/bulledair.webp',
    vrf: '/images/leagues/sla/sejour_vrf.jpg',
    vrt: '/images/leagues/sla/vrt.jpg',
    jumbo_soutien: '/images/leagues/sla/jumbo_soutien.svg',
    soutien: '/images/leagues/sla/soutien.webp',
    club: '/images/leagues/sla/club.webp',
    jumbo_activity: '/images/leagues/sla/jumbo_activity.svg',
    activity: '/images/leagues/sla/activity_pa_ph.webp',
    calendar: '/images/leagues/sla/planning_sla.png',
    entraide: '/images/leagues/sla/entraide.webp',
    partner: '/images/leagues/sla/partner_logo_sla.jpg',
    ars: '/images/leagues/sla/ars-logo.png',
    malakoff: '/images/leagues/sla/malakoff_humanis_logo.png',
    msa: '/images/leagues/sla/msa-logo.png',
    aesio: '/images/leagues/sla/aesio-logo.png?v2',
    cnsa: '/images/leagues/sla/cnsa_logo.jpg',
    afnic: '/images/leagues/sla/afnic.jpg',
  },
  ccmormal: {
    home: '/images/leagues/ccmormal/logo.png',
    hero: '/images/leagues/ccmormal/header.jpg',
    logo: '/images/leagues/ccmormal/logo.jpg',
    seo: '/images/leagues/ccmormal/seo.jpg',
    jumbo: '/images/leagues/ccmormal/jumbo.svg',
    jumboSearchResults: '/images/leagues/ccmormal/jumbo.svg',
    banner: '/images/leagues/ccmormal/banner.jpg',
    benefit: '/images/leagues/ccmormal/benefit_mormal.jpg',
    jumbo_activity: '/images/leagues/ccmormal/jumbo-activity.svg',
    jumbo_homecare: '/images/leagues/ccmormal/jumbo-homecare.svg',
    jumbo_peoplecare: '/images/leagues/ccmormal/jumbo_peoplecare.svg',
    jumbo_healthcare: '/images/leagues/ccmormal/jumbo_healthcare.svg',
    activity: '/images/leagues/ccmormal/calendar.webp',
    homecare: '/images/leagues/ccmormal/homecare.webp',
    peoplecare: '/images/leagues/ccmormal/peoplecare.webp',
    healthcare: '/images/leagues/ccmormal/club.webp',
    home_activity: '/images/leagues/ccmormal/activityhome.jpg',
    community: '/images/leagues/ccmormal/community.webp',
    course: '/images/leagues/ccmormal/course.jpg',
    sortie: '/images/leagues/ccmormal/sortie.jpg',
    visite: '/images/leagues/ccmormal/visite.jpg',
  },
  diapason: {
    home: '/images/leagues/diapason/logo.svg',
    seo: '/images/leagues/diapason/seo.jpg',
    jumbo: '/images/leagues/diapason/jumbo_.jpg',
    ehpad_domicile: '/images/leagues/diapason/ehpad_domicile.jpg',
    personne_agee: '/images/leagues/diapason/personne_agee.jpg',
    infographie: '/images/leagues/diapason/schema.png',
    partner: '/images/leagues/diapason/logo_diapason.svg',
    departement: '/images/leagues/diapason/departement.png',
    ars: '/images/leagues/diapason/ars.png',
    gcsms: '/images/leagues/diapason/gcsms.png',
    aulagnier: '/images/leagues/diapason/aulagnier.png',
    mobile3: '/images/leagues/diapason/5,5po-3.png',
    mobile4: '/images/leagues/diapason/5,5po-4-2.png',
    mobile6: '/images/leagues/diapason/5,5po-6.png',
    mobile7: '/images/leagues/diapason/5,5po-7.png',
    mobile8: '/images/leagues/diapason/5,5po-8.png',
  },
  reprise: {
    home: '/images/leagues/reprise/logo.png',
    seo: '/images/leagues/reprise/seo.jpg',
    jumbo: '/images/leagues/reprise/jumbo_reprise.jpg',
    wishes: '/images/leagues/reprise/wishes.jpg',
    video: '/images/leagues/reprise/video.webp',
    planning: '/images/leagues/reprise/planning_reprise.webp',
    sociogramme: '/images/leagues/reprise/sociogramme.webp',
    partner1: '/images/leagues/reprise/logo_interreg.png',
    partner2: '/images/leagues/reprise/logo_nord.png',
    partner3: '/images/leagues/reprise/logo_irts.png',
    wishCard: '/images/leagues/reprise/wish_card.webp',
    avatar1: '/images/leagues/wello/lead-avatar8@2x.png',
    avatar2: '/images/leagues/wello/lead-avatar9@2x.png',
    avatar3: '/images/leagues/wello/lead-avatar3@3x.png',
  },
  cd85: {
    home: '/images/leagues/cd85/logo_av.webp',
    seo: '/images/leagues/cd85/seo.jpg',
    jumbo: '/images/leagues/cd85/jumbo.jpg',
    logo: '/images/leagues/cd85/logo.jpg',
    banner: '/images/leagues/cd85/banner.jpg',
    logo_nda: '/images/leagues/cd85/logo_nda.png',
    illu: '/images/leagues/cd85/illu_vendee_.webp',
    repit: '/images/leagues/cd85/repit_.webp',
    soutien_psy: '/images/leagues/cd85/soutien_psy_.webp',
    avatar_user_1: '/images/leagues/wello/avatar13.jpg',
    avatar_user_2: '/images/leagues/wello/avatar11.jpg',
    avatar_user_3: '/images/leagues/wello/avatar10.jpg',
    logo_lna: '/images/leagues/cd85/logo_lna.png',
    logo_msa: '/images/leagues/cd85/logo_msa.png',
    activity: '/images/leagues/cd85/activity_section.webp',
    soutien: '/images/leagues/cd85/soutien_.webp',
    course: '/images/leagues/cd85/course_.webp',
  },
  hapicoop: {
    home: '/images/leagues/hapicoop/logo_hapicoop_.jpg',
  },
  chb: {
    home: '/images/leagues/chb/logo_chb.png',
    seo: '/images/leagues/chb/seo.jpg',
    jumbo: '/images/leagues/chb/jumbo_6706.jpg',
    pathologies: '/images/leagues/chb/pathologies.jpg',
    administratif: '/images/leagues/chb/administratif.jpg',
    vie_aidant: '/images/leagues/chb/vie_aidant.jpg',
    simulateur: '/images/leagues/chb/simulateur.webp',
    pro: '/images/leagues/chb/pro.webp',
    activity: '/images/leagues/chb/activity_6643.webp',
    sea: '/images/leagues/chb/sea.jpg',
    mountains: '/images/leagues/chb/mountains.jpg',
    forest: '/images/leagues/chb/forest.jpg',
    lack: '/images/leagues/chb/lack.jpg',
    volunteer_help: '/images/leagues/chb/volunteer_6739.jpg',
    pro_help: '/images/leagues/chb/pro_6789.jpg',
    repit: '/images/leagues/chb/repit_6722.jpg',
    partner: '/images/leagues/chb/chb_partner.webp',
  },
  global: {
    home: '/images/leagues/global/logo_global.png',
    seo: '/images/leagues/global/seo.jpg',
    jumbo: '/images/leagues/global/jumbo.jpg',
    hero: '/images/leagues/global/hero.webp',
    who: '/images/leagues/global/who.webp',
    accompagner: '/images/leagues/global/accompagner.webp',
    soigner: '/images/leagues/global/soigner.webp',
    divertir: '/images/leagues/global/divertir.webp',
    deplacer: '/images/leagues/global/deplacer.webp',
    former: '/images/leagues/global/former.webp',
    demarche: '/images/leagues/global/demarche.jpg',
    partner: '/images/leagues/global/bm_partner.webp',
  },
  ne: {
    home: '/images/leagues/ne/logo-nantes-erdre.png',
    seo: '/images/leagues/ne/seo.jpg',
    jumbo: '/images/leagues/ne/jumbo.jpeg',
    why: '/images/leagues/ne/why.svg',
    deplacement: '/images/leagues/ne/transport.svg',
    partner: '/images/leagues/ne/manou_partner.webp',
  },
  soss: {
    home: '/images/leagues/soss/logo.jpg',
    seo: '/images/leagues/soss/seo.jpg',
    jumbo: '/images/leagues/soss/jumbo.jpg',
    who: '/images/leagues/soss/lux.jpg',
    marguerite: '/images/leagues/soss/marguerite1.jpg',
    tools: '/images/leagues/soss/interface.jpg',
    pictome: '/images/leagues/soss/logo_pictome.webp',
    soss: '/images/leagues/soss/logo_soss.webp',
  },
  carenotebook: {
    hero: '/images/app/carenotebook-hero.svg',
    jumbo: '/images/app/jumbo.svg',
    benefit: '/images/app/carenotebook-selfie.jpg',
    feature1: '/images/app/carnet_feature1.svg',
    feature2: '/images/app/carnet_feature2.svg',
    feature3: '/images/app/carnet_feature3.svg',
    how1: '/images/app/carnet_how1.svg',
    how2: '/images/app/carnet_how2.svg',
    how3: '/images/app/carnet_how3.svg',
  },
  offers: {
    securite1: '/images/app/offers/1-securite.jpg',
    securite2: '/images/app/offers/2-securite.jpg',
    securite3: '/images/app/offers/3-securite.jpg',
    helper: {
      jumbo: '/images/app/offer_helper/jumbo.svg',
      jumbo2: '/images/app/offer_helper/jumbo_feature.svg',
      jumbo_illustration: '/images/app/offer_helper/jumbo_illustration.png',
      feature1: '/images/app/offer_helper/feature_1.jpg',
      feature2: '/images/app/offer_helper/feature_2.jpg',
      feature3: '/images/app/offer_helper/feature_3.jpg',
      main_gain: '/images/app/offer_helper/main_gain_.webp',
      fonc1: '/images/app/offer_helper/fonc1.png',
      fonc2: '/images/app/offer_helper/fonc2.png',
      fonc3: '/images/app/offer_helper/fonc3.png',
    },
    pro: {
      jumbo: '/images/app/offer_pro/jumbo.svg',
      jumbo2: '/images/app/offer_pro/jumbo_feature.svg',
      jumbo_illustration: '/images/app/offer_pro/jumbo_illustration.png',
      appfonc1: '/images/app/offer_pro/fonc1.png',
      appfonc2: '/images/app/offer_pro/fonc2.png',
      appfonc3: '/images/app/offer_pro/fonc3.png',
      feature1: '/images/app/offer_pro/feature_1.jpg',
      feature2: '/images/app/offer_pro/feature_2.jpg',
      feature3: '/images/app/offer_pro/feature_3.jpg',
      feature6: '/images/app/offer_pro/feature_6.jpg',
    },
    care: {
      jumbo: '/images/app/offer_care/jumbo.webp',
      matrice_feature1: '/images/app/offer_care/feature1.webp',
      matrice_feature2: '/images/app/offer_care/feature2.webp',
      matrice_feature3: '/images/app/offer_care/feature3.webp',
      matrice_feature4: '/images/app/offer_care/feature4.webp',
      feature1: '/images/app/offer_care/wish.webp',
      feature2: '/images/app/offer_care/carenotebook.webp',
      feature3: '/images/app/offer_care/sociogramme.webp',
      feature4: '/images/app/offer_care/planning.webp',
      blue_jumbo: '/images/app/offer_care/jumbo.svg',
      matrice: '/images/app/offer_care/matrice.webp',
      selfie: '/images/app/offer_care/selfie.webp',
      daily_carenotebook: '/images/app/offer_care/daily_carenotebook.webp',
      benefice_pro: '/images/app/offer_care/benefice_pro.webp',
      benefice_primary: '/images/app/offer_care/benefice_primary.webp',
      benefice_helper: '/images/app/offer_care/benefice_helper.webp',
      tool1: '/images/app/offer_care/tool1.webp',
      tool2: '/images/app/offer_care/tool2.webp',
      tool3: '/images/app/offer_care/tool3.webp',
      tool4: '/images/app/offer_care/tool4.webp',
      tool5: '/images/app/offer_care/tool5.webp',
      tool6: '/images/app/offer_care/tool6.webp',
    },
    partner: {
      jumbo: '/images/app/offer_partner/jumbo.svg',
      jumbo2: '/images/app/offer_partner/jumbo2.svg',
      jumbo_illustration: '/images/app/offer_partner/jumbo_illustration.png',
      main_screen: '/images/app/offer_partner/main_screen.jpg',
      techfonc1: '/images/app/offer_partner/fonc1.png',
      techfonc2: '/images/app/offer_partner/fonc2.png',
      techfonc3: '/images/app/offer_partner/fonc3.png',
      techfonc4: '/images/app/offer_partner/fonc4.png',
      techfonc5: '/images/app/offer_partner/fonc5.png',
      techfonc6: '/images/app/offer_partner/fonc6.png',
      feature1: '/images/app/offer_partner/feature_1.jpg',
      feature2: '/images/app/offer_partner/feature_2.jpg',
      matrice: '/images/app/offer_partner/matrice.jpg',
      partner1: '/images/app/offer_partner/partner1_.png',
      partner2: '/images/app/offer_partner/partner2_.png',
      partner3: '/images/app/offer_partner/partner3_.png',
      partner4: '/images/app/offer_partner/partner4_.png',
      partner5: '/images/app/offer_partner/partner5_.png',
      feature3: '/images/app/offer_partner/feature_3.jpg',
      feature4: '/images/app/offer_partner/feature_4.jpg',
      feature5: '/images/app/offer_partner/feature_5.jpg',
      feature6: '/images/app/offer_partner/feature_6.jpg',
      feature7: '/images/app/offer_partner/feature_7.jpg',
      feature8: '/images/app/offer_partner/feature_8.jpg',
      icones: '/images/app/offer_helper/icones.svg',
    },
    establishment: {
      jumbo_illustration: '/images/app/offer_establishment/jumbo_illustration.webp',
      tool1: '/images/app/offer_establishment/tool1.webp',
      tool2: '/images/app/offer_establishment/tool2.webp',
      tool3: '/images/app/offer_establishment/tool3.webp',
      tool4: '/images/app/offer_establishment/tool4.webp',
      tool5: '/images/app/offer_establishment/tool5.webp',
      tool6: '/images/app/offer_establishment/tool6.webp',
      ref1: '/images/app/offer_establishment/ref_national1.png',
      ref2: '/images/app/offer_establishment/ref_national2.png',
      ref3: '/images/app/offer_establishment/ref_national3.png',
      ref4: '/images/app/offer_establishment/ref_national4.png',
      ref5: '/images/app/offer_establishment/ref_national5.png',
      feature4: '/images/app/offer_establishment/feature_4.jpg',
      feature5: '/images/app/offer_establishment/feature_5.jpg',
      feature6: '/images/app/offer_establishment/feature_6.webp',
      jumbo2: '/images/app/offer_establishment/jumbo_feature.svg',
      feature7: '/images/app/offer_establishment/feature7.jpeg',
      actionfonc1: '/images/app/offer_establishment/fonc4_.png',
      actionfonc2: '/images/app/offer_establishment/fonc5_.png',
      actionfonc3: '/images/app/offer_establishment/fonc6_.png',
    },
    retirement: {
      jumbo_illustration: '/images/app/offer_retirement/header_ehpad_demain.jpg',
      item1: '/images/app/offer_retirement/item_1_ehpad_demain.jpg',
      item2: '/images/app/offer_retirement/item_2_ehpad_demain.jpg',
      item3: '/images/app/offer_retirement/item_3_ehpad_demain.jpg',
      security: '/images/app/offer_retirement/securite-ehpad_demain.png',
      device: '/images/app/offer_retirement/device-ehpad_demain.png',
      rex1: '/images/app/offer_retirement/rex_1-ehpad_demain.jpg',
      rex2: '/images/app/offer_retirement/rex_2-ehpad_demain.jpg',
      rex3: '/images/app/offer_retirement/rex_3-ehpad_demain.jpg',
    },
    residence: {
      jumbo_illustration: '/images/app/offer_residence/header_habitat_inclusif.jpg',
      item1: '/images/app/offer_residence/item_1_habitat_inclusif.jpg',
      item2: '/images/app/offer_residence/item_2_habitat_inclusif.jpg',
      item3: '/images/app/offer_residence/item_3_habitat_inclusif.jpg',
      item4: '/images/app/offer_residence/item_4_habitat_inclusif.jpg',
      security: '/images/app/offer_residence/securite-habitat_inclusif.png',
      device: '/images/app/offer_residence/device_habitat_inclusif.png',
      rex1: '/images/app/offer_residence/rex_1-habitat_inclusif.jpg',
      rex2: '/images/app/offer_residence/rex_2-habitat_inclusif.jpg',
    },
    helperhelp: {
      jumbo_illustration: '/images/app/offer_helper_help/header_aide_aidants.jpg',
      item1: '/images/app/offer_helper_help/item_1_aide_aidants.jpg',
      item2: '/images/app/offer_helper_help/item_2_aide_aidants.jpg',
      item3: '/images/app/offer_helper_help/item_3_aide_aidants.jpg',
      item4: '/images/app/offer_helper_help/item_4_aide_aidants.jpg',
      security: '/images/app/offer_helper_help/securite-aide_aidants.png',
      device: '/images/app/offer_helper_help/device_aide_aidants.png',
      rex1: '/images/app/offer_helper_help/rex_1-aide_aidants.jpg',
      rex2: '/images/app/offer_helper_help/rex_2-aide_aidants.jpg',
      rex3: '/images/app/offer_helper_help/rex_3-aide_aidants.jpg',
    },
    helpers: {
      jumbo_illustration: '/images/app/offer_helpers/header_offre_aidants.jpg',
      item1: '/images/app/offer_helpers/item_1_offre_aidants.jpg',
      item2: '/images/app/offer_helpers/item_2_offre_aidants.jpg',
      item3: '/images/app/offer_helpers/item_3_offre_aidants.jpg',
      security: '/images/app/offer_helpers/securite-offre_aidants.png',
      device: '/images/app/offer_helpers/device_offre_aidant.png',
      rex1: '/images/app/offer_helpers/rex_1-offre_aidant.jpg',
      rex2: '/images/app/offer_helpers/rex_2-offre_aidant.jpg',
    },
    homecare: {
      jumbo_illustration: '/images/app/offer_home_care/header-medico_social.jpg',
      item1: '/images/app/offer_home_care/item_1-medico_social.jpg',
      item2: '/images/app/offer_home_care/item_2-medico_social.jpg',
      item3: '/images/app/offer_home_care/item_3-medico_social.jpg',
      item4: '/images/app/offer_home_care/item_4-medico_social.jpg',
      security: '/images/app/offer_home_care/securite-medico_social.png',
      device: '/images/app/offer_home_care/device-medico_social.png',
      rex1: '/images/app/offer_home_care/rex_1-medico_social.jpg',
    },
  },
  partner: {
    fin1: '/images/app/partner/ref_fin_1@2x.png',
    fin2: '/images/app/partner/ref_fin_2@2x.png',
    fin3: '/images/app/partner/ref_fin_3@2x.png',
    fin4: '/images/app/partner/ref_fin_4@2x.png',
    fin5: '/images/app/partner/ref_fin_5@2x.png',
    fin6: '/images/app/partner/ref_fin_6@2x.png',
    fin7: '/images/app/partner/ref_fin_7@2x.png',
    fin8: '/images/app/partner/ref_fin_8@2x.png',
    fin9: '/images/app/partner/ref_fin_9@2x.png',
    fin10: '/images/app/partner/ref_fin_10@2x.png',
    rd1: '/images/app/partner/ref_rd_1@2x.png',
    rd2: '/images/app/partner/ref_rd_2@2x.png',
    rd3: '/images/app/partner/ref_rd_3@2x.png',
    rd4: '/images/app/partner/ref_rd_4@2x.png',
    rd5: '/images/app/partner/ref_rd_5@2x.png',
    rd6: '/images/app/partner/ref_rd_6@2x.png',
    other1: '/images/app/partner/ref_other_1@2x.png',
    other2: '/images/app/partner/ref_other_2@2x.png',
    other3: '/images/app/partner/ref_other_3@2x.png',
    other4: '/images/app/partner/ref_other_4@2x.png',
    other5: '/images/app/partner/ref_other_5@2x.png',
    other6: '/images/app/partner/ref_other_6@2x.png',
    other7: '/images/app/partner/ref_other_7@2x.png',
    other8: '/images/app/partner/ref_other_8@2x.png',
    user1: '/images/app/partner/ref_user_1_.png',
    user2: '/images/app/partner/ref_user_2_.png',
    user3: '/images/app/partner/ref_user_3_.png',
    user4: '/images/app/partner/ref_user_4_.png',
    user5: '/images/app/partner/ref_user_5_.png',
    user6: '/images/app/partner/ref_user_6_.png',
    user7: '/images/app/partner/ref_user_7_.png',
    user8: '/images/app/partner/ref_user_8_.png',
    user9: '/images/app/partner/ref_user_9_.png',
    user10: '/images/app/partner/ref_user_10_.png',
    user11: '/images/app/partner/ref_user_11_.png',
    user12: '/images/app/partner/ref_user_12_.png',
  },
  emoji: {
    rate0: '/images/emojis/rate0.png',
    rate1: '/images/emojis/rate1.png',
    rate2: '/images/emojis/rate2.png',
    rate3: '/images/emojis/rate3.png',
  },
};

export default images;
