/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { Router, StaticRouter } from 'react-router-dom';

import history from './CurrentHistory';
import { isClientSide } from '../Config/ServerConfig';

type Props = { children: any, context: Object };

function DynamicRouter(props: Props) {
  if (isClientSide()) {
    return (
      <Router basename="/" history={history}>
        {props.children}
      </Router>
    );
  }
  return (
    <StaticRouter location={global.url} context={props.context}>
      {props.children}
    </StaticRouter>
  );
}

export default DynamicRouter;
