import { put } from 'redux-saga/effects';

import AuthActions from '../Redux/AuthRedux';
import ToasterActions from '../Redux/ToasterRedux';

export default function* startup(api, { store }) {
  const states = store.getState();
  const { isLoggedIn, authToken } = states.auth;

  yield put(ToasterActions.hideToaster());

  if (isLoggedIn) {
    yield put(AuthActions.setAuthTokenRequest(authToken));
  }
}
