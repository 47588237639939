import React from 'react';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';

type Props = {
  location: Object,
  children: Object,
};

class ScrollManager extends React.Component<Props> {
  componentDidUpdate(prevProps) {
    const {
      location: { pathname: currentLocationPathName, hash },
    } = this.props;
    const {
      location: { pathname: prevLocationPathName },
    } = prevProps;

    if (currentLocationPathName !== prevLocationPathName) {
      const key = hash?.substr(1);
      const scrollTo = _.get(document.getElementById(key), 'offsetTop') || 0;
      window.scrollTo(0, scrollTo);
    }
  }

  render() {
    const { children } = this.props;
    return children;
  }
}

export default withRouter(ScrollManager);
