import React from 'react';
import { useTranslation } from 'react-i18next';

import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CardActionArea from '@material-ui/core/CardActionArea';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { Link, useHistory } from 'react-router-dom';
import Routes from '../../../Routing/Routes';
import WelloAvatar from '../../Shared/WelloAvatar';
import Images from '../../../Themes/Images';
import { getCdnUrl } from '../../../Services/ImageResizer';

const useStyles = makeStyles(theme => ({
  contentCard: {
    objectFit: 'cover',
  },
  contentService: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 0,
    paddingBottom: 15,
    minHeight: 50,
  },
  contentHelpers: {
    justifyContent: 'flex-end',
    display: 'flex',
    paddingTop: theme.spacing(2),
    paddingBottom: '16px !important',
  },
  media: {
    objectFit: 'cover',
  },
  avatar: {
    margin: '-70px auto 0px auto',
    width: 70,
    height: 70,
    border: '2px solid #fff',
  },
  service: {
    display: 'inline-flex',
    margin: 'auto 1px auto 1px',
    width: 30,
    height: 30,
  },
  headerHoldingName: {
    fontWeight: 300,
    fontSize: '1.3rem',
    display: '-webkit-box',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: '1',
    maxHeight: '3.8em',
    whiteSpace: 'normal',
    marginBottom: 0,
  },
  headerName: {
    fontWeight: 600,
    display: '-webkit-box',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: '1',
    maxHeight: '3.8em',
    whiteSpace: 'normal',
    marginBottom: 10,
  },
  city: {
    height: '40px',
    display: '-webkit-box',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: '2',
    maxHeight: '4.8em',
    whiteSpace: 'normal',
  },
  cardActions: {
    textAlign: 'center',
  },
  description: {
    display: '-webkit-box',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: '3',
    height: '55px',
    maxHeight: '5.8em',
    whiteSpace: 'normal',
  },
  helpers: {
    display: 'flex',
    alignSelf: 'center',
    // '&:first-child': {
    //   marginLeft: 0,
    // },
  },
  helperAvatar: {
    marginLeft: '-20px',
    // display: 'inline-block',
    width: 35,
    height: 35,
    fontSize: '14px',
    border: '3px solid #fff',
  },
  procount: {
    alignSelf: 'center',
    position: 'absolute',
    left: theme.spacing(2),
    fontSize: '0.8rem',
  },
}));

type Props = {
  organisation: Object,
  hideMembers: Boolean,
};

const CardOrganisation = ({ organisation, hideMembers }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const avatarMore = organisation.sampled_helpers?.length - 5;

  const renderOrganisation = () => {
    return (
      <>
        <Card className={classes.contentCard}>
          <CardMedia
            component="img"
            className={classes.media}
            height="120"
            src={getCdnUrl(organisation.banner_url, 280, 120) || Images.wello.orga_empty_banner}
            key={organisation.id}
          />
          <CardContent>
            {(organisation.holding?.cover_url || organisation.holding_cover_url) && (
              <WelloAvatar
                avatarUrl={organisation.holding?.cover_url || organisation.holding_cover_url}
                avatarClass={classes.avatar}
                size={70}
              />
            )}
            <Typography
              variant="h4"
              component="h3"
              className={classes.headerHoldingName}
              align="center"
              gutterBottom
            >
              {organisation.holding?.name || organisation.holding_name}
            </Typography>
            <Typography
              variant="h6"
              component="h3"
              className={classes.headerName}
              align="center"
              gutterBottom
            >
              {organisation.name}
            </Typography>
            {organisation.holding?.short_description && (
              <Typography
                variant="body2"
                align="center"
                className={classes.description}
                itemProp="description"
                color="textSecondary"
                gutterBottom
              >
                {organisation.holding?.short_description?.replace(/<[^>]*>/g, '') || ''}
              </Typography>
            )}
          </CardContent>
          {organisation.link && (
            <CardContent className={classes.contentService}>
              <Button
                variant="outlined"
                size="small"
                onClick={() => history.push(organisation.link)}
              >
                {t('CONSULT')}
              </Button>
            </CardContent>
          )}
          {(organisation.helper_count > 0 || organisation.sampled_helpers?.length > 0) && (
            <CardContent className={classes.contentHelpers}>
              {organisation.helper_count > 0 && !hideMembers && (
                <Typography
                  variant="body2"
                  align="left"
                  className={classes.procount}
                  color="textSecondary"
                >
                  {t('APP.ORGANISATION.HOME.LABEL.Organisations.PRO', {
                    context: organisation.helper_count > 1 && 'MANY',
                    pro_count: organisation.helper_count,
                  })}
                </Typography>
              )}
              {organisation.sampled_helpers?.length > 0 && (
                <div className={classes.helpers}>
                  {/* HELPERS */}
                  {organisation.sampled_helpers.map(helper => (
                    <WelloAvatar
                      key={helper.avatar_url}
                      avatarUrl={helper.avatar_url}
                      firstName={helper.first_name}
                      lastName={helper.last_name}
                      backgroundColor={helper.avatar_background_color}
                      avatarClass={classes.helperAvatar}
                      size={36}
                    />
                  ))}
                  {avatarMore > 0 && (
                    <Avatar className={classes.helperAvatar}>+{avatarMore}</Avatar>
                  )}
                </div>
              )}
            </CardContent>
          )}
        </Card>
      </>
    );
  };

  return (
    <Card>
      {!organisation.link && (
        <CardActionArea component={Link} to={Routes.organisationHomePath(organisation)}>
          <meta itemProp="url" content={Routes.organisationHomePath(organisation)} />
          {renderOrganisation()}
        </CardActionArea>
      )}
      {organisation.link && renderOrganisation()}
    </Card>
  );
};
export default CardOrganisation;
