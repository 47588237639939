import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import WelloAvatar from '../Components/Shared/WelloAvatar';
import images from '../Themes/Images';
import { CheckOrgaRule, CheckRoleRule } from '../Acl/Rules';
import Roles from '../Acl/Roles';
import { getRestrictionRole, isInFamilyCircles } from '../Acl/Controls';
import {
  AideLeverCoucherIcon,
  AnimauxIcon,
  CoursesIcon,
  CuisineIcon,
  HabillageIcon,
  HydratationIcon,
  InfirmierIcon,
  KinesitherapeuteIcon,
  LudiqueIcon,
  MarcheIcon,
  MedicamentsIcon,
  MenageQuotidienIcon,
  OrdonnanceIcon,
  PansementIcon,
  PiqureIcon,
  PlantesIcon,
  PriseDeSangIcon,
  PriseRepasIcon,
  RelationSocialeIcon,
  SuiviIcon,
  TemperatureIcon,
  ToiletteDoucheIcon,
  ToiletteSavonIcon,
  VisiteQuotidienIcon,
} from '../Themes/Icon';
import { getUserFamily } from './UserHelper';

export const CARENOTEBOOK_TYPE = {
  dailylife: {
    slug: 'dailylife',
    label: 'APP.ORGANISATION.MENU.CARENOTEBOOK.DAILYLIFE',
    postableType: 'Posts::Dailylife',
    defaultSelectedRestrictions: [
      '@family_admin',
      '@main_helper',
      '@family',
      '@close_circle',
      '@pro',
      '@volunteer',
      '@nurse',
      '@doctor',
      '@psychologist',
      '@kine',
      '@coordinator',
      '@caregiver',
      '@personal_carer',
      '@ergo',
    ],
    keyWords: [
      { label: 'Aide à la prise des repas', icon: <PriseRepasIcon /> },
      { label: 'Prise des médicaments', icon: <MedicamentsIcon /> },
      { label: 'Aide à la toilette', icon: <ToiletteSavonIcon /> },
      { label: 'Aide au lever et au coucher', icon: <AideLeverCoucherIcon /> },
      { label: "Aide à l'habillage", icon: <HabillageIcon /> },
      { label: 'Relation sociale', icon: <RelationSocialeIcon /> },
      { label: 'Aide à la marche et à la promenade', icon: <MarcheIcon /> },
      { label: 'Aide administrative', icon: <OrdonnanceIcon /> },
      { label: 'Aide aux courses', icon: <CoursesIcon /> },
      { label: 'Activité ludique et relationnelle', icon: <LudiqueIcon /> },
      { label: 'Préparation des repas', icon: <CuisineIcon /> },
      { label: 'Ménage', icon: <MenageQuotidienIcon /> },
      { label: "Promener l'animal de compagnie", icon: <AnimauxIcon /> },
      { label: 'Arroser les fleurs et les plantes', icon: <PlantesIcon /> },
      { label: 'Visite', icon: <VisiteQuotidienIcon /> },
    ],
  },
  healthcare: {
    slug: 'healthcare',
    label: 'APP.ORGANISATION.MENU.CARENOTEBOOK.HEALTHCARE',
    postableType: 'Posts::Healthcare',
    defaultSelectedRestrictions: [],
    keyWords: [
      { label: 'Prise de médicaments', icon: <MedicamentsIcon /> },
      { label: 'Toilette', icon: <ToiletteDoucheIcon /> },
      { label: 'Suivi médical', icon: <SuiviIcon /> },
      { label: 'Soin infirmier', icon: <InfirmierIcon /> },
      { label: 'Séance de kinésithérapie', icon: <KinesitherapeuteIcon /> },
      { label: 'Prise de sang', icon: <PriseDeSangIcon /> },
      { label: 'Prise de température', icon: <TemperatureIcon /> },
      { label: "Prescription d'ordonnance", icon: <OrdonnanceIcon /> },
      { label: 'Pansements', icon: <PansementIcon /> },
      { label: 'Hydratation', icon: <HydratationIcon /> },
      { label: 'Piqure', icon: <PiqureIcon /> },
    ],
  },
};

export const getFamilyAdminId = family => {
  if (!family) return null;

  return family.admin?.id;
};

export const getFamilyAddress = family => {
  if (!family || !family.admin) return { address: '', latitude: 0, longitude: 0 };

  return {
    address: family.admin?.formatted_address || '',
    latitude: family.admin?.coordinates?.latitude || 0,
    longitude: family.admin?.coordinates?.longitude || 0,
  };
};

export const getFamilyAvatar = (family, bigStyle) => {
  if (!family || !family?.admin)
    return <Avatar src={images.logo.wello_circle} style={bigStyle || { height: 36, width: 36 }} />;

  return (
    <WelloAvatar
      avatarUrl={family?.admin.avatar_url}
      firstName={family?.admin.first_name}
      lastName={family?.admin.last_name}
      backgroundColor={family?.admin.avatar_background_color}
      size={64}
      avatarStyle={bigStyle || { height: 36, width: 36, fontSize: '1rem' }}
    />
  );
};

export const getFamilyFirstMainHelper = family => {
  return family?.main_helpers?.length > 0 ? family.main_helpers[0] : null;
};

export const getFamilyName = family => {
  if (!family || !family.admin) return family?.name || '';

  return `${family?.admin?.first_name} ${family?.admin?.last_name?.toUpperCase()}`;
};

export const getOrganisationRoleRestrictions = (orga, carenotebookType) => {
  const roles = [];
  const hasNeighborAndFriend =
    orga.existing_roles.includes(Roles.NEIGHBOR) && orga.existing_roles.includes(Roles.FRIEND);

  orga.existing_roles.forEach(role => {
    const restriction = getRestrictionRole(role, carenotebookType, orga);
    if (orga.type === 'Organisations::Family' && role === Roles.ADMIN && restriction)
      // Add family admin
      roles.push([getFamilyName(orga), '@family_admin']);
    else if (
      role !== Roles.ADMIN &&
      restriction &&
      (!hasNeighborAndFriend || (hasNeighborAndFriend && role !== Roles.NEIGHBOR))
    )
      roles.push(restriction);
  });

  // if no pro : add admin as pro
  if (
    orga.type !== 'Organisations::Family' &&
    !roles.find(r => r[1] === '@pro') &&
    carenotebookType !== CARENOTEBOOK_TYPE.healthcare.slug
  )
    roles.push(['Pro', '@pro']);

  return roles;
};

export const getRestrictions = (carenotebookType, family, t) => {
  if (!family) return null;

  const allowedCircles =
    family.organisations_circle?.filter(
      circle =>
        CheckOrgaRule(circle.type, `carenotebook-${carenotebookType}`, { organisation: circle }) &&
        circle.validated,
    ) || [];

  const circlesRestrictions =
    allowedCircles.map(circle => ({
      id: circle.id,
      name: `${circle.holding_name} - ${circle.name}`,
      avatar: (
        <WelloAvatar avatarUrl={circle.holding_cover_url} avatarStyle={{ width: 36, height: 36 }} />
      ),
      restrictionRoles: getOrganisationRoleRestrictions(circle, carenotebookType),
      isFamily: false,
    })) || [];
  circlesRestrictions.push({
    id: family.id,
    name: t('APP.CARENOTEBOOKS.SHOW.CREATE_POST.PUBLIC.FAMILY', {
      userFirstName: family.admin.first_name,
    }),
    avatar: getFamilyAvatar(family),
    restrictionRoles: getOrganisationRoleRestrictions(family, carenotebookType),
    isFamily: true,
  });

  return circlesRestrictions;
};

export const getDefaultRestrictions = (carenotebookType, restrictions) => {
  if (!restrictions) return null;

  const defaultRestrictionsArray = restrictions
    .map(r => ({
      ...r,
      restrictionRoles: r.restrictionRoles.filter(role =>
        CARENOTEBOOK_TYPE[carenotebookType]?.defaultSelectedRestrictions.includes(role[1]),
      ),
    }))
    .filter(r => r.restrictionRoles && r.restrictionRoles.length > 0);

  const defaultRestrictionsObject = {};
  defaultRestrictionsArray.forEach(r => {
    defaultRestrictionsObject[r.id] = r.restrictionRoles.map(role => role[1]);
  });
  return defaultRestrictionsObject;
};

export const getRestrictionsArray = (restrictions, circles) => {
  const array = Object.keys(restrictions)
    .map(id => {
      const values = Object.values(restrictions[id]);
      const circle = circles?.find(c => `${c.id}` === `${id}`);
      if (values && values.length > 0 && circle) {
        const roles = values.map(v => {
          const role = circle.restrictionRoles.find(r => r[1] === v);
          return role[0];
        });

        let { name, avatar, isFamily } = circle;

        return { name, avatar, isFamily, roles };
      }

      return null;
    })
    .filter(ob => !!ob);

  const sortedArray = array.filter(r => !r.isFamily).concat(array.filter(r => r.isFamily));
  return sortedArray;
};

export const allowCarenotebookHealthcarePost = (family, currentUser) => {
  const organisation =
    currentUser?.organisations?.length > 0 &&
    currentUser?.organisations.find(orga => isInFamilyCircles(orga, family));

  const familyHasAtLeastOneHealthcareOrga = family?.admin?.organisations?.find(orga =>
    CheckOrgaRule(orga.type, `carenotebook-healthcare`),
  );

  return (
    familyHasAtLeastOneHealthcareOrga &&
    (CheckRoleRule(
      getUserFamily(currentUser, family?.slug)?.role,
      'carenotebook-healthcare:read',
    ) ||
      (organisation &&
        CheckOrgaRule(organisation?.type, 'carenotebook-healthcare') &&
        CheckRoleRule(organisation?.role, 'carenotebook-healthcare:read')))
  );
};

export const getPostType = type => {
  if (type) {
    const careNotebook = Object.values(CARENOTEBOOK_TYPE).find(cnb => cnb.postableType === type);
    return careNotebook?.slug;
  }
};
