import { call, put } from 'redux-saga/effects';

import _ from 'lodash';
import ArticlesActions from '../Redux/ArticlesRedux';
import ToasterActions from '../Redux/ToasterRedux';
import { toastResponseError } from '../Services/ApiErrorHelpers';
import history from '../Routing/CurrentHistory';

export function* getHoldingArticles(api, { holdingSlug }) {
  const response = yield call(api.getHoldingArticles, holdingSlug);

  if (response.ok) {
    yield put(ArticlesActions.getHoldingArticlesSuccess(response.data.articles));
  } else {
    yield toastResponseError(response);
    const errors = _.get(response, 'data.errors');
    yield put(ArticlesActions.getHoldingArticlesFailure(errors));
  }
}

export function* getHoldingArticle(api, { holdingSlug, articleSlug }) {
  const response = yield call(api.getHoldingArticle, holdingSlug, articleSlug);
  if (response.ok) {
    yield put(ArticlesActions.getHoldingArticleSuccess(response.data.article));
  } else {
    yield toastResponseError(response);
    yield put(
      ArticlesActions.getHoldingArticleFailure({
        ...response.data,
        status: response.status,
        holding_slug: holdingSlug,
        article_slug: articleSlug,
      }),
    );
  }
}

export function* createHoldingArticle(
  api,
  { holdingSlug, article, cover, attachments, redirectionLink },
) {
  const response = yield call(api.createHoldingArticle, holdingSlug, article, cover, attachments);
  if (response.ok) {
    yield put(ArticlesActions.createHoldingArticleSuccess(response.data.article));
    yield put(ToasterActions.showToaster({ id: 'UPDATE_SUCCESS' }, 'success', 5000));
    yield call(history.push, redirectionLink);
  } else {
    yield toastResponseError(response);
    const errors = _.get(response, 'data.errors');
    yield put(ArticlesActions.createHoldingArticleFailure(errors));
  }
}

export function* updateHoldingArticle(
  api,
  { holdingSlug, article, cover, attachments, redirectionLink },
) {
  const response = yield call(api.updateHoldingArticle, holdingSlug, article, cover, attachments);

  if (response.ok) {
    yield call(history.push, redirectionLink);
    yield put(ArticlesActions.updateHoldingArticleSuccess(response.data.article));
    yield put(ToasterActions.showToaster({ id: 'UPDATE_SUCCESS' }, 'success', 5000));
  } else {
    yield toastResponseError(response);
    const errors = _.get(response, 'data.errors');
    yield put(ArticlesActions.updateHoldingArticleFailure(errors));
  }
}

export function* destroyHoldingArticle(api, { holdingSlug, articleSlug }) {
  const response = yield call(api.destroyHoldingArticle, holdingSlug, articleSlug);
  if (response.ok) {
    yield put(ArticlesActions.destroyHoldingArticleSuccess(response.data.articles));
  } else {
    yield toastResponseError(response);
    const errors = _.get(response, 'data.errors');
    yield put(ArticlesActions.destroyHoldingArticleFailure(errors));
  }
}

export function* getLeagueArticles(api, { leagueId }) {
  const response = yield call(api.getLeagueArticles, leagueId);

  if (response.ok) {
    yield put(ArticlesActions.getLeagueArticlesSuccess(response.data.articles));
  } else {
    yield toastResponseError(response);
    const errors = _.get(response, 'data.errors');
    yield put(ArticlesActions.getLeagueArticlesFailure(errors));
  }
}

export function* getLeagueArticle(api, { leagueId, articleSlug }) {
  const response = yield call(api.getLeagueArticle, leagueId, articleSlug);
  if (response.ok) {
    yield put(ArticlesActions.getLeagueArticleSuccess(response.data.article));
  } else {
    yield toastResponseError(response);
    yield put(
      ArticlesActions.getLeagueArticleFailure({
        ...response.data,
        status: response.status,
        holding_slug: leagueId,
        article_slug: articleSlug,
      }),
    );
  }
}

export function* createLeagueArticle(api, { leagueId, article, cover, attachments }) {
  const response = yield call(api.createLeagueArticle, leagueId, article, cover, attachments);
  if (response.ok) {
    yield put(ArticlesActions.createLeagueArticleSuccess(response.data.article));
    yield put(ToasterActions.showToaster({ id: 'UPDATE_SUCCESS' }, 'success', 5000));
    yield call(history.push, `/leagues/${leagueId}/articles`);
  } else {
    yield toastResponseError(response);
    const errors = _.get(response, 'data.errors');
    yield put(ArticlesActions.createLeagueArticleFailure(errors));
  }
}

export function* updateLeagueArticle(api, { leagueId, article, cover, attachments }) {
  const response = yield call(api.updateLeagueArticle, leagueId, article, cover, attachments);

  if (response.ok) {
    yield call(history.push, `/leagues/${leagueId}/articles`);
    yield put(ArticlesActions.updateLeagueArticleSuccess(response.data.article));
    yield put(ToasterActions.showToaster({ id: 'UPDATE_SUCCESS' }, 'success', 5000));
  } else {
    yield toastResponseError(response);
    const errors = _.get(response, 'data.errors');
    yield put(ArticlesActions.updateLeagueArticleFailure(errors));
  }
}

export function* destroyLeagueArticle(api, { leagueId, articleSlug }) {
  const response = yield call(api.destroyLeagueArticle, leagueId, articleSlug);
  if (response.ok) {
    yield put(ArticlesActions.destroyLeagueArticleSuccess(response.data.articles));
  } else {
    yield toastResponseError(response);
    const errors = _.get(response, 'data.errors');
    yield put(ArticlesActions.destroyLeagueArticleFailure(errors));
  }
}

export function* getArticle(api, { articleSlug }) {
  const response = yield call(api.getArticle, articleSlug);
  if (response.ok) {
    yield put(ArticlesActions.getArticleSuccess(response.data.article));
  } else {
    yield toastResponseError(response);
    yield put(
      ArticlesActions.getArticleFailure({
        ...response.data,
        status: response.status,
        article_slug: articleSlug,
      }),
    );
  }
}
