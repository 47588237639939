import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  showToaster: ['message', 'level', 'timeout'],
  hideToaster: null,
});

export const ToasterTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  open: false,
  message: null,
  level: null,
  timeout: 5000,
});

/* ------------- Reducers ------------- */
const showToaster = (state, { message, timeout, level }) => ({
  ...state,
  open: true,
  message,
  level,
  timeout: timeout || 5000,
});
const hideToaster = () => INITIAL_STATE;

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SHOW_TOASTER]: showToaster,
  [Types.HIDE_TOASTER]: hideToaster,
});
