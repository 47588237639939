import React from 'react';
import _get from 'lodash/get';
import _set from 'lodash/set';
import moment from 'moment';
import { FrFlag, LuFlag } from '../Themes/Icon';
import { isMobile } from '../Components/Layout/Styles/LayoutStyles';

export const urlifyText = text => {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  return text.replace(urlRegex, url => {
    return `<a href="${url}">${url}</a>`;
  });
};

export const getCurrentDate = () => moment().toISOString();

export const getPastDate = () => moment().add(-2, 'M').toISOString();

export const getFutureDate = () => moment().add(6, 'months').toISOString();

export const formatHours = value => {
  let hours = value;
  if (hours && !Array.isArray(hours) && hours.length > 0) {
    try {
      hours = JSON.parse(hours);
    } catch (e) {
      console.log(e);
    }
  }
  return hours;
};

export const isOutOfTimeRange = (time, start, end) => {
  const date = time.format('YYYY-MM-DD');
  const startDate = `${date} ${start}`;
  const endDate = `${date} ${end}`;
  if (!time.isBetween(startDate, endDate, 'minute')) {
    const timeFormatted = time.format('YYYY-MM-DD HH:mm');
    // If the time is exactly start or end time it should be ok to use
    if (timeFormatted === startDate || timeFormatted === endDate) {
      return false;
    }
    return true;
  }
  return false;
};

export const isOpenedAtTransportDate = (leadDate, leadTime, openedAt) => {
  const transportDate = `${moment(leadDate).format('YYYY-MM-DD')} ${moment(leadTime).format(
    'H:mm:ss',
  )}`;
  const time = moment(transportDate);
  const day = time.isoWeekday() - 1;

  let open = false;
  if (openedAt && openedAt.length > 0 && openedAt[day]) {
    openedAt[day].forEach(hours => {
      if (!isOutOfTimeRange(time, hours.start, hours.end)) {
        open = true;
      }
    });
  }
  return open;
};

export const addNewElement = (elements, element) => {
  const newList = [...elements];
  if (newList?.find(p => p.id === element.id)) return newList;

  newList.push(element);
  newList.sort((a, b) => a.created_at.localeCompare(b.created_at));

  return newList;
};

export const getTripInfo = async (departureFrom, arrivalTo, isRoundTrip) => {
  if (
    !departureFrom ||
    !arrivalTo ||
    departureFrom?.latitude === null ||
    arrivalTo?.latitude === null
  )
    return null;

  const response = await fetch(
    `https://api.mapbox.com/directions/v5/mapbox/driving/${departureFrom.longitude},${
      departureFrom.latitude
    };${arrivalTo.longitude},${arrivalTo.latitude}${
      isRoundTrip ? `;${departureFrom.longitude},${departureFrom.latitude}` : ''
    }?alternatives=false&exclude=unpaved&geometries=geojson&overview=full&steps=false&notifications=none&access_token=${
      process.env.RAZZLE_MAPBOX_GEOCODER_API_KEY
    }`,
  );
  const data = await response.json();

  if (!data || data.code != 'Ok' || !data.routes || data.routes.length === 0) return null;

  return {
    distance: Math.round(data.routes[0].distance / 1000),
    duration: Math.round(data.routes[0].duration / 60),
    geolocs: data.routes[0].geometry.coordinates,
  };
};

export const getTripPrice = (service, tripInfo, needAccompagnement, duration) => {
  if (!tripInfo) return null;

  const { fix_fee: fixFee, distance_rate: distanceRate, escort_rate: escortRate } = service;

  let tripDistance = tripInfo.distance;
  let price = distanceRate ? tripDistance * distanceRate : 0;
  price += fixFee;

  if (needAccompagnement && duration && escortRate) {
    const escortPrice = parseFloat(duration, 10) * escortRate;
    price += escortPrice;
  }

  // On arrondit au dizaine de centimes supérieur
  const truncatedPrice = Math.floor(price * 10) / 10;
  if (truncatedPrice !== price) price = truncatedPrice + 0.1;

  return price;
};

export const coordinatesArrayToString = array => {
  return array?.reduce((acc, item) => {
    return `${acc};${item.toString()}`;
  });
};

export const stringToCoordinatesArray = (string, inverseLatLng) => {
  return string?.split(';')?.map(coo => {
    const array = coo.split(',').map(c => parseFloat(c));
    return inverseLatLng ? array.reverse() : array;
  });
};

export const createMultiAttributesTree = (data, attributeNames) => {
  if (!data) return null;
  let tree = {};

  data.forEach(dataItem => {
    let att = '';
    let nullKey = false;
    attributeNames.forEach((attributeName, i) => {
      const key = _get(dataItem, attributeName);
      if (!nullKey && key && key !== '') {
        if (i !== 0) att += '.';
        att += key;
        let value = _get(tree, att);
        if (!value) {
          // key not created
          tree = _set(tree, att, {});
        }

        if (i === attributeNames.length - 1) {
          // Add object in tree
          if (!value) value = [dataItem];
          else value.push(dataItem);
          tree = _set(tree, att, value);
        }
      } else nullKey = true;
    });
  });

  return tree;
};

export const standardizeWordCase = word => {
  if (!word || word.length === 0) return '';
  return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
};

export const getInitials = (first, last) => {
  let initials = first && first.length > 0 ? first[0].toUpperCase() : '';
  initials += last && last.length > 0 ? last[0].toUpperCase() : '';
  return initials;
};

export const getCapitalizedName = (firstName, lastName, reverseOrder = false) => {
  const first = firstName
    ?.toLowerCase()
    .split('-')
    .map(s => s.charAt(0).toUpperCase() + s.substring(1))
    .join('-');
  const last = lastName?.toUpperCase();
  return reverseOrder ? `${first} ${last}` : `${last} ${first}`;
};

export const getOrgaKey = (holdingSlug, orgaSlug) => {
  if (!holdingSlug && !orgaSlug) return '';
  if (!holdingSlug) return orgaSlug;
  if (!orgaSlug) return holdingSlug;

  return `${holdingSlug}-${orgaSlug}`;
};

export const convertDealtoPost = deal => {
  if (!deal) return null;

  return { ...deal, raw: deal.description };
};

export const checkEmailValidity = email => {
  // eslint-disable-next-line no-useless-escape
  const re =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

  const specialChars = /[`!#$%^&*()=[\]{};:"\\|,<>/?~ç]/;

  return re.test(email) && !specialChars.test(email.split('@')[0]);
};

export const checkPostCodeIsValid = postCode => {
  if (postCode && postCode.match(/^[0-9]{5}$/)) {
    return null;
  }
  return 'WELLO.DEPT.ERRORS';
};

export function checkNoSpecialCharsInName(str) {
  const specialChars = /[`!@#$%^&*()_+=[\]{};:"\\|,.<>/?~^0-9]/;
  return !specialChars.test(str);
}

export const countries = [
  {
    icon: <FrFlag fontSize="small" style={{ padding: 2 }} />,
    label: 'France',
    regex: /^(?=\d{10}$)(01|02|03|04|05|06|07|08|09)\d+/,
    error: 'WELLO.ALL_PHONE.ERRORS',
    code: 'fr',
  },
  {
    icon: <LuFlag fontSize="small" style={{ padding: 2 }} />,
    label: 'Luxembourg',
    regex: /^\d{8,9}$/,
    error: 'WELLO.LU_PHONE.ERRORS',
    code: 'lu',
  },
];

export const checkPhoneValidity = (phone, country) => {
  if (country && country?.label) {
    return !!phone?.match(country?.regex);
  } else return !!phone?.match(/^(?=\d{10}$)(01|02|03|04|05|06|07|08|09)\d+/);
};

const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] === undefined && a[orderBy] === undefined) {
    return 0;
  }
  if (b[orderBy] === undefined) {
    return -1;
  }
  if (a[orderBy] === undefined) {
    return 1;
  }
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

const getComparator = (order, orderBy) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

export const stableSort = (array, order, orderBy) => {
  const comparator = getComparator(order, orderBy);
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const sort = comparator(a[0], b[0]);
    if (sort !== 0) {
      return sort;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
};

export const getTwigEndDate = twig => {
  const taskStart = new Date(twig?.task?.start_date);
  const taskEnd = new Date(twig?.task?.end_date);
  const twigStart = new Date(twig?.event_date).getTime();
  if (twig?.task?.end_date) {
    const time = taskEnd - taskStart;
    return new Date(twigStart + time);
  }
  return new Date(twigStart + 3600000);
};

export const getRoundedTime = () => {
  let ms = 1000 * 60 * 15;
  let roundedTime = new Date(Math.round(new Date(Date.now()).getTime() / ms) * ms);

  return roundedTime.getTime();
};

export const formatMonthAndYear = date => {
  return (
    moment(date).format('MMMM YYYY').charAt(0).toUpperCase() +
    moment(date).format('MMMM YYYY').slice(1)
  );
};

export const formatUrl = url => {
  if (!url || url.includes('http')) return url;

  return `https://${url}`;
};

export const getSeoDescription = description => {
  if (description?.length > 150) {
    return (
      description
        ?.replace(/(<([^>]+)>)/gi, '')
        .replace('&nbsp;', ' ')
        .substring(0, 149) + '…'
    );
  } else return description?.replace(/(<([^>]+)>)/gi, '').replace('&nbsp;', ' ');
};

export const hex2rgba = (hex, alpha = 1) => {
  const [r, g, b] = hex.match(/\w\w/g).map(x => parseInt(x, 16));
  return `rgba(${r},${g},${b},${alpha})`;
};

export const getFormattedPhone = phone => {
  return phone.split(/(\d{2})/).join(' ');
};

export const getSettings = slidesToShow => {
  return {
    arrows: !isMobile,
    centerMode: false,
    infinite: false,
    slidesToShow: slidesToShow[0],
    slidesToScroll: 1,
    speed: 500,
    dots: isMobile,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: slidesToShow[1],
          slidesToScroll: 1,
          dots: true,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: slidesToShow[2],
          slidesToScroll: 1,
          initialSlide: 2,
          dots: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: slidesToShow[3],
          slidesToScroll: 1,
        },
      },
    ],
  };
};

export const getNumberOfDays = (date1, date2) => {
  if (!date1 || !date2) return null;

  return Math.abs((new Date(date1)?.getTime() - new Date(date2).getTime()) / (1000 * 3600 * 24));
};
