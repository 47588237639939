import { call, put } from 'redux-saga/effects';
import _ from 'lodash';
import LeadsActions from '../Redux/LeadsRedux';
import { toastResponseError } from '../Services/ApiErrorHelpers';
import history from '../Routing/CurrentHistory';
import ToasterActions from '../Redux/ToasterRedux';
import TransmissionsActions from '../Redux/TransmissionsRedux';

export function* createNonQualifiedLead(api, { holdingSlug, organisationSlug, lead }) {
  const response = yield call(api.createNonQualifiedLead, holdingSlug, organisationSlug, lead);

  if (response.ok) {
    yield put(LeadsActions.createNonQualifiedLeadSuccess(response.data['leads/non_qualified']));
  } else {
    yield toastResponseError(response);
    yield put(LeadsActions.createNonQualifiedLeadFailure(response.data));
  }
}

export function* createQualifiedLead(api, { holdingSlug, organisationSlug, lead }) {
  const response = yield call(api.createQualifiedLead, holdingSlug, organisationSlug, lead);
  if (response.ok) {
    yield put(LeadsActions.createQualifiedLeadSuccess(response.data['leads/qualified']));
    yield call(history.push, `/${holdingSlug}/${organisationSlug}/quotation/success`);
  } else {
    yield toastResponseError(response);
    yield put(LeadsActions.createQualifiedLeadFailure(response.data));
  }
}

export function* createSharedLead(
  api,
  { holdingSlug, organisationSlug, lead, primaryMembershipId },
) {
  const response = yield call(api.createSharedLead, holdingSlug, organisationSlug, lead);
  if (response.ok) {
    yield put(LeadsActions.createSharedLeadSuccess(response.data['leads/share']));
    if (
      primaryMembershipId &&
      response?.data['leads/share']?.sharer?.holding_slug &&
      response?.data['leads/share']?.sharer?.slug
    ) {
      yield put(
        TransmissionsActions.getOrganisationTransmissionsRequest(
          response.data['leads/share'].sharer.holding_slug,
          response.data['leads/share'].sharer.slug,
          primaryMembershipId,
        ),
      );
    }
    yield put(ToasterActions.showToaster({ id: 'APP.LEADS.SHARED.SUCCESS' }, 'success', 5000));
  } else {
    yield toastResponseError(response);
    yield put(LeadsActions.createSharedLeadFailure(response.data));
  }
}

export function* getLead(api, { holdingSlug, orgaSlug, leadId }) {
  const response = yield call(api.getLead, holdingSlug, orgaSlug, leadId);

  if (response.ok) {
    yield put(LeadsActions.getLeadSuccess(response.data.lead));
  } else {
    yield toastResponseError(response);
    yield put(LeadsActions.getLeadFailure({ ...response.data, status: response.status }));
  }
}

export function* getOrganisationLeads(api, { holdingSlug, orgaSlug, filterArchived, currentPage }) {
  const response = yield call(
    api.getOrganisationLeads,
    holdingSlug,
    orgaSlug,
    filterArchived,
    currentPage + 1,
  );
  if (response.ok) {
    yield put(
      LeadsActions.getOrganisationLeadsSuccess(response.data.leads, response.data.meta.total_leads),
    );
  } else {
    yield toastResponseError(response);
    yield put(LeadsActions.getOrganisationLeadsFailure(response.data));
  }
}

export function* getOrganisationAcceptedLeadsRange(api, { organisationId, range }) {
  const response = yield call(api.getOrganisationAcceptedLeadsRange, organisationId, range);
  if (response.ok) {
    yield put(
      LeadsActions.getOrganisationAcceptedLeadsRangeSuccess(
        response.data.accepted_lead_on_range,
        response.data.total_leads_on_range,
      ),
    );
  } else {
    yield toastResponseError(response);
    yield put(LeadsActions.getOrganisationAcceptedLeadsRangeFailure(response.data));
  }
}

export function* getOrganisationLeadsByType(api, { organisationId, range }) {
  const response = yield call(api.getOrganisationLeadsByType, organisationId, range);
  if (response.ok) {
    yield put(LeadsActions.getOrganisationLeadsByTypeSuccess(response.data));
  } else {
    yield toastResponseError(response);
    yield put(LeadsActions.getOrganisationLeadsByTypeFailure(response.data));
  }
}

export function* getOrganisationLeadsByDay(api, { organisationId, range }) {
  const response = yield call(api.getOrganisationLeadsByDay, organisationId, range);
  if (response.ok) {
    yield put(LeadsActions.getOrganisationLeadsByDaySuccess(response.data));
  } else {
    yield toastResponseError(response);
    yield put(LeadsActions.getOrganisationLeadsByDayFailure(response.data));
  }
}

export function* getOrganisationLeadsByWeek(api, { organisationId, range }) {
  const response = yield call(api.getOrganisationLeadsByWeek, organisationId, range);
  if (response.ok) {
    yield put(LeadsActions.getOrganisationLeadsByWeekSuccess(response.data));
  } else {
    yield toastResponseError(response);
    yield put(LeadsActions.getOrganisationLeadsByWeekFailure(response.data));
  }
}

export function* getOrganisationLeadsByMonth(api, { organisationId, range }) {
  const response = yield call(api.getOrganisationLeadsByMonth, organisationId, range);
  if (response.ok) {
    yield put(LeadsActions.getOrganisationLeadsByMonthSuccess(response.data));
  } else {
    yield toastResponseError(response);
    yield put(LeadsActions.getOrganisationLeadsByMonthFailure(response.data));
  }
}

export function* getOrganisationTotalHelpers(api, { organisationId, role }) {
  const response = yield call(api.getOrganisationTotalHelpers, organisationId, role);
  if (response.ok) {
    yield put(LeadsActions.getOrganisationTotalHelpersSuccess(response.data[0].count));
  } else {
    yield toastResponseError(response);
    yield put(LeadsActions.getOrganisationTotalHelpersFailure(response.data));
  }
}

export function* getOrganisationTopHelpers(api, { organisationId, role }) {
  const response = yield call(api.getOrganisationTopHelpers, organisationId, role);
  if (response.ok) {
    yield put(LeadsActions.getOrganisationTopHelpersSuccess(response.data));
  } else {
    yield toastResponseError(response);
    const error = _.get(response, 'data');
    yield put(LeadsActions.getOrganisationTopHelpersFailure(error));
  }
}

export function* getOrganisationHelpers(api, { organisationId, role }) {
  const response = yield call(api.getOrganisationHelpers, organisationId, role);
  if (response.ok) {
    yield put(LeadsActions.getOrganisationHelpersSuccess(response.data));
  } else {
    yield toastResponseError(response);
    const error = _.get(response, 'data');
    yield put(LeadsActions.getOrganisationHelpersFailure(error));
  }
}

export function* getHoldingLeads(api, { holdingSlug, filterArchived, currentPage }) {
  const response = yield call(
    api.getHoldingLeads,
    holdingSlug,
    null,
    filterArchived,
    currentPage + 1,
  );
  if (response.ok) {
    yield put(
      LeadsActions.getHoldingLeadsSuccess(response.data.leads, response.data.meta.total_leads),
    );
  } else {
    yield toastResponseError(response);
    yield put(LeadsActions.getHoldingLeadsFailure(response.data));
  }
}

export function* getLeagueLeads(api, { leagueId, filterArchived, currentPage, organisationId }) {
  const response = yield call(
    api.getLeagueLeads,
    leagueId,
    filterArchived,
    currentPage + 1,
    organisationId,
  );
  if (response.ok) {
    yield put(
      LeadsActions.getLeagueLeadsSuccess(
        response.data.leads,
        response.data.meta.total_leads,
        organisationId,
      ),
    );
  } else {
    yield toastResponseError(response);
    yield put(LeadsActions.getLeagueLeadsFailure(response.data));
  }
}

export function* getLeagueLead(api, { leagueId, leadId }) {
  const response = yield call(api.getLeagueLead, leagueId, leadId);
  if (response.ok) {
    yield put(LeadsActions.getLeagueLeadSuccess(response.data.lead));
  } else {
    yield toastResponseError(response);
    yield put(LeadsActions.getLeagueLeadFailure(response.data));
  }
}

export function* createLeagueLead(api, { leagueId, leadAttributes }) {
  const response = yield call(api.createLeagueLead, leagueId, leadAttributes);
  if (response.ok) {
    yield put(LeadsActions.createLeagueLeadSuccess(response.data.lead));
  } else {
    yield toastResponseError(response);
    yield put(LeadsActions.createLeagueLeadFailure(response.data));
  }
}

export function* updateLeagueLead(api, { leagueId, leadId, leadAttributes, helperId }) {
  const response = yield call(api.updateLeagueLead, leagueId, leadId, leadAttributes);
  if (response.ok) {
    yield put(LeadsActions.updateLeagueLeadSuccess(response.data.lead, leadAttributes.archived));
    yield put(TransmissionsActions.getLeagueTransmissionsRequest(leagueId, helperId));
  } else {
    yield toastResponseError(response);
    yield put(LeadsActions.updateLeagueLeadFailure(response.data));
  }
}

export function* transferLeagueLead(api, { leagueId, leadId, organisationId, helperId }) {
  const response = yield call(api.transferLeagueLead, leagueId, leadId, organisationId);
  if (response.ok) {
    yield put(
      ToasterActions.showToaster({ id: 'APP.LEAGUES.LEADS.TRANSFER.SUCCESS' }, 'success', 5000),
    );
    yield put(LeadsActions.transferLeagueLeadSuccess(response.data.lead));
    yield put(TransmissionsActions.getLeagueTransmissionsRequest(leagueId, helperId));
  } else {
    yield toastResponseError(response);
    yield put(LeadsActions.transferLeagueLeadFailure(response.data));
  }
}

export function* getFamilyLeads(api, { helperId, filterArchived, currentPage }) {
  const response = yield call(api.getFamilyLeads, helperId, filterArchived, currentPage + 1);
  if (response.ok) {
    yield put(
      LeadsActions.getFamilyLeadsSuccess(response.data.leads, response.data.meta.total_leads),
    );
  } else {
    yield put(LeadsActions.getFamilyLeadsFailure(response.data));
    yield toastResponseError(response);
  }
}

export function* getFamilyLead(api, { helperId, leadId }) {
  const response = yield call(api.getFamilyLead, helperId, leadId);

  if (response.ok) {
    yield put(LeadsActions.getFamilyLeadSuccess(response.data.lead));
  } else {
    yield toastResponseError(response);
    yield put(LeadsActions.getFamilyLeadFailure({ ...response.data, status: response.status }));
  }
}

export function* createLeadPost(api, { leagueId, holdingSlug, organisationSlug, leadId, post }) {
  const response = holdingSlug
    ? yield call(api.createPost, holdingSlug, organisationSlug, leadId, post)
    : yield call(api.createLeagueLeadPost, leagueId, leadId, post);
  if (response.ok) {
    yield put(LeadsActions.createLeadPostSuccess(leadId, response.data.post));
  } else {
    yield toastResponseError(response);
    yield put(LeadsActions.createLeadPostFailure(response.data));
  }
}

export function* updateOrganisationLead(api, { holdingSlug, orgaSlug, lead }) {
  const response = yield call(api.updateOrganisationLead, holdingSlug, orgaSlug, lead);
  if (response.ok) {
    yield put(LeadsActions.updateOrganisationLeadSuccess(response.data.lead));
  } else {
    yield toastResponseError(response);
    yield put(LeadsActions.updateOrganisationLeadFailure(response.data));
  }
}

export function* updateFamilyLead(api, { helperId, lead }) {
  const response = yield call(api.updateFamilyLead, helperId, lead);
  if (response.ok) {
    yield put(LeadsActions.updateFamilyLeadSuccess(lead, response.data.lead));
  } else {
    yield toastResponseError(response);
    yield put(LeadsActions.updateFamilyLeadFailure(response.data));
  }
}

export function* updateOrganisationLeadAndPostComment(api, { holdingSlug, orgaSlug, lead, post }) {
  let response = yield call(api.createPost, holdingSlug, orgaSlug, lead.id, post);
  if (response.ok) {
    yield put(LeadsActions.createLeadPostSuccess(lead.id, response.data.post));

    response = yield call(api.updateOrganisationLead, holdingSlug, orgaSlug, lead);
    if (response.ok) {
      yield put(LeadsActions.updateOrganisationLeadAndPostCommentSuccess(response.data.lead));
      return;
    }
  }

  yield toastResponseError(response);
  if (response.data) {
    yield put(LeadsActions.updateOrganisationLeadAndPostCommentFailure(response.data));
  }
}
