import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  createWebNotificationSubscriptionRequest: [
    'holdingSlug',
    'organisationSlug',
    'userId',
    'subscription',
  ],
  createWebNotificationSubscriptionSuccess: ['subscription'],
  createWebNotificationSubscriptionFailure: null,

  destroyWebNotificationSubscriptionRequest: [
    'holdingSlug',
    'organisationSlug',
    'userId',
    'deviceId',
  ],
  destroyWebNotificationSubscriptionSuccess: ['subscription'],
  destroyWebNotificationSubscriptionFailure: null,

  checkWebNotificationSubscriptionRequest: [
    'holdingSlug',
    'organisationSlug',
    'userId',
    'deviceId',
  ],
  checkWebNotificationSubscriptionSuccess: ['subscription'],
  checkWebNotificationSubscriptionFailure: null,

  resetWebNotificationSubscriptions: [],
});

export const WebNotificationSubscriptionsTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  isFetching: {
    createWebNotificationSubscription: false,
    checkWebNotificationSubscription: false,
    destroyWebNotificationSubscription: false,
  },
  errors: {
    createWebNotificationSubscription: null,
    checkWebNotificationSubscription: null,
    destroyWebNotificationSubscription: null,
  },
  subscription: null,
});

/* ------------- Reducers ------------- */
// -
// createWebNotificationSubscription
// -
const createWebNotificationSubscriptionRequest = state => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    createWebNotificationSubscription: true,
  },
});

const createWebNotificationSubscriptionSuccess = (state, { subscription }) => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    createWebNotificationSubscription: false,
  },
  errors: {
    ...state.errors,
    createWebNotificationSubscription: INITIAL_STATE.createWebNotificationSubscription,
  },
  subscription,
});

const createWebNotificationSubscriptionFailure = (state, { errors }) => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    createWebNotificationSubscription: false,
  },
  errors: {
    ...state.errors,
    createWebNotificationSubscription: errors,
  },
});

// -
// checkWebNotificationSubscription
// -
const checkWebNotificationSubscriptionRequest = state => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    checkWebNotificationSubscription: true,
  },
});

const checkWebNotificationSubscriptionSuccess = (state, { subscription }) => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    checkWebNotificationSubscription: false,
  },
  errors: {
    ...state.errors,
    checkWebNotificationSubscription: INITIAL_STATE.checkWebNotificationSubscription,
  },
  subscription,
});

const checkWebNotificationSubscriptionFailure = (state, { errors }) => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    checkWebNotificationSubscription: false,
  },
  errors: {
    ...state.errors,
    checkWebNotificationSubscription: errors,
  },
});

// -
// destroyWebNotificationSubscription
// -
const destroyWebNotificationSubscriptionRequest = state => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    destroyWebNotificationSubscription: true,
  },
});
const destroyWebNotificationSubscriptionSuccess = (state, { subscription }) => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    destroyWebNotificationSubscription: false,
  },
  errors: {
    ...state.errors,
    destroyWebNotificationSubscription: INITIAL_STATE.createWebNotificationSubscription,
  },
  subscription,
});
const destroyWebNotificationSubscriptionFailure = (state, { errors }) => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    destroyWebNotificationSubscription: false,
  },
  errors: {
    ...state.errors,
    destroyWebNotificationSubscription: errors,
  },
});
const resetWebNotificationSubscriptions = () => INITIAL_STATE;

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.CREATE_WEB_NOTIFICATION_SUBSCRIPTION_REQUEST]: createWebNotificationSubscriptionRequest,
  [Types.CREATE_WEB_NOTIFICATION_SUBSCRIPTION_SUCCESS]: createWebNotificationSubscriptionSuccess,
  [Types.CREATE_WEB_NOTIFICATION_SUBSCRIPTION_FAILURE]: createWebNotificationSubscriptionFailure,

  [Types.DESTROY_WEB_NOTIFICATION_SUBSCRIPTION_REQUEST]: destroyWebNotificationSubscriptionRequest,
  [Types.DESTROY_WEB_NOTIFICATION_SUBSCRIPTION_SUCCESS]: destroyWebNotificationSubscriptionSuccess,
  [Types.DESTROY_WEB_NOTIFICATION_SUBSCRIPTION_FAILURE]: destroyWebNotificationSubscriptionFailure,

  [Types.CHECK_WEB_NOTIFICATION_SUBSCRIPTION_REQUEST]: checkWebNotificationSubscriptionRequest,
  [Types.CHECK_WEB_NOTIFICATION_SUBSCRIPTION_SUCCESS]: checkWebNotificationSubscriptionSuccess,
  [Types.CHECK_WEB_NOTIFICATION_SUBSCRIPTION_FAILURE]: checkWebNotificationSubscriptionFailure,

  [Types.RESET_WEB_NOTIFICATION_SUBSCRIPTIONS]: resetWebNotificationSubscriptions,
});
