import React from 'react';
import { connect } from 'react-redux';
import LeaguesActions from '../Redux/LeaguesRedux';
import loadable from '@loadable/component';
const FullScreenLoader = loadable(() => import('../Components/Layout/FullScreenLoader'));

type Props = {
  match: Object,
  league: Object,
  getLeague: Function,
};

const mapStateToProps = state => ({
  league: state.leagues.league,
});

const mapDispatchToProps = dispatch => ({
  getLeague: leagueId => dispatch(LeaguesActions.getLeagueRequest(leagueId, false, false)),
});

const withLeague = WrappedComponent => {
  function fetchLeague(props: Props) {
    const { league, match, getLeague } = props;
    const isLeagueLoaded = league;
    const hasLeagueChanged = league && `${league.id}` !== match.params.league_id;
    if (!isLeagueLoaded || hasLeagueChanged) {
      getLeague(match.params.league_id);
      return <FullScreenLoader />;
    }
    return <WrappedComponent holding={league} {...props} />;
  }

  return connect(mapStateToProps, mapDispatchToProps)(fetchLeague);
};

export default withLeague;
