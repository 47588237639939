import fp from 'lodash/fp';
import isDate from 'lodash/isDate';
import moment from 'moment';
import { standardizeWordCase } from './DataHelper';

const isAttributeDefined = fieldName => fp.flow(fp.get(fieldName), fp.size, fp.gt(fp, 0));
const isLongerThan = size => fp.flow(fp.size, fp.gt(fp, size));
const isInList = list => fp.includes(fp, list);

const serializeDateFromDatePicker = date => (isDate(date) ? date : moment(date).toDate());

const handleAddChip = (chip, keyWords) => {
  keyWords.push(standardizeWordCase(chip));
  return keyWords;
};

const handleRemoveChip = (chip, keyWords) => {
  const newKeyWords = keyWords?.filter(keyWord => keyWord !== chip);
  return newKeyWords;
};

export {
  serializeDateFromDatePicker,
  isAttributeDefined,
  isLongerThan,
  isInList,
  handleAddChip,
  handleRemoveChip,
};
