import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import ImageWithFallback from './ImageWithFallback';
import Images from '../../Themes/Images';
import Tooltip from '@mui/material/Tooltip';
import { getInitials } from '../../Services/DataHelper';
import { getCdnUrl } from '../../Services/ImageResizer';
import HelperDetails from './HelperDetails';

const useStyles = makeStyles(() => ({
  tooltip: {
    backgroundColor: 'transparent !important',
    padding: 0,
  },
}));

type Props = {
  avatarUrl: String,
  firstName: String,
  lastName: String,
  backgroundColor: String,
  avatarClass: String,
  size: Number,
  avatarStyle: Object,
  onClick: Function,
  helper: Object,
};

const WelloAvatar = ({
  avatarUrl,
  firstName,
  lastName,
  backgroundColor,
  avatarClass,
  avatarStyle,
  size,
  onClick,
  helper,
}: Props) => {
  const showHelperDetails = Boolean(helper);
  const classes = useStyles();

  const errorNode = React.useMemo(() => {
    const intls = getInitials(firstName, lastName);
    if (intls.length > 0) {
      return (
        <Avatar
          className={avatarClass || ''}
          style={{
            ...avatarStyle,
            backgroundColor: `${backgroundColor || '#bdbdbd'}`,
            cursor: onClick ? 'pointer' : 'inherited',
          }}
          onClick={onClick}
        >
          {intls}
        </Avatar>
      );
    }

    return (
      <Avatar
        className={avatarClass || ''}
        src={Images.logo.wello_circle}
        alt="unknown"
        style={{
          ...avatarStyle,
          cursor: onClick ? 'pointer' : 'inherited',
        }}
        onClick={onClick}
      />
    );
  }, [firstName, lastName, avatarClass, backgroundColor, avatarStyle, onClick]);

  const url = size ? getCdnUrl(avatarUrl, size) : avatarUrl;

  const renderAvatar = () => (
    <Avatar
      className={avatarClass || ''}
      src={url}
      alt={firstName}
      style={{
        ...avatarStyle,
        backgroundColor: 'white',
        cursor: onClick ? 'pointer' : 'inherited',
      }}
      onClick={onClick}
    />
  );

  return (
    <ImageWithFallback
      src={url}
      successRenderer={
        showHelperDetails ? (
          <Tooltip
            title={<HelperDetails helper={helper} elevation={2} />}
            placement="top"
            enterDelay={700}
            enterNextDelay={700}
            classes={{ tooltip: classes.tooltip }}
          >
            {renderAvatar()}
          </Tooltip>
        ) : (
          renderAvatar()
        )
      }
      errorRenderer={
        showHelperDetails ? (
          <Tooltip
            title={<HelperDetails helper={helper} elevation={2} />}
            placement="top"
            enterDelay={700}
            enterNextDelay={700}
            classes={{ tooltip: classes.tooltip }}
          >
            {errorNode}
          </Tooltip>
        ) : (
          errorNode
        )
      }
    />
  );
};

export default WelloAvatar;
