import { createStore, applyMiddleware } from 'redux';
import { persistStore } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';

// import Config from '../Config/DebugConfig';
import { isClientSide, isServerSide } from '../Config/ServerConfig';
import { setStore } from '../Services/ApiTransforms';
// import { AnalyticsTrackingReduxActions, AnalyticsTrackingNavigation } from './AnalyticsTrackingMiddleware'

// creates the store
export default (rootReducer, rootSaga) => {
  /* ------------- Redux Configuration ------------- */

  const middleware = [];
  const enhancers = [];

  /* ------------- Analytics Tracking Middleware ------------- */

  // middleware.push(AnalyticsTrackingReduxActions)
  // middleware.push(AnalyticsTrackingNavigation)

  /* ------------- Saga Middleware ------------- */

  // eslint-disable-next-line no-console
  const sagaMonitor = null; // Config.useReactotron ? console.tron.createSagaMonitor() : null;
  const sagaMiddleware = createSagaMiddleware({ sagaMonitor });
  middleware.push(sagaMiddleware);

  /* ------------- Assemble Middleware ------------- */

  enhancers.push(applyMiddleware(...middleware));

  let preloadedState = {};
  if (!isServerSide()) {
    // Grab the state from a global variable injected into the server-generated HTML
    // eslint-disable-next-line no-underscore-dangle
    preloadedState = window.__PRELOADED_STATE__;
    // Allow the passed state to be garbage-collected
    // eslint-disable-next-line no-underscore-dangle
    delete window.__PRELOADED_STATE__;
  }

  // if Reactotron is enabled (default for process.env.NODE_ENV === 'development'), we'll create the store through Reactotron
  // eslint-disable-next-line no-console
  // const createAppropriateStore = createStore; // Config.useReactotron ? console.tron.createStore : createStore;
  const store = createStore(
    rootReducer,
    preloadedState,
    process.env.RAZZLE_ENVIRONMENT === 'prod'
      ? applyMiddleware(...middleware)
      : composeWithDevTools(...enhancers),
  );

  // kick off root saga
  const sagas = sagaMiddleware.run(rootSaga);

  const exportData = { store, sagas };

  if (isClientSide()) {
    // configure persistStore
    exportData.persistor = persistStore(store);
    setStore(store);
  }

  return exportData;
};
