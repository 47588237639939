import { call, put } from 'redux-saga/effects';
import _ from 'lodash';
import { toastResponseError } from '../Services/ApiErrorHelpers';
import history from '../Routing/CurrentHistory';

import HoldingActions from '../Redux/HoldingRedux';
import LeagueActions from '../Redux/LeaguesRedux';
import UsersActions from '../Redux/UsersRedux';
import ToasterActions from '../Redux/ToasterRedux';
import AuthActions from '../Redux/AuthRedux';
import OrganisationActions from '../Redux/OrganisationRedux';

export function* getHolding(api, { holdingSlug }) {
  const response = yield call(api.getHolding, holdingSlug);

  if (response.ok) {
    yield put(HoldingActions.getHoldingSuccess(response.data.holding));
  } else {
    yield toastResponseError(response);
    yield put(
      HoldingActions.getHoldingFailure({
        ...response.data,
        status: response.status,
        holding_slug: holdingSlug,
      }),
    );
  }
}

export function* getHoldings(api) {
  const response = yield call(api.getHoldings);

  if (response.ok) {
    yield put(HoldingActions.getHoldingsSuccess(response.data.holdings));
  } else {
    yield toastResponseError(response);
    yield put(HoldingActions.getHoldingsFailure(response.data));
  }
}

export function* createHolding(api, { holding, cover, charter, currentUserId, email }) {
  const response = yield call(
    api.createHolding,
    holding.league_id,
    holding,
    cover,
    charter,
    holding.type,
    email,
  );
  if (response.ok) {
    yield put(HoldingActions.createHoldingSuccess(response.data.holding));
    yield put(LeagueActions.addLeagueHolding(response.data.holding));
    if (currentUserId) yield put(UsersActions.getUserRequest(currentUserId));
    if (
      response.data.holding?.organisations.length > 0 &&
      response.data.holding?.type !== 'Holdings::Community'
    ) {
      yield call(
        history.push,
        `/${response.data.holding.slug}/${response.data.holding.organisations[0].slug}/`,
      );
    } else if (response.data.holding?.type === 'Holdings::Community') {
      yield call(history.push, `/communities/${response.data.holding.slug}/`);
    } else {
      yield call(history.push, `/leagues/${response.data.holding.league_id}/holdings`);
    }
  } else {
    yield toastResponseError(response);
    yield put(HoldingActions.createHoldingFailure(response.data));
  }
}

export function* updateHolding(
  api,
  { holding, updateAttributes, cover, charter, currentUserId, email },
) {
  const response = yield call(
    api.updateHolding,
    holding.league_id,
    holding.slug,
    updateAttributes,
    cover,
    charter,
    email,
  );
  if (response.ok) {
    if (response.data.holding?.type === 'Holdings::Community') {
      yield call(history.push, `/communities/${holding.slug}/description`);
    }
    yield put(ToasterActions.showToaster({ id: 'UPDATE_SUCCESS' }, 'success', 5000));
    yield put(HoldingActions.updateHoldingSuccess(response.data.holding));
    yield put(OrganisationActions.updateOrganisationHolding(response.data.holding));
    if (currentUserId) yield put(UsersActions.getUserRequest(currentUserId));
    if (email && response.data.holding.organisations?.length > 0)
      yield put(
        OrganisationActions.getOrganisationRequest(
          holding.slug,
          response.data.holding.organisations[0].slug,
          'full',
        ),
      );
  } else {
    yield toastResponseError(response);
    yield put(HoldingActions.updateHoldingFailure(response.data));
  }
}

export function* addHoldingOrganisation(api, { holding, organisationId, currentUser }) {
  const response = yield call(
    api.addHoldingOrganisation,
    holding.league_id,
    holding.slug,
    organisationId,
  );

  if (response.ok) {
    const { organisations } = currentUser;
    const index = _.findIndex(organisations, orga => orga.id === organisationId);
    organisations[index].holding_id = holding.id;
    organisations[index].holding_slug = holding.slug;
    yield put(AuthActions.signInSuccess({ organisations }));
    yield put(HoldingActions.addHoldingOrganisationSuccess(response.data.holding));
  } else {
    yield toastResponseError(response);
    yield put(HoldingActions.addHoldingOrganisationFailure(response.data));
  }
}

export function* removeHoldingOrganisation(api, { holdingSlug, organisation, currentUser }) {
  const response = yield call(api.removeHoldingOrganisation, holdingSlug, organisation.id);

  if (response.ok) {
    const { organisations } = currentUser;
    const index = _.findIndex(organisations, orga => orga.id === organisation.id);
    organisations[index].holding_id = null;
    yield put(AuthActions.signInSuccess({ organisations }));
    yield put(HoldingActions.removeHoldingOrganisationSuccess(response.data.holding));
  } else {
    yield toastResponseError(response);
    yield put(HoldingActions.removeHoldingOrganisationFailure(response.data));
  }
}

export function* createOrganisationHolding(
  api,
  { holdingSlug, organisationAttributes, currentUser },
) {
  const response = yield call(
    api.createOrganisationHolding,
    holdingSlug,
    organisationAttributes.organisation,
    organisationAttributes.email,
  );

  if (response.ok) {
    yield put(HoldingActions.createOrganisationHoldingSuccess(response.data.holding));
    if (currentUser) yield put(UsersActions.getUserRequest(currentUser.id));
    const newOrga = response.data.holding?.organisations?.find(
      o => o.name === organisationAttributes.organisation.name,
    );
    if (newOrga) {
      yield call(history.push, `/${response.data.holding.slug}/${newOrga.slug}/`);
    } else if (currentUser?.id === response.data.holding.admin_id) {
      yield call(history.push, `/settings/${holdingSlug}/organisations`);
    } else {
      yield call(history.push, `/leagues/${response.data.holding.league_id}/holdings`);
    }
  } else {
    yield toastResponseError(response);
    yield put(HoldingActions.createOrganisationHoldingFailure(response.data));
  }
}

export function* getHoldingServices(api, { holdingSlug }) {
  const response = yield call(api.getHoldingServices, holdingSlug);

  if (response.ok) {
    yield put(HoldingActions.getHoldingServicesSuccess(response.data.services));
  } else {
    yield toastResponseError(response);
    const error = _.get(response, 'data');
    yield put(HoldingActions.getHoldingServicesFailure(error));
  }
}

export function* getHoldingService(api, { holdingSlug, serviceSlug }) {
  const response = yield call(api.getHoldingService, holdingSlug, serviceSlug);

  if (response.ok) {
    yield put(HoldingActions.getHoldingServiceSuccess(response.data.service));
  } else {
    yield toastResponseError(response);
    const error = _.get(response, 'data');
    yield put(HoldingActions.getHoldingServiceFailure(error));
  }
}

export function* addHoldingService(api, { holdingSlug, service, image }) {
  const response = yield call(api.addHoldingService, holdingSlug, service, image);
  if (response.ok) {
    yield put(HoldingActions.addHoldingServiceSuccess(response.data.service));
    yield put(ToasterActions.showToaster({ id: 'UPDATE_SUCCESS' }, 'success', 5000));
    yield put(OrganisationActions.addOrganisationService(response.data.service));
  } else {
    yield toastResponseError(response);
    const error = _.get(response, 'data');
    yield put(HoldingActions.addHoldingServiceFailure(error));
  }
}

export function* updateHoldingService(api, { holdingSlug, service, image }) {
  const response = yield call(api.updateHoldingService, holdingSlug, service, image);

  if (response.ok) {
    yield put(HoldingActions.updateHoldingServiceSuccess(response.data.service));
    yield put(ToasterActions.showToaster({ id: 'UPDATE_SUCCESS' }, 'success', 5000));
    yield put(OrganisationActions.updateOrganisationService(response.data.service));
  } else {
    yield toastResponseError(response);
    const error = _.get(response, 'data');
    yield put(HoldingActions.updateHoldingServiceFailure(error));
  }
}

export function* removeHoldingService(api, { holdingSlug, serviceId }) {
  const response = yield call(api.removeHoldingService, holdingSlug, serviceId);

  if (response.ok) {
    yield put(HoldingActions.removeHoldingServiceSuccess(serviceId));
    yield put(OrganisationActions.removeOrganisationService(serviceId));
  } else {
    yield toastResponseError(response);
    const error = _.get(response, 'data');
    yield put(HoldingActions.removeHoldingServiceFailure(error));
  }
}

export function* getAllPathologies(api) {
  const response = yield call(api.getAllPathologies);

  if (response.ok) {
    yield put(HoldingActions.getAllPathologiesSuccess(response.data.pathologies));
  } else {
    yield toastResponseError(response);
    const error = _.get(response, 'data');
    yield put(HoldingActions.getAllPathologiesFailure(error));
  }
}

export function* getAllExpertises(api) {
  const response = yield call(api.getAllExpertises);
  if (response.ok) {
    yield put(HoldingActions.getAllExpertisesSuccess(response.data.expertises));
  } else {
    yield toastResponseError(response);
    const error = _.get(response, 'data');
    yield put(HoldingActions.getAllExpertisesFailure(error));
  }
}

export function* getLevels(api) {
  const response = yield call(api.getLevels);
  if (response.ok) {
    yield put(HoldingActions.getLevelsSuccess(response.data.levels));
  } else {
    yield toastResponseError(response);
    const error = _.get(response, 'data');
    yield put(HoldingActions.getLevelsFailure(error));
  }
}

export function* getAllEstablishmentTypes(api) {
  const response = yield call(api.getAllEstablishmentTypes);

  if (response.ok) {
    yield put(HoldingActions.getAllEstablishmentTypesSuccess(response.data.establishments));
  } else {
    yield toastResponseError(response);
    const error = _.get(response, 'data');
    yield put(HoldingActions.getAllEstablishmentTypesFailure(error));
  }
}

export function* addHoldingRubric(api, { holdingSlug, rubric }) {
  const response = yield call(api.addHoldingRubric, holdingSlug, rubric);
  if (response.ok) {
    yield put(HoldingActions.addHoldingRubricSuccess(response.data.rubric));
    yield put(ToasterActions.showToaster({ id: 'UPDATE_SUCCESS' }, 'success', 5000));
    yield put(OrganisationActions.addOrganisationRubric(response.data.rubric));
  } else {
    yield toastResponseError(response);
    const error = _.get(response, 'data');
    yield put(HoldingActions.addHoldingRubricFailure(error));
  }
}

export function* updateHoldingRubric(api, { holdingSlug, rubric }) {
  const response = yield call(api.updateHoldingRubric, holdingSlug, rubric);

  if (response.ok) {
    yield put(HoldingActions.updateHoldingRubricSuccess(response.data.rubric));
    yield put(ToasterActions.showToaster({ id: 'UPDATE_SUCCESS' }, 'success', 5000));
    yield put(OrganisationActions.updateOrganisationRubric(response.data.rubric));
  } else {
    yield toastResponseError(response);
    const error = _.get(response, 'data');
    yield put(HoldingActions.updateHoldingRubricFailure(error));
  }
}

export function* removeHoldingRubric(api, { holdingSlug, rubricId }) {
  const response = yield call(api.removeHoldingRubric, holdingSlug, rubricId);

  if (response.ok) {
    yield put(HoldingActions.removeHoldingRubricSuccess(rubricId));
    yield put(OrganisationActions.removeOrganisationRubric(rubricId));
  } else {
    yield toastResponseError(response);
    const error = _.get(response, 'data');
    yield put(HoldingActions.removeHoldingRubricFailure(error));
  }
}
