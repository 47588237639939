import { call, put } from 'redux-saga/effects';
import _ from 'lodash';
import OrganisationActions from '../Redux/OrganisationRedux';
import ToasterActions from '../Redux/ToasterRedux';
import UsersActions from '../Redux/UsersRedux';
import DealsActions from '../Redux/DealsRedux';
import ClubsActions from '../Redux/ClubsRedux';
import { toastResponseError } from '../Services/ApiErrorHelpers';
// import history from '../Routing/CurrentHistory';

export function* getOrganisation(api, { holdingSlug, organisationSlug, fetchMode }) {
  let response = null;
  if (holdingSlug) {
    response = yield call(api.getOrganisation, holdingSlug, organisationSlug, fetchMode);
  }
  if (response.ok) {
    yield put(OrganisationActions.getOrganisationSuccess(response.data.organisation));
    if (response.data.organisation.deals) {
      yield put(
        DealsActions.getDealsSuccess(
          response.data.organisation.deals,
          1,
          response.data.organisation.total_deals,
        ),
      );
    }
    if (response.data.organisation.clubs) {
      yield put(ClubsActions.getOrganisationClubsSuccess(response.data.organisation.clubs));
    }
  } else {
    yield toastResponseError(response);
    yield put(
      OrganisationActions.getOrganisationFailure({
        ...response.data,
        status: response.status,
        holding_slug: holdingSlug,
        organisation_slug: organisationSlug,
      }),
    );
  }
}

export function* updateOrganisation(
  api,
  { holdingSlug, organisationSlug, updateAttributes, banner, currentUserId },
) {
  const response = yield call(
    api.updateOrganisation,
    holdingSlug,
    organisationSlug,
    updateAttributes,
    banner,
  );
  if (response.ok) {
    yield put(OrganisationActions.updateOrganisationSuccess(response.data.organisation));
    yield put(ToasterActions.showToaster({ id: 'UPDATE_SUCCESS' }, 'success', 5000));
    if (currentUserId) yield put(UsersActions.getUserRequest(currentUserId));
  } else {
    yield toastResponseError(response);
    const error = _.get(response, 'data');
    yield put(OrganisationActions.updateOrganisationFailure(error));
  }
}

export function* getFamily(api, { organisationSlug }) {
  const response = yield call(api.getOrganisationBySlug, organisationSlug);

  if (response.ok) {
    yield put(OrganisationActions.getFamilySuccess(response.data.family));
  } else {
    yield toastResponseError(response);
    const error = _.get(response, 'data');
    yield put(
      OrganisationActions.getFamilyFailure({
        ...error,
        status: response.status,
        familySlug: organisationSlug,
      }),
    );
  }
}

export function* getOrganisationByIdentifier(api, { organisationIdentifier, method }) {
  const response = yield call(api.getOrganisationByIdentifier, organisationIdentifier, method);

  if (response.ok) {
    yield put(OrganisationActions.getOrganisationByIdentifierSuccess(response.data.organisation));
  } else {
    yield toastResponseError(response);
    const error = {
      ...response.data,
      status: response.status,
    };
    yield put(OrganisationActions.getOrganisationByIdentifierFailure(error));
  }
}

export function* getOrganisationCircles(api, { organisation }) {
  const response = yield call(api.getOrganisationCircles, organisation.id);

  if (response.ok) {
    yield put(OrganisationActions.getOrganisationCirclesSuccess(response.data.organisations));
  } else {
    yield toastResponseError(response);
    const error = _.get(response, 'data');
    yield put(OrganisationActions.getOrganisationCirclesFailure(error));
  }
}

export function* getOrganisationServices(api, { holdingSlug, organisationSlug }) {
  const response = yield call(api.getOrganisationServices, holdingSlug, organisationSlug);

  if (response.ok) {
    yield put(
      OrganisationActions.getOrganisationServicesSuccess(response.data.organisation_services),
    );
  } else {
    yield toastResponseError(response);
    const error = _.get(response, 'data');
    yield put(OrganisationActions.getOrganisationServicesFailure(error));
  }
}

export function* getOrganisationServiceTypes(api) {
  const response = yield call(api.getServiceTypes);

  if (response.ok) {
    yield put(
      OrganisationActions.getOrganisationServiceTypesSuccess(response.data.service_categories),
    );
  } else {
    yield toastResponseError(response);
    const error = _.get(response, 'data');
    yield put(OrganisationActions.getOrganisationServiceTypesFailure(error));
  }
}

export function* getOrganisationService(api, { holdingSlug, organisationSlug, serviceSlug }) {
  const response = yield call(
    api.getOrganisationService,
    holdingSlug,
    organisationSlug,
    serviceSlug,
  );

  if (response.ok) {
    yield put(
      OrganisationActions.getOrganisationServiceSuccess(response.data.organisation_service),
    );
  } else {
    yield toastResponseError(response);
    yield put(
      OrganisationActions.getOrganisationServiceFailure({
        ...response.data,
        status: response.status,
      }),
    );
  }
}

export function* addOrganisationService(api, { holdingSlug, organisationSlug, service, cover }) {
  const response = yield call(
    api.addOrganisationService,
    holdingSlug,
    organisationSlug,
    service,
    cover,
  );
  if (response.ok) {
    yield put(
      OrganisationActions.addOrganisationServiceSuccess(response.data.organisation_service),
    );
    yield put(ToasterActions.showToaster({ id: 'UPDATE_SUCCESS' }, 'success', 5000));
  } else {
    yield toastResponseError(response);
    const error = _.get(response, 'data');
    yield put(OrganisationActions.addOrganisationServiceFailure(error));
  }
}

export function* updateOrganisationService(
  api,
  { holdingSlug, organisationSlug, service, cover, organisationServicesToUpdate },
) {
  const response = yield call(
    api.updateOrganisationService,
    holdingSlug,
    organisationSlug,
    service,
    cover,
  );

  if (response.ok) {
    yield put(
      OrganisationActions.updateOrganisationServiceSuccess(response.data.organisation_service),
    );

    if (organisationServicesToUpdate?.length > 0) {
      const newOSToUpdate = organisationServicesToUpdate.filter(
        os => os.organisation_id !== organisationServicesToUpdate[0].organisation_id,
      );
      yield put(
        OrganisationActions.updateOrganisationServiceRequest(
          holdingSlug,
          organisationServicesToUpdate[0].slug,
          {
            ...organisationServicesToUpdate[0],
            service_id: response.data.organisation_service.service_id,
          },
          null,
          newOSToUpdate,
        ),
      );
    }
    yield put(ToasterActions.showToaster({ id: 'UPDATE_SUCCESS' }, 'success', 5000));
  } else {
    yield toastResponseError(response);
    const error = _.get(response, 'data');
    yield put(OrganisationActions.updateOrganisationServiceFailure(error));
  }
}

export function* removeOrganisationService(api, { holdingSlug, organisationSlug, serviceId }) {
  const response = yield call(
    api.removeOrganisationService,
    holdingSlug,
    organisationSlug,
    serviceId,
  );

  if (response.ok) {
    yield put(OrganisationActions.removeOrganisationServiceSuccess(serviceId));
  } else {
    yield toastResponseError(response);
    const error = _.get(response, 'data');
    yield put(OrganisationActions.removeOrganisationServiceFailure(error));
  }
}

export function* updateFamilyHelped(api, { userId, updateAttributes, avatar }) {
  const response = yield call(api.updateUser, userId, updateAttributes, avatar);
  if (response.ok) {
    yield put(OrganisationActions.updateFamilyHelpedSuccess(response.data.helper));
    yield put(ToasterActions.showToaster({ id: 'UPDATE_SUCCESS' }, 'success', 5000));
  } else {
    yield toastResponseError(response);
    const errors = _.get(response, 'data.errors');
    yield put(OrganisationActions.updateFamilyHelpedFailure(errors));
  }
}
