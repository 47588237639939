export const SubscribleTypes = {
  CLUB: 'Club',
  FAMILY: 'Organisations::Family',
};

export const SubscribleSubTypes = {
  DEALS: 'deals',
  TASKS: 'tasks',
  LEADS: 'leads',
  POSTS: 'posts',
};

export const hasUnread = (organisation, excludeFamily = true) => {
  if (!organisation?.subscriptions || organisation.subscriptions.length === 0) return false;

  const subUnread = organisation.subscriptions.filter(
    s => s.unread_count > 0 && (!excludeFamily || s.subscrible_type !== SubscribleTypes.FAMILY),
  );
  return subUnread?.length > 0;
};

export const userHasUnread = user => {
  if (!user || (!user.organisations && !user.families)) return false;

  return (
    user.organisations.some(orga => hasUnread(orga, false)) ||
    user.families.some(orga => hasUnread(orga, false))
  );
};

const getUnreadCount = (organisation, subscrible, subscribleId = null) => {
  if (!organisation?.subscriptions || organisation.subscriptions.length === 0) return false;

  switch (subscrible) {
    case 'deals':
      return organisation.subscriptions.find(
        s => s.sub_type === SubscribleSubTypes.DEALS && s.unread_count > 0,
      )?.unread_count;
    case 'tasks':
      return organisation.subscriptions.find(
        s => s.sub_type === SubscribleSubTypes.TASKS && s.unread_count > 0,
      )?.unread_count;
    case 'leads':
      return organisation.subscriptions.find(
        s => s.sub_type === SubscribleSubTypes.LEADS && s.unread_count > 0,
      )?.unread_count;
    case 'clubs': {
      const clubSubscriptions = organisation.subscriptions.filter(
        s => s.subscrible_type === SubscribleTypes.CLUB && s.unread_count > 0,
      );
      return clubSubscriptions.length > 0 ? clubSubscriptions.length : null;
    }
    case 'members': {
      const familySubscriptions = organisation.subscriptions
        .filter(s => s.subscrible_type === SubscribleTypes.FAMILY && s.unread_count > 0)
        .map(s => s.subscrible_id)
        .filter((value, index, self) => {
          return self.indexOf(value) === index;
        });

      return familySubscriptions.length > 0 ? familySubscriptions.length : null;
    }
    case 'member': {
      const familySubscriptions = organisation.subscriptions.filter(
        s =>
          s.subscrible_type === SubscribleTypes.FAMILY &&
          s.subscrible_id === subscribleId &&
          s.unread_count > 0,
      );
      return familySubscriptions.length > 0;
    }
    default:
      return false;
  }
};

export default getUnreadCount;

export const getSubscription = (organisation, subscribleType, subscribleSubType, subscribleId) => {
  if (!organisation?.subscriptions || organisation.subscriptions.length === 0) return null;

  const sub = organisation.subscriptions.find(
    s =>
      s.subscrible_type === subscribleType &&
      `${s.subscrible_id}` === `${subscribleId}` &&
      (!subscribleSubType || s.sub_type === subscribleSubType),
  );
  return sub;
};

export const findSubscription = (user, organisationId, subscriptionId) => {
  if (!user || (!user.organisations && !user.families)) return null;

  const orga =
    user.organisations.find(o => `${o.id}` === `${organisationId}`) ||
    user.families.find(f => `${f.id}` === `${organisationId}`);

  return orga?.subscriptions?.find(s => `${s.id}` === `${subscriptionId}`);
};

export const getUnreadPostCount = (
  organisation,
  subscribleType,
  subscribleSubType,
  subscribleId,
) => {
  if (!organisation?.subscriptions || organisation.subscriptions.length === 0) return 0;

  const sub = getSubscription(organisation, subscribleType, subscribleSubType, subscribleId);
  return sub?.unread_count || 0;
};

export const getLastReadDate = (organisation, subscribleType, subscribleSubType, subscribleId) => {
  if (!organisation?.subscriptions || organisation.subscriptions.length === 0) return null;

  const sub = getSubscription(organisation, subscribleType, subscribleSubType, subscribleId);
  return sub?.last_read_date;
};

export function getNewMessagesBarPosition(lastReadDate, posts) {
  if (!lastReadDate || !posts || posts.length === 0) return -1;
  return posts.findIndex(post => post.created_at > lastReadDate);
}

export function getOldMessagesBarPosition(lastReadDate, posts) {
  if (!lastReadDate || !posts || posts.length === 0) return -1;
  return posts.findIndex(post => post.created_at < lastReadDate);
}
