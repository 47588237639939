import React from 'react';
import { IframeIcon, VideoIcon, TextIcon, AudioIcon, ImagIcon, QuizIcon } from '../Themes/Icon';
import { checkPostCodeIsValid } from './DataHelper';

export const getAssetTypeIcon = (type, color) => {
  switch (type) {
    case 'iframe': {
      return <IframeIcon style={{ fill: color }} />;
    }
    case 'video': {
      return <VideoIcon style={{ fill: color }} />;
    }
    case 'image': {
      return <ImagIcon style={{ fill: color }} />;
    }
    case 'audio': {
      return <AudioIcon style={{ fill: color }} />;
    }
    case 'text': {
      return <TextIcon style={{ fill: color }} />;
    }
    case 'quiz': {
      return <QuizIcon style={{ fill: color }} />;
    }
  }
};

export const computeNextChapterLink = chapter => {
  const currentIndex = chapter?.sort_index;
  const nextchapter = chapter?.unit?.chapters?.find(
    chapter => chapter?.sort_index === currentIndex + 1,
  );
  if (nextchapter) {
    return `/formations/${chapter?.course_slug}/chapitres/${nextchapter?.id}`;
  } else {
    return `/formations/${chapter?.course_slug}/chapitres/${chapter.id}/success`;
  }
};

export const getLeagueQuizzes = leagueNamespace => {
  switch (leagueNamespace) {
    case 'SLA': {
      return [
        { value: 'sla-start', label: 'Questionnaire de début' },
        { value: 'sla-end', label: 'Questionnaire de fin' },
      ];
    }
    default: {
      [];
    }
  }
};

export const computeQuizMeta = (chapter, data) => {
  if (!chapter || chapter.asset_type !== 'quiz') return null;

  let meta = {};
  meta[chapter.content] = { ...data };
  return { meta: meta };
};

export const isValidQuizMeta = (chapter, data) => {
  if (!chapter || chapter.asset_type !== 'quiz' || chapter.terminated) return true;

  if (chapter.content === 'sla-start') {
    if (
      !!data['civility'] &&
      !!data['age'] &&
      !!data['postcode'] &&
      checkPostCodeIsValid(data['postcode']) === null &&
      data['primary'] === 'Oui' &&
      !!data['link'] &&
      !!data['pathology'] &&
      !!data['profession'] &&
      !!data['pfr_support'] &&
      !!data['knowledge_level'] &&
      !!data['course_hearing']
    )
      return true;
    else if (
      !!data['civility'] &&
      !!data['age'] &&
      !!data['postcode'] &&
      checkPostCodeIsValid(data['postcode']) === null &&
      data['primary'] === 'Non' &&
      !!data['who'] &&
      !!data['knowledge_level'] &&
      !!data['course_hearing']
    )
      return true;
    else if (
      !!data['civility'] &&
      !!data['age'] &&
      !!data['postcode'] &&
      checkPostCodeIsValid(data['postcode']) === null &&
      data['primary'] === 'Ne sais pas' &&
      !!data['who'] &&
      !!data['knowledge_level'] &&
      !!data['course_hearing']
    )
      return true;
    else return false;
  }

  return true;
};
