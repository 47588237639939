import { put } from 'redux-saga/effects';
import * as Sentry from '@sentry/react';

import { isServerSide } from '../Config/ServerConfig';
import ToasterActions from '../Redux/ToasterRedux';

const extractErrors = response => {
  const message = response?.data?.error?.message;

  if (message) {
    return { id: `${message}` };
  }

  // We don't have server error data, only ApiSauce's
  return { id: `APP.API_SAUCE.${response.problem}` };
};

// Display the errors in a Toast since the API does not return an error per attribute
// which means that we cannot display an error per input field
const toastResponseError = response => {
  const message = extractErrors(response);
  if (isServerSide()) {
    console.log(message);
    Sentry.addBreadcrumb({
      category: 'response',
      data: response,
      level: 'info',
    });
  }
  if (response?.status === 500 || response?.status === 400) {
    Sentry.captureMessage(JSON.stringify(response?.data));
  }
  return put(ToasterActions.showToaster(message, 'error', 5000));
};

export { toastResponseError, extractErrors };
