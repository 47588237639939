import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import {
  SITUATION_TYPES,
  SITUATION_AGES,
  SITUATION_NEEDS,
  HELPER_TYPES,
} from '../../Services/ServiceHelper';

const useStyles = makeStyles(theme => ({
  chipContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
  label: { paddingBottom: theme.spacing(1) },
}));

type Props = {
  formData: Object,
  setFormData: Function,
};

const SLACallMeBackControls = ({ formData, setFormData }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <Grid item xs={12}>
        <FormControl style={{ paddingTop: 16 }}>
          <FormLabel required style={{ color: 'black', paddingBottom: 8 }}>
            {t(`APP.ASK_CONTACT.TEXT_FIELD.LABEL.HELPER_PROFILE`)}
          </FormLabel>
          <RadioGroup
            row
            value={formData.helperType}
            onChange={event =>
              setFormData({
                ...formData,
                helperType: event.target.value,
              })
            }
          >
            {HELPER_TYPES.map(selectedType => (
              <FormControlLabel
                key={selectedType.slug}
                value={selectedType.slug}
                control={<Radio color="default" size="small" />}
                label={t(selectedType.label)}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </Grid>
      {formData.helperType !== 'other' && (
        <>
          <Grid item xs={12}>
            <FormControl>
              <FormLabel required className={classes.label}>
                {t(`APP.ASK_CONTACT.TEXT_FIELD.LABEL.HELPER_SITUATION`)}
              </FormLabel>
              <Grid className={classes.chipContainer}>
                {SITUATION_TYPES.map(selectedSituation => (
                  <Chip
                    key={selectedSituation.slug}
                    variant={formData.situation === selectedSituation.slug ? 'default' : 'outlined'}
                    size="small"
                    style={{
                      fontWeight: formData.situation === selectedSituation.slug ? 600 : 500,
                    }}
                    onClick={() =>
                      setFormData({
                        ...formData,
                        situation:
                          formData.situation === selectedSituation.slug
                            ? ''
                            : selectedSituation.slug,
                      })
                    }
                    label={t(selectedSituation.label)}
                  />
                ))}
              </Grid>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl>
              <FormLabel required className={classes.label}>
                {t(`APP.ASK_CONTACT.TEXT_FIELD.LABEL.HELPER_AGE`)}
              </FormLabel>
              <Grid className={classes.chipContainer}>
                {SITUATION_AGES.map(selectedAge => (
                  <Chip
                    key={selectedAge.slug}
                    variant={formData.age === selectedAge.slug ? 'default' : 'outlined'}
                    size="small"
                    style={{
                      fontWeight: formData.age === selectedAge.slug ? 600 : 500,
                    }}
                    onClick={() =>
                      setFormData({
                        ...formData,
                        age: formData.age === selectedAge.slug ? '' : selectedAge.slug,
                      })
                    }
                    label={t(selectedAge.label)}
                  />
                ))}
              </Grid>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl>
              <FormLabel required className={classes.label}>
                {t(`APP.ASK_CONTACT.TEXT_FIELD.LABEL.HELPER_NEEDS`)}
              </FormLabel>
              <Grid className={classes.chipContainer}>
                {SITUATION_NEEDS.map(selectedNeed => (
                  <Chip
                    key={selectedNeed.slug}
                    variant={formData.need === selectedNeed.slug ? 'default' : 'outlined'}
                    size="small"
                    onClick={() =>
                      setFormData({
                        ...formData,
                        need: formData.need === selectedNeed.slug ? '' : selectedNeed.slug,
                      })
                    }
                    style={{
                      fontWeight: formData.need === selectedNeed.slug ? 600 : 500,
                    }}
                    label={t(selectedNeed.label)}
                  />
                ))}
              </Grid>
            </FormControl>
          </Grid>
        </>
      )}
    </>
  );
};

export default SLACallMeBackControls;
