import { isClientSide } from '../Config/ServerConfig';
// https://medium.com/javascript-in-plain-english/integrating-actioncable-with-react-9f946b61556e
// https://dev.to/tegandbiscuits/using-action-cable-with-react-native-jk0
// rails6 : https://www.npmjs.com/package/@rails/actioncable
// rails5 : https://www.npmjs.com/package/actioncable
// auth: https://stackoverflow.com/questions/35501931/send-auth-token-for-authentication-to-actioncable

export const CHANNEL_DEAL = 'DealChannel';
export const CHANNEL_TASK = 'TaskChannel';
export const CHANNEL_LEAD = 'LeadChannel';
export const CHANNEL_CARENOTEBOOK = 'CarenotebookChannel';
export const CHANNEL_CLUB = 'ClubChannel';
export const CHANNEL_USER = 'UserChannel';

// const consumer = isClientSide()
// ? require('@rails/actioncable').createConsumer(process.env.RAZZLE_WEBSOCKET_URL)
// : {};

const actionCableProvider = {};

// export function subscribeChannel(token, channelName, mixin) {
export function subscribeChannel(token) {
  if (!token || !isClientSide()) {
    return null;
  }
  return null;
  // if (actionCableProvider.token !== token || !actionCableProvider.consumer) {
  //   // eslint-disable-next-line global-require
  //   const ActionCable = require('action-cable-react-jwt');
  //   actionCableProvider.token = token;
  //   actionCableProvider.consumer = ActionCable.createConsumer(
  //     process.env.RAZZLE_WEBSOCKET_URL,
  //     token,
  //   );
  // }

  // // document.cookie = `X-Authorization=${token}; path=/`; // not working as wello use multi-domain
  // return actionCableProvider.consumer.subscriptions.create(channelName, mixin);
}

// export function unsubscribeChannel(subscription) {
export function unsubscribeChannel() {
  return null;
  // if (!subscription || !isClientSide()) {
  //   return null;
  // }
  // return actionCableProvider.consumer.subscriptions.remove(subscription);
}

export default actionCableProvider;
