import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';
/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  requestStart: ['method'],
  requestEnd: [''],
});
export const RequestResponseDecoratorTypes = Types;
export default Creators;

export const INITIAL_STATE = Immutable({
  requesting: false,
  method: null,
  uid: null,
});

const requestStart = (state, { method, uid }) =>
  method === 'get'
    ? state
    : {
        requesting: true,
        method,
        uid,
      };

const requestEnd = (state, { method }) => (method === 'get' ? state : INITIAL_STATE);

export const reducer = createReducer(INITIAL_STATE, {
  [Types.REQUEST_START]: requestStart,
  [Types.REQUEST_END]: requestEnd,
});
