import { createTheme } from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';
import grey from '@material-ui/core/colors/grey';
import red from '@material-ui/core/colors/red';
import Routes from '../Routing/Routes';

// The default values for the muiTheme object can be found here:
// https://material-ui.com/customization/default-theme/
const theme = {
  breakpoints: {
    keys: ['xs', 'sm', 'md', 'lg', 'xl'],
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1200,
      xl: 1920,
    },
  },
  direction: 'ltr',
  mixins: {
    toolbar: {
      minHeight: 56,
      '@media (min-width:0px) and (orientation: landscape)': {
        minHeight: 48,
      },
      '@media (min-width:600px)': {
        minHeight: 64,
      },
    },
  },
  overrides: {
    MuiMenuItem: {
      root: {
        '@media (min-width:600px)': {
          minHeight: 48,
        },
      },
    },
  },
  wello: {
    primary: {
      thin: '#f3f7ff',
      light: '#2787FF',
      main: '#0072FF',
      dark: '#0060D7',
    },
    secondary: {
      main: '#fff',
    },
    retirement: {
      light: '#ff5d8f',
      main: '#c9184a',
    },
    residence: {
      light: '#FFA748',
      main: '#EB6229',
    },
    helperhelp: {
      light: '#922DDA',
      main: '#49176D',
    },
    helpers: { light: '#3AAAFC', main: '#0361D6' },
    homecare: {
      light: '#03D675',
      main: '#13AF3D',
    },
  },
  una: {
    primary: {
      thin: '#f3f7ff',
      light: blue[400],
      main: blue[600],
      dark: blue[800],
    },
    secondary: {
      main: '#fff',
    },
  },
  scalaa: {
    primary: {
      thin: '#fff9f9',
      light: red[400],
      main: '#ed4c59',
      dark: red[800],
    },
    secondary: {
      main: '#fff',
    },
  },
  mormal: {
    primary: {
      thin: '#f3f7ff',
      light: blue[400],
      main: blue[600],
      dark: blue[800],
    },
    secondary: {
      main: red[600],
    },
  },
  sla: {
    primary: {
      main: '#5E2677',
      dark: '#502166',
    },
  },
  ccmormal: {
    primary: {
      main: '#008F3C',
      dark: '#008438',
    },
    secondary: {
      main: '#0B2442',
    },
  },
  diapason: {
    primary: {
      main: '#27789F',
      dark: '#226b8e',
    },
  },
  reprise: {
    primary: {
      main: '#114294',
      dark: '#0e3983',
    },
    secondary: {
      main: '#D43671',
    },
  },
  cd85: {
    primary: {
      main: '#389B8F',
      dark: '#318a7f',
    },
    secondary: {
      main: '#389B8F',
    },
  },
  hapicoop: {
    primary: {
      main: '#D37660',
      dark: '#c26c57',
    },
    secondary: {
      main: '#70B698',
    },
  },
  chb: {
    primary: {
      main: '#556E46',
      dark: '#475e39',
    },
    secondary: {
      main: '#DC4282',
      dark: '#AA1E5D',
    },
    pink: '#DC4282',
    orange: '#E8941A',
    green: '#7FBAA6',
    grey: '#A7B2C4',
    purple: '#6758B7',
  },
  global: {
    primary: {
      main: '#FDBF2B',
      dark: '#ecb226',
    },
    secondary: {
      main: '#2366B0',
      dark: '#1e1d1f',
    },
  },
  ne: {
    primary: {
      main: '#087fa3',
      dark: '#067192',
    },
    secondary: {
      main: '#fff',
      dark: '#dedede',
    },
  },
  soss: {
    primary: {
      main: '#c58828',
      dark: '#b77d24',
    },
    secondary: {
      main: '#9b9790',
      dark: '#827b70',
    },
  },
  palette: {
    common: {
      black: '#000',
      white: '#fff',
      greyBackground: '#f5f5f5',
    },
    type: 'light',
    primary: {
      light: blue[400],
      main: '#1e6ce5',
      dark: blue[800],
    },
    secondary: {
      light: grey[50],
      main: grey[100],
      middle: grey[700],
      dark: grey[800],
    },
    error: {
      light: '#e57373',
      main: '#f44336',
      dark: '#d32f2f',
      contrastText: '#fff',
    },
    grey: {
      50: '#fafafa',
      100: '#f5f5f5',
      200: '#eeeeee',
      300: '#e0e0e0',
      400: '#bdbdbd',
      500: '#9e9e9e',
      600: '#757575',
      700: '#616161',
      800: '#424242',
      900: '#212121',
      A100: '#d5d5d5',
      A200: '#aaaaaa',
      A400: '#303030',
      A700: '#616161',
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
    text: {
      primary: 'rgba(39, 38, 41, 0.92)',
      secondary: 'rgba(0, 0, 0, 0.54)',
      disabled: 'rgba(0, 0, 0, 0.38)',
      hint: 'rgba(0, 0, 0, 0.38)',
    },
    divider: 'rgba(0, 0, 0, 0.12)',
    background: {
      paper: '#fff',
      default: '#f6f7f8',
    },
    action: {
      active: 'rgba(0, 0, 0, 0.54)',
      hover: 'rgba(0, 0, 0, 0.08)',
      hoverOpacity: 0.08,
      selected: 'rgba(0, 0, 0, 0.14)',
      disabled: 'rgba(0, 0, 0, 0.26)',
      disabledBackground: 'rgba(0, 0, 0, 0.12)',
    },
  },
  props: {},
  shadows: [
    'none',
    '0px 1px 3px 0.5px rgba(0, 0, 0, 0.05)',
    '0px 1px 5px 0px rgba(0, 0, 0, 0.2),0px 2px 2px 0px rgba(0, 0, 0, 0.14),0px 3px 1px -2px rgba(0, 0, 0, 0.12)',
    '0px 1px 8px 0px rgba(0, 0, 0, 0.2),0px 3px 4px 0px rgba(0, 0, 0, 0.14),0px 3px 3px -2px rgba(0, 0, 0, 0.12)',
    '0px 2px 4px -1px rgba(0, 0, 0, 0.2),0px 4px 5px 0px rgba(0, 0, 0, 0.14),0px 1px 10px 0px rgba(0, 0, 0, 0.12)',
    '0px 3px 5px -1px rgba(0, 0, 0, 0.2),0px 5px 8px 0px rgba(0, 0, 0, 0.14),0px 1px 14px 0px rgba(0, 0, 0, 0.12)',
    '0px 3px 5px -1px rgba(0, 0, 0, 0.2),0px 6px 10px 0px rgba(0, 0, 0, 0.14),0px 1px 18px 0px rgba(0, 0, 0, 0.12)',
    '0px 4px 5px -2px rgba(0, 0, 0, 0.2),0px 7px 10px 1px rgba(0, 0, 0, 0.14),0px 2px 16px 1px rgba(0, 0, 0, 0.12)',
    '0px 5px 5px -3px rgba(0, 0, 0, 0.2),0px 8px 10px 1px rgba(0, 0, 0, 0.14),0px 3px 14px 2px rgba(0, 0, 0, 0.12)',
    '0px 5px 6px -3px rgba(0, 0, 0, 0.2),0px 9px 12px 1px rgba(0, 0, 0, 0.14),0px 3px 16px 2px rgba(0, 0, 0, 0.12)',
    '0px 6px 6px -3px rgba(0, 0, 0, 0.2),0px 10px 14px 1px rgba(0, 0, 0, 0.14),0px 4px 18px 3px rgba(0, 0, 0, 0.12)',
    '0px 6px 7px -4px rgba(0, 0, 0, 0.2),0px 11px 15px 1px rgba(0, 0, 0, 0.14),0px 4px 20px 3px rgba(0, 0, 0, 0.12)',
    '0px 7px 8px -4px rgba(0, 0, 0, 0.2),0px 12px 17px 2px rgba(0, 0, 0, 0.14),0px 5px 22px 4px rgba(0, 0, 0, 0.12)',
    '0px 7px 8px -4px rgba(0, 0, 0, 0.2),0px 13px 19px 2px rgba(0, 0, 0, 0.14),0px 5px 24px 4px rgba(0, 0, 0, 0.12)',
    '0px 7px 9px -4px rgba(0, 0, 0, 0.2),0px 14px 21px 2px rgba(0, 0, 0, 0.14),0px 5px 26px 4px rgba(0, 0, 0, 0.12)',
    '0px 8px 9px -5px rgba(0, 0, 0, 0.2),0px 15px 22px 2px rgba(0, 0, 0, 0.14),0px 6px 28px 5px rgba(0, 0, 0, 0.12)',
    '0px 8px 10px -5px rgba(0, 0, 0, 0.2),0px 16px 24px 2px rgba(0, 0, 0, 0.14),0px 6px 30px 5px rgba(0, 0, 0, 0.12)',
    '0px 8px 11px -5px rgba(0, 0, 0, 0.2),0px 17px 26px 2px rgba(0, 0, 0, 0.14),0px 6px 32px 5px rgba(0, 0, 0, 0.12)',
    '0px 9px 11px -5px rgba(0, 0, 0, 0.2),0px 18px 28px 2px rgba(0, 0, 0, 0.14),0px 7px 34px 6px rgba(0, 0, 0, 0.12)',
    '0px 9px 12px -6px rgba(0, 0, 0, 0.2),0px 19px 29px 2px rgba(0, 0, 0, 0.14),0px 7px 36px 6px rgba(0, 0, 0, 0.12)',
    '0px 10px 13px -6px rgba(0, 0, 0, 0.2),0px 20px 31px 3px rgba(0, 0, 0, 0.14),0px 8px 38px 7px rgba(0, 0, 0, 0.12)',
    '0px 10px 13px -6px rgba(0, 0, 0, 0.2),0px 21px 33px 3px rgba(0, 0, 0, 0.14),0px 8px 40px 7px rgba(0, 0, 0, 0.12)',
    '0px 10px 14px -6px rgba(0, 0, 0, 0.2),0px 22px 35px 3px rgba(0, 0, 0, 0.14),0px 8px 42px 7px rgba(0, 0, 0, 0.12)',
    '0px 11px 14px -7px rgba(0, 0, 0, 0.2),0px 23px 36px 3px rgba(0, 0, 0, 0.14),0px 9px 44px 8px rgba(0, 0, 0, 0.12)',
    '0px 11px 15px -7px rgba(0, 0, 0, 0.2),0px 24px 38px 3px rgba(0, 0, 0, 0.14),0px 9px 46px 8px rgba(0, 0, 0, 0.12)',
  ],
  typography: {
    fontFamily: '"Lato", "Helvetica", "Arial", sans-serif',
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontDisplay: 'swap',
    display4: {
      fontSize: '7rem',
      fontWeight: 300,
      fontFamily: '"Lato", "Helvetica", "Arial", sans-serif',
      letterSpacing: '-.04em',
      lineHeight: '1.14286em',
      marginLeft: '-.04em',
      color: 'rgba(0, 0, 0, 0.54)',
    },
    display3: {
      fontSize: '3.5rem',
      fontWeight: 400,
      fontFamily: '"Lato", "Helvetica", "Arial", sans-serif',
      letterSpacing: '-.02em',
      lineHeight: '1.30357em',
      marginLeft: '-.02em',
      color: 'rgba(0, 0, 0, 0.54)',
    },
    display2: {
      fontSize: '2.8125rem',
      fontWeight: 400,
      fontFamily: '"Lato", "Helvetica", "Arial", sans-serif',
      lineHeight: '1.13333em',
      marginLeft: '-.02em',
      color: 'rgba(0, 0, 0, 0.54)',
    },
    display1: {
      fontSize: '2.125rem',
      fontWeight: 400,
      fontFamily: '"Lato", "Helvetica", "Arial", sans-serif',
      lineHeight: '1.20588em',
      color: 'rgba(0, 0, 0, 0.54)',
    },
    headline: {
      fontSize: '1.5rem',
      fontWeight: 400,
      fontFamily: '"Lato", "Helvetica", "Arial", sans-serif',
      lineHeight: '1.35417em',
      color: 'rgba(0, 0, 0, 0.87)',
    },
    title: {
      fontSize: '1.3125rem',
      fontWeight: 500,
      fontFamily: '"Lato", "Helvetica", "Arial", sans-serif',
      lineHeight: '1.16667em',
      color: 'rgba(0, 0, 0, 0.87)',
    },
    subheading: {
      fontSize: '1rem',
      fontWeight: 400,
      fontFamily: '"Lato", "Helvetica", "Arial", sans-serif',
      lineHeight: '1.5em',
      color: 'rgba(0, 0, 0, 0.87)',
    },
    body2: {
      color: 'rgba(70, 70, 70, 0.87)',
      fontFamily: '"Lato", "Helvetica", "Arial", sans-serif',
      fontWeight: 400,
      fontSize: '0.875rem',
      lineHeight: 1.3,
      letterSpacing: '0.01071em',
    },
    body1: {
      color: 'rgba(0, 0, 0, 0.87)',
      fontFamily: '"Lato", "Helvetica", "Arial", sans-serif',
      fontWeight: 400,
      fontSize: '0.95rem',
      lineHeight: 1.5,
      letterSpacing: '0.00938em',
    },
    caption: {
      color: 'rgba(0, 0, 0, 0.87)',
      fontFamily: '"Lato", "Helvetica", "Arial", sans-serif',
      fontWeight: 400,
      fontSize: '0.75rem',
      lineHeight: 1.33,
      letterSpacing: '0.03333em',
    },
    button: {
      color: 'rgba(0, 0, 0, 0.87)',
      fontFamily: '"Lato", "Helvetica", "Arial", sans-serif',
      fontWeight: 500,
      fontSize: '0.875rem',
      lineHeight: 1.5,
      letterSpacing: '0.02857em',
      textTransform: 'uppercase',
    },
    h1: {
      color: 'rgba(39, 38, 41, 0.92)',
      fontFamily: '"Lato", "Helvetica", "Arial", sans-serif',
      fontWeight: 900,
      fontSize: '4rem',
      '@media (max-width:900px)': {
        fontSize: '3rem',
      },
      '@media (max-width:600px)': {
        fontSize: '2.5rem',
      },
      lineHeight: 1,
      letterSpacing: '-.02222em',
    },
    h2: {
      color: 'rgba(39, 38, 41, 0.92)',
      fontFamily: '"Lato", "Helvetica", "Arial", sans-serif',
      fontWeight: 900,
      fontSize: '3.1rem',
      '@media (max-width:1200px)': {
        fontSize: '2.8rem',
      },
      '@media (max-width:900px)': {
        fontSize: '2.3rem',
      },
      '@media (max-width:600px)': {
        fontSize: '2rem',
      },
      lineHeight: 1.13,
      letterSpacing: '-0.00833em',
    },
    h3: {
      color: 'rgba(39, 38, 41, 0.92)',
      fontFamily: '"Lato", "Helvetica", "Arial", sans-serif',
      fontWeight: 900,
      fontSize: '2.1rem',
      lineHeight: 1.04,
      letterSpacing: '0em',
      '@media (max-width:600px)': {
        fontSize: '1.2rem',
      },
    },
    h4: {
      color: 'rgba(0, 0, 0, 0.87)',
      fontFamily: '"Lato", "Helvetica", "Arial", sans-serif',
      fontWeight: 600,
      fontSize: '1.2rem',
      lineHeight: 1.17,
      letterSpacing: '0.00735em',
    },
    h5: {
      color: 'rgba(0, 0, 0, 0.87)',
      fontFamily: '"Lato", "Helvetica", "Arial", sans-serif',
      fontWeight: 700,
      fontSize: '1rem',
      lineHeight: 1.33,
      letterSpacing: '0em',
      textTransform: 'uppercase',
    },
    h6: {
      color: grey[800],
      fontFamily: '"Lato", "Helvetica", "Arial", sans-serif',
      fontWeight: 700,
      fontSize: '0.9rem',
      lineHeight: 1.2,
      letterSpacing: '0.0075em',
    },
    subtitle1: {
      color: 'rgba(62, 62, 62, 0.87)',
      fontFamily: '"Lato", "Helvetica", "Arial", sans-serif',
      fontWeight: 400,
      fontSize: '0.95rem',
      lineHeight: 1.25,
      letterSpacing: '0.00938em',
    },
    subtitle2: {
      color: 'rgba(125, 125, 125, 0.87)',
      fontFamily: '"Lato", "Helvetica", "Arial", sans-serif',
      fontWeight: 400,
      fontSize: '0.875rem',
      lineHeight: 1.57,
      letterSpacing: '0.00714em',
    },
    body1Next: {
      color: 'rgba(0, 0, 0, 0.87)',
      fontFamily: '"Lato", "Helvetica", "Arial", sans-serif',
      fontWeight: 400,
      fontSize: '1rem',
      lineHeight: 1.5,
      letterSpacing: '0.00938em',
    },
    body2Next: {
      color: 'rgba(0, 0, 0, 0.87)',
      fontFamily: '"Lato", "Helvetica", "Arial", sans-serif',
      fontWeight: 400,
      fontSize: '0.875rem',
      lineHeight: 1.5,
      letterSpacing: '0.01071em',
    },
    buttonNext: {
      color: 'rgba(0, 0, 0, 0.87)',
      fontFamily: '"Lato", "Helvetica", "Arial", sans-serif',
      fontWeight: 500,
      fontSize: '0.875rem',
      lineHeight: 1.5,
      letterSpacing: '0.02857em',
      textTransform: 'uppercase',
    },
    captionNext: {
      color: 'rgba(0, 0, 0, 0.87)',
      fontFamily: '"Lato", "Helvetica", "Arial", sans-serif',
      fontWeight: 400,
      fontSize: '0.75rem',
      lineHeight: 1.66,
      letterSpacing: '0.03333em',
    },
    overline: {
      color: 'rgba(0, 0, 0, 0.87)',
      fontFamily: '"Lato", "Helvetica", "Arial", sans-serif',
      fontWeight: 400,
      fontSize: '0.75rem',
      lineHeight: 1.2, // 2.66,
      letterSpacing: '0.08333em',
      textTransform: 'uppercase',
    },
  },
  shape: {
    borderRadius: 4,
  },
  spacing: 8,
  transitions: {
    easing: {
      easeInOut: 'cubic-bezier(0.4, 0, 0.2, 1)',
      easeOut: 'cubic-bezier(0.0, 0, 0.2, 1)',
      easeIn: 'cubic-bezier(0.4, 0, 1, 1)',
      sharp: 'cubic-bezier(0.4, 0, 0.6, 1)',
    },
    duration: {
      shortest: 150,
      shorter: 200,
      short: 250,
      standard: 300,
      complex: 375,
      enteringScreen: 225,
      leavingScreen: 195,
    },
  },
  zIndex: {
    mobileStepper: 1000,
    appBar: 1100,
    drawer: 1200,
    modal: 1300,
    snackbar: 1400,
    tooltip: 1500,
  },
};
theme.current = theme[Routes.currentNamespace().toLocaleLowerCase()];
//theme.palette = { ...theme.palette, ...theme.current };
const muiTheme = createTheme(theme);
export default muiTheme;
