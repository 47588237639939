import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  getOrganisationsRequest: ['leagueId'],
  getOrganisationsSuccess: ['organisations'],
  getOrganisationsFailure: ['errors'],

  getLeagueOrganisationsRequest: ['leagueId', 'citySlug'],
  getLeagueOrganisationsSuccess: ['organisations'],
  getLeagueOrganisationsFailure: null,

  addHoldingLeagueRequest: ['leagueId', 'holdingSlug'],
  addHoldingLeagueSuccess: ['league'],
  addHoldingLeagueFailure: null,

  removeHoldingLeagueRequest: ['leagueId', 'holdingSlug'],
  removeHoldingLeagueSuccess: ['league'],
  removeHoldingLeagueFailure: null,

  resetLeagueOrganisations: [],
});

export const OrganisationsLeagueTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  isFetching: {
    getOrganisations: null,
    getLeagueOrganisations: null,
    addHoldingLeague: null,
    removeHoldingLeague: null,
  },
  organisations: [],
  league: null,
  error: null,
});

// -
// getOrganisations
// -
const getOrganisationsRequest = state => ({
  ...state,
  isFetching: { ...state.isFetching, getOrganisations: true },
  organisation: INITIAL_STATE.organisation,
  errors: INITIAL_STATE.errors,
});

const getOrganisationsSuccess = (state, { organisations }) => ({
  ...state,
  isFetching: { ...state.isFetching, getOrganisations: false },
  organisations,
});

const getOrganisationsFailure = (state, { errors }) => ({
  ...state,
  isFetching: { ...state.isFetching, getOrganisations: false },
  errors,
});

/* ------------- Reducers ------------- */
const getLeagueOrganisationsRequest = state => ({
  ...state,
  isFetching: { ...state.isFetching, getLeagueOrganisations: true },
});

const getLeagueOrganisationsSuccess = (state, { organisations }) => ({
  ...state,
  isFetching: { ...state.isFetching, getLeagueOrganisations: false },
  organisations,
});

const getLeagueOrganisationsFailure = state => ({
  ...state,
  isFetching: { ...state.isFetching, getLeagueOrganisations: false },
});

// -
// addHoldingLeague
// -
const addHoldingLeagueRequest = state => ({
  ...state,
  isFetching: { ...state.isFetching, addHoldingLeague: true },
});

const addHoldingLeagueSuccess = (state, { league }) => ({
  ...state,
  isFetching: { ...state.isFetching, addHoldingLeague: true },
  league,
});

const addHoldingLeagueFailure = (state, { error }) => ({
  ...state,
  isFetching: { ...state.isFetching, addHoldingLeague: true },
  error,
});

// -
// removeHoldingLeague
// -
const removeHoldingLeagueRequest = state => ({
  ...state,
  isFetching: { ...state.isFetching, removeHoldingLeague: true },
});

const removeHoldingLeagueSuccess = (state, { league }) => ({
  ...state,
  isFetching: { ...state.isFetching, removeHoldingLeague: true },
  league,
});

const removeHoldingLeagueFailure = (state, { error }) => ({
  ...state,
  isFetching: { ...state.isFetching, removeHoldingLeague: true },
  error,
});

const resetLeagueOrganisations = () => INITIAL_STATE;

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_ORGANISATIONS_REQUEST]: getOrganisationsRequest,
  [Types.GET_ORGANISATIONS_SUCCESS]: getOrganisationsSuccess,
  [Types.GET_ORGANISATIONS_FAILURE]: getOrganisationsFailure,

  [Types.GET_LEAGUE_ORGANISATIONS_REQUEST]: getLeagueOrganisationsRequest,
  [Types.GET_LEAGUE_ORGANISATIONS_SUCCESS]: getLeagueOrganisationsSuccess,
  [Types.GET_LEAGUE_ORGANISATIONS_FAILURE]: getLeagueOrganisationsFailure,

  [Types.ADD_HOLDING_LEAGUE_REQUEST]: addHoldingLeagueRequest,
  [Types.ADD_HOLDING_LEAGUE_SUCCESS]: addHoldingLeagueSuccess,
  [Types.ADD_HOLDING_LEAGUE_FAILURE]: addHoldingLeagueFailure,

  [Types.REMOVE_HOLDING_LEAGUE_REQUEST]: removeHoldingLeagueRequest,
  [Types.REMOVE_HOLDING_LEAGUE_SUCCESS]: removeHoldingLeagueSuccess,
  [Types.REMOVE_HOLDING_LEAGUE_FAILURE]: removeHoldingLeagueFailure,

  [Types.RESET_LEAGUE_ORGANISATIONS]: resetLeagueOrganisations,
});
