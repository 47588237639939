import forEach from 'lodash/forEach';
import isDate from 'lodash/isDate';
import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';
import isBoolean from 'lodash/isBoolean';
import isNumber from 'lodash/isNumber';
/*
convert an {} to a form data compatible with rails format
*/
const hashToFormData = (hash, path) => {
  return appendToFormData(new FormData(), hash, path);
};

export const appendToFormData = (formData, hash, path) => {
  forEach(hash, (value, key) => {
    if (isArray(value)) {
      forEach(value, arrayValue => {
        formData.append(`${path}[${key}][]`, arrayValue);
      });
    } else if (isDate(value)) {
      formData.append(`${path}[${key}]`, value);
    } else if (value instanceof Blob || value instanceof File) {
      formData.append(`${path}[${key}]`, value, value.name || 'file');
    } else if (isObject(value)) {
      forEach(value, (hashValue, hashKey) => {
        if (isArray(hashValue)) {
          forEach(hashValue, arrayValue => {
            formData.append(`${path}[${key}][${hashKey}][]`, arrayValue);
          });
        } else {
          formData.append(`${path}[${key}][${hashKey}]`, hashValue);
        }
      });
    } else if (isBoolean(value)) {
      formData.append(`${path}[${key}]`, value);
    } else if (isNumber(value)) {
      formData.append(`${path}[${key}]`, value);
    } else if (value || value === '') {
      formData.append(`${path}[${key}]`, value);
    }
  });
  return formData;
};

export const attachCover = (formData, path, key, cover) => {
  if (cover && cover.size > 0) {
    formData.append(`${path}[${key}]`, cover, cover.name);
  } else if (cover && cover.size === 0) {
    // Do not send anything to keep old image
  } else if (!cover) {
    // Erase old image
    formData.append(`${path}[${key}]`, '');
  }

  return formData;
};

export const appendCover = (formData, path, key, cover) => {
  if (cover && cover.size > 0) {
    formData.append(`${path}[${key}]`, cover, cover.name);
  } else if (cover) {
    formData.append(`${path}[${key}]`, cover.name || cover.source);
  }

  return formData;
};

export const appendAttachments = (formData, path, key, attachments) => {
  if (attachments && attachments.length > 0) {
    attachments.forEach(file => {
      if (file && file.size > 0) {
        formData.append(`${path}[${key}][]`, file, file.name);
      } else if (file.id) {
        formData.append(`${path}[${key}][]`, file.id);
      } else if (file.options?.file?.id) {
        formData.append(`${path}[${key}][]`, file.options?.file.id);
      } else if (file.name) {
        formData.append(`${path}[${key}][]`, file.name);
      } else if (file.source) {
        formData.append(`${path}[${key}][]`, file.source);
      }
    });
  }

  return formData;
};

export default hashToFormData;
