import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import { List, ListItemAvatar, ListItemText, ListSubheader } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  drawer: {
    width: '80vw',
  },
  logo: {
    alignSelf: 'center',
    padding: theme.spacing(1),
  },
  iconLeft: {
    paddingRight: theme.spacing(1),
  },
  iconRight: {
    paddingLeft: theme.spacing(1),
  },
}));

type Props = {
  open: Boolean,
  onClose: Function,
  logo: String,
  items: Object,
};

const ResponsiveDrawer = ({ open, onClose, logo, items }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [displayItemsPath, setDisplayItemsPath] = React.useState(items);

  const handleClose = () => {
    setDisplayItemsPath(items);
    onClose();
  };

  return (
    <Drawer anchor="left" open={open} onClose={handleClose} classes={{ paper: classes.drawer }}>
      <div className={classes.logo}>
        <img src={logo} height="40" alt="logo" />
      </div>
      <Divider light />
      {displayItemsPath.map(item => (
        <div key={item.title}>
          {item.items && (
            <List
              component="nav"
              aria-labelledby="nested-list-subheader"
              subheader={
                <ListSubheader
                  component="div"
                  id="nested-list-subheader"
                  style={{ position: 'static' }}
                >
                  {t(item.title)}
                </ListSubheader>
              }
            >
              {item?.items &&
                item?.items.map(it => (
                  <div key={it?.title}>
                    {it?.offers?.map(i => (
                      <MenuItem key={i.title} component={Link} to={i.link} onClick={handleClose}>
                        {t(i.title)}
                      </MenuItem>
                    ))}
                    {it?.component && <MenuList onClick={handleClose}>{it?.component}</MenuList>}
                  </div>
                ))}
              <Divider light />
            </List>
          )}

          {!item.items && item.href && (
            <div key={item.title}>
              <MenuItem component="a" href={item.href} target="_blank" onClick={handleClose}>
                {t(item.title)}
              </MenuItem>
              <Divider light />
            </div>
          )}

          {!item.items && item.link && (
            <div key={item.title}>
              <MenuItem component={Link} to={item.link} onClick={handleClose}>
                {item.avatar && <ListItemAvatar>{item.avatar}</ListItemAvatar>}
                <ListItemText primary={t(item.title)} secondary={item.subtitle} />
              </MenuItem>
              <Divider light />
            </div>
          )}
        </div>
      ))}
    </Drawer>
  );
};

export default ResponsiveDrawer;
