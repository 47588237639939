import React from 'react';
import { connect } from 'react-redux';
import loadable from '@loadable/component';
import OrganisationActions from '../Redux/OrganisationRedux';
import ToasterActions from '../Redux/ToasterRedux';
// import { Redirect } from 'react-router';
import { isClientSide } from '../Config/ServerConfig';
const FullScreenLoader = loadable(() => import('../Components/Layout/FullScreenLoader'));
const NoMatch404 = loadable(() => import('../Pages/NoMatch404'));

type Props = {
  match: Object,
  family: Object,
  getFamily: Function,
  showForbiddenToaster: Function,
};

const mapStateToProps = state => ({
  family: state.organisation.family,
  isFetching: state.organisation.isFetching.getFamily,
  errors: state.organisation.errors,
});

const mapDispatchToProps = dispatch => ({
  getFamily: organisationSlug => dispatch(OrganisationActions.getFamilyRequest(organisationSlug)),
  showForbiddenToaster: message => dispatch(ToasterActions.showToaster(message, 'error', 5000)),
});

const WithFamily = WrappedComponent => {
  function fetchFamily(props: Props) {
    if (!isClientSide()) return <FullScreenLoader />;

    const { family, match, getFamily, isFetching, errors, showForbiddenToaster } = props;
    const isGetFamilyError = errors?.familySlug === match.params.family_slug;
    const is404 = errors?.status === 404;
    const is401 = errors?.status === 401;

    if (!isFetching && !isGetFamilyError && (!family || family.slug !== match.params.family_slug)) {
      getFamily(match.params.family_slug);
      return <FullScreenLoader />;
    }
    if (isFetching) return <FullScreenLoader />;
    if (isGetFamilyError && is404) return <NoMatch404 />;
    if (isGetFamilyError && is401) {
      showForbiddenToaster({ id: 'APP.ACL.FAMILY.FORBIDDEN' });
      return <NoMatch404 />;
      // return <Redirect to="/signin" />; // causing pb of infinite loop
    }

    return <WrappedComponent family={family} {...props} />;
  }

  return connect(mapStateToProps, mapDispatchToProps)(fetchFamily);
};

export default WithFamily;
