import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  getUserRequest: ['userId'],
  getUserSuccess: ['user'],
  getUserFailure: null,

  getUserFromEmailRequest: ['email'],
  getUserFromEmailSuccess: ['user'],
  getUserFromEmailFailure: ['errors', 'email'],

  createUserRequest: ['user', 'origin'],
  createUserSuccess: [],
  createUserFailure: null,

  updateUserRequest: ['userId', 'updateAttributes', 'avatar', 'onSuccess'],
  updateUserSuccess: ['user'],
  updateUserFailure: null,

  getUsersRequest: ['query'],
  getUsersSuccess: ['userSuggestions'],
  getUsersFailure: null,

  createHelpedRequest: ['attributes', 'avatar'],
  createHelpedSuccess: ['helpedFamily'],
  createHelpedFailure: null,

  upsertForm: ['form'],

  getUserOrganisationsRequest: ['userId'],
  getUserOrganisationsSuccess: ['result'],
  getUserOrganisationsFailure: null,

  resetUser: [],
  resetHelpers: [],
});

export const UsersTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  isFetching: {
    getUser: false,
    getUserFromEmail: false,
    createUser: false,
    updateUser: false,
    getUsers: false,
    getUserOrganisations: false,
  },
  user: {},
  form: {},
  userSuggestions: [],
  usersOrganisations: {},
  errors: {
    getUser: null,
    getUserFromEmail: null,
    createUser: null,
    updateUser: null,
    getUsers: null,
    getUserOrganisations: null,
  },
});

/* ------------- Reducers ------------- */

// -
// get user from id
// -
const getUserRequest = state => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    getUser: true,
  },
});

const getUserSuccess = (state, { user }) => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    getUser: false,
  },
  errors: {
    ...state.errors,
    getUser: INITIAL_STATE.errors.getUser,
  },
  user,
});

const getUserFailure = (state, { errors }) => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    getUser: false,
  },
  errors: {
    ...state.errors,
    getUser: errors,
  },
});

// -
// get user from email
// -
const getUserFromEmailRequest = state => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    getUserFromEmail: true,
  },
});

const getUserFromEmailSuccess = (state, { user }) => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    getUserFromEmail: false,
  },
  errors: {
    ...state.errors,
    getUserFromEmail: INITIAL_STATE.errors.getUserFromEmail,
  },
  existingUser: user,
});

const getUserFromEmailFailure = (state, { errors, email }) => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    getUserFromEmail: false,
  },
  errors: {
    ...state.errors,
    getUserFromEmail: { errors, email },
  },
});

// -
// create user
// -
const createUserRequest = state => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    createUser: true,
  },
});

const createUserSuccess = state => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    createUser: false,
  },
  errors: {
    ...state.errors,
    createUser: INITIAL_STATE.errors.createUser,
  },
});

const createUserFailure = (state, { errors }) => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    createUser: false,
  },
  errors: {
    ...state.errors,
    createUser: errors,
  },
});

// -
// update user
// -
const updateUserRequest = state => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    updateUser: true,
  },
});

const updateUserSuccess = (state, { user }) => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    updateUser: false,
  },
  errors: {
    ...state.errors,
    updateUser: INITIAL_STATE.errors.updateUser,
  },
  user: state.user?.id === user?.id ? { ...state.user, ...user } : state.user,
});

const updateUserFailure = (state, { errors }) => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    updateUser: false,
  },
  errors: {
    ...state.errors,
    updateUser: errors,
  },
});

// -
// get user from id
// -
const getUsersRequest = state => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    getUsers: true,
  },
});

const getUsersSuccess = (state, { userSuggestions }) => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    getUsers: false,
  },
  errors: {
    ...state.errors,
    getUsers: INITIAL_STATE.errors.getUsers,
  },
  userSuggestions,
});

const getUsersFailure = (state, { errors }) => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    getUsers: false,
  },
  errors: {
    ...state.errors,
    getUsers: errors,
  },
  userSuggestions: INITIAL_STATE.userSuggestions,
});

// -
// create helped
// -
const createHelpedRequest = state => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    createHelped: true,
  },
});
const createHelpedSuccess = (state, { helpedFamily }) => {
  let userFamilies = state.user?.families?.slice();
  if (!userFamilies) userFamilies = [helpedFamily];
  else userFamilies.push(helpedFamily);

  return {
    ...state,
    isFetching: {
      ...state.isFetching,
      createHelped: false,
    },
    errors: {
      ...state.errors,
      createHelped: INITIAL_STATE.errors.createHelped,
    },
    user: { ...state.user, families: userFamilies },
    userUpdated: helpedFamily,
  };
};

const createHelpedFailure = (state, { errors }) => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    createHelped: false,
  },
  errors: {
    ...state.errors,
    createHelped: errors,
  },
});

// -
// upsert form
// -
const upsertForm = (state, { form }) => ({
  ...state,
  form,
});

// -
// get user organisations from id
// -
const getUserOrganisationsRequest = state => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    getUserOrganisations: true,
  },
});

const getUserOrganisationsSuccess = (state, { result }) => {
  const usersOrganisations = { ...state.usersOrganisations };
  usersOrganisations[result.meta.helper_id] = result.organisations;

  return {
    ...state,
    isFetching: {
      ...state.isFetching,
      getUserOrganisations: false,
    },
    errors: {
      ...state.errors,
      getUserOrganisations: INITIAL_STATE.errors.getUserOrganisations,
    },
    usersOrganisations,
  };
};

const getUserOrganisationsFailure = (state, { errors }) => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    getUserOrganisations: false,
  },
  errors: {
    ...state.errors,
    getUserOrganisations: errors,
  },
});

// -
// reset user
// -
const resetUser = () => INITIAL_STATE;

const resetHelpers = state => ({
  ...state,
  userSuggestions: INITIAL_STATE.userSuggestions,
});

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_USER_REQUEST]: getUserRequest,
  [Types.GET_USER_SUCCESS]: getUserSuccess,
  [Types.GET_USER_FAILURE]: getUserFailure,

  [Types.GET_USER_FROM_EMAIL_REQUEST]: getUserFromEmailRequest,
  [Types.GET_USER_FROM_EMAIL_SUCCESS]: getUserFromEmailSuccess,
  [Types.GET_USER_FROM_EMAIL_FAILURE]: getUserFromEmailFailure,

  [Types.CREATE_USER_REQUEST]: createUserRequest,
  [Types.CREATE_USER_SUCCESS]: createUserSuccess,
  [Types.CREATE_USER_FAILURE]: createUserFailure,

  [Types.UPDATE_USER_REQUEST]: updateUserRequest,
  [Types.UPDATE_USER_SUCCESS]: updateUserSuccess,
  [Types.UPDATE_USER_FAILURE]: updateUserFailure,

  [Types.GET_USERS_REQUEST]: getUsersRequest,
  [Types.GET_USERS_SUCCESS]: getUsersSuccess,
  [Types.GET_USERS_FAILURE]: getUsersFailure,

  [Types.CREATE_HELPED_REQUEST]: createHelpedRequest,
  [Types.CREATE_HELPED_SUCCESS]: createHelpedSuccess,
  [Types.CREATE_HELPED_FAILURE]: createHelpedFailure,

  [Types.UPSERT_FORM]: upsertForm,

  [Types.GET_USER_ORGANISATIONS_REQUEST]: getUserOrganisationsRequest,
  [Types.GET_USER_ORGANISATIONS_SUCCESS]: getUserOrganisationsSuccess,
  [Types.GET_USER_ORGANISATIONS_FAILURE]: getUserOrganisationsFailure,

  [Types.RESET_USER]: resetUser,

  [Types.RESET_HELPERS]: resetHelpers,
});
