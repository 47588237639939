import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';
import findAndReplace from './ReduxHelpers';
import { addNewElement } from '../Services/DataHelper';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  getOrganisationClubsRequest: ['holdingSlug', 'organisationSlug'],
  getOrganisationClubsSuccess: ['clubs'],
  getOrganisationClubsFailure: ['errors'],

  getOrganisationClubRequest: ['holdingSlug', 'organisationSlug', 'clubId'],
  getOrganisationClubSuccess: ['club'],
  getOrganisationClubFailure: ['errors'],

  createClubRequest: ['holdingSlug', 'organisationSlug', 'club', 'organisationType'],
  createClubSuccess: ['club'],
  createClubFailure: ['errors'],

  updateClubRequest: ['holdingSlug', 'organisationSlug', 'club'],
  updateClubSuccess: ['club'],
  updateClubFailure: ['errors'],

  destroyClubRequest: ['holdingSlug', 'organisationSlug', 'clubId', 'organisationType'],
  destroyClubSuccess: ['clubs'],
  destroyClubFailure: ['errors'],

  createClubMembershipsRequest: [
    'holdingSlug',
    'organisationSlug',
    'clubId',
    'membersIds',
    'organisationType',
  ],
  createClubMembershipsSuccess: ['club'],
  createClubMembershipsFailure: ['errors'],

  destroyClubMembershipRequest: ['holdingSlug', 'organisationSlug', 'clubId', 'membershipId'],
  destroyClubMembershipSuccess: ['club'],
  destroyClubMembershipFailure: ['errors'],

  getClubPostsRequest: ['holdingSlug', 'organisationSlug', 'clubId', 'page', 'lastPostDate'],
  getClubPostsSuccess: ['posts', 'totalPosts', 'page'],
  getClubPostsFailure: ['errors'],

  createClubPostRequest: ['holdingSlug', 'organisationSlug', 'clubId', 'post'],
  createClubPostSuccess: ['clubId', 'post', 'resetDate'],
  createClubPostFailure: ['errors'],

  resetPosts: [],

  resetClubs: [],
});

export const ClubsTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  isFetching: {
    getOrganisationClubs: false,
    getOrganisationClub: false,
    updateClub: false,
    destroyClub: false,
    createClubMemberships: false,
    updateClubMembership: false,
    destroyClubMembership: false,
    getClubPostsRequest: false,
    createClubPostRequest: false,
    getClubPosts: false,
  },
  clubs: [],
  posts: [],
  totalPosts: 0,
  club: null,
  memberShip: null,
  errors: {
    getOrganisationClubs: null,
    getOrganisationClub: null,
    updateClub: null,
    destroyClub: null,
    createClubMemberships: null,
    updateClubMembership: null,
    destroyClubMembership: null,
    getClubPostsRequest: null,
    createClubPostRequest: null,
    getClubPosts: null,
  },
});

/* ------------- Reducers ------------- */
// -
// getOrganisationClubs
// -

const getOrganisationClubsRequest = state => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    getOrganisationClubs: true,
  },
});

const getOrganisationClubsSuccess = (state, { clubs }) => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    getOrganisationClubs: false,
  },
  errors: {
    ...state.errors,
    getOrganisationClubs: INITIAL_STATE.errors.getOrganisationClubs,
  },
  clubs,
});

const getOrganisationClubsFailure = (state, { errors }) => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    getOrganisationClubs: false,
  },
  clubs: [],
  errors: {
    ...state.errors,
    getOrganisationClubs: errors,
  },
});

//
// get one club
//

const getOrganisationClubRequest = state => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    getOrganisationClub: true,
  },
  errors: {
    ...state.errors,
    getOrganisationClub: INITIAL_STATE.errors.getOrganisationClub,
  },
});

const getOrganisationClubSuccess = (state, { club }) => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    getOrganisationClub: false,
  },
  errors: {
    ...state.errors,
    getOrganisationClub: INITIAL_STATE.errors.getOrganisationClub,
  },
  club,
});

const getOrganisationClubFailure = (state, { errors }) => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    getOrganisationClub: false,
  },
  errors: {
    ...state.errors,
    getOrganisationClub: errors,
  },
});

//
// createClub
//
const createClubRequest = state => ({
  ...state,
  isFetching: {
    ...INITIAL_STATE.isFetching,
    createClub: true,
  },
  errors: {
    ...INITIAL_STATE.errors,
    createClub: null,
  },
});

const createClubSuccess = (state, { club }) => {
  let { clubs: newClubs } = state;
  if (newClubs) newClubs.push(club);
  else newClubs = [club];

  return {
    ...state,
    isFetching: {
      ...INITIAL_STATE.isFetching,
      createClub: false,
    },
    errors: {
      ...INITIAL_STATE.errors,
      createClub: null,
    },
    club,
    clubs: newClubs,
  };
};

const createClubFailure = (state, { errors }) => ({
  ...state,
  isFetching: {
    ...INITIAL_STATE.isFetching,
    createClub: false,
  },
  errors: {
    ...INITIAL_STATE.errors,
    createClub: errors,
  },
});

//
// updateClub
//
const updateClubRequest = state => ({
  ...state,
  isFetching: {
    ...INITIAL_STATE.isFetching,
    updateClub: true,
  },
  errors: {
    ...INITIAL_STATE.errors,
    updateClub: null,
  },
});

const updateClubSuccess = (state, { club }) => ({
  ...state,
  isFetching: {
    ...INITIAL_STATE.isFetching,
    updateClub: false,
  },
  errors: {
    ...INITIAL_STATE.errors,
    updateClub: null,
  },
  club,
  clubs: findAndReplace(c => c.id === club.id, club)(state.clubs),
});

const updateClubFailure = (state, { errors }) => ({
  ...state,
  isFetching: {
    ...INITIAL_STATE.isFetching,
    updateClub: false,
  },
  errors: {
    ...INITIAL_STATE.errors,
    updateClub: errors,
  },
});

//
// destroyClub
//
const destroyClubRequest = state => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    destroyClub: true,
  },
  errors: {
    ...state.errors,
    destroyClub: INITIAL_STATE.errors.destroyClub,
  },
});
const destroyClubSuccess = (state, { clubs }) => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    destroyClub: false,
  },
  errors: {
    ...state.errors,
    destroyClub: INITIAL_STATE.errors.destroyClub,
  },
  clubs,
});
const destroyClubFailure = (state, { errors }) => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    destroyClub: false,
  },
  errors: {
    ...state.errors,
    destroyClub: errors,
  },
});

//
// Add club members
//
const createClubMembershipsRequest = state => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    createClubMemberships: true,
  },
  errors: {
    ...state.errors,
    createClubMemberships: INITIAL_STATE.errors.createClubMemberships,
  },
});
const createClubMembershipsSuccess = (state, { club }) => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    createClubMemberships: false,
  },
  errors: {
    ...state.errors,
    createClubMemberships: INITIAL_STATE.errors.createClubMemberships,
  },
  club,
  clubs: findAndReplace(c => c.id === club.id, club)(state.clubs),
});
const createClubMembershipsFailure = (state, { errors }) => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    createClubMemberships: false,
  },
  errors: {
    ...state.errors,
    createClubMemberships: errors,
  },
});

//
// destroy club member
//
const destroyClubMembershipRequest = state => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    destroyClubMembership: true,
  },
  errors: {
    ...state.errors,
    destroyClubMembership: INITIAL_STATE.errors.destroyClubMembership,
  },
});
const destroyClubMembershipSuccess = (state, { club }) => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    destroyClubMembership: false,
  },
  errors: {
    ...state.errors,
    destroyClubMembership: INITIAL_STATE.errors.destroyClubMembership,
  },
  club,
  clubs: findAndReplace(c => c.id === club.id, club)(state.clubs),
});
const destroyClubMembershipFailure = (state, { errors }) => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    destroyClubMembership: false,
  },
  errors: {
    ...state.errors,
    destroyClubMembership: errors,
  },
});

//
// get club posts
//

const getClubPostsRequest = state => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    getClubPosts: true,
  },
  errors: {
    ...state.errors,
    getClubPosts: INITIAL_STATE.errors.getClubPosts,
  },
});

const getClubPostsSuccess = (state, { posts, totalPosts, page }) => {
  let { posts: allPosts } = state;

  if (page === 1) allPosts = [];

  posts.forEach(post => {
    allPosts = addNewElement(allPosts, post);
  });

  return {
    ...state,
    isFetching: {
      ...state.isFetching,
      getClubPosts: false,
    },
    errors: {
      ...state.errors,
      getClubPosts: INITIAL_STATE.errors.getClubPosts,
    },
    posts: allPosts,
    totalPosts,
  };
};

const getClubPostsFailure = (state, { errors }) => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    getClubPosts: false,
  },
  errors: {
    ...state.errors,
    getClubPosts: errors,
  },
});

//
// create post
//
const createClubPostRequest = state => ({
  ...state,
  isFetching: {
    ...INITIAL_STATE.isFetching,
    createClubPost: true,
  },
  errors: {
    ...INITIAL_STATE.errors,
    createClubPost: null,
  },
});

const createClubPostSuccess = (state, { clubId, post }) => {
  let { posts } = state;

  if (`${clubId}` === `${state.club.id}`) {
    posts = addNewElement(posts, post);
  }

  return {
    ...state,
    isFetching: {
      ...INITIAL_STATE.isFetching,
      createClubPost: false,
    },
    errors: {
      ...INITIAL_STATE.errors,
      createClubPost: null,
    },
    posts,
    totalPosts: state.totalPosts + 1,
  };
};

const createClubPostFailure = (state, { errors }) => ({
  ...state,
  isFetching: {
    ...INITIAL_STATE.isFetching,
    createClubPost: false,
  },
  errors: {
    ...INITIAL_STATE.errors,
    createClubPost: errors,
  },
});

const resetPosts = state => ({
  ...state,
  posts: null,
  totalPosts: 0,
});

const resetClubs = () => INITIAL_STATE;

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_ORGANISATION_CLUBS_REQUEST]: getOrganisationClubsRequest,
  [Types.GET_ORGANISATION_CLUBS_SUCCESS]: getOrganisationClubsSuccess,
  [Types.GET_ORGANISATION_CLUBS_FAILURE]: getOrganisationClubsFailure,

  [Types.GET_ORGANISATION_CLUB_REQUEST]: getOrganisationClubRequest,
  [Types.GET_ORGANISATION_CLUB_SUCCESS]: getOrganisationClubSuccess,
  [Types.GET_ORGANISATION_CLUB_FAILURE]: getOrganisationClubFailure,

  [Types.CREATE_CLUB_REQUEST]: createClubRequest,
  [Types.CREATE_CLUB_SUCCESS]: createClubSuccess,
  [Types.CREATE_CLUB_FAILURE]: createClubFailure,

  [Types.UPDATE_CLUB_REQUEST]: updateClubRequest,
  [Types.UPDATE_CLUB_SUCCESS]: updateClubSuccess,
  [Types.UPDATE_CLUB_FAILURE]: updateClubFailure,

  [Types.DESTROY_CLUB_REQUEST]: destroyClubRequest,
  [Types.DESTROY_CLUB_SUCCESS]: destroyClubSuccess,
  [Types.DESTROY_CLUB_FAILURE]: destroyClubFailure,

  [Types.CREATE_CLUB_MEMBERSHIPS_REQUEST]: createClubMembershipsRequest,
  [Types.CREATE_CLUB_MEMBERSHIPS_SUCCESS]: createClubMembershipsSuccess,
  [Types.CREATE_CLUB_MEMBERSHIPS_FAILURE]: createClubMembershipsFailure,

  [Types.DESTROY_CLUB_MEMBERSHIP_REQUEST]: destroyClubMembershipRequest,
  [Types.DESTROY_CLUB_MEMBERSHIP_SUCCESS]: destroyClubMembershipSuccess,
  [Types.DESTROY_CLUB_MEMBERSHIP_FAILURE]: destroyClubMembershipFailure,

  [Types.GET_CLUB_POSTS_REQUEST]: getClubPostsRequest,
  [Types.GET_CLUB_POSTS_SUCCESS]: getClubPostsSuccess,
  [Types.GET_CLUB_POSTS_FAILURE]: getClubPostsFailure,

  [Types.CREATE_CLUB_POST_REQUEST]: createClubPostRequest,
  [Types.CREATE_CLUB_POST_SUCCESS]: createClubPostSuccess,
  [Types.CREATE_CLUB_POST_FAILURE]: createClubPostFailure,

  [Types.RESET_POSTS]: resetPosts,
  [Types.RESET_CLUBS]: resetClubs,
});
