import { green, orange, grey } from '@material-ui/core/colors';
import { isCommunity, isFamily } from '../Acl/Controls';

const getHoldingAndOrgaName = (holdingName, organisationName) => {
  if (holdingName.toLowerCase().includes(organisationName.toLowerCase())) {
    return holdingName;
  }
  return [holdingName, organisationName].reduce((acc, item) =>
    acc.length > 0 && item && item !== '' ? `${acc} - ${item}` : `${acc}${item}`,
  );
};

export const getOrgaName = orga => {
  if (!orga || (!orga.name && !orga.holding_name)) return '';
  if (!orga.name) return orga.holding_name;
  if (!orga.holding_name) return orga.name;

  if (isCommunity(orga) || isFamily(orga)) return orga.name;

  return `${orga.holding_name} - ${orga.name}`;
};

export default getHoldingAndOrgaName;

export const getStatus = holding => {
  let icon = {};
  if (holding?.admin_status && holding?.admin_status !== 'waiting_for_email_validation') {
    icon = {
      color: green[600],
      title: 'APP.LEAGUES.SETTINGS.HOLDING.HAS_ADMIN',
      status: 1,
    };
  } else if (holding?.admin_status && holding?.admin_status === 'waiting_for_email_validation') {
    icon = {
      color: orange[600],
      title: 'APP.LEAGUES.SETTINGS.HOLDING.HAS_ADMIN_NOT_VALIDATED',
      status: 2,
    };
  } else if (!holding?.admin_status) {
    icon = {
      color: grey[500],
      title: 'APP.LEAGUES.SETTINGS.HOLDING.HAS_NOT_ADMIN',
      status: 3,
    };
  } else
    icon = {
      color: '#fff',
      title: '',
      status: 4,
    };
  return icon;
};

export const organisationSlaCTA = {
  id: 1978,
  name: 'Lille',
  description: null,
  slug: 'lille',
  banner_url:
    'https://wello-avatars.s3.eu-west-1.amazonaws.com/1fa29f91-5e21-4c3b-9040-37d7369a055a',
  holding: {
    id: 181,
    league_id: 6,
    name: 'SLA Administratif',
    cover_url:
      'https://wello-avatars.s3.eu-west-1.amazonaws.com/1a38f1e0-871b-4af0-9221-1ee7923e5850',
    slug: 'sla-administratif',
  },
};

export const organisationNeCTA = {
  id: 4690,
  name: 'Nantes',
  description: null,
  slug: 'nantes',
  banner_url:
    'https://wello-avatars.s3.eu-west-1.amazonaws.com/60a5d0a1-62fe-40a3-93bc-97999f941f3b',
  holding: {
    id: 568,
    league_id: 203,
    name: 'Manou Partages',
    cover_url:
      'https://wello-avatars.s3.eu-west-1.amazonaws.com/d3584c77-6e90-464e-9a96-3768366d40fb',
    slug: 'manou-partages',
  },
};

export const organisationUnaCTA = {
  id: 1010,
  name: "Besoin d'aide à domicile",
  description:
    "Trouvez près de chez vous une aide à domicile de confiance. Un savoir-faire, l'humain avant tout, le personnel ...",
  slug: 'una-administratif',
  banner_url: '/images/leagues/una/besoin_aide_a_domicile.jpg',
  holding: {
    id: 57,
    league_id: 1,
    name: 'UNA National',
    cover_url: '/images/leagues/una/logo_circle.jpg',
    slug: 'una-national',
  },
};

export const organisationChbCTA = {
  id: 3857,
  name: 'Raimbeaucourt',
  description: null,
  slug: 'centre-helene-borel',
  banner_url:
    'https://wello-avatars.s3.eu-west-1.amazonaws.com/9e012979-2882-4263-8fda-587805ce8f31',
  holding: {
    id: 533,
    league_id: 201,
    name: 'Centre Hélène Borel - Plateforme de services',
    cover_url:
      'https://wello-avatars.s3.eu-west-1.amazonaws.com/07bdc583-f3e6-4d2a-b59a-5a0f979e8c66',
    slug: 'aidants',
  },
};

export const organisationGlobalCTA = {
  id: 4361,
  name: 'Rennes',
  description: null,
  slug: 'rennes',
  banner_url:
    'https://wello-avatars.s3.eu-west-1.amazonaws.com/83e786a5-00b4-4ffd-ab45-aad2b6cd98b7',
  holding: {
    id: 557,
    league_id: 6,
    name: 'Bistrot Mémoire Rennais',
    cover_url:
      'https://wello-avatars.s3.eu-west-1.amazonaws.com/64861584-80ca-45e5-8cbf-567bfc7471db',
    slug: 'bmr',
  },
};
