import { call, put } from 'redux-saga/effects';
import _ from 'lodash';
import WebNotificationSubscriptionsActions from '../Redux/WebNotificationSubscriptionsRedux';
import ToasterActions from '../Redux/ToasterRedux';
import { toastResponseError } from '../Services/ApiErrorHelpers';

export function* createWebNotificationSubscription(
  api,
  { holdingSlug, organisationSlug, userId, subscription },
) {
  const response = yield call(
    api.createWebNotificationSubscription,
    holdingSlug,
    organisationSlug,
    userId,
    subscription,
  );
  if (response.ok) {
    yield put(WebNotificationSubscriptionsActions.createWebNotificationSubscriptionSuccess(true));
    yield put(ToasterActions.showToaster({ id: 'UPDATE_SUCCESS' }, 'success', 5000));
  } else {
    yield toastResponseError(response);
    const errors = _.get(response, 'data.errors');
    yield put(WebNotificationSubscriptionsActions.createWebNotificationSubscriptionFailure(errors));
  }
}

export function* checkWebNotificationSubscription(
  api,
  { holdingSlug, organisationSlug, userId, deviceId },
) {
  const response = yield call(
    api.checkWebNotificationSubscription,
    holdingSlug,
    organisationSlug,
    userId,
    deviceId,
  );
  if (response.ok) {
    yield put(WebNotificationSubscriptionsActions.checkWebNotificationSubscriptionSuccess(true));
  } else if (response.status === 404) {
    yield put(WebNotificationSubscriptionsActions.checkWebNotificationSubscriptionSuccess(false));
  } else {
    yield toastResponseError(response);
    const errors = _.get(response, 'data.errors');
    yield put(WebNotificationSubscriptionsActions.checkWebNotificationSubscriptionFailure(errors));
  }
}

export function* destroyWebNotificationSubscription(
  api,
  { holdingSlug, organisationSlug, userId, deviceId },
) {
  const response = yield call(
    api.destroyWebNotificationSubscription,
    holdingSlug,
    organisationSlug,
    userId,
    deviceId,
  );
  if (response.ok) {
    yield put(WebNotificationSubscriptionsActions.destroyWebNotificationSubscriptionSuccess(false));
  } else {
    yield toastResponseError(response);
    const errors = _.get(response, 'data.errors');
    yield put(
      WebNotificationSubscriptionsActions.destroyWebNotificationSubscriptionFailure(errors),
    );
  }
}
