import fp from 'lodash/fp';

function findAndReplace(searchPredicate, replaceWith) {
  return fp.map(element => (searchPredicate(element) ? replaceWith : element));
}

export function findAndRemove(searchPredicate) {
  return fp.filter(element => !searchPredicate(element));
}

export default findAndReplace;
