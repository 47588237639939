import { call, put } from 'redux-saga/effects';

import _ from 'lodash';
import AuthActions from '../Redux/AuthRedux';
import UsersActions from '../Redux/UsersRedux';
import ToasterActions from '../Redux/ToasterRedux';
import OrganisationActions from '../Redux/OrganisationRedux';
import LeagueActions from '../Redux/LeaguesRedux';
import { toastResponseError } from '../Services/ApiErrorHelpers';

export function* getUser(api, { userId }) {
  const response = yield call(api.getUser, userId);
  if (response.ok) {
    yield put(UsersActions.getUserSuccess(response.data.helper));
    yield put(AuthActions.signInSuccess(response.data.helper));
  } else {
    yield toastResponseError(response);
    const errors = _.get(response, 'data.errors');
    yield put(UsersActions.getUserFailure(errors));

    if (response?.status === 401 && response?.data?.error?.code === 'CANNOT_FIND_HELPER') {
      yield put(AuthActions.resetOnLogout());
    }
  }
}

export function* getUserFromEmail(api, { email }) {
  const response = yield call(api.getUserFromEmail, email);
  if (response.ok) {
    yield put(UsersActions.getUserFromEmailSuccess(response.data.helper));
  } else {
    const errors = _.get(response, 'data.errors');
    yield put(UsersActions.getUserFromEmailFailure(errors, email));
  }
}

export function* createUser(api, { user, origin }) {
  const response = yield call(api.createUser, user, origin);
  if (response.ok) {
    yield put(UsersActions.createUserSuccess());
    yield put(ToasterActions.showToaster({ id: 'CREATE_SUCCESS' }, 'success', 5000));
  } else {
    yield toastResponseError(response);
    const errors = _.get(response, 'data.errors');
    yield put(UsersActions.createUserFailure(errors));
  }
}

export function* updateUser(api, { userId, updateAttributes, avatar, onSuccess }) {
  const response = yield call(api.updateUser, userId, updateAttributes, avatar);
  if (response.ok) {
    yield put(UsersActions.updateUserSuccess(response.data.helper));
    yield put(OrganisationActions.updateFamilyHelper(response.data.helper));
    yield put(AuthActions.signInSuccess(response.data.helper));
    yield put(LeagueActions.updateLeagueHelperSuccess(response.data.helper));
    yield put(ToasterActions.showToaster({ id: 'UPDATE_SUCCESS' }, 'success', 5000));
    if (onSuccess) {
      yield call(onSuccess);
    }
  } else {
    yield toastResponseError(response);
    const errors = _.get(response, 'data.errors');
    yield put(UsersActions.updateUserFailure(errors));
  }
}

export function* getUsers(api, { query }) {
  const response = yield call(api.getUsers, query);
  if (response.ok) {
    yield put(UsersActions.getUsersSuccess(response.data.helpers));
  } else {
    yield toastResponseError(response);
    const errors = _.get(response, 'data.errors');
    yield put(UsersActions.getUsersFailure(errors));
  }
}

export function* createHelped(api, { attributes, avatar }) {
  const response = yield call(api.createHelped, attributes, avatar);
  if (response.ok) {
    yield put(UsersActions.createHelpedSuccess(response.data.helped_family));
    yield put(AuthActions.addCurrentUserFamily(response.data.helped_family));
    yield put(OrganisationActions.addFamilyToAdmin(response.data.helped_family));
    yield put(ToasterActions.showToaster({ id: 'UPDATE_SUCCESS' }, 'success', 5000));
  } else {
    yield toastResponseError(response);
    const errors = _.get(response, 'data.errors');
    yield put(UsersActions.createHelpedFailure(errors));
  }
}

export function* getUserOrganisations(api, { userId }) {
  const response = yield call(api.getUserOrganisations, userId);
  if (response.ok) {
    yield put(UsersActions.getUserOrganisationsSuccess(response.data));
  } else {
    // yield toastResponseError(response);
    const errors = _.get(response, 'data.errors');
    yield put(UsersActions.getUserOrganisationsFailure(errors));
  }
}
