import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Badge from '@material-ui/core/Badge';
import WelloAvatar from '../Components/Shared/WelloAvatar';
import images from '../Themes/Images';
import { getExpertiseStyle } from '../Themes/Expertises';

const hasUnreadNotification = user => {
  if (!user || !user.notifications || user.notifications.length === 0) return false;

  if (!user.last_notif_reading_date) return true;
  return user.notifications.some(
    notif => notif.created_at.localeCompare(user.last_notif_reading_date) > 0,
  );
};

export const getNotificationAvatar = notif => {
  if (!notif.author && !notif.holding_cover_url && !notif.family_admin)
    return <Avatar src={images.logo.wello_circle} style={{ height: 36, width: 36 }} />;

  const authorAttributes = notif.author
    ? {
        avatarUrl: notif.author.avatar_url,
        firstName: notif.author.first_name,
        lastName: notif.author.last_name,
        backgroundColor: notif.author.avatar_background_color,
      }
    : null;

  const orgaAttributes = notif.holding_cover_url
    ? {
        avatarUrl: notif.holding_cover_url,
      }
    : null;

  const familyAttributes = notif.family_admin
    ? {
        avatarUrl: notif.family_admin.avatar_url,
        firstName: notif.family_admin.first_name,
        lastName: notif.family_admin.last_name,
        backgroundColor: notif.family_admin.avatar_background_color,
      }
    : null;

  const bigAvatar = notif.family_admin ? (
    <WelloAvatar
      {...familyAttributes}
      size={64}
      avatarStyle={{ height: 36, width: 36, fontSize: '1rem' }}
    />
  ) : (
    <WelloAvatar
      {...orgaAttributes}
      size={64}
      avatarStyle={{ height: 36, width: 36, fontSize: '1rem' }}
    />
  );

  const smallAvatar = notif.expertise_slug ? (
    <Avatar
      style={{
        height: 20,
        width: 20,
        backgroundColor: 'white',
        color: getExpertiseStyle({ slug: notif.expertise_slug }).color,
      }}
    >
      {getExpertiseStyle({ slug: notif.expertise_slug }).icon}
    </Avatar>
  ) : (
    (notif.author && (
      <WelloAvatar
        {...authorAttributes}
        size={40}
        avatarStyle={{ height: 20, width: 20, fontSize: '0.72rem' }}
      />
    )) ||
    null
  );

  if (!smallAvatar) return bigAvatar;

  return (
    <Badge
      overlap="circular"
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      style={{ zIndex: 0 }}
      badgeContent={smallAvatar}
    >
      {bigAvatar}
    </Badge>
  );
};

export default hasUnreadNotification;
