import React from 'react';
import { connect } from 'react-redux';
import loadable from '@loadable/component';
import HoldingActions from '../Redux/HoldingRedux';
const FullScreenLoader = loadable(() => import('../Components/Layout/FullScreenLoader'));
const NoMatch404 = loadable(() => import('../Pages/NoMatch404'));

type Props = {
  match: Object,
  holding: Object,
  getHolding: Function,
};

const mapStateToProps = state => ({
  holding: state.holding.holding,
  errors: state.holding.errors?.getHolding,
});

const mapDispatchToProps = dispatch => ({
  getHolding: holdingSlug => dispatch(HoldingActions.getHoldingRequest(holdingSlug)),
});

const withHolding = (WrappedComponent, urlPath) => {
  function fetchHolding(props: Props) {
    const { holding, errors, match, getHolding } = props;
    const isHoldingLoaded = holding;
    const hasHoldingChanged = holding && `${holding.slug}` !== match.params.holding_slug;

    if (!isHoldingLoaded && errors) {
      const searchHasChanged = errors.holding_slug !== match.params.holding_slug;
      if (!searchHasChanged) return <NoMatch404 />;
    }

    if (match.params.holding_slug && (!isHoldingLoaded || hasHoldingChanged)) {
      getHolding(match.params.holding_slug);
      return <FullScreenLoader />;
    }
    return <WrappedComponent holding={holding} {...props} urlPath={urlPath} />;
  }

  return connect(mapStateToProps, mapDispatchToProps)(fetchHolding);
};

export default withHolding;
