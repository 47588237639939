import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

type Props = {
  classes: Object,
  requesting: Boolean,
};
const styles = {
  progress: {
    zIndex: 10000,
    position: 'fixed',
    width: '100%',
    top: 0,
  },
};

function RequestProgress(props: Props) {
  const { requesting, classes } = props;
  if (requesting) {
    return <LinearProgress className={classes.progress} />;
  }
  return null;
}

const mapStateToProps = state => ({
  requesting: state.requestResponseDecorator.requesting,
});
export default connect(mapStateToProps)(withStyles(styles)(RequestProgress));
