import { call, put } from 'redux-saga/effects';

import _ from 'lodash';
import { toastResponseError } from '../Services/ApiErrorHelpers';

import TasksActions from '../Redux/TasksRedux';
import ToasterActions from '../Redux/ToasterRedux';

import history from '../Routing/CurrentHistory';

export function* getTasksByLeague(api, { leagueId }) {
  const response = yield call(api.getTasksByLeague, leagueId);

  if (response.ok) {
    yield put(TasksActions.getTasksByLeagueSuccess(response.data.tasks));
  } else {
    yield put(TasksActions.getTasksByLeagueFailure());
  }
}

export function* createTask(
  api,
  { holdingSlug, organisationSlug, familySlug, task, cover, duplicatedTaskSlug },
) {
  const response = yield call(
    api.createTask,
    holdingSlug,
    organisationSlug,
    familySlug,
    task,
    cover,
    duplicatedTaskSlug,
  );

  if (response.ok) {
    if (familySlug) {
      yield call(history.push, `/families/${familySlug}/schedule`);
    } else {
      yield call(history.push, `/${holdingSlug}/${organisationSlug}/activites`);
    }
    yield put(TasksActions.createTaskSuccess(response.data.task));
    yield put(
      ToasterActions.showToaster({ id: 'APP.ACTIVITIES.CREATION_CONFIRMATION' }, 'success', 5000),
    );
  } else {
    yield toastResponseError(response);
    const errors = _.get(response, 'data.errors');
    yield put(TasksActions.createTaskFailure(errors));
  }
}

export function* updateTask(api, { holdingSlug, organisationSlug, familySlug, task, cover }) {
  const response = yield call(
    api.updateTask,
    holdingSlug,
    organisationSlug,
    familySlug,
    task,
    cover,
  );

  if (response.ok) {
    yield put(TasksActions.updateTaskSuccess(response.data.task));
    yield put(ToasterActions.showToaster({ id: 'UPDATE_SUCCESS' }, 'success', 5000));
    if (familySlug) {
      yield call(history.push, `/families/${familySlug}/schedule`);
    } else {
      yield call(history.push, `/${holdingSlug}/${organisationSlug}/activites`);
    }
  } else {
    yield toastResponseError(response);
    const errors = _.get(response, 'data.errors');
    yield put(TasksActions.updateTaskFailure(errors));
  }
}

export function* skipOccurence(
  api,
  { holdingSlug, organisationSlug, familySlug, taskSlug, eventDate },
) {
  const response = yield call(
    api.skipOccurence,
    holdingSlug,
    organisationSlug,
    familySlug,
    taskSlug,
    eventDate,
  );

  if (response.ok) {
    yield put(TasksActions.skipOccurenceSuccess(taskSlug, eventDate));
  } else {
    yield toastResponseError(response);
    const errors = _.get(response, 'data.errors');
    yield put(TasksActions.destroyTaskFailure(errors));
  }
}

export function* detachTaskOccurrence(
  api,
  { holdingSlug, organisationSlug, familySlug, taskSlug, eventDate, taskAttributes, cover },
) {
  const response = yield call(
    api.detachTaskOccurrence,
    holdingSlug,
    organisationSlug,
    familySlug,
    taskSlug,
    eventDate,
    taskAttributes,
    cover,
  );
  if (response.ok) {
    yield put(TasksActions.skipOccurenceSuccess(taskSlug, eventDate));
    yield put(TasksActions.detachTaskOccurrenceSuccess(response.data.task));
    yield put(ToasterActions.showToaster({ id: 'UPDATE_SUCCESS' }, 'success', 5000));
  } else {
    yield toastResponseError(response);
    const errors = _.get(response, 'data.errors');
    yield put(TasksActions.detachTaskOccurrenceFailure(errors));
  }
}

export function* destroyTask(api, { holdingSlug, organisationSlug, familySlug, taskSlug }) {
  const response = yield call(api.destroyTask, holdingSlug, organisationSlug, familySlug, taskSlug);

  if (response.ok) {
    yield put(TasksActions.destroyTaskSuccess(taskSlug));
  } else {
    yield toastResponseError(response);
    const errors = _.get(response, 'data.errors');
    yield put(TasksActions.destroyTaskFailure(errors));
  }
}

export function* getTask(api, { holdingSlug, organisationSlug, familySlug, taskSlug, eventDate }) {
  const response = yield call(
    api.getTask,
    holdingSlug,
    organisationSlug,
    familySlug,
    taskSlug,
    eventDate,
  );

  if (response.ok) {
    yield put(TasksActions.getTaskSuccess(response.data.task));
  } else {
    yield toastResponseError(response);
    yield put(
      TasksActions.getTaskFailure({
        ...response.data,
        status: response.status,
        holding_slug: holdingSlug,
        organisation_slug: organisationSlug || familySlug,
        task_slug: taskSlug,
      }),
    );
  }
}

export function* registerHelperOnTask(
  api,
  {
    holdingSlug,
    organisationSlug,
    familySlug,
    taskSlug,
    helperId,
    eventDate,
    helpedId,
    helperAttributes,
  },
) {
  const response = yield call(
    api.registerHelperOnTask,
    holdingSlug,
    organisationSlug,
    familySlug,
    taskSlug,
    helperId,
    eventDate,
    helpedId,
    helperAttributes,
  );
  if (response.ok) {
    yield put(
      TasksActions.registerHelperOnTaskSuccess(response.data.twig, response.data.meta?.helper),
    );
    // yield put(ToasterActions.showToaster({ id: 'APP.ACTIVITY.REGISTERED' }, 'success', 5000));
  } else {
    yield toastResponseError(response);
    const errors = _.get(response, 'data.error');
    yield put(TasksActions.registerHelperOnTaskFailure(errors));
  }
}

export function* unregisterHelperOnTask(
  api,
  { holdingSlug, organisationSlug, familySlug, taskSlug, helperId, eventDate, currentFamilySlug },
) {
  const response = yield call(
    api.unregisterHelperOnTask,
    holdingSlug,
    organisationSlug,
    familySlug,
    taskSlug,
    helperId,
    eventDate,
  );
  if (response.ok) {
    yield put(TasksActions.unregisterHelperOnTaskSuccess(response.data.twig, currentFamilySlug));
  } else {
    yield toastResponseError(response);
    const errors = _.get(response, 'data.error');
    yield put(TasksActions.unregisterHelperOnTaskFailure(errors));
  }
}

export function* validateHelperOnTask(
  api,
  { holdingSlug, organisationSlug, taskSlug, helperId, eventDate },
) {
  const response = yield call(
    api.validateHelperOnTask,
    holdingSlug,
    organisationSlug,
    taskSlug,
    helperId,
    eventDate,
  );
  if (response.ok) {
    yield put(TasksActions.validateHelperOnTaskSuccess(response.data.twig));
  } else {
    yield toastResponseError(response);
    const errors = _.get(response, 'data.error');
    yield put(TasksActions.validateHelperOnTaskFailure(errors));
  }
}

export function* getTwigs(
  api,
  { holdingSlug, organisationSlug, familySlug, endDate, startDate, reset },
) {
  const response = yield call(
    api.getTwigs,
    holdingSlug,
    organisationSlug,
    familySlug,
    endDate,
    startDate,
  );

  if (response.ok) {
    yield put(TasksActions.getTwigsSuccess(response.data.twigs, reset));
  } else {
    yield toastResponseError(response);
    yield put(TasksActions.getTwigsFailure(response.data));
  }
}

export function* getTwig(api, { holdingSlug, organisationSlug, familySlug, twigId }) {
  const response = yield call(api.getTwig, holdingSlug, organisationSlug, familySlug, twigId);

  if (response.ok) {
    yield put(TasksActions.getTwigSuccess(response.data.twig));
  } else {
    yield toastResponseError(response);
    yield put(TasksActions.getTwigFailure(response.data));
  }
}

export function* createTaskPost(api, { holdingSlug, organisationSlug, taskId, post }) {
  const response = yield call(api.createPost, holdingSlug, organisationSlug, taskId, post);
  if (response.ok) {
    yield put(TasksActions.createTaskPostSuccess(taskId, response.data.post));
  } else {
    yield toastResponseError(response);
    yield put(TasksActions.createTaskPostFailure(response.data));
  }
}
