import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';
import { addNewElement } from '../Services/DataHelper';
import findAndReplace from './ReduxHelpers';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  // get
  getCareNotebookPostsRequest: ['familySlug', 'page'],
  getCareNotebookPostsSuccess: [
    'familySlug',
    'careNotebookPosts',
    'totalposts',
    'importantPosts',
    'page',
  ],
  getCareNotebookPostsFailure: ['errors'],

  // show
  getCareNotebookPostRequest: ['familySlug', 'postId'],
  getCareNotebookPostSuccess: ['post'],
  getCareNotebookPostFailure: ['errors'],

  // create post
  createCareNotebookPostRequest: ['familySlug', 'familyId', 'post', 'attachment', 'subscription'],
  createCareNotebookPostSuccess: ['familySlug', 'post'],
  createCareNotebookPostFailure: ['errors'],

  // update post
  updateCareNotebookPostRequest: ['familySlug', 'familyId', 'post', 'attachment'],
  updateCareNotebookPostSuccess: ['familySlug', 'post'],
  updateCareNotebookPostFailure: ['errors'],

  // delete post
  deleteCareNotebookPostRequest: ['familySlug', 'familyId', 'postId'],
  deleteCareNotebookPostSuccess: ['familySlug', 'postId'],
  deleteCareNotebookPostFailure: ['errors'],

  // -
  // create post comment
  // -
  createCareNotebookPostCommentRequest: ['familySlug', 'postId', 'comment'],
  createCareNotebookPostCommentSuccess: ['familySlug', 'postId', 'comment'],
  createCareNotebookPostCommentFailure: ['errors'],

  // delete post
  deleteCareNotebookPostCommentRequest: ['familySlug', 'postId', 'commentId'],
  deleteCareNotebookPostCommentSuccess: ['familySlug', 'postId', 'commentId'],
  deleteCareNotebookPostCommentFailure: ['errors'],

  resetCareNotebooks: [],
  resetCareNotebookPost: [],
});

export const CareNotebooksTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  isFetching: {
    getCareNotebookPosts: false,
    getCareNotebookPost: false,
    createCareNotebookPost: false,
    updateCareNotebookPost: false,
    deleteCareNotebookPost: false,
    createCareNotebookPostComment: false,
    deleteCareNotebookPostComment: false,
  },
  postsByFamily: {},
  errors: {
    getCareNotebookPosts: null,
    getCareNotebookPost: null,
    createCareNotebookPost: null,
    updateCareNotebookPost: null,
    deleteCareNotebookPost: null,
    createCareNotebookPostComment: null,
    deleteCareNotebookPostComment: null,
  },
  careNotebookPost: null,
});

/* ------------- Reducers ------------- */

//
// get care notebook posts
//
const getCareNotebookPostsRequest = (state, { page }) => {
  let fetching = { ...state.isFetching, getCareNotebookPosts: true };
  if (page > 1) {
    fetching = { ...state.isFetching, getCareNotebookNextPosts: true };
  }

  return {
    ...state,
    isFetching: fetching,
    errors: {
      ...state.errors,
      getCareNotebookPosts: INITIAL_STATE.errors.getCareNotebookPosts,
    },
  };
};

const getCareNotebookPostsSuccess = (
  state,
  { familySlug, careNotebookPosts, totalposts, importantPosts, page },
) => {
  let fetching = { ...state.isFetching, getCareNotebookPosts: false };
  if (page > 1) {
    fetching = { ...state.isFetching, getCareNotebookNextPosts: false };
  }

  const postsByFamily = { ...state.postsByFamily };
  const carenotebook = postsByFamily[familySlug] || {};
  if (page === 1) carenotebook.posts = [];

  careNotebookPosts.forEach(post => {
    carenotebook.posts = addNewElement(carenotebook.posts, post);
  });

  carenotebook.posts.reverse();
  carenotebook.totalposts = totalposts;
  carenotebook.importantPosts = importantPosts;
  postsByFamily[familySlug] = carenotebook;
  return {
    ...state,
    isFetching: fetching,
    errors: {
      ...state.errors,
      getCareNotebookPosts: INITIAL_STATE.errors.getCareNotebookPosts,
    },
    postsByFamily,
  };
};

const getCareNotebookPostsFailure = (state, { errors }) => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    getCareNotebookPosts: false,
    getCareNotebookNextPosts: false,
  },
  errors: {
    ...state.errors,
    getCareNotebookPosts: errors,
  },
});

//
// get care notebook post
//
const getCareNotebookPostRequest = state => ({
  ...state,
  isFetching: {
    ...INITIAL_STATE.isFetching,
    getCareNotebookPost: true,
  },
  errors: {
    ...INITIAL_STATE.errors,
    getCareNotebookPost: null,
  },
  careNotebookPost: INITIAL_STATE.careNotebookPost,
});

const getCareNotebookPostSuccess = (state, { post }) => {
  return {
    ...state,
    isFetching: {
      ...INITIAL_STATE.isFetching,
      getCareNotebookPost: false,
    },
    errors: {
      ...state.errors,
      getCareNotebookPost: INITIAL_STATE.errors.getCareNotebookPost,
    },
    careNotebookPost: post,
  };
};

const getCareNotebookPostFailure = (state, { errors }) => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    getCareNotebookPost: false,
  },
  errors: {
    ...state.errors,
    getCareNotebookPost: errors,
  },
});

//
// create post
//
const createCareNotebookPostRequest = state => ({
  ...state,
  isFetching: {
    ...INITIAL_STATE.isFetching,
    createCareNotebookPost: true,
  },
  errors: {
    ...INITIAL_STATE.errors,
    createCareNotebookPost: null,
  },
  careNotebookPost: INITIAL_STATE.careNotebookPost,
});

const createCareNotebookPostSuccess = (state, { familySlug, post }) => {
  const postsByFamily = { ...state.postsByFamily };
  // const family = postsByFamily[familySlug] || {};
  // const carenotebook = family[carenotebookType] || {};
  const carenotebook = postsByFamily[familySlug] || {};

  carenotebook.posts = addNewElement(carenotebook.posts || [], post);
  carenotebook.posts.reverse();
  carenotebook.totalposts = (carenotebook.totalposts || 0) + 1;

  if (post.is_important) {
    carenotebook.importantPosts = addNewElement(carenotebook.importantPosts || [], post);
    carenotebook.importantPosts.reverse();
  }

  // family[carenotebookType] = carenotebook;
  // postsByFamily[familySlug] = family;
  postsByFamily[familySlug] = carenotebook;

  return {
    ...state,
    isFetching: {
      ...state.isFetching,
      createCareNotebookPost: false,
    },
    errors: {
      ...state.errors,
      createCareNotebookPost: INITIAL_STATE.errors.createCareNotebookPost,
    },
    postsByFamily,
    careNotebookPost: post,
  };
};

const createCareNotebookPostFailure = (state, { errors }) => ({
  ...state,
  isFetching: {
    ...INITIAL_STATE.isFetching,
    createCareNotebookPost: false,
  },
  errors: {
    ...INITIAL_STATE.errors,
    createCareNotebookPost: errors,
  },
});

//
// update post
//
const updateCareNotebookPostRequest = state => ({
  ...state,
  isFetching: {
    ...INITIAL_STATE.isFetching,
    updateCareNotebookPost: true,
  },
  errors: {
    ...INITIAL_STATE.errors,
    updateCareNotebookPost: null,
  },
});

const updateCareNotebookPostSuccess = (state, { familySlug, post }) => {
  const postsByFamily = { ...state.postsByFamily };
  // const family = postsByFamily[familySlug] || {};
  // const carenotebook = family[carenotebookType] || {};
  const carenotebook = postsByFamily[familySlug] || {};

  carenotebook.posts = findAndReplace(p => `${p.id}` === `${post.id}`, post)(carenotebook.posts);

  if (post.is_important) {
    if (carenotebook.importantPosts.find(p => `${p.id}` === `${post.id}`)) {
      carenotebook.importantPosts = findAndReplace(
        p => `${p.id}` === `${post.id}`,
        post,
      )(carenotebook.importantPosts);
    } else {
      carenotebook.importantPosts = addNewElement(carenotebook.importantPosts || [], post);
      carenotebook.importantPosts.reverse();
    }
  } else {
    carenotebook.importantPosts = carenotebook.importantPosts?.filter(
      p => `${p.id}` !== `${post?.id}`,
    );
  }

  // family[carenotebookType] = carenotebook;
  // postsByFamily[familySlug] = family;
  postsByFamily[familySlug] = carenotebook;

  return {
    ...state,
    isFetching: {
      ...state.isFetching,
      updateCareNotebookPost: false,
    },
    errors: {
      ...state.errors,
      updateCareNotebookPost: INITIAL_STATE.errors.updateCareNotebookPost,
    },
    postsByFamily,
    careNotebookPost: state.careNotebookPost?.id === post?.id ? post : state.careNotebookPost,
  };
};

const updateCareNotebookPostFailure = (state, { errors }) => ({
  ...state,
  isFetching: {
    ...INITIAL_STATE.isFetching,
    updateCareNotebookPost: false,
  },
  errors: {
    ...INITIAL_STATE.errors,
    updateCareNotebookPost: errors,
  },
});

//
// delete post
//
const deleteCareNotebookPostRequest = state => ({
  ...state,
  isFetching: {
    ...INITIAL_STATE.isFetching,
    deleteCareNotebookPost: true,
  },
  errors: {
    ...INITIAL_STATE.errors,
    deleteCareNotebookPost: null,
  },
});

const deleteCareNotebookPostSuccess = (state, { familySlug, postId }) => {
  const postsByFamily = { ...state.postsByFamily };
  // const family = postsByFamily[familySlug] || {};
  // const carenotebook = family[carenotebookType] || {};
  const carenotebook = postsByFamily[familySlug] || {};

  carenotebook.posts = carenotebook.posts?.filter(p => `${p.id}` !== `${postId}`);
  carenotebook.totalposts -= 1;
  carenotebook.importantPosts = carenotebook.importantPosts?.filter(p => `${p.id}` !== `${postId}`);

  // family[carenotebookType] = carenotebook;
  // postsByFamily[familySlug] = family;
  postsByFamily[familySlug] = carenotebook;

  return {
    ...state,
    isFetching: {
      ...state.isFetching,
      deleteCareNotebookPost: false,
    },
    errors: {
      ...state.errors,
      deleteCareNotebookPost: INITIAL_STATE.errors.deleteCareNotebookPost,
    },
    postsByFamily,
    careNotebookPost: state.careNotebookPost?.id === postId ? null : state.careNotebookPost,
  };
};

const deleteCareNotebookPostFailure = (state, { errors }) => ({
  ...state,
  isFetching: {
    ...INITIAL_STATE.isFetching,
    deleteCareNotebookPost: false,
  },
  errors: {
    ...INITIAL_STATE.errors,
    deleteCareNotebookPost: errors,
  },
});

// -
// nested, create post
// -
const createCareNotebookPostCommentRequest = state => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    createCareNotebookPostComment: true,
  },
  errors: {
    ...state.errors,
    createCareNotebookPostComment: INITIAL_STATE.errors.createCareNotebookPostComment,
  },
});

const createCareNotebookPostCommentSuccess = (state, { familySlug, postId, comment }) => {
  const postsByFamily = { ...state.postsByFamily };
  // const family = postsByFamily[familySlug] || {};
  // const carenotebook = family[carenotebookType] || {};
  const carenotebook = postsByFamily[familySlug] || {};

  const post = (carenotebook.posts || []).find(p => `${p.id}` === `${postId}`) || {};
  post.posts = addNewElement(post.posts || [], comment);
  post.posts.reverse();
  carenotebook.posts = findAndReplace(p => `${p.id}` === `${postId}`, post)(carenotebook.posts);
  carenotebook.importantPosts = findAndReplace(
    p => `${p.id}` === `${postId}`,
    post,
  )(carenotebook.importantPosts);

  // family[carenotebookType] = carenotebook;
  // postsByFamily[familySlug] = family;
  postsByFamily[familySlug] = carenotebook;

  const careNotebookPost = state.careNotebookPost?.id === postId ? state.careNotebookPost : null;
  if (careNotebookPost) {
    careNotebookPost.posts = addNewElement(post.posts || [], comment);
    careNotebookPost.posts.reverse();
  }

  return {
    ...state,
    postsByFamily,
    isFetching: {
      ...state.isFetching,
      createCareNotebookPostComment: false,
    },
    careNotebookPost,
  };
};

const createCareNotebookPostCommentFailure = (state, { errors }) => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    createCareNotebookPostComment: false,
  },
  errors: {
    ...state.errors,
    createCareNotebookPostComment: errors,
  },
});

//
// delete post
//
const deleteCareNotebookPostCommentRequest = state => ({
  ...state,
  isFetching: {
    ...INITIAL_STATE.isFetching,
    deleteCareNotebookPostComment: true,
  },
  errors: {
    ...INITIAL_STATE.errors,
    deleteCareNotebookPostComment: null,
  },
});

const deleteCareNotebookPostCommentSuccess = (state, { familySlug, postId, commentId }) => {
  const postsByFamily = { ...state.postsByFamily };
  // const family = postsByFamily[familySlug] || {};
  // const carenotebook = family[carenotebookType] || {};
  const carenotebook = postsByFamily[familySlug] || {};

  const post = (carenotebook.posts || []).find(p => `${p.id}` === `${postId}`) || {};
  post.posts = post.posts?.filter(c => `${c.id}` !== `${commentId}`);
  carenotebook.posts = findAndReplace(p => `${p.id}` === `${postId}`, post)(carenotebook.posts);
  carenotebook.importantPosts = findAndReplace(
    p => `${p.id}` === `${postId}`,
    post,
  )(carenotebook.importantPosts);

  // family[carenotebookType] = carenotebook;
  // postsByFamily[familySlug] = family;
  postsByFamily[familySlug] = carenotebook;

  return {
    ...state,
    isFetching: {
      ...state.isFetching,
      deleteCareNotebookPostComment: false,
    },
    errors: {
      ...state.errors,
      deleteCareNotebookPostComment: INITIAL_STATE.errors.deleteCareNotebookPostComment,
    },
    postsByFamily,
  };
};

const deleteCareNotebookPostCommentFailure = (state, { errors }) => ({
  ...state,
  isFetching: {
    ...INITIAL_STATE.isFetching,
    deleteCareNotebookPostComment: false,
  },
  errors: {
    ...INITIAL_STATE.errors,
    deleteCareNotebookPostComment: errors,
  },
});

const resetCareNotebooks = () => INITIAL_STATE;
const resetCareNotebookPost = state => {
  return {
    ...state,
    careNotebookPost: INITIAL_STATE.careNotebookPost,
  };
};
/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_CARE_NOTEBOOK_POSTS_REQUEST]: getCareNotebookPostsRequest,
  [Types.GET_CARE_NOTEBOOK_POSTS_SUCCESS]: getCareNotebookPostsSuccess,
  [Types.GET_CARE_NOTEBOOK_POSTS_FAILURE]: getCareNotebookPostsFailure,

  [Types.GET_CARE_NOTEBOOK_POST_REQUEST]: getCareNotebookPostRequest,
  [Types.GET_CARE_NOTEBOOK_POST_SUCCESS]: getCareNotebookPostSuccess,
  [Types.GET_CARE_NOTEBOOK_POST_FAILURE]: getCareNotebookPostFailure,

  [Types.CREATE_CARE_NOTEBOOK_POST_REQUEST]: createCareNotebookPostRequest,
  [Types.CREATE_CARE_NOTEBOOK_POST_SUCCESS]: createCareNotebookPostSuccess,
  [Types.CREATE_CARE_NOTEBOOK_POST_FAILURE]: createCareNotebookPostFailure,

  [Types.UPDATE_CARE_NOTEBOOK_POST_REQUEST]: updateCareNotebookPostRequest,
  [Types.UPDATE_CARE_NOTEBOOK_POST_SUCCESS]: updateCareNotebookPostSuccess,
  [Types.UPDATE_CARE_NOTEBOOK_POST_FAILURE]: updateCareNotebookPostFailure,

  [Types.DELETE_CARE_NOTEBOOK_POST_REQUEST]: deleteCareNotebookPostRequest,
  [Types.DELETE_CARE_NOTEBOOK_POST_SUCCESS]: deleteCareNotebookPostSuccess,
  [Types.DELETE_CARE_NOTEBOOK_POST_FAILURE]: deleteCareNotebookPostFailure,

  [Types.CREATE_CARE_NOTEBOOK_POST_COMMENT_REQUEST]: createCareNotebookPostCommentRequest,
  [Types.CREATE_CARE_NOTEBOOK_POST_COMMENT_SUCCESS]: createCareNotebookPostCommentSuccess,
  [Types.CREATE_CARE_NOTEBOOK_POST_COMMENT_FAILURE]: createCareNotebookPostCommentFailure,

  [Types.DELETE_CARE_NOTEBOOK_POST_COMMENT_REQUEST]: deleteCareNotebookPostCommentRequest,
  [Types.DELETE_CARE_NOTEBOOK_POST_COMMENT_SUCCESS]: deleteCareNotebookPostCommentSuccess,
  [Types.DELETE_CARE_NOTEBOOK_POST_COMMENT_FAILURE]: deleteCareNotebookPostCommentFailure,

  [Types.RESET_CARE_NOTEBOOKS]: resetCareNotebooks,
  [Types.RESET_CARE_NOTEBOOK_POST]: resetCareNotebookPost,
});
