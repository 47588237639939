import React from 'react';
import { connect } from 'react-redux';
import { Redirect, withRouter, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Typography from '@material-ui/core/Typography';

import _ from 'lodash';
import Authentication from './Authentication';
import ToasterActions from '../Redux/ToasterRedux';
import { isHoldingAdmin, isLeagueAdmin } from './Controls';

type Props = {
  showForbidden: Function,
  holding: Object,
  currentUser: Object,
  showForbiddenToaster: Function,
};

const HoldingAuthorization = () => WrappedComponent => {
  function WithAuthorization(props: Props) {
    const { showForbiddenToaster, holding, currentUser } = props;
    const { t } = useTranslation();
    // console.log(organisation, allowedRoles);
    if (
      !isLeagueAdmin(_.get(holding, 'league_slug'), currentUser) &&
      !isHoldingAdmin(holding?.id, currentUser)
    ) {
      showForbiddenToaster({ id: 'APP.ACL.ORGANISATION.FORBIDDEN' });

      return (
        <>
          <Typography align="center" style={{ paddingTop: '20px' }}>
            <Link to="/signin">{t('APP.ACL.ORGANISATION.FORBIDDEN')}</Link>
          </Typography>
          <Redirect to="/signin" />
        </>
      );
    }

    return <WrappedComponent {...props} />;
  }

  const mapStateToProps = state => ({
    currentUser: state.auth.currentUser,
    holding: state.holding.holding,
  });

  const mapDispatchToProps = dispatch => ({
    showForbiddenToaster: message => dispatch(ToasterActions.showToaster(message, 'error', 5000)),
  });

  return connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withRouter(Authentication(WithAuthorization)));
};

export default HoldingAuthorization;
