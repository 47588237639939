import { createBrowserHistory, createMemoryHistory } from 'history';
import TagManager from 'react-gtm-module';

import { isClientSide } from '../Config/ServerConfig';
import { isProd } from '../Acl/Controls';
// import Routes from './Routes';

// const ga4Products = [{ trackingId: process.env.RAZZLE_GA4_TRACKING_ID }]; // Global tracking ID
// if (isProd && Routes.currentLeague().namespace === 'SLA')
//   ga4Products.push({ trackingId: process.env.RAZZLE_GA4_SLA_TRACKING_ID }); // SLA tracking ID
// else if (isProd && Routes.currentLeague().namespace === 'CCMORMAL')
//   ga4Products.push({
//     trackingId: process.env.RAZZLE_GA4_CCMORMAL_TRACKING_ID,
//   });
// // CCMORMAL tracking ID
// else if (isProd && Routes.currentLeague().namespace === 'REPRISE')
//   ga4Products.push({ trackingId: process.env.RAZZLE_GA4_REPRISE_TRACKING_ID }); // REPRISE tracking ID

// ReactGA.initialize(process.env.RAZZLE_GA_TRACKING_ID);
// ReactGA4.initialize(ga4Products);

if (isProd && process.browser) {
  TagManager.initialize({
    gtmId: process.env.RAZZLE_GTM_ID,
  });
}

let history;
if (isClientSide()) {
  history = createBrowserHistory();
} else {
  history = createMemoryHistory();
}

// const hitPage = location => {
//   ReactGA.pageview(location.pathname + location.search);
//   ReactGA4.send({ hitType: 'pageview', page: location.pathname + location.search });
// };

// hitPage(history.location);
// history.listen(hitPage);

if (isClientSide()) {
  window.dataLayer = window.dataLayer || [];
}

export default history;
