import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';
import findAndReplace from './ReduxHelpers';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  createTaskRequest: [
    'holdingSlug',
    'organisationSlug',
    'familySlug',
    'task',
    'cover',
    'duplicatedTaskSlug',
  ],
  createTaskSuccess: ['task'],
  createTaskFailure: ['errors'],

  updateTaskRequest: ['holdingSlug', 'organisationSlug', 'familySlug', 'task', 'cover'],
  updateTaskSuccess: ['task'],
  updateTaskFailure: ['errors'],

  skipOccurenceRequest: ['holdingSlug', 'organisationSlug', 'familySlug', 'taskSlug', 'eventDate'],
  skipOccurenceSuccess: ['taskSlug', 'eventDate'],
  skipOccurenceFailure: ['errors'],

  detachTaskOccurrenceRequest: [
    'holdingSlug',
    'organisationSlug',
    'familySlug',
    'taskSlug',
    'eventDate',
    'taskAttributes',
    'cover',
  ],
  detachTaskOccurrenceSuccess: ['task'],
  detachTaskOccurrenceFailure: ['errors'],

  destroyTaskRequest: ['holdingSlug', 'organisationSlug', 'familySlug', 'taskSlug'],
  destroyTaskSuccess: ['taskSlug'],
  destroyTaskFailure: ['errors'],

  getTwigsRequest: [
    'holdingSlug',
    'organisationSlug',
    'familySlug',
    'endDate',
    'startDate',
    'reset',
  ],
  getTwigsSuccess: ['twigs', 'reset'],
  getTwigsFailure: ['errors'],

  getTwigRequest: ['holdingSlug', 'organisationSlug', 'familySlug', 'twigId'],
  getTwigSuccess: ['twig'],
  getTwigFailure: ['errors'],

  getTaskRequest: ['holdingSlug', 'organisationSlug', 'familySlug', 'taskSlug', 'eventDate'],
  getTaskSuccess: ['task'],
  getTaskFailure: ['errors'],

  registerHelperOnTaskRequest: [
    'holdingSlug',
    'organisationSlug',
    'familySlug',
    'taskSlug',
    'helperId',
    'eventDate',
    'helpedId',
    'helperAttributes',
  ],
  registerHelperOnTaskSuccess: ['twig', 'helper'],
  registerHelperOnTaskFailure: ['errors'],

  unregisterHelperOnTaskRequest: [
    'holdingSlug',
    'organisationSlug',
    'familySlug',
    'taskSlug',
    'helperId',
    'eventDate',
    'currentFamilySlug',
  ],
  unregisterHelperOnTaskSuccess: ['twig', 'currentFamilySlug'],
  unregisterHelperOnTaskFailure: ['errors'],

  validateHelperOnTaskRequest: [
    'holdingSlug',
    'organisationSlug',
    'taskSlug',
    'helperId',
    'eventDate',
  ],
  validateHelperOnTaskSuccess: ['twig'],
  validateHelperOnTaskFailure: ['errors'],

  // -
  // nested, create post
  // -
  createTaskPostRequest: ['holdingSlug', 'organisationSlug', 'taskId', 'post'],
  createTaskPostSuccess: ['taskId', 'post'],
  createTaskPostFailure: ['errors'],

  resetTasks: [],
  resetRegisteredHelper: [],
});

export const TasksTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  isFetching: {
    createTask: false,
    updateTask: false,
    skipOccurence: false,
    detachTaskOccurrence: false,
    destroyTask: false,
    getTask: false,
    registerHelperOnTask: false,
    unregisterHelperOnTask: false,
    validateHelperOnTask: false,
    createTaskPost: false,
    getTwigs: false,
    getTwig: false,
  },
  errors: {
    createTask: null,
    updateTask: null,
    skipOccurence: null,
    detachTaskOccurrence: null,
    destroyTask: null,
    getTask: null,
    registerHelperOnTask: null,
    unregisterHelperOnTask: null,
    validateHelperOnTask: null,
    createTaskPost: null,
    getTwigs: null,
    getTwig: null,
  },
  task: null,
  twigs: [],
  registeredHelper: null,
  unregisteredHelper: null,
});

/* ------------- Reducers ------------- */
//
// createTask
//
const createTaskRequest = state => ({
  ...state,
  isFetching: {
    ...INITIAL_STATE.isFetching,
    createTask: true,
  },
  errors: {
    ...INITIAL_STATE.errors,
    createTask: null,
  },
});

const createTaskSuccess = (state, { task }) => {
  const twigsToAdd = task?.twigs?.map(t => {
    return { ...t, task };
  });
  const newTwigs = state.twigs.concat(twigsToAdd || []);

  return {
    ...state,
    isFetching: {
      ...INITIAL_STATE.isFetching,
      createTask: false,
    },
    errors: {
      ...INITIAL_STATE.errors,
      createTask: null,
    },
    task,
    twigs: newTwigs,
  };
};

const createTaskFailure = (state, { errors }) => ({
  ...state,
  isFetching: {
    ...INITIAL_STATE.isFetching,
    createTask: false,
  },
  errors: {
    ...INITIAL_STATE.errors,
    createTask: errors,
  },
});

//
// updateTask
//
const updateTaskRequest = state => ({
  ...state,
  isFetching: {
    ...INITIAL_STATE.isFetching,
    updateTask: true,
  },
  errors: {
    ...INITIAL_STATE.errors,
    updateTask: null,
  },
});

const updateTaskSuccess = (state, { task }) => {
  const twigsToAdd = task?.twigs?.map(t => {
    return { ...t, task: { ...t.task, ...task } };
  });
  const newTwigs = state.twigs?.filter(t => t.task?.id !== task.id)?.concat(twigsToAdd || []);

  return {
    ...state,
    isFetching: {
      ...INITIAL_STATE.isFetching,
      updateTask: false,
    },
    errors: {
      ...INITIAL_STATE.errors,
      updateTask: null,
    },
    task,
    twigs: newTwigs,
  };
};

const updateTaskFailure = (state, { errors }) => ({
  ...state,
  isFetching: {
    ...INITIAL_STATE.isFetching,
    updateTask: false,
  },
  errors: {
    ...INITIAL_STATE.errors,
    updateTask: errors,
  },
});

//
// skipOccurence
//
const skipOccurenceRequest = state => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    skipOccurence: true,
  },
  errors: {
    ...state.errors,
    skipOccurence: INITIAL_STATE.errors.skipOccurence,
  },
});

const skipOccurenceSuccess = (state, { taskSlug, eventDate }) => {
  const newTwigs = state.twigs.filter(
    t => !(`${eventDate.toJSON()}` === `${t.event_date}` && `${taskSlug}` === `${t.task.slug}`),
  );

  return {
    ...state,
    isFetching: {
      ...state.isFetching,
      skipOccurence: false,
    },
    errors: {
      ...state.errors,
      skipOccurence: INITIAL_STATE.errors.skipOccurence,
    },
    twigs: newTwigs,
  };
};

const skipOccurenceFailure = (state, { errors }) => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    skipOccurence: false,
  },
  errors: {
    ...state.errors,
    skipOccurence: errors,
  },
});

//
// detach task
//
const detachTaskOccurrenceRequest = state => ({
  ...state,
  isFetching: {
    ...INITIAL_STATE.isFetching,
    detachTaskOccurrence: true,
  },
  errors: {
    ...INITIAL_STATE.errors,
    detachTaskOccurrence: null,
  },
});

const detachTaskOccurrenceSuccess = (state, { task }) => {
  const twigsToAdd = task?.twigs?.map(t => {
    return { ...t, task };
  });
  const newTwigs = state.twigs.concat(twigsToAdd || []);

  return {
    ...state,
    isFetching: {
      ...INITIAL_STATE.isFetching,
      detachTaskOccurrence: false,
    },
    errors: {
      ...INITIAL_STATE.errors,
      detachTaskOccurrence: null,
    },
    task,
    twigs: newTwigs,
  };
};

const detachTaskOccurrenceFailure = (state, { errors }) => ({
  ...state,
  isFetching: {
    ...INITIAL_STATE.isFetching,
    detachTaskOccurrence: false,
  },
  errors: {
    ...INITIAL_STATE.errors,
    detachTaskOccurrence: errors,
  },
});

//
// destroyTask
//
const destroyTaskRequest = state => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    destroyTask: true,
  },
  errors: {
    ...state.errors,
    destroyTask: INITIAL_STATE.errors.destroyTask,
  },
});
const destroyTaskSuccess = (state, { taskSlug }) => {
  const newTwigs = state.twigs.filter(t => `${taskSlug}` !== `${t.task?.slug}`);

  return {
    ...state,
    isFetching: {
      ...state.isFetching,
      destroyTask: false,
    },
    errors: {
      ...state.errors,
      destroyTask: INITIAL_STATE.errors.destroyTask,
    },
    task: null,
    twigs: newTwigs,
  };
};
const destroyTaskFailure = (state, { errors }) => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    destroyTask: false,
  },
  errors: {
    ...state.errors,
    destroyTask: errors,
  },
});

//
// get twigs
//

const getTwigsRequest = state => ({
  ...state,
  isFetching: { ...state.isFetching, getTwigs: true },
  errors: INITIAL_STATE.errors,
});

const getTwigsSuccess = (state, { twigs, reset }) => {
  const existingTwigs = state.twigs?.slice() || [];

  if (!reset) {
    twigs?.forEach(twig => {
      if (!existingTwigs?.find(t => t.event_date === twig.event_date && t.task.id === twig.task.id))
        existingTwigs.push(twig);
    });
  }

  return {
    ...state,
    isFetching: { ...state.isFetching, getTwigs: false },
    twigs: reset ? twigs : existingTwigs,
  };
};

const getTwigsFailure = (state, { errors }) => ({
  ...state,
  isFetching: { ...state.isFetching, getTwigs: false },
  twigs: [],
  errors: { ...state.errors, getTwigs: errors || true },
});

//
// getTwig
//
const getTwigRequest = state => ({
  ...state,
  isFetching: { ...state.isFetching, getTwig: true },
  errors: INITIAL_STATE.errors,
});

const getTwigSuccess = (state, { twig }) => ({
  ...state,
  isFetching: { ...state.isFetching, getTwig: false },
  twigs: findAndReplace(t => t.id === twig.id, twig)(state.twigs),
});

const getTwigFailure = (state, { errors }) => ({
  ...state,
  isFetching: { ...state.isFetching, getTwig: false },
  errors: { ...state.errors, getTwig: errors || true },
});

//
// getTaskByTwigId
//
const getTaskRequest = state => ({
  ...state,
  isFetching: { ...state.isFetching, getTask: true },
  errors: INITIAL_STATE.errors,
});

const getTaskSuccess = (state, { task }) => ({
  ...state,
  isFetching: { ...state.isFetching, getTask: false },
  task: { ...task, twig_for_date: { ...task.twig_for_date, task: task } },
});

const getTaskFailure = (state, { errors }) => ({
  ...state,
  isFetching: { ...state.isFetching, getTask: false },
  errors: { ...state.errors, getTask: errors || true },
  task: INITIAL_STATE.task,
});

// -
// nested, create post
// -
const createTaskPostRequest = state => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    createTaskPost: true,
  },
  errors: {
    ...state.errors,
    createTaskPost: INITIAL_STATE.errors.createTaskPost,
  },
});

const createTaskPostSuccess = (state, { taskId, post }) => {
  const newTask = { ...state.task };
  let updatedTwigs = state.twigs?.slice();

  if (newTask?.id === taskId) {
    const newPosts = newTask.posts?.slice() || [];
    newPosts.push(post);
    newTask.posts = newPosts;
  }

  updatedTwigs = state.twigs.map(twig => {
    if (twig?.task.id !== taskId) return twig;
    const newTwigTask = { ...twig.task };
    const newTwigPosts = newTwigTask.posts?.slice() || [];
    newTwigPosts.push(post);
    newTwigTask.posts = newTwigPosts;

    return {
      ...twig,
      task: newTwigTask,
    };
  });

  return {
    ...state,
    task: newTask,
    isFetching: {
      ...state.isFetching,
      createTaskPost: false,
    },
    twigs: updatedTwigs,
  };
};

const createTaskPostFailure = (state, { errors }) => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    createTaskPost: false,
  },
  errors: {
    ...state.errors,
    createTaskPost: errors,
  },
});

//
// registerHelperOnTask
//
const registerHelperOnTaskRequest = state => ({
  ...state,
  isFetching: { ...state.isFetching, registerHelperOnTask: true },
  errors: INITIAL_STATE.errors,
  registeredHelper: INITIAL_STATE.registeredHelper,
});
const registerHelperOnTaskSuccess = (state, { twig, helper }) => {
  const task = { ...state.task, ...twig.task };
  task.twig_for_date = { ...twig, task: task };
  return {
    ...state,
    isFetching: { ...state.isFetching, registerHelperOnTask: false },
    task,
    registeredHelper: helper,
    twigs: findAndReplace(t => t.task.id === twig.task.id && t.event_date === twig.event_date, {
      ...twig,
      task: task,
    })(state.twigs),
  };
};
const registerHelperOnTaskFailure = (state, { errors }) => ({
  ...state,
  isFetching: { ...state.isFetching, registerHelperOnTask: false },
  errors: {
    ...state.errors,
    registerHelperOnTask: errors,
  },
});

const unregisterHelperOnTaskRequest = state => ({
  ...state,
  isFetching: { ...state.isFetching, unregisterHelperOnTask: true },
  errors: INITIAL_STATE.errors,
});
const unregisterHelperOnTaskSuccess = (state, { twig, currentFamilySlug }) => {
  const task = { ...state.task, ...twig.task };
  task.twig_for_date = { ...twig, task: task };
  const newTwigs =
    twig.task?.organisation_slug === currentFamilySlug || !currentFamilySlug
      ? findAndReplace(t => t.task.id === twig.task.id && t.event_date === twig.event_date, {
          ...twig,
          task: task,
        })(state.twigs)
      : state.twigs.filter(tw => tw.id !== twig.id);
  return {
    ...state,
    isFetching: { ...state.isFetching, unregisterHelperOnTask: false },
    task,
    twigs: newTwigs,
  };
};
const unregisterHelperOnTaskFailure = (state, { errors }) => ({
  ...state,
  isFetching: { ...state.isFetching, unregisterHelperOnTask: false },
  errors: {
    ...state.errors,
    errors,
  },
});

const validateHelperOnTaskRequest = state => ({
  ...state,
  isFetching: { ...state.isFetching, validateHelperOnTask: true },
  errors: INITIAL_STATE.errors,
});

const validateHelperOnTaskSuccess = (state, { twig }) => {
  const task = { ...twig.task };
  return {
    ...state,
    isFetching: { ...state.isFetching, validateHelperOnTask: false },
    task,
    twigs: findAndReplace(
      t => t.task.id === twig.task.id && t.event_date === twig.event_date,
      twig,
    )(state.twigs),
  };
};

const validateHelperOnTaskFailure = (state, { errors }) => ({
  ...state,
  isFetching: { ...state.isFetching, validateHelperOnTask: false },
  errors: {
    ...state.errors,
    errors,
  },
});

const resetTasks = () => INITIAL_STATE;
const resetRegisteredHelper = state => ({ ...state, registeredHelper: null });

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.CREATE_TASK_REQUEST]: createTaskRequest,
  [Types.CREATE_TASK_SUCCESS]: createTaskSuccess,
  [Types.CREATE_TASK_FAILURE]: createTaskFailure,

  [Types.UPDATE_TASK_REQUEST]: updateTaskRequest,
  [Types.UPDATE_TASK_SUCCESS]: updateTaskSuccess,
  [Types.UPDATE_TASK_FAILURE]: updateTaskFailure,

  [Types.SKIP_OCCURENCE_REQUEST]: skipOccurenceRequest,
  [Types.SKIP_OCCURENCE_SUCCESS]: skipOccurenceSuccess,
  [Types.SKIP_OCCURENCE_FAILURE]: skipOccurenceFailure,

  [Types.DETACH_TASK_OCCURRENCE_REQUEST]: detachTaskOccurrenceRequest,
  [Types.DETACH_TASK_OCCURRENCE_SUCCESS]: detachTaskOccurrenceSuccess,
  [Types.DETACH_TASK_OCCURRENCE_FAILURE]: detachTaskOccurrenceFailure,

  [Types.DESTROY_TASK_REQUEST]: destroyTaskRequest,
  [Types.DESTROY_TASK_SUCCESS]: destroyTaskSuccess,
  [Types.DESTROY_TASK_FAILURE]: destroyTaskFailure,

  [Types.GET_TWIGS_REQUEST]: getTwigsRequest,
  [Types.GET_TWIGS_SUCCESS]: getTwigsSuccess,
  [Types.GET_TWIGS_FAILURE]: getTwigsFailure,

  [Types.GET_TWIG_REQUEST]: getTwigRequest,
  [Types.GET_TWIG_SUCCESS]: getTwigSuccess,
  [Types.GET_TWIG_FAILURE]: getTwigFailure,

  [Types.GET_TASK_REQUEST]: getTaskRequest,
  [Types.GET_TASK_SUCCESS]: getTaskSuccess,
  [Types.GET_TASK_FAILURE]: getTaskFailure,

  [Types.REGISTER_HELPER_ON_TASK_REQUEST]: registerHelperOnTaskRequest,
  [Types.REGISTER_HELPER_ON_TASK_SUCCESS]: registerHelperOnTaskSuccess,
  [Types.REGISTER_HELPER_ON_TASK_FAILURE]: registerHelperOnTaskFailure,

  [Types.UNREGISTER_HELPER_ON_TASK_REQUEST]: unregisterHelperOnTaskRequest,
  [Types.UNREGISTER_HELPER_ON_TASK_SUCCESS]: unregisterHelperOnTaskSuccess,
  [Types.UNREGISTER_HELPER_ON_TASK_FAILURE]: unregisterHelperOnTaskFailure,

  [Types.VALIDATE_HELPER_ON_TASK_REQUEST]: validateHelperOnTaskRequest,
  [Types.VALIDATE_HELPER_ON_TASK_SUCCESS]: validateHelperOnTaskSuccess,
  [Types.VALIDATE_HELPER_ON_TASK_FAILURE]: validateHelperOnTaskFailure,

  [Types.CREATE_TASK_POST_REQUEST]: createTaskPostRequest,
  [Types.CREATE_TASK_POST_SUCCESS]: createTaskPostSuccess,
  [Types.CREATE_TASK_POST_FAILURE]: createTaskPostFailure,

  [Types.RESET_TASKS]: resetTasks,
  [Types.RESET_REGISTERED_HELPER]: resetRegisteredHelper,
});
