import { call, put } from 'redux-saga/effects';

import _ from 'lodash';
import DealsActions from '../Redux/DealsRedux';
import ToasterActions from '../Redux/ToasterRedux';
import { toastResponseError } from '../Services/ApiErrorHelpers';
import history from '../Routing/CurrentHistory';
import { isCommunity } from '../Acl/Controls';

export function* getDeals(api, { holdingSlug, organisationSlug, page }) {
  const response = yield call(api.getDeals, holdingSlug, organisationSlug, page);

  if (response.ok) {
    yield put(
      DealsActions.getDealsSuccess(response.data.deals, page, response.data.meta.total_deals),
    );
  } else {
    yield toastResponseError(response);
    const errors = _.get(response, 'data.errors');
    yield put(DealsActions.getDealsFailure(errors));
  }
}

export function* getDeal(api, { holdingSlug, organisationSlug, dealId }) {
  const response = yield call(api.getDeal, holdingSlug, organisationSlug, dealId);
  if (response.ok) {
    yield put(DealsActions.getDealSuccess(response.data.deal));
  } else {
    yield toastResponseError(response);
    yield put(
      DealsActions.getDealFailure({
        ...response.data,
        status: response.status,
      }),
    );
  }
}
export function* createDeal(api, { holdingSlug, organisationSlug, deal, attachments }) {
  const response = yield call(api.createDeal, holdingSlug, organisationSlug, deal, attachments);
  if (response.ok) {
    yield put(DealsActions.createDealSuccess(response.data.deal));
    yield put(ToasterActions.showToaster({ id: 'CREATE_POST_SUCCESS' }, 'success', 5000));
  } else {
    yield toastResponseError(response);
    const errors = _.get(response, 'data.errors');
    yield put(DealsActions.createDealFailure(errors));
  }
}
export function* updateDeal(api, { holdingSlug, organisationSlug, deal, attachments }) {
  const response = yield call(api.updateDeal, holdingSlug, organisationSlug, deal, attachments);
  if (response.ok) {
    yield put(DealsActions.updateDealSuccess(response.data.deal));
    yield put(ToasterActions.showToaster({ id: 'UPDATE_SUCCESS' }, 'success', 5000));
  } else {
    yield toastResponseError(response);
    const errors = _.get(response, 'data.errors');
    yield put(DealsActions.updateDealFailure(errors));
  }
}

export function* destroyDeal(api, { holdingSlug, organisationSlug, dealId, organisaionType }) {
  const response = yield call(api.destroyDeal, holdingSlug, organisationSlug, dealId);

  if (response.ok) {
    yield put(DealsActions.destroyDealSuccess(response.data.deals));
    if (isCommunity({ type: organisaionType }))
      yield call(history.push, `/communities/${holdingSlug}`);
  } else {
    yield toastResponseError(response);
    const errors = _.get(response, 'data.errors');
    yield put(DealsActions.destroyDealFailure(errors));
  }
}

export function* createDealPost(api, { holdingSlug, organisationSlug, dealId, post }) {
  const response = yield call(api.createPost, holdingSlug, organisationSlug, dealId, post);
  if (response.ok) {
    yield put(DealsActions.createDealPostSuccess(dealId, response.data.post));
  } else {
    yield toastResponseError(response);
    yield put(DealsActions.createDealPostFailure(response.data));
  }
}

export function* destroyDealPost(api, { holdingSlug, organisationSlug, dealId, postId }) {
  const response = yield call(api.destroyPost, holdingSlug, organisationSlug, dealId, postId);
  if (response.ok) {
    yield put(DealsActions.destroyDealPostSuccess(dealId, postId));
  } else {
    yield toastResponseError(response);
    yield put(DealsActions.destroyDealPostFailure(response.data));
  }
}
