import { call, put } from 'redux-saga/effects';
import ToasterActions from '../Redux/ToasterRedux';
import ClubsActions from '../Redux/ClubsRedux';
import history from '../Routing/CurrentHistory';
import { toastResponseError } from '../Services/ApiErrorHelpers';
import { isCommunity } from '../Acl/Controls';
import Routes from '../Routing/Routes';

export function* getOrganisationClubs(api, { holdingSlug, organisationSlug }) {
  const response = yield call(api.getOrganisationClubs, holdingSlug, organisationSlug);

  if (response.ok) {
    yield put(ClubsActions.getOrganisationClubsSuccess(response.data.clubs));
  } else {
    yield toastResponseError(response);
    const errors = response?.data?.errors || true;
    yield put(ClubsActions.getOrganisationClubsFailure(errors));
  }
}

export function* getOrganisationClub(api, { holdingSlug, organisationSlug, clubId }) {
  const response = yield call(api.getOrganisationClub, holdingSlug, organisationSlug, clubId);
  if (response.ok) {
    yield put(ClubsActions.getOrganisationClubSuccess(response.data.club));
    yield put(
      ClubsActions.getClubPostsSuccess(response.data.club.posts, response.data.club.posts_count, 1),
    );
  } else {
    yield toastResponseError(response);
    const errors = response?.data?.errors;
    yield put(ClubsActions.getOrganisationClubFailure(errors));
  }
}

export function* createClub(api, { holdingSlug, organisationSlug, club, organisationType }) {
  const response = yield call(api.createClub, holdingSlug, organisationSlug, club);
  const organisation = {
    holding_slug: holdingSlug,
    slug: organisationSlug,
    type: organisationType,
  };
  if (response.ok) {
    yield put(ClubsActions.createClubSuccess(response.data.club));
    yield call(
      history.push,
      `${Routes.organisationHomePath(organisation)}/clubs/${response.data.club.id}/members`,
    );
  } else {
    yield toastResponseError(response);
    const errors = response?.data?.errors;
    yield put(ClubsActions.createClubFailure(errors));
  }
}

export function* updateClub(api, { holdingSlug, organisationSlug, club }) {
  const response = yield call(api.updateClub, holdingSlug, organisationSlug, club);

  if (response.ok) {
    yield put(ClubsActions.updateClubSuccess(response.data.club));
    yield put(ToasterActions.showToaster({ id: 'UPDATE_SUCCESS' }, 'success', 5000));
  } else {
    yield toastResponseError(response);
    const errors = response?.data?.errors;
    yield put(ClubsActions.updateClubFailure(errors));
  }
}

export function* destroyClub(api, { holdingSlug, organisationSlug, clubId, organisationType }) {
  const response = yield call(api.destroyClub, holdingSlug, organisationSlug, clubId);
  if (response.ok) {
    yield put(ClubsActions.destroyClubSuccess(response.data.clubs));
    if (isCommunity({ type: organisationType })) {
      yield call(history.push, `/communities/${holdingSlug}/clubs/`);
    } else {
      yield call(history.push, `/${holdingSlug}/${organisationSlug}/clubs`);
    }
  } else {
    yield toastResponseError(response);
    const errors = response?.data?.errors;
    yield put(ClubsActions.destroyClubFailure(errors));
  }
}

export function* createClubMemberships(
  api,
  { holdingSlug, organisationSlug, clubId, membersIds, organisationType },
) {
  const response = yield call(
    api.createClubMemberships,
    holdingSlug,
    organisationSlug,
    clubId,
    membersIds,
  );
  if (response.ok) {
    yield put(ClubsActions.createClubMembershipsSuccess(response.data.club));
    if (isCommunity({ type: organisationType })) {
      yield call(history.push, `/communities/${holdingSlug}/clubs/${clubId}`);
    } else {
      yield call(history.push, `/${holdingSlug}/${organisationSlug}/clubs/${clubId}`);
    }
  } else {
    yield toastResponseError(response);
    const errors = response?.data?.errors;
    yield put(ClubsActions.createClubMembershipsFailure(errors));
  }
}

export function* destroyClubMembership(
  api,
  { holdingSlug, organisationSlug, clubId, membershipId },
) {
  const response = yield call(
    api.destroyClubMembership,
    holdingSlug,
    organisationSlug,
    clubId,
    membershipId,
  );
  if (response.ok) {
    yield put(ClubsActions.destroyClubMembershipSuccess(response.data.club));
  } else {
    yield toastResponseError(response);
    const errors = response?.data?.errors;
    yield put(ClubsActions.destroyClubMembershipFailure(errors));
  }
}

export function* getClubPosts(api, { holdingSlug, organisationSlug, clubId, page, lastPostDate }) {
  const response = yield call(
    api.getClubPosts,
    holdingSlug,
    organisationSlug,
    clubId,
    page,
    lastPostDate,
  );
  if (response.ok) {
    yield put(
      ClubsActions.getClubPostsSuccess(response.data.posts, response.data.meta?.total_posts, page),
    );
  } else {
    yield toastResponseError(response);
    const errors = response?.data?.errors;
    yield put(ClubsActions.getClubPostsFailure(errors));
  }
}

export function* createClubPost(api, { holdingSlug, organisationSlug, clubId, post }) {
  const response = yield call(api.createClubPost, holdingSlug, organisationSlug, clubId, post);
  if (response.ok) {
    yield put(ClubsActions.createClubPostSuccess(clubId, response.data.post, true));
  } else {
    yield toastResponseError(response);
    const errors = response?.data?.errors;
    yield put(ClubsActions.createClubPostFailure(errors));
  }
}
