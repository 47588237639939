import { call, put } from 'redux-saga/effects';

import _ from 'lodash';
import CoursesActions from '../Redux/CoursesRedux';
import ToasterActions from '../Redux/ToasterRedux';
import { toastResponseError } from '../Services/ApiErrorHelpers';
import history from '../Routing/CurrentHistory';
import { computeNextChapterLink } from '../Services/CourseHelper';

export function* getLeagueCourses(api, { leagueId }) {
  const response = yield call(api.getLeagueCourses, leagueId);
  if (response.ok) {
    yield put(CoursesActions.getLeagueCoursesSuccess(response.data.courses));
  } else {
    yield toastResponseError(response);
    const errors = _.get(response, 'data.errors');
    yield put(CoursesActions.getLeagueCoursesFailure(errors));
  }
}

export function* getLeagueCourse(api, { leagueId, courseSlug }) {
  const response = yield call(api.getLeagueCourse, leagueId, courseSlug);
  if (response.ok) {
    yield put(CoursesActions.getLeagueCourseSuccess(response.data.course));
  } else {
    yield toastResponseError(response);
    yield put(
      CoursesActions.getLeagueCourseFailure({
        ...response.data,
        status: response.status,
        holding_slug: leagueId,
        course_slug: courseSlug,
      }),
    );
  }
}

export function* createLeagueCourse(api, { leagueId, courseAttributes, cover, attachments }) {
  const response = yield call(
    api.createLeagueCourse,
    leagueId,
    courseAttributes,
    cover,
    attachments,
  );
  if (response.ok) {
    yield put(CoursesActions.createLeagueCourseSuccess(response.data.course));
    yield call(history.push, `/leagues/${leagueId}/formations/${response.data.course.slug}/edit`);
  } else {
    yield toastResponseError(response);
    const errors = _.get(response, 'data.errors');
    yield put(CoursesActions.createLeagueCourseFailure(errors));
  }
}

export function* updateLeagueCourse(api, { leagueId, courseAttributes, cover, attachments }) {
  const response = yield call(
    api.updateLeagueCourse,
    leagueId,
    courseAttributes,
    cover,
    attachments,
  );

  if (response.ok) {
    yield put(CoursesActions.updateLeagueCourseSuccess(response.data.course));
    yield put(ToasterActions.showToaster({ id: 'UPDATE_SUCCESS' }, 'success', 5000));
  } else {
    yield toastResponseError(response);
    const errors = _.get(response, 'data.errors');
    yield put(CoursesActions.updateLeagueCourseFailure(errors));
  }
}

export function* destroyLeagueCourse(api, { leagueId, courseSlug }) {
  const response = yield call(api.destroyLeagueCourse, leagueId, courseSlug);
  if (response.ok) {
    yield put(CoursesActions.destroyLeagueCourseSuccess(courseSlug));
  } else {
    yield toastResponseError(response);
    const errors = _.get(response, 'data.errors');
    yield put(CoursesActions.destroyLeagueCourseFailure(errors));
  }
}

export function* getHoldingCourses(api, { holdingSlug }) {
  const response = yield call(api.getHoldingCourses, holdingSlug);
  if (response.ok) {
    yield put(CoursesActions.getHoldingCoursesSuccess(response.data.courses));
  } else {
    yield toastResponseError(response);
    const errors = _.get(response, 'data.errors');
    yield put(CoursesActions.getHoldingCoursesFailure(errors));
  }
}

export function* getHoldingCourse(api, { holdingSlug, courseSlug }) {
  const response = yield call(api.getHoldingCourse, holdingSlug, courseSlug);
  if (response.ok) {
    yield put(CoursesActions.getHoldingCourseSuccess(response.data.course));
  } else {
    yield toastResponseError(response);
    yield put(
      CoursesActions.getHoldingCourseFailure({
        ...response.data,
        status: response.status,
        holding_slug: holdingSlug,
        course_slug: courseSlug,
      }),
    );
  }
}

export function* createHoldingCourse(
  api,
  { holdingSlug, orgaSlug, courseAttributes, cover, attachments },
) {
  const response = yield call(
    api.createHoldingCourse,
    holdingSlug,
    courseAttributes,
    cover,
    attachments,
  );
  if (response.ok) {
    yield put(CoursesActions.createHoldingCourseSuccess(response.data.course));
    yield call(
      history.push,
      `/${holdingSlug}/${orgaSlug}/formations/${response.data.course.slug}/edit`,
    );
  } else {
    yield toastResponseError(response);
    const errors = _.get(response, 'data.errors');
    yield put(CoursesActions.createHoldingCourseFailure(errors));
  }
}

export function* updateHoldingCourse(api, { holdingSlug, courseAttributes, cover, attachments }) {
  const response = yield call(
    api.updateHoldingCourse,
    holdingSlug,
    courseAttributes,
    cover,
    attachments,
  );

  if (response.ok) {
    yield put(CoursesActions.updateHoldingCourseSuccess(response.data.course));
    yield put(ToasterActions.showToaster({ id: 'UPDATE_SUCCESS' }, 'success', 5000));
  } else {
    yield toastResponseError(response);
    const errors = _.get(response, 'data.errors');
    yield put(CoursesActions.updateHoldingCourseFailure(errors));
  }
}

export function* destroyHoldingCourse(api, { holdingSlug, courseSlug }) {
  const response = yield call(api.destroyHoldingCourse, holdingSlug, courseSlug);
  if (response.ok) {
    yield put(CoursesActions.destroyHoldingCourseSuccess(courseSlug));
  } else {
    yield toastResponseError(response);
    const errors = _.get(response, 'data.errors');
    yield put(CoursesActions.destroyHoldingCourseFailure(errors));
  }
}

export function* createLeagueCourseUnit(api, { leagueId, courseSlug, unitAttributes }) {
  const response = yield call(api.createLeagueCourseUnit, leagueId, courseSlug, unitAttributes);
  if (response.ok) {
    yield put(CoursesActions.createLeagueCourseUnitSuccess(response.data.unit));
  } else {
    yield toastResponseError(response);
    const errors = _.get(response, 'data.errors');
    yield put(CoursesActions.createLeagueCourseUnitFailure(errors));
  }
}

export function* updateLeagueCourseUnit(api, { leagueId, courseSlug, unitAttributes }) {
  const response = yield call(api.updateLeagueCourseUnit, leagueId, courseSlug, unitAttributes);

  if (response.ok) {
    yield put(CoursesActions.updateLeagueCourseUnitSuccess(response.data.unit));
    yield put(ToasterActions.showToaster({ id: 'UPDATE_SUCCESS' }, 'success', 5000));
  } else {
    yield toastResponseError(response);
    const errors = _.get(response, 'data.errors');
    yield put(CoursesActions.updateLeagueCourseUnitFailure(errors));
  }
}

export function* destroyLeagueCourseUnit(api, { leagueId, courseSlug, unitId }) {
  const response = yield call(api.destroyLeagueCourseUnit, leagueId, courseSlug, unitId);
  if (response.ok) {
    yield put(CoursesActions.destroyLeagueCourseUnitSuccess(unitId));
  } else {
    yield toastResponseError(response);
    const errors = _.get(response, 'data.errors');
    yield put(CoursesActions.destroyLeagueCourseUnitFailure(errors));
  }
}

export function* createHoldingCourseUnit(api, { holdingSlug, courseSlug, unitAttributes }) {
  const response = yield call(api.createHoldingCourseUnit, holdingSlug, courseSlug, unitAttributes);
  if (response.ok) {
    yield put(CoursesActions.createHoldingCourseUnitSuccess(response.data.unit));
  } else {
    yield toastResponseError(response);
    const errors = _.get(response, 'data.errors');
    yield put(CoursesActions.createHoldingCourseUnitFailure(errors));
  }
}

export function* updateHoldingCourseUnit(api, { holdingSlug, courseSlug, unitAttributes }) {
  const response = yield call(api.updateHoldingCourseUnit, holdingSlug, courseSlug, unitAttributes);

  if (response.ok) {
    yield put(CoursesActions.updateHoldingCourseUnitSuccess(response.data.unit));
    yield put(ToasterActions.showToaster({ id: 'UPDATE_SUCCESS' }, 'success', 5000));
  } else {
    yield toastResponseError(response);
    const errors = _.get(response, 'data.errors');
    yield put(CoursesActions.updateHoldingCourseUnitFailure(errors));
  }
}

export function* destroyHoldingCourseUnit(api, { holdingSlug, courseSlug, unitId }) {
  const response = yield call(api.destroyHoldingCourseUnit, holdingSlug, courseSlug, unitId);
  if (response.ok) {
    yield put(CoursesActions.destroyHoldingCourseUnitSuccess(unitId));
  } else {
    yield toastResponseError(response);
    const errors = _.get(response, 'data.errors');
    yield put(CoursesActions.destroyHoldingCourseUnitFailure(errors));
  }
}

export function* getLeagueCourseChapter(api, { leagueId, courseSlug, chapterId }) {
  const response = yield call(api.getLeagueCourseChapter, leagueId, courseSlug, chapterId);
  if (response.ok) {
    yield put(CoursesActions.getLeagueCourseChapterSuccess(response.data.chapter));
  } else {
    yield toastResponseError(response);
    const errors = _.get(response, 'data.errors');
    yield put(CoursesActions.getLeagueCourseChapterFailure(errors));
  }
}

export function* createLeagueCourseChapter(
  api,
  { leagueId, courseSlug, chapterAttributes, attachments },
) {
  const response = yield call(
    api.createLeagueCourseChapter,
    leagueId,
    courseSlug,
    chapterAttributes,
    attachments,
  );
  if (response.ok) {
    yield put(CoursesActions.createLeagueCourseChapterSuccess(response.data.chapter));
    yield call(
      history.push,
      `/leagues/${leagueId}/formations/${courseSlug}/chapitres/${response.data.chapter.id}/edit`,
    );
  } else {
    yield toastResponseError(response);
    const errors = _.get(response, 'data.errors');
    yield put(CoursesActions.createLeagueCourseChapterFailure(errors));
  }
}

export function* updateLeagueCourseChapter(
  api,
  { leagueId, courseSlug, chapterAttributes, attachments },
) {
  const response = yield call(
    api.updateLeagueCourseChapter,
    leagueId,
    courseSlug,
    chapterAttributes,
    attachments,
  );

  if (response.ok) {
    yield put(CoursesActions.updateLeagueCourseChapterSuccess(response.data.chapter));
    yield put(ToasterActions.showToaster({ id: 'UPDATE_SUCCESS' }, 'success', 5000));
  } else {
    yield toastResponseError(response);
    const errors = _.get(response, 'data.errors');
    yield put(CoursesActions.updateLeagueCourseChapterFailure(errors));
  }
}

export function* destroyLeagueCourseChapter(api, { leagueId, courseSlug, chapterId }) {
  const response = yield call(api.destroyLeagueCourseChapter, leagueId, courseSlug, chapterId);
  if (response.ok) {
    yield put(CoursesActions.destroyLeagueCourseChapterSuccess(response.data.unit));
  } else {
    yield toastResponseError(response);
    const errors = _.get(response, 'data.errors');
    yield put(CoursesActions.destroyLeagueCourseChapterFailure(errors));
  }
}

export function* getHoldingCourseChapter(api, { holdingSlug, courseSlug, chapterId }) {
  const response = yield call(api.getHoldingCourseChapter, holdingSlug, courseSlug, chapterId);
  if (response.ok) {
    yield put(CoursesActions.getHoldingCourseChapterSuccess(response.data.chapter));
  } else {
    yield toastResponseError(response);
    const errors = _.get(response, 'data.errors');
    yield put(CoursesActions.getHoldingCourseChapterFailure(errors));
  }
}

export function* createHoldingCourseChapter(
  api,
  { holdingSlug, orgaSlug, courseSlug, chapterAttributes, attachments },
) {
  const response = yield call(
    api.createHoldingCourseChapter,
    holdingSlug,
    courseSlug,
    chapterAttributes,
    attachments,
  );
  if (response.ok) {
    yield put(CoursesActions.createHoldingCourseChapterSuccess(response.data.chapter));
    yield call(
      history.push,
      `/${holdingSlug}/${orgaSlug}/formations/${courseSlug}/chapitres/${response.data.chapter.id}/edit`,
    );
  } else {
    yield toastResponseError(response);
    const errors = _.get(response, 'data.errors');
    yield put(CoursesActions.createHoldingCourseChapterFailure(errors));
  }
}

export function* updateHoldingCourseChapter(
  api,
  { holdingSlug, courseSlug, chapterAttributes, attachments },
) {
  const response = yield call(
    api.updateHoldingCourseChapter,
    holdingSlug,
    courseSlug,
    chapterAttributes,
    attachments,
  );

  if (response.ok) {
    yield put(CoursesActions.updateHoldingCourseChapterSuccess(response.data.chapter));
    yield put(ToasterActions.showToaster({ id: 'UPDATE_SUCCESS' }, 'success', 5000));
  } else {
    yield toastResponseError(response);
    const errors = _.get(response, 'data.errors');
    yield put(CoursesActions.updateHoldingCourseChapterFailure(errors));
  }
}

export function* destroyHoldingCourseChapter(api, { holdingSlug, courseSlug, chapterId }) {
  const response = yield call(api.destroyHoldingCourseChapter, holdingSlug, courseSlug, chapterId);
  if (response.ok) {
    yield put(CoursesActions.destroyHoldingCourseChapterSuccess(response.data.unit));
  } else {
    yield toastResponseError(response);
    const errors = _.get(response, 'data.errors');
    yield put(CoursesActions.destroyHoldingCourseChapterFailure(errors));
  }
}

export function* createUserChapterLink(api, { userId, chapter, attributes }) {
  const response = yield call(api.createUserChapterLink, userId, chapter.id, attributes);

  if (response.ok) {
    yield put(CoursesActions.createUserChapterLinkSuccess(response.data.chapter));
    yield call(history.push, computeNextChapterLink(chapter));
  } else {
    yield toastResponseError(response);
    const errors = _.get(response, 'data.errors');
    yield put(CoursesActions.createUserChapterLinkFailure(errors));
  }
}

export function* getFamilyCourses(api, { helperId }) {
  const response = yield call(api.getFamilyCourses, helperId);

  if (response.ok) {
    yield put(CoursesActions.getFamilyCoursesSuccess(response.data.courses));
  } else {
    yield toastResponseError(response);
    const errors = _.get(response, 'data.errors');
    yield put(CoursesActions.getFamilyCoursesFailure(errors));
  }
}

export function* getCourse(api, { courseSlug }) {
  const response = yield call(api.getCourse, courseSlug);
  if (response.ok) {
    yield put(CoursesActions.getCourseSuccess(response.data.course));
  } else {
    yield put(
      CoursesActions.getCourseFailure({
        ...response.data,
        status: response.status,
        course_slug: courseSlug,
      }),
    );
  }
}

export function* getCourseChapter(api, { courseSlug, chapterId }) {
  const response = yield call(api.getCourseChapter, courseSlug, chapterId);
  if (response.ok) {
    yield put(CoursesActions.getCourseChapterSuccess(response.data.chapter));
  } else {
    yield put(
      CoursesActions.getCourseChapterFailure({
        ...response.data,
        status: response.status,
        chapterId: chapterId,
      }),
    );
  }
}
