import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';
import findAndReplace, { findAndRemove } from './ReduxHelpers';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  getLeagueCoursesRequest: ['leagueId'],
  getLeagueCoursesSuccess: ['courses'],
  getLeagueCoursesFailure: ['errors '],

  getLeagueCourseRequest: ['leagueId', 'courseSlug'],
  getLeagueCourseSuccess: ['course'],
  getLeagueCourseFailure: ['errors'],

  createLeagueCourseRequest: ['leagueId', 'courseAttributes', 'cover', 'attachments'],
  createLeagueCourseSuccess: ['course'],
  createLeagueCourseFailure: ['errors'],

  updateLeagueCourseRequest: ['leagueId', 'courseAttributes', 'cover', 'attachments'],
  updateLeagueCourseSuccess: ['course'],
  updateLeagueCourseFailure: ['errors'],

  destroyLeagueCourseRequest: ['leagueId', 'courseSlug'],
  destroyLeagueCourseSuccess: ['courseSlug'],
  destroyLeagueCourseFailure: ['errors'],

  createLeagueCourseUnitRequest: ['leagueId', 'courseSlug', 'unitAttributes'],
  createLeagueCourseUnitSuccess: ['unit'],
  createLeagueCourseUnitFailure: ['errors'],

  updateLeagueCourseUnitRequest: ['leagueId', 'courseSlug', 'unitAttributes'],
  updateLeagueCourseUnitSuccess: ['unit'],
  updateLeagueCourseUnitFailure: ['errors'],

  destroyLeagueCourseUnitRequest: ['leagueId', 'courseSlug', 'unitId'],
  destroyLeagueCourseUnitSuccess: ['unitId'],
  destroyLeagueCourseUnitFailure: ['errors'],

  getLeagueCourseChapterRequest: ['leagueId', 'courseSlug', 'chapterId'],
  getLeagueCourseChapterSuccess: ['chapter'],
  getLeagueCourseChapterFailure: ['errors'],

  createLeagueCourseChapterRequest: ['leagueId', 'courseSlug', 'chapterAttributes', 'attachments'],
  createLeagueCourseChapterSuccess: ['chapter'],
  createLeagueCourseChapterFailure: ['errors'],

  updateLeagueCourseChapterRequest: ['leagueId', 'courseSlug', 'chapterAttributes', 'attachments'],
  updateLeagueCourseChapterSuccess: ['chapter'],
  updateLeagueCourseChapterFailure: ['errors'],

  destroyLeagueCourseChapterRequest: ['leagueId', 'courseSlug', 'chapterId'],
  destroyLeagueCourseChapterSuccess: ['unit'],
  destroyLeagueCourseChapterFailure: ['errors'],

  getHoldingCoursesRequest: ['holdingSlug'],
  getHoldingCoursesSuccess: ['courses'],
  getHoldingCoursesFailure: ['errors '],

  getHoldingCourseRequest: ['holdingSlug', 'courseSlug'],
  getHoldingCourseSuccess: ['course'],
  getHoldingCourseFailure: ['errors'],

  createHoldingCourseRequest: [
    'holdingSlug',
    'orgaSlug',
    'courseAttributes',
    'cover',
    'attachments',
  ],
  createHoldingCourseSuccess: ['course'],
  createHoldingCourseFailure: ['errors'],

  updateHoldingCourseRequest: ['holdingSlug', 'courseAttributes', 'cover', 'attachments'],
  updateHoldingCourseSuccess: ['course'],
  updateHoldingCourseFailure: ['errors'],

  destroyHoldingCourseRequest: ['holdingSlug', 'courseSlug'],
  destroyHoldingCourseSuccess: ['courseSlug'],
  destroyHoldingCourseFailure: ['errors'],

  createHoldingCourseUnitRequest: ['holdingSlug', 'courseSlug', 'unitAttributes'],
  createHoldingCourseUnitSuccess: ['unit'],
  createHoldingCourseUnitFailure: ['errors'],

  updateHoldingCourseUnitRequest: ['holdingSlug', 'courseSlug', 'unitAttributes'],
  updateHoldingCourseUnitSuccess: ['unit'],
  updateHoldingCourseUnitFailure: ['errors'],

  destroyHoldingCourseUnitRequest: ['holdingSlug', 'courseSlug', 'unitId'],
  destroyHoldingCourseUnitSuccess: ['unitId'],
  destroyHoldingCourseUnitFailure: ['errors'],

  getHoldingCourseChapterRequest: ['holdingSlug', 'courseSlug', 'chapterId'],
  getHoldingCourseChapterSuccess: ['chapter'],
  getHoldingCourseChapterFailure: ['errors'],

  createHoldingCourseChapterRequest: [
    'holdingSlug',
    'orgaSlug',
    'courseSlug',
    'chapterAttributes',
    'attachments',
  ],
  createHoldingCourseChapterSuccess: ['chapter'],
  createHoldingCourseChapterFailure: ['errors'],

  updateHoldingCourseChapterRequest: [
    'holdingSlug',
    'courseSlug',
    'chapterAttributes',
    'attachments',
  ],
  updateHoldingCourseChapterSuccess: ['chapter'],
  updateHoldingCourseChapterFailure: ['errors'],

  destroyHoldingCourseChapterRequest: ['holdingSlug', 'courseSlug', 'chapterId'],
  destroyHoldingCourseChapterSuccess: ['unit'],
  destroyHoldingCourseChapterFailure: ['errors'],

  createUserChapterLinkRequest: ['userId', 'chapter', 'attributes'],
  createUserChapterLinkSuccess: ['chapter'],
  createUserChapterLinkFailure: ['errors'],

  getFamilyCoursesRequest: ['helperId'],
  getFamilyCoursesSuccess: ['courses'],
  getFamilyCoursesFailure: ['errors'],

  getCourseRequest: ['courseSlug'],
  getCourseSuccess: ['course'],
  getCourseFailure: ['errors'],

  getCourseChapterRequest: ['courseSlug', 'chapterId'],
  getCourseChapterSuccess: ['chapter'],
  getCourseChapterFailure: ['errors'],

  resetCourses: [],
});

export const CoursesTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  isFetching: {
    getLeagueCourses: false,
    getLeagueCourse: false,
    createLeagueCourse: false,
    updateLeagueCourse: false,
    destroyLeagueCourse: false,
    createLeagueCourseUnit: false,
    updateLeagueCourseUnit: false,
    destroyLeagueCourseUnit: false,
    getLeagueCourseChapter: false,
    createLeagueCourseChapter: false,
    updateLeagueCourseChapter: false,
    destroyLeagueCourseChapter: false,
    getHoldingCourses: false,
    getHoldingCourse: false,
    createHoldingCourse: false,
    updateHoldingCourse: false,
    destroyHoldingCourse: false,
    createHoldingCourseUnit: false,
    updateHoldingCourseUnit: false,
    destroyHoldingCourseUnit: false,
    getHoldingCourseChapter: false,
    createHoldingCourseChapter: false,
    updateHoldingCourseChapter: false,
    destroyHoldingCourseChapter: false,
    createUserChapterLink: false,
    getFamilyCourses: false,
    getCourse: false,
    getCourseChapter: false,
  },
  courses: [],
  course: null,
  chapter: null,
  errors: {
    getLeagueCourses: null,
    getLeagueCourse: null,
    createLeagueCourse: null,
    updateLeagueCourse: null,
    destroyLeagueCourse: null,
    createLeagueCourseUnit: null,
    updateLeagueCourseUnit: null,
    destroyLeagueCourseUnit: null,
    getLeagueCourseChapter: null,
    createLeagueCourseChapter: null,
    updateLeagueCourseChapter: null,
    destroyLeagueCourseChapter: null,
    getHoldingCourses: null,
    getHoldingCourse: null,
    createHoldingCourse: null,
    updateHoldingCourse: null,
    destroyHoldingCourse: null,
    createHoldingCourseUnit: null,
    updateHoldingCourseUnit: null,
    destroyHoldingCourseUnit: null,
    getHoldingCourseChapter: null,
    createHoldingCourseChapter: null,
    updateHoldingCourseChapter: null,
    destroyHoldingCourseChapter: null,
    createUserChapterLink: null,
    getFamilyCourses: null,
    getCourse: null,
    getCourseChapter: null,
  },
});

/* ------------- Reducers ------------- */

// -
// getLeagueCourses
// -

const getLeagueCoursesRequest = state => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    getLeagueCourses: true,
  },
});

const getLeagueCoursesSuccess = (state, { courses }) => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    getLeagueCourses: false,
  },
  errors: {
    ...state.errors,
    getLeagueCourses: INITIAL_STATE.errors.getLeagueCourses,
  },
  courses,
});

const getLeagueCoursesFailure = (state, { errors }) => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    getLeagueCourses: false,
  },
  courses: [],
  errors: {
    ...state.errors,
    getLeagueCourses: errors,
  },
});

//
// get one League course
//

const getLeagueCourseRequest = state => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    getLeagueCourse: false,
  },
  errors: {
    ...state.errors,
    getLeagueCourse: INITIAL_STATE.errors.getLeagueCourse,
  },
});

const getLeagueCourseSuccess = (state, { course }) => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    getLeagueCourse: false,
  },
  errors: {
    ...state.errors,
    getLeagueCourse: INITIAL_STATE.errors.getLeagueCourse,
  },
  course,
});

const getLeagueCourseFailure = (state, { errors }) => {
  return {
    ...state,
    isFetching: {
      ...state.isFetching,
      getLeagueCourse: false,
    },
    errors: {
      ...state.errors,
      getLeagueCourse: errors,
    },
  };
};

//
// createLeagueCourse
//
const createLeagueCourseRequest = state => ({
  ...state,
  isFetching: {
    ...INITIAL_STATE.isFetching,
    createLeagueCourse: true,
  },
  errors: {
    ...INITIAL_STATE.errors,
    createLeagueCourse: null,
  },
});

const createLeagueCourseSuccess = (state, { course }) => ({
  ...state,
  isFetching: {
    ...INITIAL_STATE.isFetching,
    createLeagueCourse: false,
  },
  errors: {
    ...INITIAL_STATE.errors,
    createLeagueCourse: null,
  },
  course,
});

const createLeagueCourseFailure = (state, { errors }) => ({
  ...state,
  isFetching: {
    ...INITIAL_STATE.isFetching,
    createLeagueCourse: false,
  },
  errors: {
    ...INITIAL_STATE.errors,
    createLeagueCourse: errors,
  },
});

//
// updateLeagueCourse
//
const updateLeagueCourseRequest = state => ({
  ...state,
  isFetching: {
    ...INITIAL_STATE.isFetching,
    updateLeagueCourse: true,
  },
  errors: {
    ...INITIAL_STATE.errors,
    updateLeagueCourse: null,
  },
});

const updateLeagueCourseSuccess = (state, { course }) => ({
  ...state,
  isFetching: {
    ...INITIAL_STATE.isFetching,
    updateLeagueCourse: false,
  },
  errors: {
    ...INITIAL_STATE.errors,
    updateLeagueCourse: null,
  },
  course,
});

const updateLeagueCourseFailure = (state, { errors }) => ({
  ...state,
  isFetching: {
    ...INITIAL_STATE.isFetching,
    updateLeagueCourse: false,
  },
  errors: {
    ...INITIAL_STATE.errors,
    updateLeagueCourse: errors,
  },
});

//
// destroyLeagueCourse
//
const destroyLeagueCourseRequest = state => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    destroyLeagueCourse: true,
  },
  errors: {
    ...state.errors,
    destroyLeagueCourse: INITIAL_STATE.errors.destroyLeagueCourse,
  },
});

const destroyLeagueCourseSuccess = (state, { courseSlug }) => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    destroyLeagueCourse: false,
  },
  errors: {
    ...state.errors,
    destroyLeagueCourse: INITIAL_STATE.errors.destroyLeagueCourse,
  },
  courses: findAndRemove(c => c.slug === courseSlug)(state.courses),
});

const destroyLeagueCourseFailure = (state, { errors }) => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    destroyLeagueCourse: false,
  },
  errors: {
    ...state.errors,
    destroyLeagueCourse: errors,
  },
});

//
// createLeagueCourseUnit
//
const createLeagueCourseUnitRequest = state => ({
  ...state,
  isFetching: {
    ...INITIAL_STATE.isFetching,
    createLeagueCourseUnit: true,
  },
  errors: {
    ...INITIAL_STATE.errors,
    createLeagueCourseUnit: null,
  },
});

const createLeagueCourseUnitSuccess = (state, { unit }) => {
  const newCourse = { ...state.course };
  const units = newCourse.units.slice();
  units.push(unit);
  newCourse.units = units;

  return {
    ...state,
    isFetching: {
      ...INITIAL_STATE.isFetching,
      createLeagueCourseUnit: false,
    },
    errors: {
      ...INITIAL_STATE.errors,
      createLeagueCourseUnit: null,
    },
    course: newCourse,
  };
};

const createLeagueCourseUnitFailure = (state, { errors }) => ({
  ...state,
  isFetching: {
    ...INITIAL_STATE.isFetching,
    createLeagueCourseUnit: false,
  },
  errors: {
    ...INITIAL_STATE.errors,
    createLeagueCourseUnit: errors,
  },
});

//
// updateLeagueCourseUnit
//
const updateLeagueCourseUnitRequest = state => ({
  ...state,
  isFetching: {
    ...INITIAL_STATE.isFetching,
    updateLeagueCourseUnit: true,
  },
  errors: {
    ...INITIAL_STATE.errors,
    updateLeagueCourseUnit: null,
  },
});

const updateLeagueCourseUnitSuccess = (state, { unit }) => {
  const newCourse = { ...state.course };
  newCourse.units = findAndReplace(u => u.id === unit.id, unit)(state.course.units);

  return {
    ...state,
    isFetching: {
      ...INITIAL_STATE.isFetching,
      updateLeagueCourseUnit: false,
    },
    errors: {
      ...INITIAL_STATE.errors,
      updateLeagueCourseUnit: null,
    },
    course: newCourse,
  };
};

const updateLeagueCourseUnitFailure = (state, { errors }) => ({
  ...state,
  isFetching: {
    ...INITIAL_STATE.isFetching,
    updateLeagueCourseUnit: false,
  },
  errors: {
    ...INITIAL_STATE.errors,
    updateLeagueCourseUnit: errors,
  },
});

//
// destroyLeagueCourseUnit
//
const destroyLeagueCourseUnitRequest = state => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    destroyLeagueCourseUnit: true,
  },
  errors: {
    ...state.errors,
    destroyLeagueCourseUnit: INITIAL_STATE.errors.destroyLeagueCourseUnit,
  },
});

const destroyLeagueCourseUnitSuccess = (state, { unitId }) => {
  const newCourse = { ...state.course };
  newCourse.units = findAndRemove(u => u.id === unitId)(state.course.units);

  return {
    ...state,
    isFetching: {
      ...state.isFetching,
      destroyLeagueCourseUnit: false,
    },
    errors: {
      ...state.errors,
      destroyLeagueCourseUnit: INITIAL_STATE.errors.destroyLeagueCourseUnit,
    },
    course: newCourse,
  };
};

const destroyLeagueCourseUnitFailure = (state, { errors }) => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    destroyLeagueCourseUnit: false,
  },
  errors: {
    ...state.errors,
    destroyLeagueCourseUnit: errors,
  },
});

//
// get one League course
//

const getLeagueCourseChapterRequest = state => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    getLeagueCourseChapter: false,
  },
  errors: {
    ...state.errors,
    getLeagueCourseChapter: INITIAL_STATE.errors.getLeagueCourseChapter,
  },
});

const getLeagueCourseChapterSuccess = (state, { chapter }) => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    getLeagueCourseChapter: false,
  },
  errors: {
    ...state.errors,
    getLeagueCourseChapter: INITIAL_STATE.errors.getLeagueCourseChapter,
  },
  chapter,
});

const getLeagueCourseChapterFailure = (state, { errors }) => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    getLeagueCourseChapter: false,
  },
  errors: {
    ...state.errors,
    getLeagueCourseChapter: errors,
  },
});

//
// createLeagueCourseChapter
//
const createLeagueCourseChapterRequest = state => ({
  ...state,
  isFetching: {
    ...INITIAL_STATE.isFetching,
    createLeagueCourseChapter: true,
  },
  errors: {
    ...INITIAL_STATE.errors,
    createLeagueCourseChapter: null,
  },
});

const createLeagueCourseChapterSuccess = (state, { chapter }) => ({
  ...state,
  isFetching: {
    ...INITIAL_STATE.isFetching,
    createLeagueCourseChapter: false,
  },
  errors: {
    ...INITIAL_STATE.errors,
    createLeagueCourseChapter: null,
  },
  chapter: chapter,
});

const createLeagueCourseChapterFailure = (state, { errors }) => ({
  ...state,
  isFetching: {
    ...INITIAL_STATE.isFetching,
    createLeagueCourseChapter: false,
  },
  errors: {
    ...INITIAL_STATE.errors,
    createLeagueCourseChapter: errors,
  },
});

//
// updateLeagueCourseChapter
//
const updateLeagueCourseChapterRequest = state => ({
  ...state,
  isFetching: {
    ...INITIAL_STATE.isFetching,
    updateLeagueCourseChapter: true,
  },
  errors: {
    ...INITIAL_STATE.errors,
    updateLeagueCourseChapter: null,
  },
});

const updateLeagueCourseChapterSuccess = (state, { chapter }) => ({
  ...state,
  isFetching: {
    ...INITIAL_STATE.isFetching,
    updateLeagueCourseChapter: false,
  },
  errors: {
    ...INITIAL_STATE.errors,
    updateLeagueCourseChapter: null,
  },
  chapter,
});

const updateLeagueCourseChapterFailure = (state, { errors }) => ({
  ...state,
  isFetching: {
    ...INITIAL_STATE.isFetching,
    updateLeagueCourseChapter: false,
  },
  errors: {
    ...INITIAL_STATE.errors,
    updateLeagueCourseChapter: errors,
  },
});

//
// destroyLeagueCourseChapter
//
const destroyLeagueCourseChapterRequest = state => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    destroyLeagueCourseChapter: true,
  },
  errors: {
    ...state.errors,
    destroyLeagueCourseChapter: INITIAL_STATE.errors.destroyLeagueCourseChapter,
  },
});

const destroyLeagueCourseChapterSuccess = (state, { unit }) => {
  const newCourse = { ...state.course };
  newCourse.units = findAndReplace(u => u.id === unit.id, unit)(newCourse.units);

  return {
    ...state,
    isFetching: {
      ...state.isFetching,
      destroyLeagueCourseChapter: false,
    },
    errors: {
      ...state.errors,
      destroyLeagueCourseChapter: INITIAL_STATE.errors.destroyLeagueCourseChapter,
    },
    course: newCourse,
  };
};

const destroyLeagueCourseChapterFailure = (state, { errors }) => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    destroyLeagueCourseChapter: false,
  },
  errors: {
    ...state.errors,
    destroyLeagueCourseChapter: errors,
  },
});

// -
// getHoldingCourses
// -

const getHoldingCoursesRequest = state => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    getHoldingCourses: true,
  },
});

const getHoldingCoursesSuccess = (state, { courses }) => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    getHoldingCourses: false,
  },
  errors: {
    ...state.errors,
    getHoldingCourses: INITIAL_STATE.errors.getHoldingCourses,
  },
  courses,
});

const getHoldingCoursesFailure = (state, { errors }) => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    getHoldingCourses: false,
  },
  courses: [],
  errors: {
    ...state.errors,
    getHoldingCourses: errors,
  },
});

//
// get one Holding course
//

const getHoldingCourseRequest = state => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    getHoldingCourse: false,
  },
  errors: {
    ...state.errors,
    getHoldingCourse: INITIAL_STATE.errors.getHoldingCourse,
  },
});

const getHoldingCourseSuccess = (state, { course }) => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    getHoldingCourse: false,
  },
  errors: {
    ...state.errors,
    getHoldingCourse: INITIAL_STATE.errors.getHoldingCourse,
  },
  course,
});

const getHoldingCourseFailure = (state, { errors }) => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    getHoldingCourse: false,
  },
  errors: {
    ...state.errors,
    getHoldingCourse: errors,
  },
});

//
// createHoldingCourse
//
const createHoldingCourseRequest = state => ({
  ...state,
  isFetching: {
    ...INITIAL_STATE.isFetching,
    createHoldingCourse: true,
  },
  errors: {
    ...INITIAL_STATE.errors,
    createHoldingCourse: null,
  },
});

const createHoldingCourseSuccess = (state, { course }) => ({
  ...state,
  isFetching: {
    ...INITIAL_STATE.isFetching,
    createHoldingCourse: false,
  },
  errors: {
    ...INITIAL_STATE.errors,
    createHoldingCourse: null,
  },
  course,
});

const createHoldingCourseFailure = (state, { errors }) => ({
  ...state,
  isFetching: {
    ...INITIAL_STATE.isFetching,
    createHoldingCourse: false,
  },
  errors: {
    ...INITIAL_STATE.errors,
    createHoldingCourse: errors,
  },
});

//
// updateHoldingCourse
//
const updateHoldingCourseRequest = state => ({
  ...state,
  isFetching: {
    ...INITIAL_STATE.isFetching,
    updateHoldingCourse: true,
  },
  errors: {
    ...INITIAL_STATE.errors,
    updateHoldingCourse: null,
  },
});

const updateHoldingCourseSuccess = (state, { course }) => ({
  ...state,
  isFetching: {
    ...INITIAL_STATE.isFetching,
    updateHoldingCourse: false,
  },
  errors: {
    ...INITIAL_STATE.errors,
    updateHoldingCourse: null,
  },
  course,
});

const updateHoldingCourseFailure = (state, { errors }) => ({
  ...state,
  isFetching: {
    ...INITIAL_STATE.isFetching,
    updateHoldingCourse: false,
  },
  errors: {
    ...INITIAL_STATE.errors,
    updateHoldingCourse: errors,
  },
});

//
// destroyHoldingCourse
//
const destroyHoldingCourseRequest = state => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    destroyHoldingCourse: true,
  },
  errors: {
    ...state.errors,
    destroyHoldingCourse: INITIAL_STATE.errors.destroyHoldingCourse,
  },
});

const destroyHoldingCourseSuccess = (state, { courseSlug }) => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    destroyHoldingCourse: false,
  },
  errors: {
    ...state.errors,
    destroyHoldingCourse: INITIAL_STATE.errors.destroyHoldingCourse,
  },
  courses: findAndRemove(c => c.slug === courseSlug)(state.courses),
});

const destroyHoldingCourseFailure = (state, { errors }) => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    destroyHoldingCourse: false,
  },
  errors: {
    ...state.errors,
    destroyHoldingCourse: errors,
  },
});

//
// createHoldingCourseUnit
//
const createHoldingCourseUnitRequest = state => ({
  ...state,
  isFetching: {
    ...INITIAL_STATE.isFetching,
    createHoldingCourseUnit: true,
  },
  errors: {
    ...INITIAL_STATE.errors,
    createHoldingCourseUnit: null,
  },
});

const createHoldingCourseUnitSuccess = (state, { unit }) => {
  const newCourse = { ...state.course };
  const units = newCourse.units.slice();
  units.push(unit);
  newCourse.units = units;

  return {
    ...state,
    isFetching: {
      ...INITIAL_STATE.isFetching,
      createHoldingCourseUnit: false,
    },
    errors: {
      ...INITIAL_STATE.errors,
      createHoldingCourseUnit: null,
    },
    course: newCourse,
  };
};

const createHoldingCourseUnitFailure = (state, { errors }) => ({
  ...state,
  isFetching: {
    ...INITIAL_STATE.isFetching,
    createHoldingCourseUnit: false,
  },
  errors: {
    ...INITIAL_STATE.errors,
    createHoldingCourseUnit: errors,
  },
});

//
// updateHoldingCourseUnit
//
const updateHoldingCourseUnitRequest = state => ({
  ...state,
  isFetching: {
    ...INITIAL_STATE.isFetching,
    updateHoldingCourseUnit: true,
  },
  errors: {
    ...INITIAL_STATE.errors,
    updateHoldingCourseUnit: null,
  },
});

const updateHoldingCourseUnitSuccess = (state, { unit }) => {
  const newCourse = { ...state.course };
  newCourse.units = findAndReplace(u => u.id === unit.id, unit)(state.course.units);

  return {
    ...state,
    isFetching: {
      ...INITIAL_STATE.isFetching,
      updateHoldingCourseUnit: false,
    },
    errors: {
      ...INITIAL_STATE.errors,
      updateHoldingCourseUnit: null,
    },
    course: newCourse,
  };
};

const updateHoldingCourseUnitFailure = (state, { errors }) => ({
  ...state,
  isFetching: {
    ...INITIAL_STATE.isFetching,
    updateHoldingCourseUnit: false,
  },
  errors: {
    ...INITIAL_STATE.errors,
    updateHoldingCourseUnit: errors,
  },
});

//
// destroyHoldingCourseUnit
//
const destroyHoldingCourseUnitRequest = state => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    destroyHoldingCourseUnit: true,
  },
  errors: {
    ...state.errors,
    destroyHoldingCourseUnit: INITIAL_STATE.errors.destroyHoldingCourseUnit,
  },
});

const destroyHoldingCourseUnitSuccess = (state, { unitId }) => {
  const newCourse = { ...state.course };
  newCourse.units = findAndRemove(u => u.id === unitId)(state.course.units);

  return {
    ...state,
    isFetching: {
      ...state.isFetching,
      destroyHoldingCourseUnit: false,
    },
    errors: {
      ...state.errors,
      destroyHoldingCourseUnit: INITIAL_STATE.errors.destroyHoldingCourseUnit,
    },
    course: newCourse,
  };
};

const destroyHoldingCourseUnitFailure = (state, { errors }) => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    destroyHoldingCourseUnit: false,
  },
  errors: {
    ...state.errors,
    destroyHoldingCourseUnit: errors,
  },
});

//
// get one Holding course
//

const getHoldingCourseChapterRequest = state => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    getHoldingCourseChapter: false,
  },
  errors: {
    ...state.errors,
    getHoldingCourseChapter: INITIAL_STATE.errors.getHoldingCourseChapter,
  },
});

const getHoldingCourseChapterSuccess = (state, { chapter }) => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    getHoldingCourseChapter: false,
  },
  errors: {
    ...state.errors,
    getHoldingCourseChapter: INITIAL_STATE.errors.getHoldingCourseChapter,
  },
  chapter,
});

const getHoldingCourseChapterFailure = (state, { errors }) => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    getHoldingCourseChapter: false,
  },
  errors: {
    ...state.errors,
    getHoldingCourseChapter: errors,
  },
});

//
// createHoldingCourseChapter
//
const createHoldingCourseChapterRequest = state => ({
  ...state,
  isFetching: {
    ...INITIAL_STATE.isFetching,
    createHoldingCourseChapter: true,
  },
  errors: {
    ...INITIAL_STATE.errors,
    createHoldingCourseChapter: null,
  },
});

const createHoldingCourseChapterSuccess = (state, { chapter }) => ({
  ...state,
  isFetching: {
    ...INITIAL_STATE.isFetching,
    createHoldingCourseChapter: false,
  },
  errors: {
    ...INITIAL_STATE.errors,
    createHoldingCourseChapter: null,
  },
  chapter: chapter,
});

const createHoldingCourseChapterFailure = (state, { errors }) => ({
  ...state,
  isFetching: {
    ...INITIAL_STATE.isFetching,
    createHoldingCourseChapter: false,
  },
  errors: {
    ...INITIAL_STATE.errors,
    createHoldingCourseChapter: errors,
  },
});

//
// updateHoldingCourseChapter
//
const updateHoldingCourseChapterRequest = state => ({
  ...state,
  isFetching: {
    ...INITIAL_STATE.isFetching,
    updateHoldingCourseChapter: true,
  },
  errors: {
    ...INITIAL_STATE.errors,
    updateHoldingCourseChapter: null,
  },
});

const updateHoldingCourseChapterSuccess = (state, { chapter }) => ({
  ...state,
  isFetching: {
    ...INITIAL_STATE.isFetching,
    updateHoldingCourseChapter: false,
  },
  errors: {
    ...INITIAL_STATE.errors,
    updateHoldingCourseChapter: null,
  },
  chapter,
});

const updateHoldingCourseChapterFailure = (state, { errors }) => ({
  ...state,
  isFetching: {
    ...INITIAL_STATE.isFetching,
    updateHoldingCourseChapter: false,
  },
  errors: {
    ...INITIAL_STATE.errors,
    updateHoldingCourseChapter: errors,
  },
});

//
// destroyHoldingCourseChapter
//
const destroyHoldingCourseChapterRequest = state => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    destroyHoldingCourseChapter: true,
  },
  errors: {
    ...state.errors,
    destroyHoldingCourseChapter: INITIAL_STATE.errors.destroyHoldingCourseChapter,
  },
});

const destroyHoldingCourseChapterSuccess = (state, { unit }) => {
  const newCourse = { ...state.course };
  newCourse.units = findAndReplace(u => u.id === unit.id, unit)(newCourse.units);

  return {
    ...state,
    isFetching: {
      ...state.isFetching,
      destroyHoldingCourseChapter: false,
    },
    errors: {
      ...state.errors,
      destroyHoldingCourseChapter: INITIAL_STATE.errors.destroyHoldingCourseChapter,
    },
    course: newCourse,
  };
};

const destroyHoldingCourseChapterFailure = (state, { errors }) => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    destroyHoldingCourseChapter: false,
  },
  errors: {
    ...state.errors,
    destroyHoldingCourseChapter: errors,
  },
});

//
// createUserChapterLink
//
const createUserChapterLinkRequest = state => ({
  ...state,
  isFetching: {
    ...INITIAL_STATE.isFetching,
    createUserChapterLink: true,
  },
  errors: {
    ...INITIAL_STATE.errors,
    createUserChapterLink: null,
  },
});

const createUserChapterLinkSuccess = (state, { chapter }) => ({
  ...state,
  isFetching: {
    ...INITIAL_STATE.isFetching,
    createUserChapterLink: false,
  },
  errors: {
    ...INITIAL_STATE.errors,
    createUserChapterLink: null,
  },
  chapter: chapter,
  course: {
    ...state.course,
    units: findAndReplace(u => u.id === chapter.unit.id, chapter.unit)(state.course.units),
  },
});

const createUserChapterLinkFailure = (state, { errors }) => ({
  ...state,
  isFetching: {
    ...INITIAL_STATE.isFetching,
    createUserChapterLink: false,
  },
  errors: {
    ...INITIAL_STATE.errors,
    createUserChapterLink: errors,
  },
});

//
// getFamilyCourses
//

const getFamilyCoursesRequest = state => ({
  ...state,
  isFetching: { ...state.isFetching, getFamilyCourses: true },
  errors: {
    ...state.errors,
    getFamilyCourses: INITIAL_STATE.errors.getFamilyCourses,
  },
});

const getFamilyCoursesSuccess = (state, { courses }) => ({
  ...state,
  isFetching: { ...state.isFetching, getFamilyCourses: false },
  courses,
});

const getFamilyCoursesFailure = (state, { errors }) => ({
  ...state,
  isFetching: { ...state.isFetching, getFamilyCourses: false },
  errors: {
    ...state.errors,
    getFamilyCourses: errors,
  },
});

//
// get one  course
//

const getCourseRequest = state => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    getCourse: false,
  },
  errors: {
    ...state.errors,
    getCourse: INITIAL_STATE.errors.getCourse,
  },
});

const getCourseSuccess = (state, { course }) => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    getCourse: false,
  },
  errors: {
    ...state.errors,
    getCourse: INITIAL_STATE.errors.getCourse,
  },
  course,
});

const getCourseFailure = (state, { errors }) => {
  return {
    ...state,
    isFetching: {
      ...state.isFetching,
      getCourse: false,
    },
    errors: {
      ...state.errors,
      getCourse: errors,
    },
  };
};

//
// get one course chapter
//

const getCourseChapterRequest = state => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    getCourseChapter: false,
  },
  errors: {
    ...state.errors,
    getCourseChapter: INITIAL_STATE.errors.getCourseChapter,
  },
});

const getCourseChapterSuccess = (state, { chapter }) => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    getCourseChapter: false,
  },
  errors: {
    ...state.errors,
    getCourseChapter: INITIAL_STATE.errors.getCourseChapter,
  },
  chapter,
});

const getCourseChapterFailure = (state, { errors }) => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    getCourseChapter: false,
  },
  errors: {
    ...state.errors,
    getCourseChapter: errors,
  },
});

const resetCourses = () => INITIAL_STATE;

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_LEAGUE_COURSES_REQUEST]: getLeagueCoursesRequest,
  [Types.GET_LEAGUE_COURSES_SUCCESS]: getLeagueCoursesSuccess,
  [Types.GET_LEAGUE_COURSES_FAILURE]: getLeagueCoursesFailure,

  [Types.GET_LEAGUE_COURSE_REQUEST]: getLeagueCourseRequest,
  [Types.GET_LEAGUE_COURSE_SUCCESS]: getLeagueCourseSuccess,
  [Types.GET_LEAGUE_COURSE_FAILURE]: getLeagueCourseFailure,

  [Types.CREATE_LEAGUE_COURSE_REQUEST]: createLeagueCourseRequest,
  [Types.CREATE_LEAGUE_COURSE_SUCCESS]: createLeagueCourseSuccess,
  [Types.CREATE_LEAGUE_COURSE_FAILURE]: createLeagueCourseFailure,

  [Types.UPDATE_LEAGUE_COURSE_REQUEST]: updateLeagueCourseRequest,
  [Types.UPDATE_LEAGUE_COURSE_SUCCESS]: updateLeagueCourseSuccess,
  [Types.UPDATE_LEAGUE_COURSE_FAILURE]: updateLeagueCourseFailure,

  [Types.DESTROY_LEAGUE_COURSE_REQUEST]: destroyLeagueCourseRequest,
  [Types.DESTROY_LEAGUE_COURSE_SUCCESS]: destroyLeagueCourseSuccess,
  [Types.DESTROY_LEAGUE_COURSE_FAILURE]: destroyLeagueCourseFailure,

  [Types.CREATE_LEAGUE_COURSE_UNIT_REQUEST]: createLeagueCourseUnitRequest,
  [Types.CREATE_LEAGUE_COURSE_UNIT_SUCCESS]: createLeagueCourseUnitSuccess,
  [Types.CREATE_LEAGUE_COURSE_UNIT_FAILURE]: createLeagueCourseUnitFailure,

  [Types.UPDATE_LEAGUE_COURSE_UNIT_REQUEST]: updateLeagueCourseUnitRequest,
  [Types.UPDATE_LEAGUE_COURSE_UNIT_SUCCESS]: updateLeagueCourseUnitSuccess,
  [Types.UPDATE_LEAGUE_COURSE_UNIT_FAILURE]: updateLeagueCourseUnitFailure,

  [Types.DESTROY_LEAGUE_COURSE_UNIT_REQUEST]: destroyLeagueCourseUnitRequest,
  [Types.DESTROY_LEAGUE_COURSE_UNIT_SUCCESS]: destroyLeagueCourseUnitSuccess,
  [Types.DESTROY_LEAGUE_COURSE_UNIT_FAILURE]: destroyLeagueCourseUnitFailure,

  [Types.GET_LEAGUE_COURSE_CHAPTER_REQUEST]: getLeagueCourseChapterRequest,
  [Types.GET_LEAGUE_COURSE_CHAPTER_SUCCESS]: getLeagueCourseChapterSuccess,
  [Types.GET_LEAGUE_COURSE_CHAPTER_FAILURE]: getLeagueCourseChapterFailure,

  [Types.CREATE_LEAGUE_COURSE_CHAPTER_REQUEST]: createLeagueCourseChapterRequest,
  [Types.CREATE_LEAGUE_COURSE_CHAPTER_SUCCESS]: createLeagueCourseChapterSuccess,
  [Types.CREATE_LEAGUE_COURSE_CHAPTER_FAILURE]: createLeagueCourseChapterFailure,

  [Types.UPDATE_LEAGUE_COURSE_CHAPTER_REQUEST]: updateLeagueCourseChapterRequest,
  [Types.UPDATE_LEAGUE_COURSE_CHAPTER_SUCCESS]: updateLeagueCourseChapterSuccess,
  [Types.UPDATE_LEAGUE_COURSE_CHAPTER_FAILURE]: updateLeagueCourseChapterFailure,

  [Types.DESTROY_LEAGUE_COURSE_CHAPTER_REQUEST]: destroyLeagueCourseChapterRequest,
  [Types.DESTROY_LEAGUE_COURSE_CHAPTER_SUCCESS]: destroyLeagueCourseChapterSuccess,
  [Types.DESTROY_LEAGUE_COURSE_CHAPTER_FAILURE]: destroyLeagueCourseChapterFailure,

  [Types.GET_HOLDING_COURSES_REQUEST]: getHoldingCoursesRequest,
  [Types.GET_HOLDING_COURSES_SUCCESS]: getHoldingCoursesSuccess,
  [Types.GET_HOLDING_COURSES_FAILURE]: getHoldingCoursesFailure,

  [Types.GET_HOLDING_COURSE_REQUEST]: getHoldingCourseRequest,
  [Types.GET_HOLDING_COURSE_SUCCESS]: getHoldingCourseSuccess,
  [Types.GET_HOLDING_COURSE_FAILURE]: getHoldingCourseFailure,

  [Types.CREATE_HOLDING_COURSE_REQUEST]: createHoldingCourseRequest,
  [Types.CREATE_HOLDING_COURSE_SUCCESS]: createHoldingCourseSuccess,
  [Types.CREATE_HOLDING_COURSE_FAILURE]: createHoldingCourseFailure,

  [Types.UPDATE_HOLDING_COURSE_REQUEST]: updateHoldingCourseRequest,
  [Types.UPDATE_HOLDING_COURSE_SUCCESS]: updateHoldingCourseSuccess,
  [Types.UPDATE_HOLDING_COURSE_FAILURE]: updateHoldingCourseFailure,

  [Types.DESTROY_HOLDING_COURSE_REQUEST]: destroyHoldingCourseRequest,
  [Types.DESTROY_HOLDING_COURSE_SUCCESS]: destroyHoldingCourseSuccess,
  [Types.DESTROY_HOLDING_COURSE_FAILURE]: destroyHoldingCourseFailure,

  [Types.CREATE_HOLDING_COURSE_UNIT_REQUEST]: createHoldingCourseUnitRequest,
  [Types.CREATE_HOLDING_COURSE_UNIT_SUCCESS]: createHoldingCourseUnitSuccess,
  [Types.CREATE_HOLDING_COURSE_UNIT_FAILURE]: createHoldingCourseUnitFailure,

  [Types.UPDATE_HOLDING_COURSE_UNIT_REQUEST]: updateHoldingCourseUnitRequest,
  [Types.UPDATE_HOLDING_COURSE_UNIT_SUCCESS]: updateHoldingCourseUnitSuccess,
  [Types.UPDATE_HOLDING_COURSE_UNIT_FAILURE]: updateHoldingCourseUnitFailure,

  [Types.DESTROY_HOLDING_COURSE_UNIT_REQUEST]: destroyHoldingCourseUnitRequest,
  [Types.DESTROY_HOLDING_COURSE_UNIT_SUCCESS]: destroyHoldingCourseUnitSuccess,
  [Types.DESTROY_HOLDING_COURSE_UNIT_FAILURE]: destroyHoldingCourseUnitFailure,

  [Types.GET_HOLDING_COURSE_CHAPTER_REQUEST]: getHoldingCourseChapterRequest,
  [Types.GET_HOLDING_COURSE_CHAPTER_SUCCESS]: getHoldingCourseChapterSuccess,
  [Types.GET_HOLDING_COURSE_CHAPTER_FAILURE]: getHoldingCourseChapterFailure,

  [Types.CREATE_HOLDING_COURSE_CHAPTER_REQUEST]: createHoldingCourseChapterRequest,
  [Types.CREATE_HOLDING_COURSE_CHAPTER_SUCCESS]: createHoldingCourseChapterSuccess,
  [Types.CREATE_HOLDING_COURSE_CHAPTER_FAILURE]: createHoldingCourseChapterFailure,

  [Types.UPDATE_HOLDING_COURSE_CHAPTER_REQUEST]: updateHoldingCourseChapterRequest,
  [Types.UPDATE_HOLDING_COURSE_CHAPTER_SUCCESS]: updateHoldingCourseChapterSuccess,
  [Types.UPDATE_HOLDING_COURSE_CHAPTER_FAILURE]: updateHoldingCourseChapterFailure,

  [Types.DESTROY_HOLDING_COURSE_CHAPTER_REQUEST]: destroyHoldingCourseChapterRequest,
  [Types.DESTROY_HOLDING_COURSE_CHAPTER_SUCCESS]: destroyHoldingCourseChapterSuccess,
  [Types.DESTROY_HOLDING_COURSE_CHAPTER_FAILURE]: destroyHoldingCourseChapterFailure,

  [Types.CREATE_USER_CHAPTER_LINK_REQUEST]: createUserChapterLinkRequest,
  [Types.CREATE_USER_CHAPTER_LINK_SUCCESS]: createUserChapterLinkSuccess,
  [Types.CREATE_USER_CHAPTER_LINK_FAILURE]: createUserChapterLinkFailure,

  [Types.GET_FAMILY_COURSES_REQUEST]: getFamilyCoursesRequest,
  [Types.GET_FAMILY_COURSES_SUCCESS]: getFamilyCoursesSuccess,
  [Types.GET_FAMILY_COURSES_FAILURE]: getFamilyCoursesFailure,

  [Types.GET_COURSE_REQUEST]: getCourseRequest,
  [Types.GET_COURSE_SUCCESS]: getCourseSuccess,
  [Types.GET_COURSE_FAILURE]: getCourseFailure,

  [Types.GET_COURSE_CHAPTER_REQUEST]: getCourseChapterRequest,
  [Types.GET_COURSE_CHAPTER_SUCCESS]: getCourseChapterSuccess,
  [Types.GET_COURSE_CHAPTER_FAILURE]: getCourseChapterFailure,

  [Types.RESET_COURSES]: resetCourses,
});
