import _get from 'lodash/get';

const RESIZE_ERRORS = {
  READ_FILE: 'READ_FILE_ERROR',
  MAKE_IMAGE: 'IMAGE_ERROR',
};

const asyncFileReader = file =>
  new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = () => {
      reject(new Error(RESIZE_ERRORS.READ_FILE));
    };
    fileReader.readAsDataURL(file);
  });

const asyncImageFromFileContent = fileContent =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.onload = () => {
      resolve(image);
    };
    image.onerror = () => {
      reject(new Error(RESIZE_ERRORS.MAKE_IMAGE));
    };
    image.src = fileContent;
  });

const asyncRedrawImagetoBlob = (image, blobType, { width, height, quality }) =>
  new Promise((resolve, reject) => {
    try {
      const srcW = image.width;
      const srcH = image.height;
      const ratio = Math.min(height / srcH, width / srcW);
      const canvas = document.createElement('canvas');

      canvas.height = srcH * ratio;
      canvas.width = srcW * ratio;
      canvas.getContext('2d').drawImage(image, 0, 0, canvas.width, canvas.height);
      canvas.toBlob(resolve, blobType, quality);
    } catch (e) {
      reject(e);
    }
  });

const resizeImage = (file, { width, height, quality }) => {
  const blobType = file.type;

  return new Promise((resolve, reject) => {
    asyncFileReader(file)
      .then(asyncImageFromFileContent)
      .then(image => asyncRedrawImagetoBlob(image, blobType, { width, height, quality }))
      .then(resolve)
      .catch(reject);
  });
};

const isWebpSupported = () => {
  if (typeof document === 'undefined') return true; // SSR
  // eslint-disable-next-line no-undef
  if (typeof Modernizr !== 'undefined' && !!_get(Modernizr, 'webp')) {
    return true;
  }
  return false;
};

export const UNLOCKED_PARAM = 'X-Amz-Expires';

export function getCdnUrl(url, width, height) {
  const isUnlocked = url?.includes(UNLOCKED_PARAM);
  if (!url || (!width && !height) || isUnlocked) {
    return url; // no resize available/needed
  }
  const format = isWebpSupported() ? 'webp' : 'jpg';
  const quality = 85;
  let crop = '';
  if (width && height) {
    crop = '&fit=cover';
  }
  /*
   * https://images.weserv.nl/docs/
   */
  return `https://images.weserv.nl/?url=${encodeURIComponent(url)}&q=${quality}&output=${format}${
    width ? `&w=${width}` : ''
  }${height ? `&h=${height}` : ''}${crop}&dpr=1.5`;
}

export { resizeImage, RESIZE_ERRORS };
