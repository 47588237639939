import algoliasearch from 'algoliasearch/lite';
import _ from 'lodash';
import isArray from 'lodash/isArray';
import { area, intersect, distance, polygon as tpolygon } from '@turf/turf';

export const getLeagueSearchResultTabs = namespace => {
  const tabs = [];
  if (['CCMORMAL', 'CD85', 'GLOBAL', 'SLA', 'SOSS', 'REPRISE'].includes(namespace))
    tabs.push('organisations');
  if (['CCMORMAL', 'CD85', 'GLOBAL', 'MORMAL', 'NE', 'REPRISE', 'SLA', 'SOSS'].includes(namespace))
    tabs.push('twigs');
  if (['CCMORMAL', 'SLA', 'GLOBAL', 'SOSS', 'REPRISE'].includes(namespace)) tabs.push('articles');
  if (['GLOBAL', 'SLA', 'SOSS'].includes(namespace)) tabs.push('clubs');
  if (['GLOBAL', 'SLA', 'SOSS'].includes(namespace)) tabs.push('courses');
  if (['GLOBAL'].includes(namespace)) tabs.push('transports');

  return tabs;
};

export const LEAGUES_WITH_RESULTS_ALGO = ['SLA', 'CD85', 'CCMORMAL'];

export const searchClient = algoliasearch(
  process.env.RAZZLE_ALGOLIA_WELLO_APP_ID,
  process.env.RAZZLE_ALGOLIA_WELLO_API_KEY,
);

export const formatType = slug => {
  switch (slug) {
    case 'structures':
      return 'organisations';
    case 'activites':
      return 'twigs';
    case 'articles':
      return 'articles';
    case 'communautes':
      return 'clubs';
    case 'formations':
      return 'courses';
    case 'transports':
      return 'transports';
    default:
      return slug;
  }
};

export const sluggifyType = type => {
  switch (type) {
    case 'organisations':
      return 'structures';
    case 'twigs':
      return 'activites';
    case 'articles':
      return 'articles';
    case 'clubs':
      return 'communautes';
    case 'courses':
      return 'formations';
    case 'transports':
      return 'transports';
    default:
      return 'structures';
  }
};

export const wordifyType = slug => {
  switch (slug) {
    case 'structures':
      return 'Structures';
    case 'activites':
      return 'Activités';
    case 'articles':
      return 'Articles';
    case 'communautes':
      return 'Communautés';
    case 'formations':
      return 'Formations';
    case 'transports':
      return 'Transports';
    default:
      return 'Structures';
  }
};

export const INDEX_NAMES = {
  ORGANISATION: 'Organisation',
  ARTICLE: 'Article',
  SERVICE: 'Expertise',
  COURSE: 'Course',
  COMMUNITY: 'Community',
  TWIG: 'Twig',
};

export const LEAGUE_SLUG = {
  SLA: 'soutenir-les-aidants',
  Mormal: 'deplacez-vous',
  CCMormal: 'mormaletvous',
  Reprise: 'reprise',
  CD85: 'aidants-vendee',
  Wello: 'wello',
  CHB: 'centre-helene-borel',
  Global: 'global',
  NE: 'nantes-erdre',
  SOSS: 'bien-vivre-au-luxembourg',
};

export const RESTRICT_SEARCH_ATTRIBUTES = {
  ORGANISATION_SUGGESTIONS_ATTRIBUTES: ['a_name', 'a_short_description', 'a_holding_name'],
  TWIG_SUGGESTIONS_ATTRIBUTES: ['a_excerpt'],
};

export const FILTERING_ATTRIBUTES = {
  ORGANISATION_PATHOLOGY_TYPE_ATTRIBUTE: 'a_pathology.pathology_type',
  ORGANISATION_ESTABLISHMENT_TYPE_ATTRIBUTE: 'a_establishment.slug',
  TWIG_DATE_ATTRIBUTE: 'a_indexable_date',
  TWIG_ACTIVITY_LABEL_ATTRIBUTE: 'a_activity_label',
  TWIG_ACTIVITY_ID_ATTRIBUTE: 'a_activity_id',
  TWIG_EXPERTISE_SLUG_ATTRIBUTE: 'a_expertise.slug',
  LEAGUE_ATTRIBUTE: 'a_league.slug',
  IS_WELLO_ATTRIBUTE: 'a_is_wello',
  // ORGANISATION_SERVICE_FACET_ATTRIBUTE: 'a_service.facet',
  ORGANISATION_SERVICE_LEVEL_ATTRIBUTE: 'a_service.level',
  ORGANISATION_SERVICE_EXPERTISE_SLUG_ATTRIBUTE: 'a_service.expertise_slug',
  // ARTICLE_EXPERTISE_FACET_ATTRIBUTE: 'a_facet',
  POSTABLE_TYPE_ATTRIBUTE: 'a_postable_type',
  POSTABLE_SLUG_ATTRIBUTE: 'a_postable_slug',
  LEAGUE_SLUG_ATTRIBUTE: 'a_league_slug',
  ORGANISATION_TYPE_ATTRIBUTE: 'a_type',
  ORGANISATION_SERVICE_CATEGORY_SLUG: 'a_service.service_category.slug',
  RELATED_WORDS_ATTRIBUTE: 'a_related_words',
  POSTABLE_NAME_ATTRIBUTE: 'a_postable_name',
};

export const ESTABLISHMENT_SLUG = {
  PFR_PA: 'pfr-personnes-agees',
  PFR_PH: 'pfr-personnes-en-situation-de-handicap',
  PFR_PA_PH: 'pfr-personnes-agees-personnes-en-situation-de-handicap',
};

export const SEARCH_BAR_SEARCH_ATTRIBUTES = {
  SLA: {
    hitsPerPage: 4,
    restrictSearchableAttributes: RESTRICT_SEARCH_ATTRIBUTES.ORGANISATION_SUGGESTIONS_ATTRIBUTES,
    filters: `${FILTERING_ATTRIBUTES.LEAGUE_ATTRIBUTE}:${LEAGUE_SLUG.SLA} AND ${FILTERING_ATTRIBUTES.IS_WELLO_ATTRIBUTE}:1`,
  },
  CCMormal: {
    hitsPerPage: 4,
    restrictSearchableAttributes: RESTRICT_SEARCH_ATTRIBUTES.ORGANISATION_SUGGESTIONS_ATTRIBUTES,
    filters: `${FILTERING_ATTRIBUTES.LEAGUE_ATTRIBUTE}:${LEAGUE_SLUG.CCMormal} AND ${FILTERING_ATTRIBUTES.IS_WELLO_ATTRIBUTE}:1`,
  },
  CD85: {
    hitsPerPage: 4,
    restrictSearchableAttributes: RESTRICT_SEARCH_ATTRIBUTES.ORGANISATION_SUGGESTIONS_ATTRIBUTES,
    filters: `${FILTERING_ATTRIBUTES.LEAGUE_ATTRIBUTE}:${LEAGUE_SLUG.CD85} AND ${FILTERING_ATTRIBUTES.IS_WELLO_ATTRIBUTE}:1`,
  },
  CHB: {
    hitsPerPage: 4,
    restrictSearchableAttributes: RESTRICT_SEARCH_ATTRIBUTES.ORGANISATION_SUGGESTIONS_ATTRIBUTES,
    filters: `${FILTERING_ATTRIBUTES.LEAGUE_ATTRIBUTE}:${LEAGUE_SLUG.CHB} AND ${FILTERING_ATTRIBUTES.IS_WELLO_ATTRIBUTE}:1`,
  },
  Global: {
    hitsPerPage: 4,
    restrictSearchableAttributes: RESTRICT_SEARCH_ATTRIBUTES.ORGANISATION_SUGGESTIONS_ATTRIBUTES,
    filters: `${FILTERING_ATTRIBUTES.LEAGUE_ATTRIBUTE}:${LEAGUE_SLUG.Global} AND ${FILTERING_ATTRIBUTES.IS_WELLO_ATTRIBUTE}:1`,
  },
  SOSS: {
    hitsPerPage: 4,
    restrictSearchableAttributes: RESTRICT_SEARCH_ATTRIBUTES.ORGANISATION_SUGGESTIONS_ATTRIBUTES,
    filters: `${FILTERING_ATTRIBUTES.LEAGUE_ATTRIBUTE}:${LEAGUE_SLUG.SOSS} AND ${FILTERING_ATTRIBUTES.IS_WELLO_ATTRIBUTE}:1`,
  },
  Reprise: {
    hitsPerPage: 4,
    restrictSearchableAttributes: RESTRICT_SEARCH_ATTRIBUTES.ORGANISATION_SUGGESTIONS_ATTRIBUTES,
    filters: `${FILTERING_ATTRIBUTES.LEAGUE_ATTRIBUTE}:${LEAGUE_SLUG.Reprise} AND ${FILTERING_ATTRIBUTES.IS_WELLO_ATTRIBUTE}:1`,
  },
};

export const GEOGRAPHIC_LIMITS = {
  Mormal: {
    polygon: [
      [
        [50.3541412, 3.5755949],
        [50.2784595, 3.4850776],
        [50.188646, 3.4631229],
        [50.0819917, 3.4889954],
        [50.0018449, 3.6749252],
        [50.0322661, 3.8550953],
        [50.1167566, 3.9295753],
        [50.2612161, 4.0199049],
        [50.3440671, 4.0045971],
        [50.3667078, 3.8910682],
        [50.3889084, 3.7671003],
        [50.3701123, 3.7215482],
        [50.3460903, 3.7046561],
        [50.3946164, 3.673428],
        [50.3780258, 3.6086468],
        [50.3541412, 3.5755949],
      ],
    ],
    bbox: [3.418559, 49.947365, 4.025604, 50.431319],
    proximity: [3.700433, 50.219909],
  },
  CD85: {
    polygon: [
      [
        [47.0592217, -2.3253733],
        [46.6855748, -2.4352366],
        [46.2422584, -1.2561536],
        [46.3099814, -0.8209598],
        [46.2848486, -0.7212782],
        [46.370196, -0.5180311],
        [46.4358125, -0.5831873],
        [46.6294846, -0.5941737],
        [46.8177675, -0.6889307],
        [47.0103903, -0.9091347],
        [47.0985037, -1.2664801],
        [47.0403576, -1.5595704],
        [46.8950449, -1.5648383],
        [47.048725, -1.9919318],
        [47.0592217, -2.3253733],
      ],
    ],
    bbox: [-2.3987614706025, 46.266566974958, -0.53779518169029, 47.083893903306],
    proximity: [-1.4482662, 46.6613966],
  },
  SOSS: {
    polygon: [
      [
        [49.5460792, 5.8085597],
        [49.5400099, 5.8037303],
        [49.5331017, 5.8147609],
        [49.5339929, 5.8284938],
        [49.5241881, 5.824374],
        [49.5054642, 5.8394802],
        [49.4885175, 5.8793056],
        [49.4920857, 5.9246242],
        [49.4813802, 5.9465969],
        [49.4482279, 5.9605765],
        [49.4410848, 5.9990287],
        [49.4428706, 6.0676932],
        [49.4607258, 6.1308646],
        [49.4982006, 6.1803031],
        [49.4821435, 6.2627006],
        [49.4464422, 6.378057],
        [49.4767899, 6.3945365],
        [49.5659391, 6.3945365],
        [49.5908717, 6.4274955],
        [49.6620376, 6.4604545],
        [49.7100159, 6.529119],
        [49.7561724, 6.5373588],
        [49.821781, 6.5428519],
        [49.8270967, 6.476934],
        [49.824567, 6.4217556],
        [49.8608774, 6.3352382],
        [49.8759249, 6.3009059],
        [49.9025716, 6.248678],
        [49.952963, 6.2308252],
        [49.9882939, 6.1786401],
        [50.0191872, 6.1566675],
        [50.0615229, 6.1346948],
        [50.1271695, 6.1435193],
        [50.1316934, 6.1356926],
        [50.1381306, 6.1162318],
        [50.1497918, 6.1279048],
        [50.1554014, 6.1191501],
        [50.1634296, 6.1230983],
        [50.1735454, 6.0966624],
        [50.171292, 6.0753134],
        [50.1629352, 6.0732535],
        [50.1567766, 6.0619238],
        [50.1678843, 6.0374218],
        [50.1898704, 6.0243756],
        [50.1771197, 5.9577709],
        [50.1353262, 5.9502178],
        [50.1190389, 5.8891064],
        [50.0827008, 5.8769989],
        [50.0646325, 5.8447266],
        [50.0452346, 5.8563995],
        [50.0117105, 5.809021],
        [49.9847861, 5.8289337],
        [49.9609389, 5.7698822],
        [49.9171881, 5.7479095],
        [49.8947191, 5.7289732],
        [49.8854292, 5.7605588],
        [49.8761375, 5.7708585],
        [49.87481, 5.7502592],
        [49.8318669, 5.7317197],
        [49.7869548, 5.7515681],
        [49.7887281, 5.7831538],
        [49.7448211, 5.8188593],
        [49.7190794, 5.8222926],
        [49.721299, 5.8614314],
        [49.7075355, 5.8751643],
        [49.6742206, 5.8573115],
        [49.6622217, 5.8971369],
        [49.6395489, 5.8957636],
        [49.595952, 5.8401453],
        [49.5803722, 5.8593714],
        [49.5460792, 5.8085597],
      ],
    ],
    bbox: [5.7017541, 49.4444681, 6.550405, 50.1944914],
    proximity: [6.131514, 49.815764],
  },
};

export const compareHits = (firstHits, secondHits) => {
  if (firstHits.length !== secondHits.length) {
    return false;
  }

  const firstObjectIds = firstHits.map(hit => hit.objectID);
  const secondObjectIds = secondHits.map(hit => hit.objectID);

  firstObjectIds.sort();
  secondObjectIds.sort();

  if (_.isEqualWith(firstObjectIds, secondObjectIds)) return true;

  return false;
};

export const getRankingScore = (points, center, bbox, bboxPolygon, bboxArea) => {
  if (!bboxPolygon || !bboxArea) return 0;
  let score = 0;

  if (center) {
    const d = distance(
      [center.lat, center.lng],
      [(bbox[3] + bbox[1]) / 2, (bbox[2] + bbox[0]) / 2],
    );
    if (d > 1) score = 1 / d;
    else if (d >= 0 && d <= 1) score = 1;
  }

  if (
    center &&
    (!points || points.length < 3) &&
    center.lat > bbox[1] &&
    center.lat < bbox[3] &&
    center.lng > bbox[0] &&
    center.lng < bbox[2]
  ) {
    score += 1;
  }

  if (points && points.length > 2) {
    const turfOrgaPolygon = [points.map(pt => [pt.lat, pt.lng])];
    const orgaPolygon = tpolygon(turfOrgaPolygon);

    const intersectionPolygon = intersect(orgaPolygon, bboxPolygon);
    if (!intersectionPolygon) return score;

    const intersectionArea = area(intersectionPolygon);
    if (!intersectionArea) return score;

    const orgaArea = area(orgaPolygon);
    const boxRecoverScore = intersectionArea / bboxArea;
    const orgaRecoverScore = orgaArea ? 0.1 * (intersectionArea / orgaArea) : 0;
    const subScore = 100 * (boxRecoverScore + orgaRecoverScore);

    score += subScore + 1;
  }
  return score;
};

export const getBboxData = bbox => {
  if (!bbox || !isArray(bbox) || bbox?.length !== 4) return [null, null];
  const turfPolygon = [
    [
      [bbox[1], bbox[0]],
      [bbox[3], bbox[0]],
      [bbox[3], bbox[2]],
      [bbox[1], bbox[2]],
      [bbox[1], bbox[0]],
    ],
  ];
  const pol = tpolygon(turfPolygon);
  const ar = area(pol);
  return [pol, ar];
};
