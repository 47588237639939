import React from 'react';
import { connect } from 'react-redux';
import loadable from '@loadable/component';
import OrganisationActions from '../Redux/OrganisationRedux';
import OrganisationMembershipActions from '../Redux/OrganisationMembershipRedux';
const FullScreenLoader = loadable(() => import('../Components/Layout/FullScreenLoader'));
const NoMatch404 = loadable(() => import('../Pages/NoMatch404'));
import { isCommunity } from '../Acl/Controls';

type Props = {
  match: Object,
  organisation: Object,
  getOrganisation: Function,
  resetOrganisationMembership: Function,
};

const mapStateToProps = state => ({
  organisation: state.organisation.organisation,
  isFetching: state.organisation.isFetching.getOrganisation,
  errors: state.organisation.errors,
});

const mapDispatchToProps = dispatch => ({
  resetOrganisationMembership: () =>
    dispatch(OrganisationMembershipActions.resetOrganisationMembership()),
  getOrganisation: (holdingSlug, organisationSlug, fetchMode) =>
    dispatch(OrganisationActions.getOrganisationRequest(holdingSlug, organisationSlug, fetchMode)),
  resetOrganisation: () => dispatch(OrganisationActions.resetOrganisation()),
});

const withOrganisation = (WrappedComponent, fetchMode) => {
  function fetchOrganisation(props: Props) {
    const {
      organisation,
      match,
      getOrganisation,
      resetOrganisationMembership,
      errors,
      isFetching,
    } = props;
    const isOrganisationLoaded = organisation;
    const orgaSlug = match.params.organisation_slug;
    const hSlug = match.params.holding_slug;

    const hasOrganisationChanged =
      organisation &&
      ((!isCommunity(organisation) && organisation.slug !== orgaSlug) ||
        (hSlug && hSlug !== organisation.holding_slug));
    let notReady = false;
    if (isOrganisationLoaded && fetchMode) {
      notReady =
        fetchMode === 'full' &&
        (!organisation.services ||
          (organisation.holding_id && !organisation.holding) ||
          !organisation.sampled_twigs);
      notReady = notReady || (fetchMode === 'holding' && !organisation.holding);
      notReady = notReady || (fetchMode === 'services' && !organisation.services);
    }

    if (!isOrganisationLoaded && errors && errors.status === 404) {
      const searchHasChanged =
        errors.holding_slug !== hSlug ||
        (!isCommunity(organisation) && errors.organisation_slug !== orgaSlug);
      if (!searchHasChanged) return <NoMatch404 />;
    }

    if (!isOrganisationLoaded || hasOrganisationChanged || notReady) {
      if (!isFetching) {
        resetOrganisationMembership();
        if (orgaSlug) {
          getOrganisation(hSlug, orgaSlug, fetchMode);
        } else {
          getOrganisation(hSlug, hSlug, fetchMode);
        }
      }
      return <FullScreenLoader />;
    }

    return <WrappedComponent organisation={organisation} {...props} />;
  }

  return connect(mapStateToProps, mapDispatchToProps)(fetchOrganisation);
};

export default withOrganisation;
