import { call, put } from 'redux-saga/effects';
import _ from 'lodash';
import TransmissionsActions from '../Redux/TransmissionsRedux';
// import AuthActions from '../Redux/AuthRedux';
// import history from '../Routing/CurrentHistory';
import { toastResponseError } from '../Services/ApiErrorHelpers';
// import { getOrgaKey } from '../Services/DataHelper';

export function* getOrganisationTransmissions(
  api,
  { holdingSlug, organisationSlug, helperOrgaId },
) {
  const response = yield call(
    api.getOrganisationTransmissions,
    holdingSlug,
    organisationSlug,
    helperOrgaId,
  );
  if (response.ok) {
    yield put(
      TransmissionsActions.getOrganisationTransmissionsSuccess(response.data.transmissions),
    );
  } else {
    yield toastResponseError(response);
    const error = _.get(response, 'data');
    yield put(TransmissionsActions.getOrganisationTransmissionsFailure(error));
  }
}

export function* getLeagueTransmissions(api, { leagueId, helperId }) {
  const response = yield call(api.getLeagueTransmissions, leagueId, helperId);
  if (response.ok) {
    yield put(TransmissionsActions.getLeagueTransmissionsSuccess(response.data.transmissions));
  } else {
    yield toastResponseError(response);
    const error = _.get(response, 'data');
    yield put(TransmissionsActions.getLeagueTransmissionsFailure(error));
  }
}

export function* createOrganisationTransmission(
  api,
  { holdingSlug, organisationSlug, helperOrgaId, transmissionAttributes, attachments },
) {
  const response = yield call(
    api.createOrganisationTransmission,
    holdingSlug,
    organisationSlug,
    helperOrgaId,
    transmissionAttributes,
    attachments,
  );
  if (response.ok) {
    yield put(
      TransmissionsActions.createOrganisationTransmissionSuccess(response.data.transmission),
    );
  } else {
    yield toastResponseError(response);
    const error = _.get(response, 'data');
    yield put(TransmissionsActions.createOrganisationTransmissionFailure(error));
  }
}

export function* createLeagueTransmission(api, { leagueId, helperId, transmissionAttributes }) {
  const response = yield call(
    api.createLeagueTransmission,
    leagueId,
    helperId,
    transmissionAttributes,
  );
  if (response.ok) {
    yield put(TransmissionsActions.createLeagueTransmissionSuccess(response.data.transmission));
  } else {
    yield toastResponseError(response);
    const error = _.get(response, 'data');
    yield put(TransmissionsActions.createLeagueTransmissionFailure(error));
  }
}

export function* updateOrganisationTransmission(
  api,
  {
    holdingSlug,
    organisationSlug,
    helperOrgaId,
    transmissionId,
    transmissionAttributes,
    attachments,
  },
) {
  const response = yield call(
    api.updateOrganisationTransmission,
    holdingSlug,
    organisationSlug,
    helperOrgaId,
    transmissionId,
    transmissionAttributes,
    attachments,
  );
  if (response.ok) {
    yield put(
      TransmissionsActions.updateOrganisationTransmissionSuccess(response.data.transmission),
    );
  } else {
    yield toastResponseError(response);
    const error = _.get(response, 'data');
    yield put(TransmissionsActions.updateOrganisationTransmissionFailure(error));
  }
}

export function* updateLeagueTransmission(
  api,
  { leagueId, helperId, transmissionId, transmissionAttributes },
) {
  const response = yield call(
    api.updateLeagueTransmission,
    leagueId,
    helperId,
    transmissionId,
    transmissionAttributes,
  );
  if (response.ok) {
    yield put(TransmissionsActions.updateLeagueTransmissionSuccess(response.data.transmission));
  } else {
    yield toastResponseError(response);
    const error = _.get(response, 'data');
    yield put(TransmissionsActions.updateLeagueTransmissionFailure(error));
  }
}

export function* destroyOrganisationTransmission(
  api,
  { holdingSlug, organisationSlug, helperOrgaId, transmissionId },
) {
  const response = yield call(
    api.destroyOrganisationTransmission,
    holdingSlug,
    organisationSlug,
    helperOrgaId,
    transmissionId,
  );
  if (response.ok) {
    yield put(TransmissionsActions.destroyOrganisationTransmissionSuccess(transmissionId));
  } else {
    yield toastResponseError(response);
    const error = _.get(response, 'data');
    yield put(TransmissionsActions.destroyOrganisationTransmissionFailure(error));
  }
}

export function* destroyLeagueTransmission(api, { leagueId, helperId, transmissionId }) {
  const response = yield call(api.destroyLeagueTransmission, leagueId, helperId, transmissionId);
  if (response.ok) {
    yield put(TransmissionsActions.destroyLeagueTransmissionSuccess(transmissionId));
  } else {
    yield toastResponseError(response);
    const error = _.get(response, 'data');
    yield put(TransmissionsActions.destroyLeagueTransmissionFailure(error));
  }
}

export function* createOrganisationTransmissionPost(
  api,
  { holdingSlug, organisationSlug, postableId, post, attachments },
) {
  const response = yield call(
    api.createPost,
    holdingSlug,
    organisationSlug,
    postableId,
    post,
    attachments,
  );
  if (response.ok) {
    yield put(
      TransmissionsActions.createOrganisationTransmissionPostSuccess(
        postableId,
        response.data.post,
      ),
    );
  } else {
    yield toastResponseError(response);
    const error = _.get(response, 'data');
    yield put(TransmissionsActions.createOrganisationTransmissionPostFailure(error));
  }
}

export function* createLeagueTransmissionPost(api, { leagueId, postableId, post, attachments }) {
  const response = yield call(api.createPost, null, null, postableId, post, attachments, leagueId);
  if (response.ok) {
    yield put(
      TransmissionsActions.createLeagueTransmissionPostSuccess(postableId, response.data.post),
    );
  } else {
    yield toastResponseError(response);
    const error = _.get(response, 'data');
    yield put(TransmissionsActions.createLeagueTransmissionPostFailure(error));
  }
}

export function* updateOrganisationTransmissionPost(
  api,
  { holdingSlug, organisationSlug, postableId, post, attachments },
) {
  const response = yield call(
    api.updatePost,
    holdingSlug,
    organisationSlug,
    postableId,
    post,
    attachments,
  );
  if (response.ok) {
    yield put(
      TransmissionsActions.updateOrganisationTransmissionPostSuccess(
        postableId,
        post.id,
        response.data.post,
      ),
    );
  } else {
    yield toastResponseError(response);
    const error = _.get(response, 'data');
    yield put(TransmissionsActions.updateOrganisationTransmissionPostFailure(error));
  }
}

export function* updateLeagueTransmissionPost(api, { leagueId, postableId, post, attachments }) {
  const response = yield call(api.updatePost, null, null, postableId, post, attachments, leagueId);
  if (response.ok) {
    yield put(
      TransmissionsActions.updateLeagueTransmissionPostSuccess(
        postableId,
        post.id,
        response.data.post,
      ),
    );
  } else {
    yield toastResponseError(response);
    const error = _.get(response, 'data');
    yield put(TransmissionsActions.updateLeagueTransmissionPostFailure(error));
  }
}

export function* destroyOrganisationTransmissionPost(
  api,
  { holdingSlug, organisationSlug, postableId, postId },
) {
  const response = yield call(api.destroyPost, holdingSlug, organisationSlug, postableId, postId);
  if (response.ok) {
    yield put(TransmissionsActions.destroyOrganisationTransmissionPostSuccess(postableId, postId));
  } else {
    yield toastResponseError(response);
    const error = _.get(response, 'data');
    yield put(TransmissionsActions.destroyOrganisationTransmissionPostFailure(error));
  }
}

export function* destroyLeagueTransmissionPost(api, { leagueId, postableId, postId }) {
  const response = yield call(api.destroyPost, null, null, postableId, postId, leagueId);
  if (response.ok) {
    yield put(TransmissionsActions.destroyLeagueTransmissionPostSuccess(postableId, postId));
  } else {
    yield toastResponseError(response);
    const error = _.get(response, 'data');
    yield put(TransmissionsActions.destroyLeagueTransmissionPostFailure(error));
  }
}
