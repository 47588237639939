/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

import { persistor, store, sagas } from './Redux';
import StartupActions from './Redux/StartupRedux';
import theme from './Themes/muiTheme';

import './I18n';
import { isClientSide } from './Config/ServerConfig';

const onBeforeLift = () => {
  store.dispatch(StartupActions.startup(store));
};

function withRoot(Component) {
  function WithRoot(props) {
    React.useEffect(() => {
      const jssStyles = document.querySelector('#jss-server-side');
      if (jssStyles) {
        jssStyles.parentNode.removeChild(jssStyles);
      }
    }, []);
    if (isClientSide()) {
      return (
        <Provider store={store}>
          <PersistGate onBeforeLift={onBeforeLift} persistor={persistor}>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <Component {...props} />
            </ThemeProvider>
          </PersistGate>
        </Provider>
      );
    }
    sagas.cancel(); // fix memory leak, use server provided store and saga (not duplicated one)
    // console.log(props.store.getState().leagues);
    return (
      <Provider store={props.store}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Component {...props} />
        </ThemeProvider>
      </Provider>
    );
  }

  return WithRoot;
}

export default withRoot;
