import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';
import fp from 'lodash/fp';
import findAndReplace from './ReduxHelpers';
import { isFamily } from '../Acl/Controls';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  getOrganisationRequest: ['holdingSlug', 'organisationSlug', 'fetchMode'],
  getOrganisationSuccess: ['organisation'],
  getOrganisationFailure: ['errors'],

  updateOrganisationRequest: [
    'holdingSlug',
    'organisationSlug',
    'updateAttributes',
    'banner',
    'currentUserId',
  ],
  updateOrganisationSuccess: ['organisation'],
  updateOrganisationFailure: ['errors'],

  getFamilyRequest: ['organisationSlug'],
  getFamilySuccess: ['family'],
  getFamilyFailure: ['errors'],

  getOrganisationByIdentifierRequest: ['organisationIdentifier', 'method'],
  getOrganisationByIdentifierSuccess: ['organisation'],
  getOrganisationByIdentifierFailure: ['errors'],

  getOrganisationCirclesRequest: ['organisation'],
  getOrganisationCirclesSuccess: ['organisationCircles'],
  getOrganisationCirclesFailure: ['errors'],

  getOrganisationServiceTypesRequest: [],
  getOrganisationServiceTypesSuccess: ['serviceTypes'],
  getOrganisationServiceTypesFailure: ['errors'],

  getOrganisationServicesRequest: ['holdingSlug', 'organisationSlug'],
  getOrganisationServicesSuccess: ['services'],
  getOrganisationServicesFailure: ['errors'],

  getOrganisationServiceRequest: ['holdingSlug', 'organisationSlug', 'serviceSlug'],
  getOrganisationServiceSuccess: ['service'],
  getOrganisationServiceFailure: ['errors'],

  addOrganisationServiceRequest: ['holdingSlug', 'organisationSlug', 'service', 'cover'],
  addOrganisationServiceSuccess: ['service'],
  addOrganisationServiceFailure: ['errors'],

  updateOrganisationServiceRequest: [
    'holdingSlug',
    'organisationSlug',
    'service',
    'cover',
    'organisationServicesToUpdate',
  ],
  updateOrganisationServiceSuccess: ['service'],
  updateOrganisationServiceFailure: ['errors'],

  removeOrganisationServiceRequest: ['holdingSlug', 'organisationSlug', 'serviceId'],
  removeOrganisationServiceSuccess: ['serviceId'],
  removeOrganisationServiceFailure: ['errors'],

  updateOrganisationNotifications: ['holdingSlug', 'organisationSlug', 'notifications'],
  updateFamilyHelper: ['helper'],

  updateFamilyHelpedRequest: ['userId', 'updateAttributes', 'avatar'],
  updateFamilyHelpedSuccess: ['helper'],
  updateFamilyHelpedFailure: null,

  addFamilyToAdmin: ['helpedFamily'],

  resetOrganisation: [],
  updateOrganisationHolding: ['holding'],
  addOrganisationService: ['service'],
  updateOrganisationService: ['service'],
  removeOrganisationService: ['serviceId'],
  addOrganisationRubric: ['rubric'],
  updateOrganisationRubric: ['rubric'],
  removeOrganisationRubric: ['rubricId'],
  addOrganisationSubscription: ['subscription'],
  updateOrganisationSubscription: ['subscription'],
  removeOrganisationSubscription: ['subscriptionId'],

  removeOrganisationPrimaryMembership: ['membership'],
});

export const OrganisationTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  isFetching: {
    getOrganisation: null,
    getOrganisations: null,
    updateOrganisation: null,
    getFamily: null,
    getOrganisationByIdentifier: null,
    getOrganisationCircles: null,
    getOrganisationServiceTypes: null,
    getOrganisationServices: null,
    getOrganisationService: null,
    getOrganisationTopHelpers: null,
  },
  family: null,
  organisation: null,
  organisations: null,
  organisationCircles: null,
  organisationServices: null,
  organisationService: null,
  serviceTypes: null,
  activities: null,
  errors: null,
  organisationTopHelpers: null,
});

/* ------------- Reducers ------------- */
// -
// getOrganisation
// -
const getOrganisationRequest = state => ({
  ...state,
  isFetching: { ...state.isFetching, getOrganisation: true },
  errors: INITIAL_STATE.errors,
});

const getOrganisationSuccess = (state, { organisation }) => ({
  ...state,
  isFetching: { ...state.isFetching, getOrganisation: false },
  organisation,
});

const getOrganisationFailure = (state, { errors }) => ({
  ...state,
  isFetching: { ...state.isFetching, getOrganisation: false },
  errors,
});

// -
// updateOrganisation
//
const updateOrganisationRequest = state => ({
  ...state,
  isFetching: { ...state.isFetching, updateOrganisation: true },
});

const updateOrganisationSuccess = (state, { organisation }) => ({
  ...state,
  isFetching: { ...state.isFetching, updateOrganisation: false },
  organisation,
  errors: INITIAL_STATE.errors,
});

const updateOrganisationFailure = (state, { errors }) => ({
  ...state,
  isFetching: { ...state.isFetching, updateOrganisation: false },
  errors,
});

// -
// getFamily
// -
const getFamilyRequest = state => ({
  ...state,
  isFetching: { ...state.isFetching, getFamily: true },
  family: INITIAL_STATE.family,
  errors: INITIAL_STATE.errors,
});

const getFamilySuccess = (state, { family }) => ({
  ...state,
  isFetching: { ...state.isFetching, getFamily: false },
  family,
});

const getFamilyFailure = (state, { errors }) => ({
  ...state,
  isFetching: { ...state.isFetching, getFamily: false },
  errors,
});

// -
// getOrganisationByIdentifier
// -
const getOrganisationByIdentifierRequest = state => ({
  ...state,
  isFetching: { ...state.isFetching, getOrganisationByIdentifier: true },
  errors: INITIAL_STATE.errors,
});

const getOrganisationByIdentifierSuccess = (state, { organisation }) => ({
  ...state,
  isFetching: { ...state.isFetching, getOrganisationByIdentifier: false },
  family: isFamily(organisation) ? organisation : state.family,
  organisation: isFamily(organisation) ? state.organisation : organisation,
});

const getOrganisationByIdentifierFailure = (state, { errors }) => ({
  ...state,
  isFetching: { ...state.isFetching, getOrganisationByIdentifier: false },
  errors,
});

// -
// getOrganisationCircles
// -
const getOrganisationCirclesRequest = state => ({
  ...state,
  isFetching: { ...state.isFetching, getOrganisationCircles: true },
  organisationCircles: INITIAL_STATE.organisationCircles,
  errors: INITIAL_STATE.errors,
});
const getOrganisationCirclesSuccess = (state, { organisationCircles }) => ({
  ...state,
  isFetching: { ...state.isFetching, getOrganisationCircles: false },
  organisationCircles,
});
const getOrganisationCirclesFailure = (state, { errors }) => ({
  isFetching: { ...state.isFetching, getOrganisationCircles: false },
  errors,
});

// getOrganisationServiceTypes
const getOrganisationServiceTypesRequest = state => ({
  ...state,
  isFetching: { ...state.isFetching, getOrganisationServiceTypes: true },
  organisationServices: INITIAL_STATE.organisationServices,
  errors: INITIAL_STATE.errors,
});
const getOrganisationServiceTypesSuccess = (state, { serviceTypes }) => ({
  ...state,
  isFetching: { ...state.isFetching, getOrganisationServiceTypes: false },
  serviceTypes,
});
const getOrganisationServiceTypesFailure = (state, { errors }) => ({
  ...state,
  isFetching: { ...state.isFetching, getOrganisationServiceTypes: false },
  errors,
});

// getOrganisationServices
const getOrganisationServicesRequest = state => ({
  ...state,
  isFetching: { ...state.isFetching, getOrganisationServices: true },
  organisationServices: INITIAL_STATE.organisationServices,
  errors: INITIAL_STATE.errors,
});
const getOrganisationServicesSuccess = (state, { services }) => ({
  ...state,
  isFetching: { ...state.isFetching, getOrganisationServices: false },
  organisationServices: services,
});

const getOrganisationServicesFailure = (state, { errors }) => ({
  ...state,
  isFetching: { ...state.isFetching, getOrganisationServices: false },
  errors,
});

// getOrganisationService
const getOrganisationServiceRequest = state => ({
  ...state,
  isFetching: { ...state.isFetching, getOrganisationService: true },
  organisationService: INITIAL_STATE.organisationService,
  errors: INITIAL_STATE.errors,
});
const getOrganisationServiceSuccess = (state, { service: organisationService }) => ({
  ...state,
  isFetching: { ...state.isFetching, getOrganisationService: false },
  organisationService,
});
const getOrganisationServiceFailure = (state, { errors }) => ({
  ...state,
  isFetching: { ...state.isFetching, getOrganisationService: false },
  errors,
});

// addOrganisationService
const addOrganisationServiceRequest = state => ({
  ...state,
  isFetching: { ...state.isFetching, addOrganisationService: true },
});
const addOrganisationServiceSuccess = (state, { service }) => {
  return {
    ...state,
    isFetching: { ...state.isFetching, addOrganisationService: false },
    organisationServices: [...state.organisationServices, service],
    organisation: { ...state.organisation, services: [...state.organisationServices, service] },
    errors: INITIAL_STATE.errors,
  };
};

const addOrganisationServiceFailure = (state, { errors }) => ({
  ...state,
  isFetching: { ...state.isFetching, addOrganisationService: false },
  errors,
});

// updateOrganisationService
const updateOrganisationServiceRequest = state => ({
  ...state,
  isFetching: { ...state.isFetching, updateOrganisationService: true },
});
const updateOrganisationServiceSuccess = (state, { service: updatedService }) => {
  const { organisationServices, organisation } = state;
  if (updatedService.organisation_id === organisation.id) {
    let { organisationService } = state;
    const updatedList = fp.map(service => {
      if (service.id === updatedService.id) {
        return { ...service, ...updatedService };
      }
      return service;
    })(organisationServices);

    if (organisationService && organisationService.id === updatedService.id) {
      organisationService = updatedService;
    }

    let allServices = state.organisation.all_services?.slice();
    const newAllServicesOS = allServices?.find(os => os.service_id === updatedService.service_id);
    if (newAllServicesOS)
      allServices = findAndReplace(
        os => os.service_id === updatedService.service_id,
        updatedService,
      )(allServices);

    let orgaServices = state.organisation.services.slice();
    const newServicesOS = orgaServices?.find(os => os.service_id === updatedService.service_id);
    if (newServicesOS) {
      orgaServices = findAndReplace(
        os => os.service_id === updatedService.service_id,
        updatedService,
      )(orgaServices);
    } else if (orgaServices?.length > 0) orgaServices.push(updatedService);
    else orgaServices = [updatedService];

    orgaServices = orgaServices.filter(s => s.activated);

    return {
      ...state,
      isFetching: { ...state.isFetching, updateOrganisationService: false },
      organisationServices: updatedList,
      organisationService,
      organisation: { ...state.organisation, all_services: allServices, services: orgaServices },
      errors: INITIAL_STATE.errors,
    };
  }

  let allServices = state.organisation.all_services?.slice();
  const newAllServicesOS = allServices?.find(os => os.service_id === updatedService.service_id);
  if (newAllServicesOS)
    allServices = findAndReplace(os => os.service_id === updatedService.service_id, {
      ...newAllServicesOS,
      service: updatedService.service,
    })(allServices);

  return {
    ...state,
    isFetching: { ...state.isFetching, updateOrganisationService: false },
    organisation: { ...state.organisation, all_services: allServices },
    errors: INITIAL_STATE.errors,
  };
};
const updateOrganisationServiceFailure = (state, { errors }) => ({
  ...state,
  isFetching: { ...state.isFetching, updateOrganisationService: false },
  errors,
});

// removeOrganisationService
const removeOrganisationServiceRequest = state => ({
  ...state,
  isFetching: { ...state.isFetching, removeOrganisationService: true },
});

const removeOrganisationServiceSuccess = (state, { serviceId }) => ({
  ...state,
  isFetching: { ...state.isFetching, removeOrganisationService: false },
  organisationServices: state.organisationServices.filter(service => service.id !== serviceId),
  errors: INITIAL_STATE.errors,
});

const removeOrganisationServiceFailure = (state, { errors }) => ({
  ...state,
  isFetching: { ...state.isFetching, removeOrganisationService: false },
  errors,
});

const updateOrganisationNotifications = (
  state,
  { holdingSlug, organisationSlug, notifications },
) => {
  if (
    state.organisation?.holding_slug !== holdingSlug ||
    state.organisation?.slug !== organisationSlug
  )
    return state;

  return {
    ...state,
    organisation: { ...state.organisation, notifications },
  };
};

const updateFamilyHelper = (state, { helper }) => {
  if (state.family?.admin?.id !== helper?.id) return state;
  const helperUpdated = { ...state.family?.admin, ...helper };
  return {
    ...state,
    family: { ...state.family, admin: helperUpdated },
  };
};

const updateFamilyHelpedRequest = state => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    updateFamilyHelped: true,
  },
});

const updateFamilyHelpedSuccess = (state, { helper }) => {
  const helped = state.family?.admin?.families?.find(family => family?.admin?.id === helper?.id);
  const updatedHelped = { ...helped, admin: { ...helped.admin, ...helper } };

  const updatedFamilies = findAndReplace(
    family => family?.admin?.id === helper?.id,
    updatedHelped,
  )(state.family?.admin?.families);

  return {
    ...state,
    isFetching: { ...state.isFetching, updateFamilyHelped: false },
    family: { ...state.family, admin: { ...state.family?.admin, families: updatedFamilies } },
  };
};

const updateFamilyHelpedFailure = (state, { errors }) => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    updateFamilyHelped: false,
  },
  errors: {
    ...state.errors,
    updateFamilyHelped: errors,
  },
});

const addFamilyToAdmin = (state, { helpedFamily }) => {
  let userFamilies = state.family?.admin?.families?.slice();
  if (!userFamilies) userFamilies = [helpedFamily];
  else userFamilies.push(helpedFamily);

  const newAdmin = { ...state.family?.admin, families: userFamilies };

  return {
    ...state,
    family: { ...state.family, admin: newAdmin },
  };
};

const updateOrganisationHolding = (state, { holding }) => ({
  ...state,
  isFetching: { ...state.isFetching, updateOrganisation: false },
  organisation: {
    ...state.organisation,
    holding,
    holding_rubrics: holding?.rubrics || state.organisation?.holding_rubrics,
  },
  errors: INITIAL_STATE.errors,
});

const addOrganisationService = (state, { service }) => {
  let services = state.organisation.services;
  const newOS = {
    id: null,
    service_id: service.id,
    organisation_id: state.organisation.id,
    service,
  };
  if (!services) services = [newOS];
  else services.push(newOS);

  return {
    ...state,
    organisation: { ...state.organisation, services: services },
  };
};

const updateOrganisationService = (state, { service }) => {
  let orgaServices = state.organisation.services?.slice();
  const newServicesOS = orgaServices?.find(os => `${os.service_id}` === `${service.id}`);
  if (newServicesOS)
    orgaServices = findAndReplace(os => `${os.service_id}` === `${service.id}`, {
      ...newServicesOS,
      service,
    })(orgaServices);

  return {
    ...state,
    organisation: { ...state.organisation, services: orgaServices },
  };
};

const removeOrganisationService = (state, { serviceId }) => {
  const services = state.organisation.services?.filter(os => `${os.service_id}` !== `${serviceId}`);

  return {
    ...state,
    organisation: { ...state.organisation, services },
  };
};

const addOrganisationRubric = (state, { rubric }) => {
  let rubrics = state.organisation.holding_rubrics;
  if (!rubrics) rubrics = [rubric];
  else rubrics.push(rubric);

  return {
    ...state,
    organisation: { ...state.organisation, holding_rubrics: rubrics },
  };
};

const updateOrganisationRubric = (state, { rubric }) => {
  let rubrics = state.organisation.holding_rubrics?.slice();
  const oldRubric = rubrics?.find(rub => `${rub.id}` === `${rubric.id}`);
  if (oldRubric) rubrics = findAndReplace(rub => `${rub.id}` === `${rubric.id}`, rubric)(rubrics);

  return {
    ...state,
    organisation: { ...state.organisation, holding_rubrics: rubrics },
  };
};

const removeOrganisationRubric = (state, { rubricId }) => {
  const rubrics = state.organisation.holding_rubrics?.filter(rub => `${rub.id}` !== `${rubricId}`);

  return {
    ...state,
    organisation: { ...state.organisation, holding_rubrics: rubrics },
  };
};

const addOrganisationSubscription = (state, { subscription }) => {
  if (!subscription) return state;

  const isOrgaSub = state.organisation && subscription.organisation_id === state.organisation.id;
  const isFamilySub = state.family && subscription.subscrible_id === state.family.id;

  let subscriptions = isOrgaSub
    ? state.organisation.subscriptions?.slice()
    : state.family.subscriptions?.slice();
  if (!subscriptions) subscriptions = [subscription];
  else subscriptions.push(subscription);

  return {
    ...state,
    organisation: isOrgaSub ? { ...state.organisation, subscriptions } : state.organisation,
    family: isFamilySub ? { ...state.family, subscriptions } : state.family,
  };
};

const updateOrganisationSubscription = (state, { subscription }) => {
  if (!subscription) return state;

  const isOrgaSub = state.organisation && subscription.organisation_id === state.organisation.id;
  const isFamilySub = state.family && subscription.subscrible_id === state.family.id;

  if (!isOrgaSub && !isFamilySub) return state;

  let subscriptions = isOrgaSub
    ? state.organisation.subscriptions?.slice()
    : state.family.subscriptions?.slice();
  const oldSub = subscriptions?.find(sub => `${sub.id}` === `${subscription.id}`);
  if (oldSub)
    subscriptions = findAndReplace(
      sub => `${sub.id}` === `${subscription.id}`,
      subscription,
    )(subscriptions);

  return {
    ...state,
    organisation: isOrgaSub ? { ...state.organisation, subscriptions } : state.organisation,
    family: isFamilySub ? { ...state.family, subscriptions } : state.family,
  };
};

const removeOrganisationSubscription = (state, { subscriptionId }) => {
  const orgaSubscriptions = state.organisation?.subscriptions?.filter(
    sub => `${sub.id}` !== `${subscriptionId}`,
  );
  const familySubscriptions = state.family?.subscriptions?.filter(
    sub => `${sub.id}` !== `${subscriptionId}`,
  );

  return {
    ...state,
    organisation: orgaSubscriptions
      ? { ...state.organisation, subscriptions: orgaSubscriptions }
      : state.organisation,
    family: familySubscriptions
      ? { ...state.family, subscriptions: familySubscriptions }
      : state.family,
  };
};

const removeOrganisationPrimaryMembership = (state, { membership }) => {
  if (state.organisation?.id !== membership.organisation_id) return state;

  const orgaSubscriptions = state.organisation?.subscriptions?.filter(
    sub => sub.subscrible_id !== membership.helper.family_id,
  );

  return {
    ...state,
    organisation: {
      ...state.organisation,
      subscriptions: orgaSubscriptions,
    },
  };
};

const resetOrganisation = () => INITIAL_STATE;
/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_ORGANISATION_REQUEST]: getOrganisationRequest,
  [Types.GET_ORGANISATION_SUCCESS]: getOrganisationSuccess,
  [Types.GET_ORGANISATION_FAILURE]: getOrganisationFailure,

  [Types.UPDATE_ORGANISATION_REQUEST]: updateOrganisationRequest,
  [Types.UPDATE_ORGANISATION_SUCCESS]: updateOrganisationSuccess,
  [Types.UPDATE_ORGANISATION_FAILURE]: updateOrganisationFailure,

  [Types.GET_FAMILY_REQUEST]: getFamilyRequest,
  [Types.GET_FAMILY_SUCCESS]: getFamilySuccess,
  [Types.GET_FAMILY_FAILURE]: getFamilyFailure,

  [Types.GET_ORGANISATION_BY_IDENTIFIER_REQUEST]: getOrganisationByIdentifierRequest,
  [Types.GET_ORGANISATION_BY_IDENTIFIER_SUCCESS]: getOrganisationByIdentifierSuccess,
  [Types.GET_ORGANISATION_BY_IDENTIFIER_FAILURE]: getOrganisationByIdentifierFailure,

  [Types.GET_ORGANISATION_CIRCLES_REQUEST]: getOrganisationCirclesRequest,
  [Types.GET_ORGANISATION_CIRCLES_SUCCESS]: getOrganisationCirclesSuccess,
  [Types.GET_ORGANISATION_CIRCLES_FAILURE]: getOrganisationCirclesFailure,

  [Types.GET_ORGANISATION_SERVICE_TYPES_REQUEST]: getOrganisationServiceTypesRequest,
  [Types.GET_ORGANISATION_SERVICE_TYPES_SUCCESS]: getOrganisationServiceTypesSuccess,
  [Types.GET_ORGANISATION_SERVICE_TYPES_FAILURE]: getOrganisationServiceTypesFailure,

  [Types.GET_ORGANISATION_SERVICES_REQUEST]: getOrganisationServicesRequest,
  [Types.GET_ORGANISATION_SERVICES_SUCCESS]: getOrganisationServicesSuccess,
  [Types.GET_ORGANISATION_SERVICES_FAILURE]: getOrganisationServicesFailure,

  [Types.GET_ORGANISATION_SERVICE_REQUEST]: getOrganisationServiceRequest,
  [Types.GET_ORGANISATION_SERVICE_SUCCESS]: getOrganisationServiceSuccess,
  [Types.GET_ORGANISATION_SERVICE_FAILURE]: getOrganisationServiceFailure,

  [Types.ADD_ORGANISATION_SERVICE_REQUEST]: addOrganisationServiceRequest,
  [Types.ADD_ORGANISATION_SERVICE_SUCCESS]: addOrganisationServiceSuccess,
  [Types.ADD_ORGANISATION_SERVICE_FAILURE]: addOrganisationServiceFailure,

  [Types.UPDATE_ORGANISATION_SERVICE_REQUEST]: updateOrganisationServiceRequest,
  [Types.UPDATE_ORGANISATION_SERVICE_SUCCESS]: updateOrganisationServiceSuccess,
  [Types.UPDATE_ORGANISATION_SERVICE_FAILURE]: updateOrganisationServiceFailure,

  [Types.REMOVE_ORGANISATION_SERVICE_REQUEST]: removeOrganisationServiceRequest,
  [Types.REMOVE_ORGANISATION_SERVICE_SUCCESS]: removeOrganisationServiceSuccess,
  [Types.REMOVE_ORGANISATION_SERVICE_FAILURE]: removeOrganisationServiceFailure,

  [Types.UPDATE_ORGANISATION_NOTIFICATIONS]: updateOrganisationNotifications,
  [Types.UPDATE_FAMILY_HELPER]: updateFamilyHelper,

  [Types.UPDATE_FAMILY_HELPED_REQUEST]: updateFamilyHelpedRequest,
  [Types.UPDATE_FAMILY_HELPED_SUCCESS]: updateFamilyHelpedSuccess,
  [Types.UPDATE_FAMILY_HELPED_FAILURE]: updateFamilyHelpedFailure,

  [Types.ADD_FAMILY_TO_ADMIN]: addFamilyToAdmin,
  [Types.UPDATE_ORGANISATION_HOLDING]: updateOrganisationHolding,
  [Types.RESET_ORGANISATION]: resetOrganisation,
  [Types.ADD_ORGANISATION_SERVICE]: addOrganisationService,
  [Types.UPDATE_ORGANISATION_SERVICE]: updateOrganisationService,
  [Types.REMOVE_ORGANISATION_SERVICE]: removeOrganisationService,
  [Types.ADD_ORGANISATION_RUBRIC]: addOrganisationRubric,
  [Types.UPDATE_ORGANISATION_RUBRIC]: updateOrganisationRubric,
  [Types.REMOVE_ORGANISATION_RUBRIC]: removeOrganisationRubric,
  [Types.ADD_ORGANISATION_SUBSCRIPTION]: addOrganisationSubscription,
  [Types.UPDATE_ORGANISATION_SUBSCRIPTION]: updateOrganisationSubscription,
  [Types.REMOVE_ORGANISATION_SUBSCRIPTION]: removeOrganisationSubscription,
  [Types.REMOVE_ORGANISATION_PRIMARY_MEMBERSHIP]: removeOrganisationPrimaryMembership,
});
