import { call, put } from 'redux-saga/effects';

import CitiesActions from '../Redux/CitiesRedux';

export default function* getCities(api, { term, allowedZipCodes }) {
  const response = yield call(api.getCities, term, allowedZipCodes);

  if (response.ok) {
    yield put(CitiesActions.getCitiesSuccess(response.data.cities));
  } else {
    yield put(CitiesActions.getCitiesFailure());
  }
}
