const Roles = {
  ADMIN: 'admin',
  PRIMARY: 'primary',
  VOLUNTEER: 'volunteer',
  PRO: 'pro',
  EXTERIOR: 'exterior',

  DOCTOR: 'doctor',
  NURSE: 'nurse',
  PSYCHOLOGIST: 'psychologist',
  KINE: 'kine',
  ERGO: 'ergo',
  CAREGIVER: 'caregiver',
  PERSONAL_CARER: 'personal_carer',
  COORDINATOR: 'coordinator',
  EXTERNAL: 'external',

  NEIGHBOR: 'neighbor',
  FAMILY: 'family',
  FRIEND: 'friend',
  MAIN_HELPER: 'main_helper',
  PRIMARY_HELPED: 'primary_helped',

  VISITOR: 'visitor',
  FAMILY_VISITOR: 'family-orga-visitor',
  FAMILY_PRO: 'family-orga-pro',
  FAMILY_COORDINATOR: 'family-orga-coordinator',

  MODERATOR: 'moderator',
  MEMBER: 'member',
};

export const PROROLES = [
  Roles.ADMIN,
  Roles.PRO,
  Roles.DOCTOR,
  Roles.NURSE,
  Roles.PSYCHOLOGIST,
  Roles.ERGO,
  Roles.KINE,
  Roles.CAREGIVER,
  Roles.PERSONAL_CARER,
  Roles.COORDINATOR,
  Roles.VOLUNTEER,
  Roles.EXTERNAL,
  Roles.MODERATOR,
  Roles.MEMBER,
];

export const FAMILYPROROLES = [Roles.FAMILY_PRO, Roles.FAMILY_COORDINATOR];

export const getFormattedRole = (role, t, long = null) => {
  const short = t(`APP.ROLE.VALIDATED.${role?.toUpperCase()}`, '');
  return long ? t(`APP.ROLE.VALIDATED.LONG.${role?.toUpperCase()}`, short) : short;
};

export const getFormattedPendingRole = (role, t) => {
  return t(`APP.ROLE.PENDING.${role?.toUpperCase()}`, '');
};

export const getExistingRoles = (organisation, t, exceptions = []) => {
  return (
    organisation?.existing_roles
      ?.filter(role => !exceptions.includes(role))
      .map(role => ({ value: role, label: getFormattedRole(role, t) })) || []
  );
};

export const getHoldingRoles = t => {
  return [Roles.ADMIN, Roles.PRO, Roles.VOLUNTEER, Roles.PRIMARY].map(role => ({
    value: role,
    label: getFormattedRole(role, t),
  }));
};

export const getRolesForOrganisation = (organisation, t, exceptions = []) => {
  switch (organisation?.type) {
    case 'Organisations::Family':
      return [Roles.NEIGHBOR, Roles.FAMILY, Roles.FRIEND, Roles.MAIN_HELPER, Roles.PRIMARY_HELPED]
        .filter(role => !exceptions.includes(role))
        .map(role => ({
          value: role,
          label: getFormattedRole(role, t),
        }));

    case 'Organisations::Association':
      return [Roles.ADMIN, Roles.PRO, Roles.EXTERNAL, Roles.PRIMARY, Roles.VOLUNTEER]
        .filter(role => !exceptions.includes(role))
        .map(role => ({
          value: role,
          label: getFormattedRole(role, t),
        }));
    case 'Organisations::Pfr':
      return [
        Roles.ADMIN,
        Roles.PRO,
        Roles.COORDINATOR,
        Roles.EXTERNAL,
        Roles.PRIMARY,
        Roles.VOLUNTEER,
        Roles.PSYCHOLOGIST,
      ]
        .filter(role => !exceptions.includes(role))
        .map(role => ({
          value: role,
          label: getFormattedRole(role, t),
        }));
    case 'Organisations::Establishment':
      return [
        Roles.ADMIN,
        Roles.PRO,
        Roles.COORDINATOR,
        Roles.EXTERNAL,
        Roles.PRIMARY,
        Roles.VOLUNTEER,
        Roles.EXTERIOR,
      ]
        .filter(role => !exceptions.includes(role))
        .map(role => ({
          value: role,
          label: getFormattedRole(role, t, true),
        }));
    case 'Organisations::Community':
      return [Roles.ADMIN, Roles.MODERATOR, Roles.MEMBER]
        .filter(role => !exceptions.includes(role))
        .map(role => ({
          value: role,
          label: getFormattedRole(role, t),
        }));

    default:
      return [
        Roles.ADMIN,
        Roles.PRO,
        Roles.DOCTOR,
        Roles.NURSE,
        Roles.PSYCHOLOGIST,
        Roles.ERGO,
        Roles.KINE,
        Roles.CAREGIVER,
        Roles.PERSONAL_CARER,
        Roles.COORDINATOR,
        Roles.EXTERNAL,
        Roles.VOLUNTEER,
        Roles.PRIMARY,
        Roles.EXTERIOR,
      ]
        .filter(role => !exceptions.includes(role))
        .map(role => ({
          value: role,
          label: getFormattedRole(role, t),
        }));
  }
};

export const getDefaultHoldingApplicationRoles = holding => {
  switch (holding?.type) {
    case 'Holdings::Establishment':
      return [Roles.PRIMARY, Roles.EXTERIOR];
    case 'Holdings::Pfr':
      return [Roles.PRIMARY, Roles.VOLUNTEER];
    default:
      return [Roles.PRIMARY];
  }
};

export const getPossibleHoldingApplicationRoles = holding => {
  switch (holding?.type) {
    case 'Holdings::Establishment':
      return [Roles.PRIMARY, Roles.VOLUNTEER, Roles.EXTERIOR];
    default:
      return [Roles.PRIMARY, Roles.VOLUNTEER];
  }
};

export default Roles;
