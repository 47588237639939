import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import loadable from '@loadable/component';
import ArticlesActions from '../Redux/ArticlesRedux';
const FullScreenLoader = loadable(() => import('../Components/Layout/FullScreenLoader'));

type Props = {
  match: Object,
  article: Object,
  getArticle: Function,
  holding: Object,
};

const mapStateToProps = state => ({
  article: state.articles.holdingArticle,
  holding: state.holding.holding,
});

const mapDispatchToProps = dispatch => ({
  getArticle: (holdingSlug, articleSlug) =>
    dispatch(ArticlesActions.getHoldingArticleRequest(holdingSlug, articleSlug)),
});

const withHoldingArticle = WrappedComponent => {
  function fetchArticle(props: Props) {
    const { article, match, getArticle, holding } = props;
    const { article_slug: articleSlug, holding_slug: holdingSlug } = match.params;
    const articleIsMissing = !article;
    const articleHasChanged = article?.slug !== articleSlug;
    if (_.some([articleIsMissing, articleHasChanged])) {
      getArticle(holding ? holding.slug : holdingSlug, articleSlug);
      return <FullScreenLoader />;
    }
    return <WrappedComponent {...props} article={article} />;
  }

  return connect(mapStateToProps, mapDispatchToProps)(fetchArticle);
};

export default withHoldingArticle;
