// we put this guy first
// import '../Config/ReactotronConfig'; // TODO enable only on client side

import { persistCombineReducers } from 'redux-persist';
import { combineReducers } from 'redux';

import ReduxPersist from '../Config/ReduxPersist';
import configureStore from './CreateStore';
import rootSaga from '../Sagas';
import { isClientSide } from '../Config/ServerConfig';

const reducers = {
  /* eslint-disable global-require */
  auth: require('./AuthRedux').reducer,
  cities: require('./CitiesRedux').reducer,
  leads: require('./LeadsRedux').reducer,
  holding: require('./HoldingRedux').reducer,
  organisationsLeague: require('./OrganisationsLeagueRedux').reducer,
  organisation: require('./OrganisationRedux').reducer,
  organisationMembership: require('./OrganisationMembershipRedux').reducer,
  toaster: require('./ToasterRedux').reducer,
  leagues: require('./LeaguesRedux').reducer,
  users: require('./UsersRedux').reducer,
  email: require('./EmailRedux').reducer,
  tasks: require('./TasksRedux').reducer,
  deals: require('./DealsRedux').reducer,
  courses: require('./CoursesRedux').reducer,
  clubs: require('./ClubsRedux').reducer,
  articles: require('./ArticlesRedux').reducer,
  careNotebooks: require('./CareNotebooksRedux').reducer,
  requestResponseDecorator: require('./RequestResponseDecoratorRedux').reducer,
  webNotificationSubscriptions: require('./WebNotificationSubscriptionsRedux').reducer,
  transmissions: require('./TransmissionsRedux').reducer,
  algolia: require('./AlgoliaRedux').reducer,
};

const storeConfiguration = () => {
  /* ------------- Assemble The Reducers ------------- */
  const rootReducer = isClientSide()
    ? persistCombineReducers(ReduxPersist.storeConfig, reducers)
    : combineReducers(reducers);

  return configureStore(rootReducer, rootSaga);
};

// create our store and persistor
export const { persistor, store, sagas } = storeConfiguration();
