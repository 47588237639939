import _ from 'lodash';

import Roles, { FAMILYPROROLES } from './Roles';
import { CheckRoleRule } from './Rules';

/*
Controls Roles VS Accesses
*/
export const isProd = process.env.RAZZLE_ENVIRONMENT === 'prod';

export const hasRole = (allowedRoles, role) => allowedRoles.includes(role);

export const isAssociation = organisation => organisation?.type === 'Organisations::Association';
export const isPfr = organisation => organisation?.type === 'Organisations::Pfr';
export const isEstablishment = organisation =>
  organisation?.type === 'Organisations::Establishment';
export const isCommunity = organisation => organisation?.type === 'Organisations::Community';
export const isFamily = organisation => organisation?.type === 'Organisations::Family';
export const isMyFamily = organisation =>
  organisation?.type === 'Organisations::Family' && organisation?.role === 'admin';
export const isSap = organisation => organisation?.type === 'Organisations::Sap';
export const isHealthcare = organisation => organisation?.type === 'Organisations::Healthcare';
export const isReferenced = organisation => organisation?.type === 'Organisations::Referenced';
export const isOther = organisation => organisation?.type === 'Organisations::Other';
export const isInFamilyCircles = (organisation, family) =>
  !!family?.organisations_circle?.find(orga => orga.id === organisation.id);
export const hasCarenotebooks = organisation =>
  organisation?.type === 'Organisations::Family' && organisation?.admin?.has_carenotebook;
export const hasTransport = organisation => {
  let found = false;
  if (organisation.services) {
    organisation.services.forEach(os => {
      if (os.service && os.service.service_category.slug === 'transport') found = true;
    });
  }
  return found;
};
export const isServiceTransport = service => {
  return service && service.service_category && service.service_category.slug === 'transport';
};
export const isServiceRepit = service => {
  return service && service.service_category && service.service_category.slug === 'repit';
};
export const isServiceSap = service => {
  return service && service.service_category && service.service_category.slug === 'aide-a-domicile';
};
export const isServiceOther = service => {
  return service && service.service_category && service.service_category.slug === 'other';
};

export const isVisitor = role => hasRole([Roles.VISITOR], role);
export const isAdmin = role => hasRole([Roles.ADMIN], role);
export const isCoordinator = role => hasRole([Roles.COORDINATOR], role);
export const isMainHelper = role => hasRole([Roles.MAIN_HELPER], role);
export const isFamilyPro = role => hasRole(FAMILYPROROLES, role);
export const isPrimary = role => hasRole([Roles.PRIMARY], role);
export const isVolunteer = role => hasRole([Roles.VOLUNTEER], role);
export const isExterior = role => hasRole([Roles.EXTERIOR], role);
export const isPro = role =>
  hasRole(
    [
      Roles.PRO,
      Roles.NURSE,
      Roles.PSYCHOLOGIST,
      Roles.DOCTOR,
      Roles.KINE,
      Roles.COORDINATOR,
      Roles.CAREGIVER,
      Roles.PERSONAL_CARER,
      Roles.ERGO,
      Roles.EXTERNAL,
    ],
    role,
  );
export const isCommunityMember = role =>
  hasRole([Roles.ADMIN, Roles.MODERATOR, Roles.MEMBER], role);
export const isSuperAdmin = user => user && user.email === 'infra@wello.fr';
export const isLeagueAdmin = (leagueSlug, user) =>
  user && !!_.find(user.leagues, league => league.slug === leagueSlug);
export const isHoldingAdmin = (holdingId, user) =>
  user && !!_.find(user.holdings, holding => holding.id === holdingId);

export const canEditHolding = (organisation, currentUser) =>
  currentUser &&
  (isHoldingAdmin(organisation?.holding_id, currentUser) ||
    isLeagueAdmin(organisation?.holding?.league_slug, currentUser));

export const hasHolding = organisation => organisation.holding_id !== null;

export const isFamilyAdminLeagueAdmin = (user, family) => {
  if (
    !user ||
    !user.leagues ||
    !family ||
    !family.admin ||
    !family.admin.in_leagues_ids ||
    family.admin.in_leagues_ids.length === 0
  )
    return false;

  const leaguesIds = user.leagues.map(l => l.id);
  return family.admin.in_leagues_ids.some(id => leaguesIds.includes(id));
};

export const getHelpedsFamilies = currentUser => {
  return currentUser?.families?.filter(family => !isMyFamily(family) && isMainHelper(family.role));
};

export const getRoleInFamily = (user, familySlug) => {
  if (!user || !user.families) return Roles.VISITOR;

  const orga = user.families.find(organisation => organisation.slug === familySlug);

  if (!orga) return Roles.VISITOR;

  return orga.role;
};

export const getRoleInOrga = (user, holdingSlug, orgaSlug) => {
  if (!user || !user.organisations) return Roles.VISITOR;

  const orga = user.organisations.find(
    organisation => organisation.slug === orgaSlug && organisation.holding_slug === holdingSlug,
  );

  if (!orga) return Roles.VISITOR;

  return orga.role;
};

export const getPendingRoleInOrga = (user, holdingSlug, orgaSlug) => {
  if (!user || !user.pending_organisations) return Roles.VISITOR;

  const orga = user.pending_organisations.find(
    organisation => organisation.slug === orgaSlug && organisation.holding_slug === holdingSlug,
  );

  if (!orga) return Roles.VISITOR;

  return orga.role;
};

export const getRoleInCommunity = (user, holdingSlug) => {
  if (!user || !user.communities) return Roles.VISITOR;
  const orga = user.communities.find(community => community.holding_slug === holdingSlug);
  if (!orga) return Roles.VISITOR;
  return orga.role;
};

export const getPendingRoleInCommunity = (user, holdingSlug) => {
  if (!user || !user.pending_communities) return Roles.VISITOR;
  const orga = user.pending_communities.find(community => community.holding_slug === holdingSlug);
  if (!orga) return Roles.VISITOR;
  return orga.role;
};

export const getRole = (user, holdingSlug, orgaSlug, familySlug) => {
  if (!user) return Roles.VISITOR;

  if (holdingSlug && !orgaSlug && !familySlug) {
    return getRoleInCommunity(user, holdingSlug);
  }

  if (!holdingSlug) {
    if (orgaSlug) return getRoleInFamily(user, orgaSlug);
    if (familySlug) return getRoleInFamily(user, familySlug);
    return Roles.VISITOR;
  }

  if (orgaSlug && familySlug) {
    const role = getRoleInOrga(user, holdingSlug, orgaSlug);
    if (isAdmin(role) || isCoordinator(role)) return Roles.FAMILY_COORDINATOR;
    if (isPro(role)) return Roles.FAMILY_PRO;
    return Roles.FAMILY_VISITOR;
  }

  if (orgaSlug) {
    return getRoleInOrga(user, holdingSlug, orgaSlug);
  }

  return Roles.VISITOR;
};

export const getPendingRole = (user, holdingSlug, orgaSlug) => {
  if (!user) return Roles.VISITOR;

  if (holdingSlug && !orgaSlug) {
    return getPendingRoleInCommunity(user, holdingSlug);
  }

  if (orgaSlug) {
    return getPendingRoleInOrga(user, holdingSlug, orgaSlug);
  }

  return Roles.VISITOR;
};

export const getRestrictionRole = (role, carenotebookType, organisation) => {
  if (
    carenotebookType &&
    !CheckRoleRule(role, `carenotebook-${carenotebookType}:read`, { organisation })
  )
    return null;

  switch (role) {
    case Roles.ADMIN: {
      return ['Aidé', '@family_admin'];
    }
    case Roles.NEIGHBOR:
    case Roles.FRIEND: {
      return ['Ami & Voisin', '@close_circle'];
    }
    case Roles.MAIN_HELPER: {
      return ['Aidant référent', '@main_helper'];
    }
    case Roles.FAMILY: {
      return ['Famille', '@family'];
    }
    case Roles.PRO: {
      return ['Pro', '@pro'];
    }
    case Roles.VOLUNTEER: {
      return ['Bénévole', '@volunteer'];
    }
    case Roles.NURSE: {
      return ['Infirmièr(e)', '@nurse'];
    }
    case Roles.DOCTOR: {
      return ['Médecin', '@doctor'];
    }
    case Roles.PSYCHOLOGIST: {
      return ['Psychologue', '@psychologist'];
    }
    case Roles.ERGO: {
      return ['Ergothérapeute', '@ergo'];
    }
    case Roles.CAREGIVER: {
      return ['Aide soignante', '@caregiver'];
    }
    case Roles.PERSONAL_CARER: {
      return ['Auxiliaire de vie', '@personal_carer'];
    }
    case Roles.COORDINATOR: {
      return ['Coordinateur', '@coordinator'];
    }
    case Roles.KINE: {
      return ['Kinésithérapeute', '@kine'];
    }
    case Roles.EXTERNAL: {
      return ['Intervenant', '@external'];
    }
    default:
      return null;
  }
};
