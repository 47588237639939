import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  checkEmailTokenRequest: ['token'],
  checkEmailTokenSuccess: ['emailToken'],
  checkEmailTokenFailure: ['error'],

  sendEmailRequest: ['email'],
  sendEmailFailure: ['error'],

  resetEmailToken: {},
});

export const EmailTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  isFetching: false,
  emailToken: {},
  error: null,
});

/* ------------- Reducers ------------- */
const checkEmailTokenRequest = state => ({
  ...state,
  isFetching: true,
  emailToken: INITIAL_STATE.emailToken,
  error: INITIAL_STATE.error,
});

const checkEmailTokenSuccess = (state, { emailToken }) => ({
  ...state,
  isFetching: INITIAL_STATE.isFetching,
  emailToken,
  error: INITIAL_STATE.error,
});

const checkEmailTokenFailure = (state, { error }) => ({
  ...state,
  isFetching: INITIAL_STATE.isFetching,
  emailToken: INITIAL_STATE.emailToken,
  error,
});

const sendEmailRequest = state => ({
  ...state,
  error: INITIAL_STATE.error,
});

const sendEmailFailure = (state, { error }) => ({
  ...state,
  isFetching: INITIAL_STATE.isFetching,
  error,
});

const resetEmailToken = () => INITIAL_STATE;

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.CHECK_EMAIL_TOKEN_REQUEST]: checkEmailTokenRequest,
  [Types.CHECK_EMAIL_TOKEN_SUCCESS]: checkEmailTokenSuccess,
  [Types.CHECK_EMAIL_TOKEN_FAILURE]: checkEmailTokenFailure,

  [Types.SEND_EMAIL_REQUEST]: sendEmailRequest,
  [Types.SEND_EMAIL_FAILURE]: sendEmailFailure,

  [Types.RESET_EMAIL_TOKEN]: resetEmailToken,
});
