import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';
import findAndReplace from './ReduxHelpers';
import { hasUnread, SubscribleTypes, userHasUnread } from '../Services/SubscriptionHelper';
import hasUnreadNotification from '../Services/NotificationHelper';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  setAuthTokenRequest: ['authToken'],
  setAuthTokenSuccess: ['authToken'],
  setAuthTokenFailure: null,

  removeAuthToken: null,

  signInRequest: ['loginAttributes'],
  signInSuccess: ['currentUser'],
  signInFailure: ['errors'],

  resetPasswordRequest: ['emailOrPseudo'],
  resetPasswordSuccess: [null],
  resetPasswordFailure: ['errors'],

  changePasswordRequest: ['password', 'passwordConfirmation', 'token', 'oldPassword'],
  changePasswordSuccess: [null],
  changePasswordFailure: ['errors'],

  changePinRequest: ['pin', 'helperId'],
  changePinSuccess: [null],
  changePinFailure: ['errors'],

  signUpInvitationRequest: ['password', 'verificationToken', 'redirectUrl'],
  signUpInvitationSuccess: null,
  signUpInvitationFailure: ['errors'],

  signUpConfirmationRequest: ['verificationToken', 'email'],
  signUpConfirmationSuccess: [null],
  signUpConfirmationFailure: ['errors'],

  signUpOrganisationRequest: ['holdingSlug', 'organisationSlug', 'user'],
  signUpOrganisationSuccess: ['pendingUser'],
  signUpOrganisationFailure: ['errors'],

  resendConfirmationEmailRequest: ['organisation', 'email'],
  resendConfirmationEmailSuccess: [null],
  resendConfirmationEmailFailure: ['errors'],

  createHelperSubscriptionRequest: ['helperId', 'subscription'],
  createHelperSubscriptionSuccess: ['subscription'],
  createHelperSubscriptionFailure: ['errors'],

  updateHelperSubscriptionRequest: ['helperId', 'subscription'],
  updateHelperSubscriptionSuccess: ['subscription'],
  updateHelperSubscriptionFailure: ['errors'],

  deleteHelperSubscriptionRequest: ['helperId', 'subscriptionId'],
  deleteHelperSubscriptionSuccess: ['subscriptionId'],
  deleteHelperSubscriptionFailure: ['errors'],

  getHelperNotificationsRequest: ['helperId'],
  getHelperNotificationsSuccess: ['notifications'],
  getHelperNotificationsFailure: ['errors'],

  createHelperNotification: ['notification'],

  updateHelperNotificationRequest: ['helperId', 'notification'],
  updateHelperNotificationSuccess: ['notification'],
  updateHelperNotificationFailure: ['errors'],

  deleteHelperNotificationRequest: ['helperId', 'notificationId'],
  deleteHelperNotificationSuccess: ['notificationId'],
  deleteHelperNotificationFailure: ['errors'],

  updateHelperRequest: ['helperId', 'helperAttributes', 'noDecorator'],
  updateHelperSuccess: ['helper'],
  updateHelperFailure: ['errors'],

  updateHasUnreadObject: ['hasNewObject'],

  addCurrentUserFamily: ['helpedFamily'],

  removeUserOrganisationPrimaryMembership: ['membership'],

  resetPendingUser: [],
  resetOnLogout: null,

  resetIfNewUser: ['helper'],
});

export const AuthTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  isLoggedIn: null,
  authToken: null,
  currentUser: null,
  currentVisitor: null,
  pendingUser: null,
  errors: null,
  hasNewObject: false,
  notifications: null,
  isFetching: null,
});

/* ------------- Reducers ------------- */

// -
// SET AUTH TOKEN
// -
const setAuthTokenRequest = state => ({ ...state });
const setAuthTokenSuccess = (state, { authToken }) => ({
  ...state,
  authToken,
});
const setAuthTokenFailure = state => ({ ...state });

// -
// SET AUTH TOKEN
// -
const removeAuthToken = () => INITIAL_STATE;

// -
// SIGN IN
// -
const signInRequest = state => ({
  ...state,
  isFetching: true,
  errors: null,
});
const signInSuccess = (state, { currentUser }) => {
  const user = { ...currentUser };
  const families = user.families
    ? user.families.slice()
    : state.currentUser?.families?.slice() || [];
  const existingFamily = families.find(f => f.id === user.family_id);
  const newFamily = { ...existingFamily, slug: currentUser?.family_slug };
  const newFamilies = findAndReplace(f => f.id === user?.family_id, newFamily)(families);

  return {
    ...state,
    isFetching: false,
    errors: null,
    isLoggedIn: !!state.authToken && !!currentUser?.id,
    currentUser:
      currentUser?.id && (state.currentUser === null || state.currentUser?.id === currentUser?.id)
        ? {
            ...state.currentUser,
            ...currentUser,
            family: { ...state.currentUser?.family, slug: currentUser?.family_slug },
            families: newFamilies,
          }
        : state.currentUser,
    currentVisitor: !currentUser?.id ? { ...state.currentVisitor, ...currentUser } : null,
    hasNewObject: userHasUnread(currentUser) || hasUnreadNotification(state.currentUser),
  };
};
const signInFailure = (state, { errors }) => ({
  ...state,
  isFetching: false,
  errors,
});

// -
// resetPassword
// -
const resetPasswordRequest = state => ({
  ...state,
  isFetching: true,
});
const resetPasswordSuccess = state => ({
  ...state,
  isFetching: false,
  errors: null,
});
const resetPasswordFailure = (state, { errors }) => ({
  ...state,
  isFetching: false,
  errors,
});

// -
// changePassword
// -
const changePasswordRequest = state => ({
  ...state,
  isFetching: true,
});
const changePasswordSuccess = state => ({
  ...state,
  isFetching: false,
  errors: null,
});
const changePasswordFailure = (state, { errors }) => ({
  ...state,
  isFetching: false,
  errors,
});

// -
// changePassword
// -
const changePinRequest = state => ({
  ...state,
  isFetching: true,
});
const changePinSuccess = state => ({
  ...state,
  isFetching: false,
  errors: null,
});
const changePinFailure = (state, { errors }) => ({
  ...state,
  isFetching: false,
  errors,
});

// -
// SIGN UP, invitation
// -
const signUpInvitationRequest = state => ({
  ...state,
  isFetching: true,
  errors: null,
});
const signUpInvitationSuccess = state => ({
  ...state,
  isFetching: false,
  errors: null,
});
const signUpInvitationFailure = (state, { errors }) => ({
  ...state,
  isFetching: false,
  errors,
});

// -
// SIGN UP, confirmation
// -
const signUpConfirmationRequest = state => ({
  ...state,
  isFetching: true,
  errors: null,
});
const signUpConfirmationSuccess = state => ({
  ...state,
  isFetching: false,
  errors: null,
});
const signUpConfirmationFailure = (state, { errors }) => ({
  ...state,
  isFetching: false,
  errors,
});

// -
// SIGN UP, organisation
// -
const signUpOrganisationRequest = state => ({
  ...state,
  isFetching: true,
});

const signUpOrganisationSuccess = (state, { pendingUser }) => ({
  ...state,
  isFetching: false,
  errors: null,
  pendingUser,
});
const signUpOrganisationFailure = (state, { errors }) => ({
  ...state,
  isFetching: false,
  errors,
});

const resendConfirmationEmailRequest = state => ({
  ...state,
  isFetching: true,
  errors: null,
});
const resendConfirmationEmailSuccess = state => ({
  ...state,
  isFetching: false,
  errors: null,
});
const resendConfirmationEmailFailure = (state, { errors }) => ({
  ...state,
  isFetching: false,
  errors,
});

// -
// Create subscription
// -
const createHelperSubscriptionRequest = state => ({
  ...state,
  isFetching: true,
  errors: null,
});
const createHelperSubscriptionSuccess = (state, { subscription }) => {
  const orga = state.currentUser.organisations.find(o => o.id === subscription.organisation_id);
  const family = state.currentUser.families.find(o => o.id === subscription.organisation_id);
  const myFamily =
    state.currentUser.family?.id === subscription.organisation_id ? state.currentUser.family : null;

  if (orga) {
    if (orga.subscriptions?.length > 0) orga.subscriptions.push(subscription);
    else orga.subscriptions = [subscription];
  }
  if (family) {
    if (family.subscriptions?.length > 0) family.subscriptions.push(subscription);
    else family.subscriptions = [subscription];
  }
  if (myFamily) {
    if (myFamily.subscriptions?.length > 0) myFamily.subscriptions.push(subscription);
    else myFamily.subscriptions = [subscription];
  }

  let primaries = state.currentUser.primaries_subscriptions?.slice() || [];
  if (
    subscription.subscrible &&
    subscription.subscrible_type === SubscribleTypes.FAMILY &&
    subscription.holding_slug
  ) {
    if (primaries?.length > 0) primaries.push(subscription);
    else primaries = [subscription];
  }

  return {
    ...state,
    isFetching: false,
    errors: null,
    currentUser: {
      ...state.currentUser,
      organisations: orga
        ? findAndReplace(o => orga.id === o.id, orga)(state.currentUser.organisations)
        : state.currentUser.organisations,
      families: family
        ? findAndReplace(f => f.id === family.id, family)(state.currentUser.families)
        : state.currentUser.families,
      family: myFamily || state.currentUser.family,
      primaries_subscriptions: primaries,
    },
    hasNewObject:
      hasUnread(state.currentUser) ||
      subscription.unread_count ||
      hasUnreadNotification(state.currentUser),
  };
};
const createHelperSubscriptionFailure = (state, { errors }) => ({
  ...state,
  isFetching: false,
  errors,
});

// -
// update subscription
// -
const updateHelperSubscriptionRequest = state => ({
  ...state,
  isFetching: true,
  errors: null,
});
const updateHelperSubscriptionSuccess = (state, { subscription }) => {
  const orga = state.currentUser.organisations.find(o => o.id === subscription.organisation_id);
  const family = state.currentUser.families.find(o => o.id === subscription.organisation_id);
  const myFamily =
    state.currentUser.family?.id === subscription.organisation_id ? state.currentUser.family : null;
  const primarySub = state.currentUser.primaries_subscriptions.find(s => s.id === subscription.id);

  if (orga) {
    orga.subscriptions = findAndReplace(
      s => s.id === subscription.id,
      subscription,
    )(orga.subscriptions);
  }
  if (family) {
    family.subscriptions = findAndReplace(
      s => s.id === subscription.id,
      subscription,
    )(family.subscriptions);
  }
  if (myFamily) {
    myFamily.subscriptions = findAndReplace(
      s => s.id === subscription.id,
      subscription,
    )(myFamily.subscriptions);
  }

  return {
    ...state,
    isFetching: false,
    currentUser: {
      ...state.currentUser,
      organisations: orga
        ? findAndReplace(o => orga.id === o.id, orga)(state.currentUser.organisations)
        : state.currentUser.organisations,
      families: family
        ? findAndReplace(f => f.id === family.id, family)(state.currentUser.families)
        : state.currentUser.families,
      family: myFamily || state.currentUser.family,
      primaries_subscriptions: primarySub
        ? findAndReplace(s => s.id === subscription.id, {
            ...primarySub,
            unread_count: subscription.unread_count,
          })(state.currentUser.primaries_subscriptions)
        : state.currentUser.primaries_subscriptions,
    },

    hasNewObject:
      hasUnread(state.currentUser) ||
      subscription.unread_count ||
      hasUnreadNotification(state.currentUser),
    errors: null,
  };
};

const updateHelperSubscriptionFailure = (state, { errors }) => ({
  ...state,
  isFetching: false,
  errors,
});

// -
// delete subscription
// -
const deleteHelperSubscriptionRequest = state => ({
  ...state,
  isFetching: true,
  errors: null,
});
const deleteHelperSubscriptionSuccess = (state, { subscriptionId }) => {
  const orga = state.currentUser.organisations.find(o =>
    o.subscriptions?.find(s => `${s.id}` === `${subscriptionId}`),
  );
  const family = state.currentUser.families.find(f =>
    f.subscriptions?.find(s => `${s.id}` === `${subscriptionId}`),
  );
  const myFamily = state.currentUser.family.subscriptions?.find(
    s => `${s.id}` === `${subscriptionId}`,
  )
    ? state.currentUser.family
    : null;

  const primarySubs = state.currentUser.primaries_subscriptions?.filter(
    s => s.id !== subscriptionId,
  );

  if (orga) orga.subscriptions = orga.subscriptions.filter(s => `${s.id}` === `${subscriptionId}`);
  if (family)
    family.subscriptions = family.subscriptions.filter(s => `${s.id}` === `${subscriptionId}`);
  if (myFamily)
    myFamily.subscriptions = myFamily.subscriptions.filter(s => `${s.id}` === `${subscriptionId}`);

  return {
    ...state,
    isFetching: false,
    errors: null,
    currentUser: {
      ...state.currentUser,
      organisations: orga
        ? findAndReplace(o => orga.id === o.id, orga)(state.currentUser.organisations)
        : state.currentUser.organisations,
      families: family
        ? findAndReplace(f => f.id === family.id, family)(state.currentUser.families)
        : state.currentUser.families,
      family: myFamily || state.currentUser.family,
      primaries_subscriptions: primarySubs,
    },
    hasNewObject: hasUnread(state.currentUser) || hasUnreadNotification(state.currentUser),
  };
};
const deleteHelperSubscriptionFailure = (state, { errors }) => ({
  ...state,
  isFetching: false,
  errors,
});

// -
// get Notifications
// -
const getHelperNotificationsRequest = state => ({
  ...state,
  isFetching: true,
  errors: INITIAL_STATE.errors,
});

const getHelperNotificationsSuccess = (state, { notifications }) => ({
  ...state,
  isFetching: false,
  notifications,
});

const getHelperNotificationsFailure = (state, { errors }) => ({
  ...state,
  isFetching: false,
  errors,
});

// -
// add notification
// -
const createHelperNotification = (state, { notification }) => {
  let notifications = state.currentUser.notifications?.slice() || [];
  if (notifications?.length > 0) notifications.push(notification);
  else notifications = [notification];

  notifications.sort((a, b) => b.created_at.localeCompare(a.created_at));
  return {
    ...state,
    isFetching: false,
    errors: null,
    currentUser: {
      ...state.currentUser,
      notifications,
    },
    hasNewObject:
      hasUnread(state.currentUser) ||
      hasUnreadNotification({
        ...state.currentUser,
        notifications,
      }),
  };
};

// -
// update notification
// -
const updateHelperNotificationRequest = state => ({
  ...state,
  isFetching: true,
  errors: null,
});
const updateHelperNotificationSuccess = (state, { notification }) => {
  const notifications = findAndReplace(
    n => n.id === notification.id,
    notification,
  )(state.currentUser.notifications);

  const allNotifications = state.notifications
    ? findAndReplace(n => n.id === notification.id, notification)(state.notifications)
    : null;

  return {
    ...state,
    isFetching: false,
    currentUser: {
      ...state.currentUser,
      notifications,
    },
    notifications: allNotifications,
    hasNewObject: hasUnread(state.currentUser) || hasUnreadNotification(state.currentUser),
    errors: null,
  };
};

const updateHelperNotificationFailure = (state, { errors }) => ({
  ...state,
  isFetching: false,
  errors,
});

// -
// delete notification
// -
const deleteHelperNotificationRequest = state => ({
  ...state,
  isFetching: true,
  errors: null,
});
const deleteHelperNotificationSuccess = (state, { notificationId }) => {
  const notifications = state.currentUser.notifications.filter(
    n => `${n.id}` !== `${notificationId}`,
  );

  const allNotifications = state.notifications?.filter(n => `${n.id}` !== `${notificationId}`);

  return {
    ...state,
    isFetching: false,
    errors: null,
    currentUser: {
      ...state.currentUser,
      notifications,
    },
    notifications: allNotifications,
    hasNewObject: hasUnread(state.currentUser) || hasUnreadNotification(state.currentUser),
  };
};
const deleteHelperNotificationFailure = (state, { errors }) => ({
  ...state,
  isFetching: false,
  errors,
});

// -
// update notification
// -
const updateHelperRequest = state => ({
  ...state,
  isFetching: true,
  errors: null,
});
const updateHelperSuccess = (state, { helper }) => {
  return {
    ...state,
    isFetching: false,
    currentUser: { ...state.currentUser, ...helper },
    hasNewObject: hasUnread(helper) || hasUnreadNotification(helper),
    errors: null,
  };
};

const updateHelperFailure = (state, { errors }) => ({
  ...state,
  isFetching: false,
  errors,
});

const updateHasUnreadObject = (state, { hasNewObject }) => {
  if (!hasNewObject && !userHasUnread(state.currentUser)) {
    return {
      ...state,
      hasNewObject: true,
    };
  }
  return {
    ...state,
    hasNewObject,
  };
};

const addCurrentUserFamily = (state, { helpedFamily }) => {
  if (!helpedFamily.main_helpers.find(h => h.id === state.currentUser?.id)) return state;

  let userFamilies = state.currentUser?.families?.slice();
  if (!userFamilies) userFamilies = [helpedFamily];
  else userFamilies.push(helpedFamily);

  return {
    ...state,
    currentUser: { ...state.currentUser, families: userFamilies },
  };
};

const resetPendingUser = state => ({
  ...state,
  pendingUser: INITIAL_STATE.pendingUser,
});

const removeUserOrganisationPrimaryMembership = (state, { membership }) => {
  const primarySubscriptions = state.currentUser.primaries_subscriptions?.filter(
    sub =>
      sub.subscrible_id !== membership.helper.family_id ||
      sub.organisation_id !== membership.organisation_id,
  );

  return {
    ...state,
    currentUser: {
      ...state.currentUser,
      primaries_subscriptions: primarySubscriptions,
    },
  };
};

// -
// LOGOUT
// -
const resetOnLogout = () => INITIAL_STATE;

const resetIfNewUser = (state, { helper }) => {
  const currentUser = state.currentUser;
  if (currentUser && helper && currentUser.id !== helper.id) return INITIAL_STATE;
  return state;
};

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_AUTH_TOKEN_REQUEST]: setAuthTokenRequest,
  [Types.SET_AUTH_TOKEN_SUCCESS]: setAuthTokenSuccess,
  [Types.SET_AUTH_TOKEN_FAILURE]: setAuthTokenFailure,

  [Types.REMOVE_AUTH_TOKEN]: removeAuthToken,

  [Types.SIGN_IN_REQUEST]: signInRequest,
  [Types.SIGN_IN_SUCCESS]: signInSuccess,
  [Types.SIGN_IN_FAILURE]: signInFailure,

  [Types.RESET_PASSWORD_REQUEST]: resetPasswordRequest,
  [Types.RESET_PASSWORD_SUCCESS]: resetPasswordSuccess,
  [Types.RESET_PASSWORD_FAILURE]: resetPasswordFailure,

  [Types.CHANGE_PASSWORD_REQUEST]: changePasswordRequest,
  [Types.CHANGE_PASSWORD_SUCCESS]: changePasswordSuccess,
  [Types.CHANGE_PASSWORD_FAILURE]: changePasswordFailure,

  [Types.CHANGE_PIN_REQUEST]: changePinRequest,
  [Types.CHANGE_PIN_SUCCESS]: changePinSuccess,
  [Types.CHANGE_PIN_FAILURE]: changePinFailure,

  [Types.SIGN_UP_INVITATION_REQUEST]: signUpInvitationRequest,
  [Types.SIGN_UP_INVITATION_SUCCESS]: signUpInvitationSuccess,
  [Types.SIGN_UP_INVITATION_FAILURE]: signUpInvitationFailure,

  [Types.SIGN_UP_CONFIRMATION_REQUEST]: signUpConfirmationRequest,
  [Types.SIGN_UP_CONFIRMATION_SUCCESS]: signUpConfirmationSuccess,
  [Types.SIGN_UP_CONFIRMATION_FAILURE]: signUpConfirmationFailure,

  [Types.SIGN_UP_ORGANISATION_REQUEST]: signUpOrganisationRequest,
  [Types.SIGN_UP_ORGANISATION_SUCCESS]: signUpOrganisationSuccess,
  [Types.SIGN_UP_ORGANISATION_FAILURE]: signUpOrganisationFailure,

  [Types.RESEND_CONFIRMATION_EMAIL_REQUEST]: resendConfirmationEmailRequest,
  [Types.RESEND_CONFIRMATION_EMAIL_SUCCESS]: resendConfirmationEmailSuccess,
  [Types.RESEND_CONFIRMATION_EMAIL_FAILURE]: resendConfirmationEmailFailure,

  [Types.CREATE_HELPER_SUBSCRIPTION_REQUEST]: createHelperSubscriptionRequest,
  [Types.CREATE_HELPER_SUBSCRIPTION_SUCCESS]: createHelperSubscriptionSuccess,
  [Types.CREATE_HELPER_SUBSCRIPTION_FAILURE]: createHelperSubscriptionFailure,

  [Types.UPDATE_HELPER_SUBSCRIPTION_REQUEST]: updateHelperSubscriptionRequest,
  [Types.UPDATE_HELPER_SUBSCRIPTION_SUCCESS]: updateHelperSubscriptionSuccess,
  [Types.UPDATE_HELPER_SUBSCRIPTION_FAILURE]: updateHelperSubscriptionFailure,

  [Types.DELETE_HELPER_SUBSCRIPTION_REQUEST]: deleteHelperSubscriptionRequest,
  [Types.DELETE_HELPER_SUBSCRIPTION_SUCCESS]: deleteHelperSubscriptionSuccess,
  [Types.DELETE_HELPER_SUBSCRIPTION_FAILURE]: deleteHelperSubscriptionFailure,

  [Types.GET_HELPER_NOTIFICATIONS_REQUEST]: getHelperNotificationsRequest,
  [Types.GET_HELPER_NOTIFICATIONS_SUCCESS]: getHelperNotificationsSuccess,
  [Types.GET_HELPER_NOTIFICATIONS_FAILURE]: getHelperNotificationsFailure,

  [Types.CREATE_HELPER_NOTIFICATION]: createHelperNotification,

  [Types.UPDATE_HELPER_NOTIFICATION_REQUEST]: updateHelperNotificationRequest,
  [Types.UPDATE_HELPER_NOTIFICATION_SUCCESS]: updateHelperNotificationSuccess,
  [Types.UPDATE_HELPER_NOTIFICATION_FAILURE]: updateHelperNotificationFailure,

  [Types.DELETE_HELPER_NOTIFICATION_REQUEST]: deleteHelperNotificationRequest,
  [Types.DELETE_HELPER_NOTIFICATION_SUCCESS]: deleteHelperNotificationSuccess,
  [Types.DELETE_HELPER_NOTIFICATION_FAILURE]: deleteHelperNotificationFailure,

  [Types.UPDATE_HELPER_REQUEST]: updateHelperRequest,
  [Types.UPDATE_HELPER_SUCCESS]: updateHelperSuccess,
  [Types.UPDATE_HELPER_FAILURE]: updateHelperFailure,

  [Types.UPDATE_HAS_UNREAD_OBJECT]: updateHasUnreadObject,

  [Types.ADD_CURRENT_USER_FAMILY]: addCurrentUserFamily,

  [Types.RESET_PENDING_USER]: resetPendingUser,
  [Types.RESET_ON_LOGOUT]: resetOnLogout,
  [Types.RESET_IF_NEW_USER]: resetIfNewUser,

  [Types.REMOVE_USER_ORGANISATION_PRIMARY_MEMBERSHIP]: removeUserOrganisationPrimaryMembership,
});
