import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';
import _ from 'lodash';
import findAndReplace, { findAndRemove } from './ReduxHelpers';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  createQualifiedLeadRequest: ['holdingSlug', 'organisationSlug', 'lead'],
  createQualifiedLeadSuccess: ['lead'],
  createQualifiedLeadFailure: ['errors'],

  createSharedLeadRequest: ['holdingSlug', 'organisationSlug', 'lead', 'primaryMembershipId'],
  createSharedLeadSuccess: ['lead'],
  createSharedLeadFailure: ['errors'],

  createNonQualifiedLeadRequest: ['holdingSlug', 'organisationSlug', 'lead'],
  createNonQualifiedLeadSuccess: ['lead'],
  createNonQualifiedLeadFailure: ['errors'],

  getLeadRequest: ['holdingSlug', 'orgaSlug', 'leadId'],
  getLeadSuccess: ['lead'],
  getLeadFailure: ['errors'],

  getOrganisationLeadsRequest: [
    'holdingSlug',
    'orgaSlug',
    'filterArchived',
    'currentPage',
    'range',
  ],
  getOrganisationLeadsSuccess: ['leads', 'totalleads'],
  getOrganisationLeadsFailure: ['errors'],

  getOrganisationAcceptedLeadsRangeRequest: ['organisationId', 'range'],
  getOrganisationAcceptedLeadsRangeSuccess: ['acceptedLeadsRange', 'totalLeadsRange'],
  getOrganisationAcceptedLeadsRangeFailure: ['errors'],

  getOrganisationLeadsByTypeRequest: ['organisationId', 'range'],
  getOrganisationLeadsByTypeSuccess: ['leadsByType'],
  getOrganisationLeadsByTypeFailure: ['errors'],

  getOrganisationLeadsByDayRequest: ['organisationId', 'range'],
  getOrganisationLeadsByDaySuccess: ['leadsByDay'],
  getOrganisationLeadsByDayFailure: ['errors'],

  getOrganisationLeadsByWeekRequest: ['organisationId', 'range'],
  getOrganisationLeadsByWeekSuccess: ['leadsByWeek'],
  getOrganisationLeadsByWeekFailure: ['errors'],

  getOrganisationLeadsByMonthRequest: ['organisationId', 'range'],
  getOrganisationLeadsByMonthSuccess: ['leadsByMonth'],
  getOrganisationLeadsByMonthFailure: ['errors'],

  getOrganisationTotalHelpersRequest: ['organisationId', 'role'],
  getOrganisationTotalHelpersSuccess: ['count'],
  getOrganisationTotalHelpersFailure: ['errors'],

  getOrganisationTopHelpersRequest: ['organisationId', 'role'],
  getOrganisationTopHelpersSuccess: ['topHelp'],
  getOrganisationTopHelpersFailure: ['errors'],

  getOrganisationHelpersRequest: ['organisationId', 'role'],
  getOrganisationHelpersSuccess: ['helpers'],
  getOrganisationHelpersFailure: ['errors'],

  getHoldingLeadsRequest: ['holdingSlug', 'filterArchived', 'currentPage'],
  getHoldingLeadsSuccess: ['leads', 'totalleads'],
  getHoldingLeadsFailure: ['errors'],

  getLeagueLeadsRequest: ['leagueId', 'filterArchived', 'currentPage', 'organisationId'],
  getLeagueLeadsSuccess: ['leads', 'totalleads', 'organisationId'],
  getLeagueLeadsFailure: ['errors'],

  getLeagueLeadRequest: ['leagueId', 'leadId'],
  getLeagueLeadSuccess: ['lead'],
  getLeagueLeadFailure: ['errors'],

  createLeagueLeadRequest: ['leagueId', 'leadAttributes'],
  createLeagueLeadSuccess: ['lead'],
  createLeagueLeadFailure: ['errors'],

  updateLeagueLeadRequest: ['leagueId', 'leadId', 'leadAttributes', 'helperId'],
  updateLeagueLeadSuccess: ['lead', 'archived'],
  updateLeagueLeadFailure: ['errors'],

  transferLeagueLeadRequest: ['leagueId', 'leadId', 'organisationId', 'helperId'],
  transferLeagueLeadSuccess: ['lead'],
  transferLeagueLeadFailure: ['errors'],

  getFamilyLeadsRequest: ['helperId', 'filterArchived', 'currentPage'],
  getFamilyLeadsSuccess: ['leads', 'totalleads'],
  getFamilyLeadsFailure: ['errors'],

  getFamilyLeadRequest: ['helperId', 'leadId'],
  getFamilyLeadSuccess: ['lead'],
  getFamilyLeadFailure: ['errors'],

  updateFamilyLeadRequest: ['helperId', 'lead'],
  updateFamilyLeadSuccess: ['lead'],
  updateFamilyLeadFailure: ['errors'],

  updateOrganisationLeadRequest: ['holdingSlug', 'orgaSlug', 'lead'],
  updateOrganisationLeadSuccess: ['lead'],
  updateOrganisationLeadFailure: ['errors'],

  createLeadPostRequest: ['leagueId', 'holdingSlug', 'organisationSlug', 'leadId', 'post'],
  createLeadPostSuccess: ['leadId', 'post'],
  createLeadPostFailure: ['errors'],

  updateOrganisationLeadAndPostCommentRequest: ['holdingSlug', 'orgaSlug', 'lead', 'post'],
  updateOrganisationLeadAndPostCommentSuccess: ['lead'],
  updateOrganisationLeadAndPostCommentFailure: ['errors'],
  resetLeads: [],
  resetLead: [],

  addLead: ['lead'],
});

export const LeadsTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
  isFetching: {
    createQualifiedLead: false,
    createSharedLead: false,
    createNonQualifiedLead: false,
    getLead: false,
    getOrganisationLeads: false,
    getOrganisationAcceptedLeadsRange: false,
    getOrganisationLeadsByType: false,
    getOrganisationLeadsByDay: false,
    getOrganisationLeadsByWeek: false,
    getOrganisationLeadsByMonth: false,
    getHoldingLeads: false,
    createLeadPost: false,
    updateOrganisationLead: false,
    updateFamilyLead: false,
    getLeagueLeads: false,
    getLeagueLead: false,
    createLeagueLead: false,
    updateLeagueLead: false,
    transferLeagueLead: false,
    getFamilyLeads: false,
    getFamilyLead: false,
    updateOrganisationLeadAndPostComment: false,
    getOrganisationTotalHelpers: false,
    getOrganisationTopHelpers: false,
    getOrganisationHelpers: false,
  },
  errors: {
    createQualifiedLead: null,
    createSharedLead: null,
    createNonQualifiedLead: null,
    getLead: null,
    getOrganisationLeads: null,
    getOrganisationAcceptedLeadsRange: null,
    getHoldingLeads: null,
    createLeadPost: null,
    updateOrganisationLead: null,
    updateFamilyLead: null,
    getFamilyLeads: null,
    getFamilyLead: null,
    updateOrganisationLeadAndPostComment: null,
    getOrganisationLeadsByType: null,
    getOrganisationLeadsByDay: null,
    getOrganisationLeadsByWeek: null,
    getOrganisationLeadsByMonth: null,
    getOrganisationTotalHelpers: null,
    getOrganisationTopHelpers: null,
    getOrganisationHelpers: null,
  },
  totalleads: 0,
  lead: null,
  leads: [],
  leadsByDay: [],
  leadsByWeek: [],
  leadsByMonth: [],
  leadsByType: [],
});

/* ------------- Reducers ------------- */
// -
// create, by kind
// -
// createQualifiedLead
const createQualifiedLeadRequest = state => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    createQualifiedLead: true,
  },
  errors: {
    ...state.errors,
    createQualifiedLead: INITIAL_STATE.errors.createQualifiedLead,
  },
  lead: INITIAL_STATE.lead,
});

const createQualifiedLeadSuccess = (state, { lead }) => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    createQualifiedLead: false,
  },
  lead,
});

const createQualifiedLeadFailure = (state, { errors }) => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    createQualifiedLead: false,
  },
  errors: {
    ...state.errors,
    createQualifiedLead: errors,
  },
});

// createSharedLead
const createSharedLeadRequest = state => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    createSharedLead: true,
  },
  errors: {
    ...state.errors,
    createSharedLead: INITIAL_STATE.errors.createSharedLead,
  },
  lead: INITIAL_STATE.lead,
});

const createSharedLeadSuccess = (state, { lead }) => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    createSharedLead: false,
  },
  lead,
});

const createSharedLeadFailure = (state, { errors }) => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    createSharedLead: false,
  },
  errors: {
    ...state.errors,
    createSharedLead: errors,
  },
});

// createNonQualifiedLead
const createNonQualifiedLeadRequest = state => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    createNonQualifiedLead: true,
  },
  errors: {
    ...state.errors,
    createNonQualifiedLead: INITIAL_STATE.errors.createNonQualifiedLead,
  },
  lead: INITIAL_STATE.lead,
});

const createNonQualifiedLeadSuccess = (state, { lead }) => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    createNonQualifiedLead: false,
  },
  lead,
});

const createNonQualifiedLeadFailure = (state, { errors }) => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    createNonQualifiedLead: false,
  },
  errors: {
    ...state.errors,
    createNonQualifiedLead: errors,
  },
});

// getLead
const getLeadRequest = state => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    getLead: true,
  },
  errors: {
    ...state.errors,
    getLead: INITIAL_STATE.errors.getLead,
  },
  lead: INITIAL_STATE.lead,
});

const getLeadSuccess = (state, { lead }) => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    getLead: false,
  },
  lead,
});

const getLeadFailure = (state, { errors }) => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    getLead: false,
  },
  errors: {
    ...state.errors,
    getLead: errors,
  },
});

// -
// nested, create post
// -
const createLeadPostRequest = state => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    createLeadPost: true,
  },
  errors: {
    ...state.errors,
    createLeadPost: INITIAL_STATE.errors.createLeadPost,
  },
});

const createLeadPostSuccess = (state, { leadId, post }) => {
  const currentLead = state.lead ? _.assign({}, state.lead) : state.lead;
  if (currentLead.id === leadId) {
    currentLead.posts.push(post);
  }

  return {
    ...state,
    lead: currentLead,
    isFetching: {
      ...state.isFetching,
      createLeadPost: false,
    },
  };
};

const createLeadPostFailure = (state, { errors }) => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    createLeadPost: false,
  },
  errors: {
    ...state.errors,
    createLeadPost: errors,
  },
});

// getOrganisationLeads
const getOrganisationLeadsRequest = state => ({
  ...state,
  isFetching: { ...state.isFetching, getOrganisationLeads: true },
  errors: {
    ...state.errors,
    getOrganisationLeads: INITIAL_STATE.errors.getOrganisationLeads,
  },
});

const getOrganisationLeadsSuccess = (state, { leads, totalleads }) => ({
  ...state,
  isFetching: { ...state.isFetching, getOrganisationLeads: false },
  leads,
  totalleads,
});

const getOrganisationLeadsFailure = (state, { errors }) => ({
  ...state,
  leads: [],
  isFetching: { ...state.isFetching, getOrganisationLeads: false },
  errors: {
    ...state.errors,
    getOrganisationLeads: errors || true,
  },
});

// getOrganisationAcceptedLeadsRange
const getOrganisationAcceptedLeadsRangeRequest = state => ({
  ...state,
  isFetching: { ...state.isFetching, getOrganisationAcceptedLeadsRange: true },
  errors: {
    ...state.errors,
    getOrganisationAcceptedLeadsRange: INITIAL_STATE.errors.getOrganisationAcceptedLeadsRange,
  },
});

const getOrganisationAcceptedLeadsRangeSuccess = (
  state,
  { acceptedLeadsRange, totalLeadsRange },
) => ({
  ...state,
  isFetching: { ...state.isFetching, getOrganisationAcceptedLeadsRange: false },
  acceptedLeadsRange,
  totalLeadsRange,
});

const getOrganisationAcceptedLeadsRangeFailure = (state, { errors }) => ({
  ...state,
  isFetching: { ...state.isFetching, getOrganisationAcceptedLeadsRange: false },
  errors: {
    ...state.errors,
    getOrganisationAcceptedLeadsRange: errors || true,
  },
});

// getOrganisationLeadsByType
const getOrganisationLeadsByTypeRequest = state => ({
  ...state,
  isFetching: { ...state.isFetching, getOrganisationLeadsByType: true },
  errors: {
    ...state.errors,
    getOrganisationLeadsByType: INITIAL_STATE.errors.getOrganisationLeadsByType,
  },
});

const getOrganisationLeadsByTypeSuccess = (state, { leadsByType }) => ({
  ...state,
  isFetching: { ...state.isFetching, getOrganisationLeadsByType: false },
  leadsByType,
});

const getOrganisationLeadsByTypeFailure = (state, { errors }) => ({
  ...state,
  isFetching: { ...state.isFetching, getOrganisationLeadsByType: false },
  errors: {
    ...state.errors,
    getOrganisationLeadsByType: errors || true,
  },
});

// getOrganisationLeadsByDay

const getOrganisationLeadsByDayRequest = state => ({
  ...state,
  isFetching: { ...state.isFetching, getOrganisationLeadsByDay: true },
  errors: {
    ...state.errors,
    getOrganisationLeadsByDay: INITIAL_STATE.errors.getOrganisationLeadsByDay,
  },
});

const getOrganisationLeadsByDaySuccess = (state, { leadsByDay }) => ({
  ...state,
  isFetching: { ...state.isFetching, getOrganisationLeadsByDay: false },
  leadsByDay,
});

const getOrganisationLeadsByDayFailure = (state, { errors }) => ({
  ...state,
  isFetching: { ...state.isFetching, getOrganisationLeadsByDay: false },
  errors: {
    ...state.errors,
    getOrganisationLeadsByDay: errors || true,
  },
});

// getOrganisationLeadsByWeek

const getOrganisationLeadsByWeekRequest = state => ({
  ...state,
  isFetching: { ...state.isFetching, getOrganisationLeadsByWeek: true },
  errors: {
    ...state.errors,
    getOrganisationLeadsByWeek: INITIAL_STATE.errors.getOrganisationLeadsByWeek,
  },
});

const getOrganisationLeadsByWeekSuccess = (state, { leadsByWeek }) => ({
  ...state,
  isFetching: { ...state.isFetching, getOrganisationLeadsByWeek: false },
  leadsByWeek,
});

const getOrganisationLeadsByWeekFailure = (state, { errors }) => ({
  ...state,
  isFetching: { ...state.isFetching, getOrganisationLeadsByWeek: false },
  errors: {
    ...state.errors,
    getOrganisationLeadsByWeek: errors || true,
  },
});

// getOrganisationLeadsByMonth

const getOrganisationLeadsByMonthRequest = state => ({
  ...state,
  isFetching: { ...state.isFetching, getOrganisationLeadsByMonth: true },
  errors: {
    ...state.errors,
    getOrganisationLeadsByMonth: INITIAL_STATE.errors.getOrganisationLeadsByMonth,
  },
});

const getOrganisationLeadsByMonthSuccess = (state, { leadsByMonth }) => ({
  ...state,
  isFetching: { ...state.isFetching, getOrganisationLeadsByMonth: false },
  leadsByMonth,
});

const getOrganisationLeadsByMonthFailure = (state, { errors }) => ({
  ...state,
  isFetching: { ...state.isFetching, getOrganisationLeadsByMonth: false },
  errors: {
    ...state.errors,
    getOrganisationLeadsByMonth: errors || true,
  },
});

// getHoldingLeads
const getHoldingLeadsRequest = state => ({
  ...state,
  isFetching: { ...state.isFetching, getHoldingLeads: true },
  errors: {
    ...state.errors,
    getHoldingLeads: INITIAL_STATE.errors.getHoldingLeads,
  },
});

const getHoldingLeadsSuccess = (state, { leads, totalleads }) => ({
  ...state,
  isFetching: { ...state.isFetching, getHoldingLeads: false },
  leads,
  totalleads,
});

// getOrganisationTotalHelpers

const getOrganisationTotalHelpersRequest = state => ({
  ...state,
  isFetching: { ...state.isFetching, getOrganisationTotalHelpers: true },
  errors: {
    ...state.errors,
    getOrganisationTotalHelpers: INITIAL_STATE.errors.getOrganisationTotalHelpers,
  },
});

const getOrganisationTotalHelpersSuccess = (state, { count }) => ({
  ...state,
  isFetching: { ...state.isFetching, getOrganisationTotalHelpers: false },
  count,
});

const getOrganisationTotalHelpersFailure = (state, { errors }) => ({
  ...state,
  isFetching: { ...state.isFetching, getOrganisationTotalHelpers: false },
  errors: {
    ...state.errors,
    getOrganisationTotalHelpers: errors || true,
  },
});

// getOrganisationTopHelpers

const getOrganisationTopHelpersRequest = state => ({
  ...state,
  isFetching: { ...state.isFetching, getOrganisationTopHelpers: true },
  errors: {
    ...state.errors,
    getOrganisationTopHelpers: INITIAL_STATE.errors.getOrganisationTopHelpers,
  },
});

const getOrganisationTopHelpersSuccess = (state, { topHelp }) => ({
  ...state,
  isFetching: { ...state.isFetching, getOrganisationTopHelpers: false },
  topHelp,
});

const getOrganisationTopHelpersFailure = (state, { errors }) => ({
  ...state,
  isFetching: { ...state.isFetching, getOrganisationTopHelpers: false },
  errors: {
    ...state.errors,
    getOrganisationTopHelpers: errors || true,
  },
});

// getOrganisationHelpers

const getOrganisationHelpersRequest = state => ({
  ...state,
  isFetching: { ...state.isFetching, getOrganisationHelpers: true },
  errors: {
    ...state.errors,
    getOrganisationHelpers: INITIAL_STATE.errors.getOrganisationHelpers,
  },
});

const getOrganisationHelpersSuccess = (state, { helpers }) => ({
  ...state,
  isFetching: { ...state.isFetching, getOrganisationHelpers: false },
  helpers,
});

const getOrganisationHelpersFailure = (state, { errors }) => ({
  ...state,
  isFetching: { ...state.isFetching, getOrganisationHelpers: false },
  errors: {
    ...state.errors,
    getOrganisationHelpers: errors || true,
  },
});

const getHoldingLeadsFailure = (state, { errors }) => ({
  ...state,
  leads: [],
  isFetching: { ...state.isFetching, getHoldingLeads: false },
  errors: {
    ...state.errors,
    getHoldingLeads: errors || true,
  },
});

// list leads by league
const getLeagueLeadsRequest = state => ({
  ...state,
  isFetching: { ...state.isFetching, getLeagueLeads: true },
  errors: {
    ...state.errors,
    getLeagueLeads: INITIAL_STATE.errors.getLeagueLeads,
  },
});

const getLeagueLeadsSuccess = (state, { leads, totalleads, organisationId }) => ({
  ...state,
  isFetching: { ...state.isFetching, getLeagueLeads: false },
  leads: organisationId || organisationId === 0 ? leads : state.leads,
  allLeads: organisationId || organisationId === 0 ? state.allLeads : leads,
  totalleads,
});

const getLeagueLeadsFailure = (state, { errors }) => ({
  ...state,
  isFetching: { ...state.isFetching, getLeagueLeads: false },
  errors: {
    ...state.errors,
    getLeagueLeads: errors,
  },
});

// get league lead
const getLeagueLeadRequest = state => ({
  ...state,
  isFetching: { ...state.isFetching, getLeagueLead: true },
  errors: {
    ...state.errors,
    getLeagueLead: INITIAL_STATE.errors.getLeagueLead,
  },
});

const getLeagueLeadSuccess = (state, { lead }) => ({
  ...state,
  isFetching: { ...state.isFetching, getLeagueLead: false },
  lead,
});

const getLeagueLeadFailure = (state, { errors }) => ({
  ...state,
  isFetching: { ...state.isFetching, getLeagueLead: false },
  errors: {
    ...state.errors,
    getLeagueLead: errors,
  },
});

// create league lead
const createLeagueLeadRequest = state => ({
  ...state,
  isFetching: { ...state.isFetching, createLeagueLead: true },
  errors: {
    ...state.errors,
    createLeagueLead: INITIAL_STATE.errors.createLeagueLead,
  },
});

const createLeagueLeadSuccess = (state, { lead }) => {
  return {
    ...state,
    isFetching: { ...state.isFetching, createLeagueLead: false },
    lead,
  };
};

const createLeagueLeadFailure = (state, { errors }) => ({
  ...state,
  isFetching: { ...state.isFetching, createLeagueLead: false },
  errors: {
    ...state.errors,
    createLeagueLead: errors,
  },
});

// update league lead
const updateLeagueLeadRequest = state => ({
  ...state,
  isFetching: { ...state.isFetching, updateLeagueLead: true },
  errors: {
    ...state.errors,
    updateLeagueLead: INITIAL_STATE.errors.updateLeagueLead,
  },
});

const updateLeagueLeadSuccess = (state, { lead, archived }) => ({
  ...state,
  isFetching: { ...state.isFetching, updateLeagueLead: false },
  leads: archived
    ? findAndRemove(l => l.id === lead.id, lead)(state.leads)
    : findAndReplace(l => l.id === lead.id, lead)(state.leads),
  allLeads: archived
    ? findAndRemove(l => l.id === lead.id, lead)(state.allLeads)
    : findAndReplace(l => l.id === lead.id, lead)(state.allLeads),
  totalleads: archived ? state.totalleads - 1 : state.totalleads,
});

const updateLeagueLeadFailure = (state, { errors }) => ({
  ...state,
  isFetching: { ...state.isFetching, updateLeagueLead: false },
  errors: {
    ...state.errors,
    updateLeagueLead: errors,
  },
});

// transfer league lead
const transferLeagueLeadRequest = state => ({
  ...state,
  isFetching: { ...state.isFetching, transferLeagueLead: true },
  errors: {
    ...state.errors,
    transferLeagueLead: INITIAL_STATE.errors.transferLeagueLead,
  },
});

const transferLeagueLeadSuccess = (state, { lead }) => ({
  ...state,
  isFetching: { ...state.isFetching, transferLeagueLead: false },
  leads: findAndReplace(l => l.id === lead.id, lead)(state.leads),
  allLeads: findAndReplace(l => l.id === lead.id, lead)(state.allLeads),
});

const transferLeagueLeadFailure = (state, { errors }) => ({
  ...state,
  isFetching: { ...state.isFetching, transferLeagueLead: false },
  errors: {
    ...state.errors,
    transferLeagueLead: errors,
  },
});

// list leads by kind for families
const getFamilyLeadsRequest = state => ({
  ...state,
  isFetching: { ...state.isFetching, getFamilyLeads: true },
  errors: {
    ...state.errors,
    getFamilyLeads: INITIAL_STATE.errors.getFamilyLeads,
  },
});

const getFamilyLeadsSuccess = (state, { leads, totalleads }) => ({
  ...state,
  isFetching: { ...state.isFetching, getFamilyLeads: false },
  leads,
  totalleads,
});

const getFamilyLeadsFailure = (state, { errors }) => ({
  ...state,
  isFetching: { ...state.isFetching, getFamilyLeads: false },
  errors: {
    ...state.errors,
    getFamilyLeads: errors,
  },
});

// getFamily
const getFamilyLeadRequest = state => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    getFamilyLead: true,
  },
  errors: {
    ...state.errors,
    getFamilyLead: INITIAL_STATE.errors.getFamilyLead,
  },
  lead: INITIAL_STATE.lead,
});

const getFamilyLeadSuccess = (state, { lead }) => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    getFamilyLead: false,
  },
  lead,
});

const getFamilyLeadFailure = (state, { errors }) => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    getFamilyLead: false,
  },
  errors: {
    ...state.errors,
    getFamilyLead: errors,
  },
});

// update for Organisation
const updateOrganisationLeadRequest = state => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    updateOrganisationLead: true,
  },
  errors: {
    ...state.errors,
    updateOrganisationLead: INITIAL_STATE.errors.updateOrganisationLead,
  },
});

const updateOrganisationLeadSuccess = (state, { lead: updatedLead }) => {
  const isSameLead = lead => lead.id === updatedLead.id;
  let remove = false;
  const oldLead = state.leads?.find(lead => lead.id === updatedLead.id);
  if (oldLead && oldLead.archived !== updatedLead.archived) remove = true;
  return {
    ...state,
    isFetching: { ...state.isFetching, updateOrganisationLead: false },
    leads: remove
      ? findAndRemove(isSameLead, updatedLead)(state.leads)
      : findAndReplace(isSameLead, updatedLead)(state.leads),
  };
};

const updateOrganisationLeadFailure = (state, { errors }) => ({
  ...state,
  isFetching: { ...state.isFetching, updateOrganisationLead: false },
  errors: {
    ...state.errors,
    updateOrganisationLead: errors,
  },
});

const updateOrganisationLeadAndPostCommentRequest = state => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    updateOrganisationLeadAndPostComment: true,
  },
  errors: {
    ...state.errors,
    updateOrganisationLeadAndPostComment: INITIAL_STATE.errors.updateOrganisationLeadAndPostComment,
  },
});

const updateOrganisationLeadAndPostCommentSuccess = (state, { lead: updatedLead }) => {
  const isSameLead = lead => lead.id === updatedLead.id;

  return {
    ...state,
    isFetching: { ...state.isFetching, updateOrganisationLeadAndPostComment: false },
    leads: findAndReplace(isSameLead, updatedLead)(state.leads),
  };
};

const updateOrganisationLeadAndPostCommentFailure = (state, { errors }) => ({
  ...state,
  isFetching: { ...state.isFetching, updateOrganisationLeadAndPostComment: false },
  errors: {
    ...state.errors,
    updateOrganisationLeadAndPostComment: errors,
  },
});

// update for families
const updateFamilyLeadRequest = state => ({
  ...state,
  isFetching: { ...state.isFetching, updateFamilyLead: true },
  errors: {
    ...state.errors,
    updateFamilyLead: INITIAL_STATE.errors.updateFamilyLead,
  },
});

const updateFamilyLeadSuccess = (state, { lead: updatedLead }) => {
  const isSameLead = lead => lead.id === updatedLead.id;
  let remove = false;
  const oldLead = state.leads?.find(lead => lead.id === updatedLead.id);
  if (oldLead && oldLead.archived !== updatedLead.archived) remove = true;
  return {
    ...state,
    isFetching: { ...state.isFetching, updateFamilyLead: false },
    leads: remove
      ? findAndRemove(isSameLead, updatedLead)(state.leads)
      : findAndReplace(isSameLead, updatedLead)(state.leads),
  };
};

const updateFamilyLeadFailure = (state, { errors }) => ({
  ...state,
  isFetching: { ...state.isFetching, updateFamilyLead: false },
  errors: {
    ...state.errors,
    updateFamilyLead: errors,
  },
});

// resetLeads
const resetLeads = () => INITIAL_STATE;

const resetLead = state => {
  return {
    ...state,
    lead: INITIAL_STATE.lead,
  };
};

const addLead = (state, { lead }) => {
  let leads = state.leads?.slice();
  if (leads) {
    leads.pop();
    leads.unshift(lead);
  } else leads = [lead];
  return {
    ...state,
    leads,
    totalleads: state.totalleads + 1,
  };
};

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.CREATE_QUALIFIED_LEAD_REQUEST]: createQualifiedLeadRequest,
  [Types.CREATE_QUALIFIED_LEAD_SUCCESS]: createQualifiedLeadSuccess,
  [Types.CREATE_QUALIFIED_LEAD_FAILURE]: createQualifiedLeadFailure,

  [Types.CREATE_SHARED_LEAD_REQUEST]: createSharedLeadRequest,
  [Types.CREATE_SHARED_LEAD_SUCCESS]: createSharedLeadSuccess,
  [Types.CREATE_SHARED_LEAD_FAILURE]: createSharedLeadFailure,

  [Types.CREATE_NON_QUALIFIED_LEAD_REQUEST]: createNonQualifiedLeadRequest,
  [Types.CREATE_NON_QUALIFIED_LEAD_SUCCESS]: createNonQualifiedLeadSuccess,
  [Types.CREATE_NON_QUALIFIED_LEAD_FAILURE]: createNonQualifiedLeadFailure,

  [Types.GET_LEAD_REQUEST]: getLeadRequest,
  [Types.GET_LEAD_SUCCESS]: getLeadSuccess,
  [Types.GET_LEAD_FAILURE]: getLeadFailure,

  [Types.GET_ORGANISATION_LEADS_REQUEST]: getOrganisationLeadsRequest,
  [Types.GET_ORGANISATION_LEADS_SUCCESS]: getOrganisationLeadsSuccess,
  [Types.GET_ORGANISATION_LEADS_FAILURE]: getOrganisationLeadsFailure,

  [Types.GET_ORGANISATION_ACCEPTED_LEADS_RANGE_REQUEST]: getOrganisationAcceptedLeadsRangeRequest,
  [Types.GET_ORGANISATION_ACCEPTED_LEADS_RANGE_SUCCESS]: getOrganisationAcceptedLeadsRangeSuccess,
  [Types.GET_ORGANISATION_ACCEPTED_LEADS_RANGE_FAILURE]: getOrganisationAcceptedLeadsRangeFailure,

  [Types.GET_ORGANISATION_LEADS_BY_TYPE_REQUEST]: getOrganisationLeadsByTypeRequest,
  [Types.GET_ORGANISATION_LEADS_BY_TYPE_SUCCESS]: getOrganisationLeadsByTypeSuccess,
  [Types.GET_ORGANISATION_LEADS_BY_TYPE_FAILURE]: getOrganisationLeadsByTypeFailure,

  [Types.GET_ORGANISATION_LEADS_BY_DAY_REQUEST]: getOrganisationLeadsByDayRequest,
  [Types.GET_ORGANISATION_LEADS_BY_DAY_SUCCESS]: getOrganisationLeadsByDaySuccess,
  [Types.GET_ORGANISATION_LEADS_BY_DAY_FAILURE]: getOrganisationLeadsByDayFailure,

  [Types.GET_ORGANISATION_LEADS_BY_WEEK_REQUEST]: getOrganisationLeadsByWeekRequest,
  [Types.GET_ORGANISATION_LEADS_BY_WEEK_SUCCESS]: getOrganisationLeadsByWeekSuccess,
  [Types.GET_ORGANISATION_LEADS_BY_WEEK_FAILURE]: getOrganisationLeadsByWeekFailure,

  [Types.GET_ORGANISATION_LEADS_BY_MONTH_REQUEST]: getOrganisationLeadsByMonthRequest,
  [Types.GET_ORGANISATION_LEADS_BY_MONTH_SUCCESS]: getOrganisationLeadsByMonthSuccess,
  [Types.GET_ORGANISATION_LEADS_BY_MONTH_FAILURE]: getOrganisationLeadsByMonthFailure,

  [Types.GET_ORGANISATION_TOTAL_HELPERS_REQUEST]: getOrganisationTotalHelpersRequest,
  [Types.GET_ORGANISATION_TOTAL_HELPERS_SUCCESS]: getOrganisationTotalHelpersSuccess,
  [Types.GET_ORGANISATION_TOTAL_HELPERS_FAILURE]: getOrganisationTotalHelpersFailure,

  [Types.GET_ORGANISATION_TOP_HELPERS_REQUEST]: getOrganisationTopHelpersRequest,
  [Types.GET_ORGANISATION_TOP_HELPERS_SUCCESS]: getOrganisationTopHelpersSuccess,
  [Types.GET_ORGANISATION_TOP_HELPERS_FAILURE]: getOrganisationTopHelpersFailure,

  [Types.GET_ORGANISATION_HELPERS_REQUEST]: getOrganisationHelpersRequest,
  [Types.GET_ORGANISATION_HELPERS_SUCCESS]: getOrganisationHelpersSuccess,
  [Types.GET_ORGANISATION_HELPERS_FAILURE]: getOrganisationHelpersFailure,

  [Types.GET_HOLDING_LEADS_REQUEST]: getHoldingLeadsRequest,
  [Types.GET_HOLDING_LEADS_SUCCESS]: getHoldingLeadsSuccess,
  [Types.GET_HOLDING_LEADS_FAILURE]: getHoldingLeadsFailure,

  [Types.GET_LEAGUE_LEADS_REQUEST]: getLeagueLeadsRequest,
  [Types.GET_LEAGUE_LEADS_SUCCESS]: getLeagueLeadsSuccess,
  [Types.GET_LEAGUE_LEADS_FAILURE]: getLeagueLeadsFailure,

  [Types.GET_LEAGUE_LEAD_REQUEST]: getLeagueLeadRequest,
  [Types.GET_LEAGUE_LEAD_SUCCESS]: getLeagueLeadSuccess,
  [Types.GET_LEAGUE_LEAD_FAILURE]: getLeagueLeadFailure,

  [Types.CREATE_LEAGUE_LEAD_REQUEST]: createLeagueLeadRequest,
  [Types.CREATE_LEAGUE_LEAD_SUCCESS]: createLeagueLeadSuccess,
  [Types.CREATE_LEAGUE_LEAD_FAILURE]: createLeagueLeadFailure,

  [Types.UPDATE_LEAGUE_LEAD_REQUEST]: updateLeagueLeadRequest,
  [Types.UPDATE_LEAGUE_LEAD_SUCCESS]: updateLeagueLeadSuccess,
  [Types.UPDATE_LEAGUE_LEAD_FAILURE]: updateLeagueLeadFailure,

  [Types.TRANSFER_LEAGUE_LEAD_REQUEST]: transferLeagueLeadRequest,
  [Types.TRANSFER_LEAGUE_LEAD_SUCCESS]: transferLeagueLeadSuccess,
  [Types.TRANSFER_LEAGUE_LEAD_FAILURE]: transferLeagueLeadFailure,

  [Types.GET_FAMILY_LEADS_REQUEST]: getFamilyLeadsRequest,
  [Types.GET_FAMILY_LEADS_SUCCESS]: getFamilyLeadsSuccess,
  [Types.GET_FAMILY_LEADS_FAILURE]: getFamilyLeadsFailure,

  [Types.GET_FAMILY_LEAD_REQUEST]: getFamilyLeadRequest,
  [Types.GET_FAMILY_LEAD_SUCCESS]: getFamilyLeadSuccess,
  [Types.GET_FAMILY_LEAD_FAILURE]: getFamilyLeadFailure,

  [Types.UPDATE_ORGANISATION_LEAD_REQUEST]: updateOrganisationLeadRequest,
  [Types.UPDATE_ORGANISATION_LEAD_SUCCESS]: updateOrganisationLeadSuccess,
  [Types.UPDATE_ORGANISATION_LEAD_FAILURE]: updateOrganisationLeadFailure,

  [Types.UPDATE_FAMILY_LEAD_REQUEST]: updateFamilyLeadRequest,
  [Types.UPDATE_FAMILY_LEAD_SUCCESS]: updateFamilyLeadSuccess,
  [Types.UPDATE_FAMILY_LEAD_FAILURE]: updateFamilyLeadFailure,

  [Types.CREATE_LEAD_POST_REQUEST]: createLeadPostRequest,
  [Types.CREATE_LEAD_POST_SUCCESS]: createLeadPostSuccess,
  [Types.CREATE_LEAD_POST_FAILURE]: createLeadPostFailure,

  [Types.UPDATE_ORGANISATION_LEAD_AND_POST_COMMENT_REQUEST]:
    updateOrganisationLeadAndPostCommentRequest,
  [Types.UPDATE_ORGANISATION_LEAD_AND_POST_COMMENT_SUCCESS]:
    updateOrganisationLeadAndPostCommentSuccess,
  [Types.UPDATE_ORGANISATION_LEAD_AND_POST_COMMENT_FAILURE]:
    updateOrganisationLeadAndPostCommentFailure,

  [Types.RESET_LEADS]: resetLeads,
  [Types.RESET_LEAD]: resetLead,
  [Types.ADD_LEAD]: addLead,
});
