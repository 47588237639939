import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';
import findAndReplace from './ReduxHelpers';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  getOrganisationTransmissionsRequest: ['holdingSlug', 'organisationSlug', 'helperOrgaId'],
  getOrganisationTransmissionsSuccess: ['transmissions'],
  getOrganisationTransmissionsFailure: ['errors'],

  getLeagueTransmissionsRequest: ['leagueId', 'helperId'],
  getLeagueTransmissionsSuccess: ['transmissions'],
  getLeagueTransmissionsFailure: ['errors'],

  createOrganisationTransmissionRequest: [
    'holdingSlug',
    'organisationSlug',
    'helperOrgaId',
    'transmissionAttributes',
    'attachments',
  ],
  createOrganisationTransmissionSuccess: ['transmission'],
  createOrganisationTransmissionFailure: ['errors'],

  createLeagueTransmissionRequest: ['leagueId', 'helperId', 'transmissionAttributes'],
  createLeagueTransmissionSuccess: ['transmission'],
  createLeagueTransmissionFailure: ['errors'],

  updateOrganisationTransmissionRequest: [
    'holdingSlug',
    'organisationSlug',
    'helperOrgaId',
    'transmissionId',
    'transmissionAttributes',
    'attachments',
  ],
  updateOrganisationTransmissionSuccess: ['transmission'],
  updateOrganisationTransmissionFailure: ['errors'],

  updateLeagueTransmissionRequest: [
    'leagueId',
    'helperId',
    'transmissionId',
    'transmissionAttributes',
  ],
  updateLeagueTransmissionSuccess: ['transmission'],
  updateLeagueTransmissionFailure: ['errors'],

  destroyOrganisationTransmissionRequest: [
    'holdingSlug',
    'organisationSlug',
    'helperOrgaId',
    'transmissionId',
  ],
  destroyOrganisationTransmissionSuccess: ['transmissionId'],
  destroyOrganisationTransmissionFailure: ['errors'],

  destroyLeagueTransmissionRequest: ['leagueId', 'helperId', 'transmissionId'],
  destroyLeagueTransmissionSuccess: ['transmissionId'],
  destroyLeagueTransmissionFailure: ['errors'],

  createOrganisationTransmissionPostRequest: [
    'holdingSlug',
    'organisationSlug',
    'postableId',
    'post',
    'attachments',
  ],
  createOrganisationTransmissionPostSuccess: ['transmissionId', 'post'],
  createOrganisationTransmissionPostFailure: ['errors'],

  createLeagueTransmissionPostRequest: ['leagueId', 'postableId', 'post', 'attachments'],
  createLeagueTransmissionPostSuccess: ['transmissionId', 'post'],
  createLeagueTransmissionPostFailure: ['errors'],

  updateOrganisationTransmissionPostRequest: [
    'holdingSlug',
    'organisationSlug',
    'postableId',
    'post',
    'attachments',
  ],
  updateOrganisationTransmissionPostSuccess: ['transmissionId', 'postId', 'post'],
  updateOrganisationTransmissionPostFailure: ['errors'],

  updateLeagueTransmissionPostRequest: ['leagueId', 'postableId', 'post', 'attachments'],
  updateLeagueTransmissionPostSuccess: ['transmissionId', 'postId', 'post'],
  updateLeagueTransmissionPostFailure: ['errors'],

  destroyOrganisationTransmissionPostRequest: [
    'holdingSlug',
    'organisationSlug',
    'postableId',
    'postId',
  ],
  destroyOrganisationTransmissionPostSuccess: ['transmissionId', 'postId'],
  destroyOrganisationTransmissionPostFailure: ['errors'],

  destroyLeagueTransmissionPostRequest: ['leagueId', 'postableId', 'postId'],
  destroyLeagueTransmissionPostSuccess: ['transmissionId', 'postId'],
  destroyLeagueTransmissionPostFailure: ['errors'],

  resetTransmissions: [],
});

export const TransmissionsTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  isFetching: {
    getOrganisationTransmissions: false,
    getLeagueTransmissions: false,
    createOrganisationTransmission: false,
    createLeagueTransmission: false,
    getTransmission: false,
    updateOrganisationTransmission: false,
    updateLeagueTransmission: false,
    deleteTransmission: false,
    getTransmissionPosts: false,
    createOrganisationTransmissionPost: false,
    createLeagueTransmissionPost: false,
    updateOrganisationTransmissionPost: false,
    updateLeagueTransmissionPost: false,
    deleteOrganisationTransmissionPost: false,
    destroyLeagueTransmissionPost: false,
  },
  transmissions: null,
  transmission: null,
  errors: {
    getOrganisationTransmissions: null,
    getLeagueTransmissions: null,
    createOrganisationTransmission: null,
    createLeagueTransmission: null,
    getTransmission: null,
    updateOrganisationTransmission: null,
    updateLeagueTransmission: null,
    deleteTransmission: null,
    getTransmissionPosts: null,
    createOrganisationTransmissionPost: null,
    createLeagueTransmissionPost: null,
    updateOrganisationTransmissionPost: null,
    updateLeagueTransmissionPost: null,
    deleteOrganisationTransmissionPost: null,
    destroyLeagueTransmissionPost: null,
  },
});

/* ------------- Reducers ------------- */

//
// get orga transmissions
//

const getOrganisationTransmissionsRequest = state => ({
  ...state,
  isFetching: { ...state.isFetching, getOrganisationTransmissions: true },
  errors: INITIAL_STATE.errors,
});

const getOrganisationTransmissionsSuccess = (state, { transmissions }) => ({
  ...state,
  isFetching: { ...state.isFetching, getOrganisationTransmissions: false },
  transmissions,
});

const getOrganisationTransmissionsFailure = (state, { errors }) => ({
  ...state,
  isFetching: { ...state.isFetching, getOrganisationTransmissions: false },
  transmissions: [],
  errors: { ...state.errors, getOrganisationTransmissions: errors || true },
});

//
// get league transmissions
//

const getLeagueTransmissionsRequest = state => ({
  ...state,
  isFetching: { ...state.isFetching, getLeagueTransmissions: true },
  errors: INITIAL_STATE.errors,
});

const getLeagueTransmissionsSuccess = (state, { transmissions }) => ({
  ...state,
  isFetching: { ...state.isFetching, getLeagueTransmissions: false },
  transmissions,
});

const getLeagueTransmissionsFailure = (state, { errors }) => ({
  ...state,
  isFetching: { ...state.isFetching, getLeagueTransmissions: false },
  transmissions: [],
  errors: { ...state.errors, getLeagueTransmissions: errors || true },
});

//
// create orga transmissions
//

const createOrganisationTransmissionRequest = state => ({
  ...state,
  isFetching: { ...state.isFetching, createOrganisationTransmission: true },
  errors: INITIAL_STATE.errors,
  transmission: null,
});

const createOrganisationTransmissionSuccess = (state, { transmission }) => {
  const newTransmissions = state.transmissions?.slice() || [];
  newTransmissions.unshift(transmission);

  return {
    ...state,
    isFetching: { ...state.isFetching, createOrganisationTransmission: false },
    transmissions: newTransmissions,
    transmission,
  };
};

const createOrganisationTransmissionFailure = (state, { errors }) => ({
  ...state,
  isFetching: { ...state.isFetching, createOrganisationTransmission: false },
  errors: { ...state.errors, createOrganisationTransmission: errors || true },
  transmission: null,
});

//
// create league transmissions
//

const createLeagueTransmissionRequest = state => ({
  ...state,
  isFetching: { ...state.isFetching, createLeagueTransmission: true },
  errors: INITIAL_STATE.errors,
  transmission: null,
});

const createLeagueTransmissionSuccess = (state, { transmission }) => {
  const newTransmissions = state.transmissions?.slice() || [];
  newTransmissions.unshift(transmission);

  return {
    ...state,
    isFetching: { ...state.isFetching, createLeagueTransmission: false },
    transmissions: newTransmissions,
    transmission,
  };
};

const createLeagueTransmissionFailure = (state, { errors }) => ({
  ...state,
  isFetching: { ...state.isFetching, createLeagueTransmission: false },
  errors: { ...state.errors, createLeagueTransmission: errors || true },
  transmission: null,
});

//
// update orga transmissions
//

const updateOrganisationTransmissionRequest = state => ({
  ...state,
  isFetching: { ...state.isFetching, updateOrganisationTransmission: true },
  errors: INITIAL_STATE.errors,
});

const updateOrganisationTransmissionSuccess = (state, { transmission }) => {
  let updateOrganisationTransmissions = state.transmissions?.slice();

  updateOrganisationTransmissions = findAndReplace(
    t => t.id === transmission.id,
    transmission,
  )(state.transmissions);

  return {
    ...state,
    isFetching: { ...state.isFetching, updateOrganisationTransmission: false },
    transmissions: updateOrganisationTransmissions,
  };
};

const updateOrganisationTransmissionFailure = (state, { errors }) => ({
  ...state,
  isFetching: { ...state.isFetching, updateOrganisationTransmission: false },
  errors: { ...state.errors, updateOrganisationTransmission: errors || true },
});

//
// update league transmissions
//

const updateLeagueTransmissionRequest = state => ({
  ...state,
  isFetching: { ...state.isFetching, updateLeagueTransmission: true },
  errors: INITIAL_STATE.errors,
});

const updateLeagueTransmissionSuccess = (state, { transmission }) => {
  let updateLeagueTransmissions = state.transmissions?.slice();

  updateLeagueTransmissions = findAndReplace(
    t => t.id === transmission.id,
    transmission,
  )(state.transmissions);

  return {
    ...state,
    isFetching: { ...state.isFetching, updateLeagueTransmission: false },
    transmissions: updateLeagueTransmissions,
  };
};

const updateLeagueTransmissionFailure = (state, { errors }) => ({
  ...state,
  isFetching: { ...state.isFetching, updateLeagueTransmission: false },
  errors: { ...state.errors, updateLeagueTransmission: errors || true },
});

//
// destroy transmissions
//

const destroyOrganisationTransmissionRequest = state => ({
  ...state,
  isFetching: { ...state.isFetching, destroyOrganisationTransmission: true },
  errors: INITIAL_STATE.errors,
});

const destroyOrganisationTransmissionSuccess = (state, { transmissionId }) => {
  const transmissions = state.transmissions?.slice()?.filter(t => t.id !== transmissionId);

  return {
    ...state,
    isFetching: { ...state.isFetching, destroyOrganisationTransmission: false },
    transmissions,
  };
};

const destroyOrganisationTransmissionFailure = (state, { errors }) => ({
  ...state,
  isFetching: { ...state.isFetching, destroyOrganisationTransmission: false },
  errors: { ...state.errors, destroyOrganisationTransmission: errors || true },
});

//
// destroy transmissions
//

const destroyLeagueTransmissionRequest = state => ({
  ...state,
  isFetching: { ...state.isFetching, destroyLeagueTransmission: true },
  errors: INITIAL_STATE.errors,
});

const destroyLeagueTransmissionSuccess = (state, { transmissionId }) => {
  const transmissions = state.transmissions?.slice()?.filter(t => t.id !== transmissionId);

  return {
    ...state,
    isFetching: { ...state.isFetching, destroyLeagueTransmission: false },
    transmissions,
  };
};

const destroyLeagueTransmissionFailure = (state, { errors }) => ({
  ...state,
  isFetching: { ...state.isFetching, destroyLeagueTransmission: false },
  errors: { ...state.errors, destroyLeagueTransmission: errors || true },
});

//
// create orga transmission post
//

const createOrganisationTransmissionPostRequest = state => ({
  ...state,
  isFetching: { ...state.isFetching, createOrganisationTransmissionPost: true },
  errors: INITIAL_STATE.errors,
});

const createOrganisationTransmissionPostSuccess = (state, { transmissionId, post }) => {
  const transmission = state.transmissions?.find(t => t.id === transmissionId);
  if (transmission && transmission.posts) {
    const newPosts = transmission.posts.slice();
    newPosts.unshift(post);
    transmission.posts = newPosts;
  } else if (transmission) {
    transmission.posts = [post];
  }

  return {
    ...state,
    isFetching: { ...state.isFetching, createOrganisationTransmissionPost: false },
    transmissions: findAndReplace(t => t.id === transmissionId, transmission)(state.transmissions),
  };
};

const createOrganisationTransmissionPostFailure = (state, { errors }) => ({
  ...state,
  isFetching: { ...state.isFetching, createOrganisationTransmissionPost: false },
  errors: { ...state.errors, createOrganisationTransmissionPost: errors || true },
});

//
// create league transmission post
//

const createLeagueTransmissionPostRequest = state => ({
  ...state,
  isFetching: { ...state.isFetching, createLeagueTransmissionPost: true },
  errors: INITIAL_STATE.errors,
});

const createLeagueTransmissionPostSuccess = (state, { transmissionId, post }) => {
  const transmission = state.transmissions?.find(t => t.id === transmissionId);
  if (transmission && transmission.posts) {
    const newPosts = transmission.posts.slice();
    newPosts.unshift(post);
    transmission.posts = newPosts;
  } else if (transmission) {
    transmission.posts = [post];
  }

  return {
    ...state,
    isFetching: { ...state.isFetching, createLeagueTransmissionPost: false },
    transmissions: findAndReplace(t => t.id === transmissionId, transmission)(state.transmissions),
  };
};

const createLeagueTransmissionPostFailure = (state, { errors }) => ({
  ...state,
  isFetching: { ...state.isFetching, createLeagueTransmissionPost: false },
  errors: { ...state.errors, createLeagueTransmissionPost: errors || true },
});

//
// update orga transmission post
//

const updateOrganisationTransmissionPostRequest = state => ({
  ...state,
  isFetching: { ...state.isFetching, updateOrganisationTransmissionPost: true },
  errors: INITIAL_STATE.errors,
});

const updateOrganisationTransmissionPostSuccess = (state, { transmissionId, postId, post }) => {
  const transmission = state.transmissions?.find(t => t.id === transmissionId);
  transmission.posts = findAndReplace(p => p.id === postId, post)(transmission.posts);

  return {
    ...state,
    isFetching: { ...state.isFetching, updateOrganisationTransmissionPost: false },
    transmissions: findAndReplace(t => t.id === transmissionId, transmission)(state.transmissions),
  };
};

const updateOrganisationTransmissionPostFailure = (state, { errors }) => ({
  ...state,
  isFetching: { ...state.isFetching, updateOrganisationTransmissionPost: false },
  errors: { ...state.errors, updateOrganisationTransmissionPost: errors || true },
});

//
// update league transmission post
//

const updateLeagueTransmissionPostRequest = state => ({
  ...state,
  isFetching: { ...state.isFetching, updateLeagueTransmissionPost: true },
  errors: INITIAL_STATE.errors,
});

const updateLeagueTransmissionPostSuccess = (state, { transmissionId, postId, post }) => {
  const transmission = state.transmissions?.find(t => t.id === transmissionId);
  transmission.posts = findAndReplace(p => p.id === postId, post)(transmission.posts);

  return {
    ...state,
    isFetching: { ...state.isFetching, updateLeagueTransmissionPost: false },
    transmissions: findAndReplace(t => t.id === transmissionId, transmission)(state.transmissions),
  };
};

const updateLeagueTransmissionPostFailure = (state, { errors }) => ({
  ...state,
  isFetching: { ...state.isFetching, updateLeagueTransmissionPost: false },
  errors: { ...state.errors, updateLeagueTransmissionPost: errors || true },
});

//
// destroy orga transmission post
//

const destroyOrganisationTransmissionPostRequest = state => ({
  ...state,
  isFetching: { ...state.isFetching, destroyOrganisationTransmissionPost: true },
  errors: INITIAL_STATE.errors,
});

const destroyOrganisationTransmissionPostSuccess = (state, { transmissionId, postId }) => {
  const transmission = state.transmissions?.find(t => t.id === transmissionId);
  if (transmission?.posts) {
    const newPosts = transmission.posts.slice()?.filter(p => p.id !== postId);
    transmission.posts = newPosts;
  }

  return {
    ...state,
    isFetching: { ...state.isFetching, destroyOrganisationTransmissionPost: false },
    transmissions: findAndReplace(t => t.id === transmissionId, transmission)(state.transmissions),
  };
};

const destroyOrganisationTransmissionPostFailure = (state, { errors }) => ({
  ...state,
  isFetching: { ...state.isFetching, destroyOrganisationTransmissionPost: false },
  errors: { ...state.errors, destroyOrganisationTransmissionPost: errors || true },
});

//
// destroy league transmission post
//

const destroyLeagueTransmissionPostRequest = state => ({
  ...state,
  isFetching: { ...state.isFetching, destroyLeagueTransmissionPost: true },
  errors: INITIAL_STATE.errors,
});

const destroyLeagueTransmissionPostSuccess = (state, { transmissionId, postId }) => {
  const transmission = state.transmissions?.find(t => t.id === transmissionId);
  if (transmission?.posts) {
    const newPosts = transmission.posts.slice()?.filter(p => p.id !== postId);
    transmission.posts = newPosts;
  }

  return {
    ...state,
    isFetching: { ...state.isFetching, destroyLeagueTransmissionPost: false },
    transmissions: findAndReplace(t => t.id === transmissionId, transmission)(state.transmissions),
  };
};

const destroyLeagueTransmissionPostFailure = (state, { errors }) => ({
  ...state,
  isFetching: { ...state.isFetching, destroyLeagueTransmissionPost: false },
  errors: { ...state.errors, destroyLeagueTransmissionPost: errors || true },
});

const resetTransmissions = () => INITIAL_STATE;

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_ORGANISATION_TRANSMISSIONS_REQUEST]: getOrganisationTransmissionsRequest,
  [Types.GET_ORGANISATION_TRANSMISSIONS_SUCCESS]: getOrganisationTransmissionsSuccess,
  [Types.GET_ORGANISATION_TRANSMISSIONS_FAILURE]: getOrganisationTransmissionsFailure,

  [Types.GET_LEAGUE_TRANSMISSIONS_REQUEST]: getLeagueTransmissionsRequest,
  [Types.GET_LEAGUE_TRANSMISSIONS_SUCCESS]: getLeagueTransmissionsSuccess,
  [Types.GET_LEAGUE_TRANSMISSIONS_FAILURE]: getLeagueTransmissionsFailure,

  [Types.CREATE_ORGANISATION_TRANSMISSION_REQUEST]: createOrganisationTransmissionRequest,
  [Types.CREATE_ORGANISATION_TRANSMISSION_SUCCESS]: createOrganisationTransmissionSuccess,
  [Types.CREATE_ORGANISATION_TRANSMISSION_FAILURE]: createOrganisationTransmissionFailure,

  [Types.CREATE_LEAGUE_TRANSMISSION_REQUEST]: createLeagueTransmissionRequest,
  [Types.CREATE_LEAGUE_TRANSMISSION_SUCCESS]: createLeagueTransmissionSuccess,
  [Types.CREATE_LEAGUE_TRANSMISSION_FAILURE]: createLeagueTransmissionFailure,

  [Types.UPDATE_ORGANISATION_TRANSMISSION_REQUEST]: updateOrganisationTransmissionRequest,
  [Types.UPDATE_ORGANISATION_TRANSMISSION_SUCCESS]: updateOrganisationTransmissionSuccess,
  [Types.UPDATE_ORGANISATION_TRANSMISSION_FAILURE]: updateOrganisationTransmissionFailure,

  [Types.UPDATE_LEAGUE_TRANSMISSION_REQUEST]: updateLeagueTransmissionRequest,
  [Types.UPDATE_LEAGUE_TRANSMISSION_SUCCESS]: updateLeagueTransmissionSuccess,
  [Types.UPDATE_LEAGUE_TRANSMISSION_FAILURE]: updateLeagueTransmissionFailure,

  [Types.DESTROY_ORGANISATION_TRANSMISSION_REQUEST]: destroyOrganisationTransmissionRequest,
  [Types.DESTROY_ORGANISATION_TRANSMISSION_SUCCESS]: destroyOrganisationTransmissionSuccess,
  [Types.DESTROY_ORGANISATION_TRANSMISSION_FAILURE]: destroyOrganisationTransmissionFailure,

  [Types.DESTROY_LEAGUE_TRANSMISSION_REQUEST]: destroyLeagueTransmissionRequest,
  [Types.DESTROY_LEAGUE_TRANSMISSION_SUCCESS]: destroyLeagueTransmissionSuccess,
  [Types.DESTROY_LEAGUE_TRANSMISSION_FAILURE]: destroyLeagueTransmissionFailure,

  [Types.CREATE_ORGANISATION_TRANSMISSION_POST_REQUEST]: createOrganisationTransmissionPostRequest,
  [Types.CREATE_ORGANISATION_TRANSMISSION_POST_SUCCESS]: createOrganisationTransmissionPostSuccess,
  [Types.CREATE_ORGANISATION_TRANSMISSION_POST_FAILURE]: createOrganisationTransmissionPostFailure,

  [Types.CREATE_LEAGUE_TRANSMISSION_POST_REQUEST]: createLeagueTransmissionPostRequest,
  [Types.CREATE_LEAGUE_TRANSMISSION_POST_SUCCESS]: createLeagueTransmissionPostSuccess,
  [Types.CREATE_LEAGUE_TRANSMISSION_POST_FAILURE]: createLeagueTransmissionPostFailure,

  [Types.UPDATE_ORGANISATION_TRANSMISSION_POST_REQUEST]: updateOrganisationTransmissionPostRequest,
  [Types.UPDATE_ORGANISATION_TRANSMISSION_POST_SUCCESS]: updateOrganisationTransmissionPostSuccess,
  [Types.UPDATE_ORGANISATION_TRANSMISSION_POST_FAILURE]: updateOrganisationTransmissionPostFailure,

  [Types.UPDATE_LEAGUE_TRANSMISSION_POST_REQUEST]: updateLeagueTransmissionPostRequest,
  [Types.UPDATE_LEAGUE_TRANSMISSION_POST_SUCCESS]: updateLeagueTransmissionPostSuccess,
  [Types.UPDATE_LEAGUE_TRANSMISSION_POST_FAILURE]: updateLeagueTransmissionPostFailure,

  [Types.DESTROY_ORGANISATION_TRANSMISSION_POST_REQUEST]:
    destroyOrganisationTransmissionPostRequest,
  [Types.DESTROY_ORGANISATION_TRANSMISSION_POST_SUCCESS]:
    destroyOrganisationTransmissionPostSuccess,
  [Types.DESTROY_ORGANISATION_TRANSMISSION_POST_FAILURE]:
    destroyOrganisationTransmissionPostFailure,

  [Types.DESTROY_LEAGUE_TRANSMISSION_POST_REQUEST]: destroyLeagueTransmissionPostRequest,
  [Types.DESTROY_LEAGUE_TRANSMISSION_POST_SUCCESS]: destroyLeagueTransmissionPostSuccess,
  [Types.DESTROY_LEAGUE_TRANSMISSION_POST_FAILURE]: destroyLeagueTransmissionPostFailure,

  [Types.RESET_TRANSMISSIONS]: resetTransmissions,
});
