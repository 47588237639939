import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';
import findAndReplace from './ReduxHelpers';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  getOrganisationMembershipRequest: ['holdingSlug', 'organisationSlug', 'role'],
  getOrganisationMembershipSuccess: ['members'],
  getOrganisationMembershipFailure: ['errors'],

  updateOrganisationMembershipRequest: [
    'holdingSlug',
    'organisationSlug',
    'membershipHelperId',
    'updateAttributes',
    'currentUserId',
    'updateType',
  ],
  updateOrganisationMembershipSuccess: ['membership'],
  updateOrganisationMembershipFailure: ['errors'],

  destroyOrganisationMembershipRequest: [
    'holdingSlug',
    'organisationSlug',
    'membershipHelperId',
    'membershipId',
  ],
  destroyOrganisationMembershipSuccess: ['membership'],
  destroyOrganisationMembershipFailure: ['errors'],

  createOrganisationMembershipRequest: [
    'holdingSlug',
    'organisationSlug',
    'membershipAttributes',
    'helperAttributes',
  ],
  createOrganisationMembershipSuccess: ['member'],
  createOrganisationMembershipFailure: ['error'],

  resendInvitationRequest: ['holdingSlug', 'organisationSlug', 'helperId'],
  resendInvitationSuccess: ['member'],
  resendInvitationFailure: ['error'],

  resetNewMembership: [],
  resetOrganisationMembership: [],
});

export const OrganisationMembershipTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  isFetching: {
    getOrganisationMembership: false,
    updateOrganisationMembership: false,
    destroyOrganisationMembership: false,
    createOrganisationMembership: false,
    resendInvitation: false,
  },
  errors: {
    getOrganisationMembership: null,
    updateOrganisationMembership: null,
    destroyOrganisationMembership: null,
    createOrganisationMembership: null,
    resendInvitation: null,
  },
  members: null,
  newMembership: null,
  updateMembership: null,
});

/* ------------- Reducers ------------- */
// -
// getOrganisationMembership
// -
const getOrganisationMembershipRequest = state => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    getOrganisationMembership: true,
  },
});

const getOrganisationMembershipSuccess = (state, { members }) => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    getOrganisationMembership: false,
  },
  members,
  errors: {
    ...state.errors,
    getOrganisationMembership: INITIAL_STATE.errors.getOrganisationMembership,
  },
});

const getOrganisationMembershipFailure = (state, { errors }) => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    getOrganisationMembership: false,
  },
  errors: {
    ...state.errors,
    getOrganisationMembership: errors,
  },
});

// -
// updateOrganisationMemberships
// -
const updateOrganisationMembershipRequest = state => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    updateOrganisationMembership: true,
  },
  updateMembership: INITIAL_STATE.updateMembership,
});

const updateOrganisationMembershipSuccess = (state, { membership }) => {
  const newMembers = findAndReplace(
    member => member.id === membership.id,
    membership,
  )(state.members);
  return {
    ...state,
    isFetching: {
      ...state.isFetching,
      updateOrganisationMembership: false,
    },
    errors: {
      ...state.errors,
      getOrganisationMembership: INITIAL_STATE.errors.updateOrganisationMembership,
    },
    members: newMembers,
    updateMembership: membership,
  };
};

const updateOrganisationMembershipFailure = (state, { errors }) => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    updateOrganisationMembership: false,
  },
  errors: {
    ...state.errors,
    updateOrganisationMembership: errors,
  },
  updateMembership: INITIAL_STATE.updateMembership,
});

// -
// destroyOrganisationMembership
// -
const destroyOrganisationMembershipRequest = state => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    destroyOrganisationMembership: true,
  },
});
const destroyOrganisationMembershipSuccess = (state, { membership }) => {
  const memberships = state.members?.slice()?.filter(m => m.id !== membership.id);

  return {
    ...state,
    isFetching: {
      ...state.isFetching,
      destroyOrganisationMembership: false,
    },
    members: memberships,
  };
};
const destroyOrganisationMembershipFailure = (state, { errors }) => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    destroyOrganisationMembership: false,
  },
  errors: {
    ...state.errors,
    destroyOrganisationMembership: errors,
  },
});

const createOrganisationMembershipRequest = state => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    createOrganisationMembership: true,
  },
  newMembership: INITIAL_STATE.newMembership,
});
const createOrganisationMembershipSuccess = (state, { member }) => {
  const newMembers = state.members?.slice() || [];
  newMembers.push(member);
  return {
    ...state,
    isFetching: {
      ...state.isFetching,
      createOrganisationMembership: false,
    },
    members: newMembers,
    newMembership: member,
  };
};
const createOrganisationMembershipFailure = (state, { errors }) => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    createOrganisationMembership: false,
  },
  errors: {
    ...state.errors,
    createOrganisationMembership: errors,
  },
  newMembership: INITIAL_STATE.newMembership,
});

const resendInvitationRequest = state => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    resendInvitation: true,
  },
});
const resendInvitationSuccess = (state, { member }) => {
  return {
    ...state,
    isFetching: {
      ...state.isFetching,
      resendInvitation: false,
    },
    members: findAndReplace(m => m.id === member.id, member)(state.members),
  };
};
const resendInvitationFailure = (state, { errors }) => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    resendInvitation: false,
  },
  errors: {
    ...state.errors,
    resendInvitation: errors,
  },
});

const resetNewMembership = state => ({
  ...state,
  newMembership: INITIAL_STATE.newMembership,
  updateMembership: INITIAL_STATE.updateMembership,
});

// -
// resetOrganisationMemberships
// -
const resetOrganisationMembership = () => INITIAL_STATE;

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_ORGANISATION_MEMBERSHIP_REQUEST]: getOrganisationMembershipRequest,
  [Types.GET_ORGANISATION_MEMBERSHIP_SUCCESS]: getOrganisationMembershipSuccess,
  [Types.GET_ORGANISATION_MEMBERSHIP_FAILURE]: getOrganisationMembershipFailure,

  [Types.UPDATE_ORGANISATION_MEMBERSHIP_REQUEST]: updateOrganisationMembershipRequest,
  [Types.UPDATE_ORGANISATION_MEMBERSHIP_SUCCESS]: updateOrganisationMembershipSuccess,
  [Types.UPDATE_ORGANISATION_MEMBERSHIP_FAILURE]: updateOrganisationMembershipFailure,

  [Types.DESTROY_ORGANISATION_MEMBERSHIP_REQUEST]: destroyOrganisationMembershipRequest,
  [Types.DESTROY_ORGANISATION_MEMBERSHIP_SUCCESS]: destroyOrganisationMembershipSuccess,
  [Types.DESTROY_ORGANISATION_MEMBERSHIP_FAILURE]: destroyOrganisationMembershipFailure,

  [Types.CREATE_ORGANISATION_MEMBERSHIP_REQUEST]: createOrganisationMembershipRequest,
  [Types.CREATE_ORGANISATION_MEMBERSHIP_SUCCESS]: createOrganisationMembershipSuccess,
  [Types.CREATE_ORGANISATION_MEMBERSHIP_FAILURE]: createOrganisationMembershipFailure,

  [Types.RESEND_INVITATION_REQUEST]: resendInvitationRequest,
  [Types.RESEND_INVITATION_SUCCESS]: resendInvitationSuccess,
  [Types.RESEND_INVITATION_FAILURE]: resendInvitationFailure,

  [Types.RESET_NEW_MEMBERSHIP]: resetNewMembership,
  [Types.RESET_ORGANISATION_MEMBERSHIP]: resetOrganisationMembership,
});
