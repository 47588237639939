import { call, put } from 'redux-saga/effects';

import AuthActions from '../Redux/AuthRedux';
import EmailActions from '../Redux/EmailRedux';
import UsersActions from '../Redux/UsersRedux';
import { toastResponseError } from '../Services/ApiErrorHelpers';
import ToasterActions from '../Redux/ToasterRedux';

export function* checkEmailToken(api, { token }) {
  const response = yield call(api.checkEmailToken, token);
  if (response.ok) {
    yield put(AuthActions.resetIfNewUser(response.data.helper));
    yield put(AuthActions.setAuthTokenRequest(response.data.helper.token));
    yield put(AuthActions.signInSuccess(response.data.helper));
    yield put(UsersActions.getUserSuccess(response.data.helper));
    yield put(EmailActions.checkEmailTokenSuccess(response.data.helper.email_token));
  } else if (response.data && response.data.error) {
    yield put(EmailActions.checkEmailTokenFailure(response.data.error));
  } else {
    toastResponseError(response);
  }
}

export function* sendEmail(api, { email }) {
  const response = yield call(api.sendEmail, email);
  if (response.ok) {
    yield put(ToasterActions.showToaster({ id: 'APP.SEND_EMAIL.SUCCESS' }, 'success', 5000));
  } else if (response.data && response.data.error) {
    yield put(EmailActions.sendEmailFailure(response.data.error));
  } else {
    toastResponseError(response);
  }
}
