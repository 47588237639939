import { call, put } from 'redux-saga/effects';

import _ from 'lodash';
import AuthActions from '../Redux/AuthRedux';
import ToasterActions from '../Redux/ToasterRedux';
import OrganisationActions from '../Redux/OrganisationRedux';
import HoldingsActions from '../Redux/HoldingRedux';
import LeaguesActions from '../Redux/LeaguesRedux';
import ClubsActions from '../Redux/ClubsRedux';
import ArticlesActions from '../Redux/ArticlesRedux';
import DealsActions from '../Redux/DealsRedux';
import LeadsActions from '../Redux/LeadsRedux';
import TasksActions from '../Redux/TasksRedux';
import CarenotebooksActions from '../Redux/CareNotebooksRedux';
import TransmissionsActions from '../Redux/TransmissionsRedux';
import UsersActions from '../Redux/UsersRedux';
import { toastResponseError } from '../Services/ApiErrorHelpers';
import history from '../Routing/CurrentHistory';

export function* setAuthToken(api, { authToken }) {
  const response = yield call(api.setAuthToken, authToken);

  if (response) {
    yield put(AuthActions.setAuthTokenSuccess(authToken));
  } else {
    yield put(AuthActions.setAuthTokenFailure());
  }
}

export function* removeAuthToken(api) {
  yield call(api.removeAuthToken);
}

export function* signIn(api, { loginAttributes }) {
  const response = yield call(api.signIn, loginAttributes);
  if (response.ok) {
    yield put(AuthActions.resetIfNewUser(response.data.helper));
    yield put(AuthActions.setAuthTokenRequest(response.data.helper.token));
    yield put(HoldingsActions.getHoldingsSuccess(response.data.helper.holdings));
    yield put(AuthActions.signInSuccess(response.data.helper));
    yield put(UsersActions.getUserSuccess(response.data.helper));

    const { login_unread_posts_count: unreadMessages } = response.data.helper;
    if (unreadMessages && unreadMessages > 0) {
      yield put(AuthActions.updateHasUnreadObject(true));
    } else {
      yield put(AuthActions.updateHasUnreadObject(false));
    }
  } else {
    yield toastResponseError(response);
    const error = _.get(response, 'data.error');
    yield put(AuthActions.signInFailure(error));
  }
}

export function* resetPassword(api, { emailOrPseudo }) {
  const response = yield call(api.resetPassword, emailOrPseudo);

  if (response.ok) {
    yield put(AuthActions.resetPasswordSuccess());
    if (response.data.success?.message)
      yield put(
        ToasterActions.showToaster({ id: response.data.success?.message }, 'success', 10000),
      );
  } else {
    yield toastResponseError(response);
    const errors = _.get(response, 'data.errors');
    yield put(AuthActions.resetPasswordFailure(errors));
  }
}

export function* changePassword(api, { password, passwordConfirmation, token, oldPassword }) {
  const response = yield call(
    api.changePassword,
    password,
    passwordConfirmation,
    token,
    oldPassword,
  );

  if (response.ok) {
    yield put(AuthActions.changePasswordSuccess());
    yield put(ToasterActions.showToaster({ id: 'WELLO.CHANGE_PASSWORD.SUCCESS' }, 'success', 5000));
    if (token) yield call(history.push, `/signin`);
  } else {
    yield toastResponseError(response);
    const errors = _.get(response, 'data.errors');
    yield put(AuthActions.changePasswordFailure(errors));
  }
}

export function* changePin(api, { pin, helperId }) {
  const response = yield call(api.changePin, pin, helperId);

  if (response.ok) {
    yield put(AuthActions.changePinSuccess());
    yield put(ToasterActions.showToaster({ id: 'WELLO.CHANGE_PIN.SUCCESS' }, 'success', 5000));
  } else {
    yield toastResponseError(response);
    const errors = _.get(response, 'data.errors');
    yield put(AuthActions.changePinFailure(errors));
  }
}

export function* signUpOrganisation(api, { holdingSlug, organisationSlug, user }) {
  const response = yield call(api.signUpOrganisation, holdingSlug, organisationSlug, user);

  if (response.ok) {
    yield put(AuthActions.signUpOrganisationSuccess(response.data.helper));
    yield put(OrganisationActions.getOrganisationRequest(holdingSlug, organisationSlug, 'full'));
  } else {
    yield toastResponseError(response);
    yield put(AuthActions.signUpOrganisationFailure(response.data));
  }
}
export function* signUpInvitation(api, { password, verificationToken, redirectUrl }) {
  const response = yield call(api.signUpInvitation, password, verificationToken);

  if (response.ok) {
    // update token
    yield put(AuthActions.resetIfNewUser(response.data.helper));
    yield put(AuthActions.setAuthTokenRequest(response.data.helper.token));
    // reload organisation, role has changed with email validated
    yield put(OrganisationActions.resetOrganisation());
    // update current user
    yield put(AuthActions.signInSuccess(response.data.helper));
    yield put(AuthActions.signUpInvitationSuccess(response.data.helper));
    yield put(UsersActions.getUserSuccess(response.data.helper));
  } else {
    yield toastResponseError(response);
    yield put(AuthActions.signUpInvitationFailure(response.data.error));
    if (response.data.error?.code === 'HELPER_ALREADY_SIGNED_UP')
      yield call(history.push, `/signin?redirect_url=${redirectUrl}`);
  }
}

export function* signUpConfirmation(api, { verificationToken, email }) {
  const response = yield call(api.signUpConfirmation, verificationToken);
  if (response.ok) {
    // update token
    yield put(AuthActions.resetIfNewUser(response.data.helper));
    yield put(AuthActions.setAuthTokenRequest(response.data.helper.token));
    // update current user
    yield put(AuthActions.signInSuccess(response.data.helper));
    yield put(AuthActions.signUpConfirmationSuccess(response.data.helper));
    yield put(UsersActions.getUserSuccess(response.data.helper));
    yield put(
      ToasterActions.showToaster({ id: 'WELLO.SIGN_UP_CONFIRMATION.SUCCESS' }, 'success', 5000),
    );
  } else {
    yield toastResponseError(response);
    yield put(AuthActions.signUpConfirmationFailure(response.data.error));
    if (response.data.error?.code === 'PASSWORD_NOT_SET')
      yield call(history.push, `/signup/verification/${verificationToken}/${email}`);
    else yield call(history.push, `/signin`);
  }
}

export function* logout(api) {
  yield call(api.removeAuthToken);
  yield put(HoldingsActions.resetHoldings());
  yield put(LeaguesActions.resetLeagues());
  yield put(OrganisationActions.resetOrganisation());
  yield put(ClubsActions.resetClubs());
  yield put(DealsActions.resetDeals());
  yield put(LeadsActions.resetLeads());
  yield put(ArticlesActions.resetArticles());
  yield put(TasksActions.resetTasks());
  yield put(TransmissionsActions.resetTransmissions());
  yield put(CarenotebooksActions.resetCareNotebooks());
  yield put(UsersActions.resetUser());
  yield call(history.push, `/signin`);
}

export function* resendConfirmationEmail(api, { organisation, email }) {
  const response = yield call(api.resendConfirmationEmail, organisation.slug, email);
  if (response.ok) {
    yield put(AuthActions.resendConfirmationEmailSuccess());
    yield put(
      ToasterActions.showToaster({ id: 'WELLO.RESEND_CONFIRMATION.SUCCESS' }, 'success', 5000),
    );
    yield call(history.push, `/signin`);
  } else {
    yield toastResponseError(response);
    yield put(AuthActions.resendConfirmationEmailFailure(response.data));
  }
}

export function* createHelperSubscription(api, { helperId, subscription }) {
  const response = yield call(api.createHelperSubscription, helperId, subscription);
  if (response.ok) {
    yield put(
      AuthActions.createHelperSubscriptionSuccess({
        ...response.data.subscription,
        subscrible: subscription.subscrible,
      }),
    );
    yield put(OrganisationActions.addOrganisationSubscription(response.data.subscription));
    yield put(ToasterActions.showToaster({ id: 'APP.SUBSCRIPTIONS.CREATED' }, 'success', 5000));
  } else {
    yield toastResponseError(response);
    yield put(AuthActions.createHelperSubscriptionFailure(response.data));
  }
}

export function* updateHelperSubscription(api, { helperId, subscription }) {
  const response = yield call(api.updateHelperSubscription, helperId, subscription);
  if (response.ok) {
    yield put(AuthActions.updateHelperSubscriptionSuccess(response.data.subscription));
    yield put(OrganisationActions.updateOrganisationSubscription(response.data.subscription));
  } else {
    yield toastResponseError(response);
    yield put(AuthActions.updateHelperSubscriptionFailure(response.data));
  }
}

export function* deleteHelperSubscription(api, { helperId, subscriptionId }) {
  const response = yield call(api.deleteHelperSubscription, helperId, subscriptionId);
  if (response.ok) {
    yield put(AuthActions.deleteHelperSubscriptionSuccess(subscriptionId));
    yield put(OrganisationActions.removeOrganisationSubscription(subscriptionId));
    yield put(ToasterActions.showToaster({ id: 'APP.SUBSCRIPTIONS.DELETED' }, 'success', 5000));
  } else {
    yield toastResponseError(response);
    yield put(AuthActions.deleteHelperSubscriptionFailure(response.data));
  }
}

export function* getHelperNotifications(api, { helperId }) {
  const response = yield call(api.getHelperNotifications, helperId);
  if (response.ok) {
    yield put(AuthActions.getHelperNotificationsSuccess(response.data.notifications));
  } else {
    yield toastResponseError(response);
    yield put(AuthActions.getHelperNotificationsFailure(response.data));
  }
}

export function* updateHelperNotification(api, { helperId, notification }) {
  const response = yield call(api.updateHelperNotification, helperId, notification);
  if (response.ok) {
    yield put(AuthActions.updateHelperNotificationSuccess(response.data.notification));
  } else {
    yield toastResponseError(response);
    yield put(AuthActions.updateHelperNotificationFailure(response.data));
  }
}

export function* deleteHelperNotification(api, { helperId, notificationId }) {
  const response = yield call(api.deleteHelperNotification, helperId, notificationId);
  if (response.ok) {
    yield put(AuthActions.deleteHelperNotificationSuccess(notificationId));
    yield put(ToasterActions.showToaster({ id: 'APP.SUBSCRIPTIONS.DELETED' }, 'success', 5000));
  } else {
    yield toastResponseError(response);
    yield put(AuthActions.deleteHelperSubscriptionFailure(response.data));
  }
}

export function* updateHelper(api, { helperId, helperAttributes, noDecorator }) {
  const response = yield call(api.updateUser, helperId, helperAttributes, null, noDecorator);
  if (response.ok) {
    yield put(AuthActions.updateHelperSuccess(response.data.helper));
  } else {
    yield toastResponseError(response);
    yield put(AuthActions.updateHelperFailure(response.data));
  }
}
