import React from 'react';
import { useTranslation } from 'react-i18next';

import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { checkPhoneValidity, countries } from '../../Services/DataHelper';

type Props = {
  phone: String,
  phoneError: String,
  countryCode: String,
  setPhone: Function,
  setPhoneError: Function,
  setCountryCode: Function,
  readOnly: Boolean,
  noMargin: Boolean,
  variant: String,
  required: Boolean,
};

const PhoneControl = ({
  phone,
  noMargin,
  phoneError,
  countryCode,
  setPhone,
  setPhoneError,
  setCountryCode,
  readOnly,
  variant,
  required,
}: Props) => {
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = React.useState();
  const country = countries?.find(c => c.code == countryCode) || countries[0];

  React.useEffect(() => {
    !phone || checkPhoneValidity(phone, country)
      ? setPhoneError(null)
      : setPhoneError(t(country.error));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phone, country]);

  const handleClickCountry = c => {
    setCountryCode(c.code);
    setAnchorEl(null);
  };

  return (
    <FormControl component="fieldset" fullWidth>
      <TextField
        name="phone"
        value={phone}
        onChange={event => {
          setPhone(event.target.value);
        }}
        label={t('APP.HOLDING.SETUP.TEXT_FIELD.LABEL.PHONE')}
        margin={noMargin ? 'default' : 'normal'}
        variant={variant || 'outlined'}
        helperText={phoneError}
        error={phoneError !== null}
        fullWidth
        disabled={readOnly}
        required={required}
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{
          type: 'phone',
          autoComplete: 'phone',
          readOnly: readOnly,
          startAdornment: (
            <>
              <IconButton
                aria-label="menu"
                onClick={event => setAnchorEl(event.currentTarget)}
                size="small"
                style={{ marginRight: 8 }}
                disabled={readOnly}
              >
                {country.icon}
              </IconButton>
              <Menu
                id="long-menu"
                MenuListProps={{
                  'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                value={countryCode}
              >
                {countries.map(c => (
                  <MenuItem
                    key={c.code}
                    onClick={() => {
                      handleClickCountry(c);
                    }}
                  >
                    <ListItemIcon>{c.icon}</ListItemIcon>
                    <ListItemText>{c.label}</ListItemText>
                  </MenuItem>
                ))}
              </Menu>
            </>
          ),
        }}
      />
    </FormControl>
  );
};

export default PhoneControl;
