import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import loadable from '@loadable/component';
import ArticlesActions from '../Redux/ArticlesRedux';
const FullScreenLoader = loadable(() => import('../Components/Layout/FullScreenLoader'));

type Props = {
  match: Object,
  article: Object,
  getArticle: Function,
  league: Object,
};

const mapStateToProps = state => ({
  article: state.articles.leagueArticle,
  league: state.leagues.league,
});

const mapDispatchToProps = dispatch => ({
  getArticle: (leagueId, articleSlug) =>
    dispatch(ArticlesActions.getLeagueArticleRequest(leagueId, articleSlug)),
});

const withLeagueArticle = WrappedComponent => {
  function fetchArticle(props: Props) {
    const { article, match, getArticle, league } = props;
    const { article_slug: articleSlug, league_id: leagueId } = match.params;
    const articleIsMissing = !article;
    const articleHasChanged = article?.slug !== articleSlug;
    if (_.some([articleIsMissing, articleHasChanged])) {
      getArticle(league ? league.id : leagueId, articleSlug);
      return <FullScreenLoader />;
    }
    return <WrappedComponent {...props} article={article} />;
  }

  return connect(mapStateToProps, mapDispatchToProps)(fetchArticle);
};

export default withLeagueArticle;
