import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';
import _concat from 'lodash/concat';
import findAndReplace from './ReduxHelpers';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  getHoldingArticlesRequest: ['holdingSlug'],
  getHoldingArticlesSuccess: ['articles'],
  getHoldingArticlesFailure: ['errors '],

  getHoldingArticleRequest: ['holdingSlug', 'articleSlug'],
  getHoldingArticleSuccess: ['article'],
  getHoldingArticleFailure: ['errors'],

  createHoldingArticleRequest: [
    'holdingSlug',
    'article',
    'cover',
    'attachments',
    'redirectionLink',
  ],
  createHoldingArticleSuccess: ['article'],
  createHoldingArticleFailure: ['errors'],

  updateHoldingArticleRequest: [
    'holdingSlug',
    'article',
    'cover',
    'attachments',
    'redirectionLink',
  ],
  updateHoldingArticleSuccess: ['article'],
  updateHoldingArticleFailure: ['errors'],

  destroyHoldingArticleRequest: ['holdingSlug', 'articleSlug'],
  destroyHoldingArticleSuccess: ['articles'],
  destroyHoldingArticleFailure: ['errors'],

  getLeagueArticlesRequest: ['leagueId'],
  getLeagueArticlesSuccess: ['articles'],
  getLeagueArticlesFailure: ['errors '],

  getLeagueArticleRequest: ['leagueId', 'articleSlug'],
  getLeagueArticleSuccess: ['article'],
  getLeagueArticleFailure: ['errors'],

  createLeagueArticleRequest: ['leagueId', 'article', 'cover', 'attachments'],
  createLeagueArticleSuccess: ['article'],
  createLeagueArticleFailure: ['errors'],

  updateLeagueArticleRequest: ['leagueId', 'article', 'cover', 'attachments'],
  updateLeagueArticleSuccess: ['article'],
  updateLeagueArticleFailure: ['errors'],

  destroyLeagueArticleRequest: ['leagueId', 'articleSlug'],
  destroyLeagueArticleSuccess: ['articles'],
  destroyLeagueArticleFailure: ['errors'],

  getArticleRequest: ['articleSlug'],
  getArticleSuccess: ['article'],
  getArticleFailure: ['errors'],

  resetArticles: [],
});

export const ArticlesTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  isFetching: {
    getHoldingArticles: false,
    getHoldingArticle: false,
    createHoldingArticle: false,
    updateHoldingArticle: false,
    getLeagueArticles: false,
    getLeagueArticle: false,
    createLeagueArticle: false,
    updateLeagueArticle: false,
    getArticle: false,
  },
  holdingArticles: null,
  holdingArticle: null,
  leagueArticles: null,
  leagueArticle: null,
  article: null,
  errors: {
    getHoldingArticles: null,
    getHoldingArticle: null,
    createHoldingArticle: null,
    updateHoldingArticle: null,
    getLeagueArticles: null,
    getLeagueArticle: null,
    createLeagueArticle: null,
    updateLeagueArticle: null,
    getArticle: null,
  },
});

/* ------------- Reducers ------------- */
// -
// getHoldingArticles
// -

const getHoldingArticlesRequest = state => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    getHoldingArticles: true,
  },
});

const getHoldingArticlesSuccess = (state, { articles }) => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    getHoldingArticles: false,
  },
  errors: {
    ...state.errors,
    getHoldingArticles: INITIAL_STATE.errors.getHoldingArticles,
  },
  holdingArticles: articles,
});

const getHoldingArticlesFailure = (state, { errors }) => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    getHoldingArticles: false,
  },
  articles: [],
  errors: {
    ...state.errors,
    getHoldingArticles: errors,
  },
});

//
// get one article
//

const getHoldingArticleRequest = state => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    getHoldingArticle: true,
  },
  errors: {
    ...state.errors,
    getHoldingArticle: INITIAL_STATE.errors.getHoldingArticle,
  },
});

const getHoldingArticleSuccess = (state, { article }) => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    getHoldingArticle: false,
  },
  errors: {
    ...state.errors,
    getHoldingArticle: INITIAL_STATE.errors.getHoldingArticle,
  },
  holdingArticle: article,
});

const getHoldingArticleFailure = (state, { errors }) => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    getHoldingArticle: false,
  },
  errors: {
    ...state.errors,
    getHoldingArticle: errors,
  },
});

//
// createArticle
//
const createHoldingArticleRequest = state => ({
  ...state,
  isFetching: {
    ...INITIAL_STATE.isFetching,
    createHoldingArticle: true,
  },
  errors: {
    ...INITIAL_STATE.errors,
    createHoldingArticle: null,
  },
});

const createHoldingArticleSuccess = (state, { article }) => ({
  ...state,
  isFetching: {
    ...INITIAL_STATE.isFetching,
    createHoldingArticle: false,
  },
  errors: {
    ...INITIAL_STATE.errors,
    createHoldingArticle: null,
  },
  holdingArticle: article,
  holdingArticles: _concat([article], state.holdingArticles || []),
});

const createHoldingArticleFailure = (state, { errors }) => ({
  ...state,
  isFetching: {
    ...INITIAL_STATE.isFetching,
    createHoldingArticle: false,
  },
  errors: {
    ...INITIAL_STATE.errors,
    createHoldingArticle: errors,
  },
});

//
// updateArticle
//
const updateHoldingArticleRequest = state => ({
  ...state,
  isFetching: {
    ...INITIAL_STATE.isFetching,
    updateHoldingArticle: true,
  },
  errors: {
    ...INITIAL_STATE.errors,
    updateHoldingArticle: null,
  },
});

const updateHoldingArticleSuccess = (state, { article }) => ({
  ...state,
  isFetching: {
    ...INITIAL_STATE.isFetching,
    updateHoldingArticle: false,
  },
  errors: {
    ...INITIAL_STATE.errors,
    updateHoldingArticle: null,
  },
  holdingArticle: article,
  holdingArticles: findAndReplace(a => a.id === article.id, article)(state.holdingArticles),
});

const updateHoldingArticleFailure = (state, { errors }) => ({
  ...state,
  isFetching: {
    ...INITIAL_STATE.isFetching,
    updateHoldingArticle: false,
  },
  errors: {
    ...INITIAL_STATE.errors,
    updateHoldingArticle: errors,
  },
});

//
// destroyArticle
//
const destroyHoldingArticleRequest = state => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    destroyHoldingArticle: true,
  },
  errors: {
    ...state.errors,
    destroyHoldingArticle: INITIAL_STATE.errors.destroyHoldingArticle,
  },
});
const destroyHoldingArticleSuccess = (state, { articles }) => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    destroyHoldingArticle: false,
  },
  errors: {
    ...state.errors,
    destroyHoldingArticle: INITIAL_STATE.errors.destroyHoldingArticle,
  },
  holdingArticles: articles,
});
const destroyHoldingArticleFailure = (state, { errors }) => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    destroyHoldingArticle: false,
  },
  errors: {
    ...state.errors,
    destroyHoldingArticle: errors,
  },
});

/* ------------- League Reducers ------------- */
// -
// getLeagueArticles
// -

const getLeagueArticlesRequest = state => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    getLeagueArticles: true,
  },
});

const getLeagueArticlesSuccess = (state, { articles }) => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    getLeagueArticles: false,
  },
  errors: {
    ...state.errors,
    getLeagueArticles: INITIAL_STATE.errors.getLeagueArticles,
  },
  leagueArticles: articles,
});

const getLeagueArticlesFailure = (state, { errors }) => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    getLeagueArticles: false,
  },
  articles: [],
  errors: {
    ...state.errors,
    getLeagueArticles: errors,
  },
});

//
// get one article
//

const getLeagueArticleRequest = state => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    getLeagueArticle: true,
  },
  errors: {
    ...state.errors,
    getLeagueArticle: INITIAL_STATE.errors.getLeagueArticle,
  },
});

const getLeagueArticleSuccess = (state, { article }) => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    getLeagueArticle: false,
  },
  errors: {
    ...state.errors,
    getLeagueArticle: INITIAL_STATE.errors.getLeagueArticle,
  },
  leagueArticle: article,
});

const getLeagueArticleFailure = (state, { errors }) => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    getLeagueArticle: false,
  },
  errors: {
    ...state.errors,
    getLeagueArticle: errors,
  },
});

//
// create league Article
//
const createLeagueArticleRequest = state => ({
  ...state,
  isFetching: {
    ...INITIAL_STATE.isFetching,
    createLeagueArticle: true,
  },
  errors: {
    ...INITIAL_STATE.errors,
    createLeagueArticle: null,
  },
});

const createLeagueArticleSuccess = (state, { article }) => ({
  ...state,
  isFetching: {
    ...INITIAL_STATE.isFetching,
    createLeagueArticle: false,
  },
  errors: {
    ...INITIAL_STATE.errors,
    createLeagueArticle: null,
  },
  leagueArticle: article,
  leagueArticles: _concat([article], state.leagueArticles || []),
});

const createLeagueArticleFailure = (state, { errors }) => ({
  ...state,
  isFetching: {
    ...INITIAL_STATE.isFetching,
    createLeagueArticle: false,
  },
  errors: {
    ...INITIAL_STATE.errors,
    createLeagueArticle: errors,
  },
});

//
// updateArticle
//
const updateLeagueArticleRequest = state => ({
  ...state,
  isFetching: {
    ...INITIAL_STATE.isFetching,
    updateLeagueArticle: true,
  },
  errors: {
    ...INITIAL_STATE.errors,
    updateLeagueArticle: null,
  },
});

const updateLeagueArticleSuccess = (state, { article }) => ({
  ...state,
  isFetching: {
    ...INITIAL_STATE.isFetching,
    updateLeagueArticle: false,
  },
  errors: {
    ...INITIAL_STATE.errors,
    updateLeagueArticle: null,
  },
  leagueArticle: article,
});

const updateLeagueArticleFailure = (state, { errors }) => ({
  ...state,
  isFetching: {
    ...INITIAL_STATE.isFetching,
    updateLeagueArticle: false,
  },
  errors: {
    ...INITIAL_STATE.errors,
    updateLeagueArticle: errors,
  },
});

//
// destroyArticle
//
const destroyLeagueArticleRequest = state => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    destroyLeagueArticle: true,
  },
  errors: {
    ...state.errors,
    destroyLeagueArticle: INITIAL_STATE.errors.destroyLeagueArticle,
  },
});
const destroyLeagueArticleSuccess = (state, { articles }) => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    destroyLeagueArticle: false,
  },
  errors: {
    ...state.errors,
    destroyLeagueArticle: INITIAL_STATE.errors.destroyLeagueArticle,
  },
  leagueArticles: articles,
});
const destroyLeagueArticleFailure = (state, { errors }) => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    destroyLeagueArticle: false,
  },
  errors: {
    ...state.errors,
    destroyLeagueArticle: errors,
  },
});

//
// get one article
//

const getArticleRequest = state => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    getArticle: true,
  },
  errors: {
    ...state.errors,
    getArticle: INITIAL_STATE.errors.getArticle,
  },
});

const getArticleSuccess = (state, { article }) => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    getArticle: false,
  },
  errors: {
    ...state.errors,
    getArticle: INITIAL_STATE.errors.getArticle,
  },
  article,
});

const getArticleFailure = (state, { errors }) => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    getArticle: false,
  },
  errors: {
    ...state.errors,
    getArticle: errors,
  },
});

const resetArticles = () => INITIAL_STATE;

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_HOLDING_ARTICLES_REQUEST]: getHoldingArticlesRequest,
  [Types.GET_HOLDING_ARTICLES_SUCCESS]: getHoldingArticlesSuccess,
  [Types.GET_HOLDING_ARTICLES_FAILURE]: getHoldingArticlesFailure,

  [Types.GET_HOLDING_ARTICLE_REQUEST]: getHoldingArticleRequest,
  [Types.GET_HOLDING_ARTICLE_SUCCESS]: getHoldingArticleSuccess,
  [Types.GET_HOLDING_ARTICLE_FAILURE]: getHoldingArticleFailure,

  [Types.CREATE_HOLDING_ARTICLE_REQUEST]: createHoldingArticleRequest,
  [Types.CREATE_HOLDING_ARTICLE_SUCCESS]: createHoldingArticleSuccess,
  [Types.CREATE_HOLDING_ARTICLE_FAILURE]: createHoldingArticleFailure,

  [Types.UPDATE_HOLDING_ARTICLE_REQUEST]: updateHoldingArticleRequest,
  [Types.UPDATE_HOLDING_ARTICLE_SUCCESS]: updateHoldingArticleSuccess,
  [Types.UPDATE_HOLDING_ARTICLE_FAILURE]: updateHoldingArticleFailure,

  [Types.DESTROY_HOLDING_ARTICLE_REQUEST]: destroyHoldingArticleRequest,
  [Types.DESTROY_HOLDING_ARTICLE_SUCCESS]: destroyHoldingArticleSuccess,
  [Types.DESTROY_HOLDING_ARTICLE_FAILURE]: destroyHoldingArticleFailure,

  [Types.GET_LEAGUE_ARTICLES_REQUEST]: getLeagueArticlesRequest,
  [Types.GET_LEAGUE_ARTICLES_SUCCESS]: getLeagueArticlesSuccess,
  [Types.GET_LEAGUE_ARTICLES_FAILURE]: getLeagueArticlesFailure,

  [Types.GET_LEAGUE_ARTICLE_REQUEST]: getLeagueArticleRequest,
  [Types.GET_LEAGUE_ARTICLE_SUCCESS]: getLeagueArticleSuccess,
  [Types.GET_LEAGUE_ARTICLE_FAILURE]: getLeagueArticleFailure,

  [Types.CREATE_LEAGUE_ARTICLE_REQUEST]: createLeagueArticleRequest,
  [Types.CREATE_LEAGUE_ARTICLE_SUCCESS]: createLeagueArticleSuccess,
  [Types.CREATE_LEAGUE_ARTICLE_FAILURE]: createLeagueArticleFailure,

  [Types.UPDATE_LEAGUE_ARTICLE_REQUEST]: updateLeagueArticleRequest,
  [Types.UPDATE_LEAGUE_ARTICLE_SUCCESS]: updateLeagueArticleSuccess,
  [Types.UPDATE_LEAGUE_ARTICLE_FAILURE]: updateLeagueArticleFailure,

  [Types.DESTROY_LEAGUE_ARTICLE_REQUEST]: destroyLeagueArticleRequest,
  [Types.DESTROY_LEAGUE_ARTICLE_SUCCESS]: destroyLeagueArticleSuccess,
  [Types.DESTROY_LEAGUE_ARTICLE_FAILURE]: destroyLeagueArticleFailure,

  [Types.GET_ARTICLE_REQUEST]: getArticleRequest,
  [Types.GET_ARTICLE_SUCCESS]: getArticleSuccess,
  [Types.GET_ARTICLE_FAILURE]: getArticleFailure,

  [Types.RESET_ARTICLES]: resetArticles,
});
