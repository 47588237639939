import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Redirect, withRouter, Link, useRouteMatch } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';

import Authentication from './Authentication';
import ToasterActions from '../Redux/ToasterRedux';
import { CheckRoleRule } from './Rules';
import { getRole, isInFamilyCircles, isMyFamily } from './Controls';

type Props = {
  showForbidden: Function,
  family: Object,
  showForbiddenToaster: Function,
  currentUser: Object,
};

const FamilyAuthorization = action => WrappedComponent => {
  function WithAuthorization(props: Props) {
    const { showForbiddenToaster, family, currentUser } = props;
    const { t } = useTranslation();
    const match = useRouteMatch();
    const { family_slug: familySlug } = match.params;

    const forbiddenUser =
      !family ||
      !CheckRoleRule(family?.role, 'organisation:read', { organisation: family }) ||
      (action !== '' && !CheckRoleRule(family?.role, action, { organisation: family }));

    const organisation =
      currentUser?.organisations?.length > 0 &&
      currentUser?.organisations.find(
        orga =>
          isInFamilyCircles(orga, family) &&
          ((action !== '' &&
            CheckRoleRule(
              getRole(currentUser, orga?.holding_slug, orga?.slug, familySlug),
              action,
            )) ||
            CheckRoleRule(
              getRole(currentUser, orga?.holding_slug, orga?.slug, familySlug),
              'family:read',
            )),
      );

    const forbiddenOrga = !organisation || !family;

    if (isMyFamily(family)) {
      return <WrappedComponent {...props} />;
    } else if (family && !forbiddenUser) {
      return <WrappedComponent {...props} />;
    } else if (family && organisation && !forbiddenOrga) {
      return <WrappedComponent {...props} organisation={organisation} />;
    } else {
      showForbiddenToaster({ id: 'APP.ACL.FAMILY.FORBIDDEN' });

      return (
        <>
          <Typography align="center" style={{ paddingTop: '20px' }}>
            <Link to="/signin">{t('APP.ACL.FAMILY.FORBIDDEN')}</Link>
          </Typography>
          <Redirect to="/signin" />
        </>
      );
    }
  }

  const mapStateToProps = state => ({
    currentUser: state.auth.currentUser,
  });

  const mapDispatchToProps = dispatch => ({
    showForbiddenToaster: message => dispatch(ToasterActions.showToaster(message, 'error', 5000)),
  });

  return connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withRouter(Authentication(WithAuthorization)));
};

export default FamilyAuthorization;
