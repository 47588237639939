import React from 'react';
import orderBy from 'lodash/orderBy';
import {
  OutdoorIcon,
  CafeIcon,
  CarteIcon,
  ActivitesSportivesIcon,
  MaintienFormeIcon,
  SortiesCulturellesIcon,
  JeuxIcon,
  RencontresIcon,
  ArtIcon,
  MusiqueIcon,
  EducationSpecialiseeIcon,
  InsertionProIcon,
  RepitIcon,
  AccueilJourIcon,
  HalteRepitIcon,
  HebergementTemporaireIcon,
  RelayageIcon,
  SejourRepitIcon,
  TelephoneIcon,
  VisiteIcon,
  PoubelleIcon,
  ChienIcon,
  VoletIcon,
  PlanteIcon,
  ServiceIcon,
  NumeriqueIcon,
  TransportIcon,
  HabitatIcon,
  AidesTechniquesIcon,
  AdministrativesIcon,
  AidesJuridiquesIcon,
  PainIcon,
  CourrierIcon,
  VigilantIcon,
  CourseIcon,
  MenageIcon,
  LitIcon,
  PortageRepasIcon,
  RepasIcon,
  ToiletteIcon,
  NuitIcon,
  AccompagnementIcon,
  InfoSanteIcon,
  SoutienPsyIcon,
  FormationIcon,
  InformationIcon,
  MedicamentIcon,
  ConstantesIcon,
  RdvMedicauxIcon,
  PreventionIcon,
  BienEtreIcon,
  GroupeParoleIcon,
  PairAidanceIcon,
  CommunauteIcon,
  SoinIcon,
  KineIcon,
  ErgoIcon,
  HopitalDomicileIcon,
  WelloIcon,
  PersonneAgeeIcon,
  AccueillantsFamiliauxIcon,
  MediationFamilialeIcon,
  CounselingIcon,
  SocioEsthetiqueIcon,
} from './Icon';
import Images from './Images';
import { grey } from '@material-ui/core/colors';

export const LEVEL_NAMES = [
  'information-et-formation',
  'convivialite',
  'solutions-de-repit',
  'entraide',
  'aide-a-domicile',
  'soutien-aux-aidants',
  'soins-a-domicile',
];

export default function getExpertiseLevelStyle(level) {
  switch (level) {
    case LEVEL_NAMES[0]: {
      return {
        color: '#FED638',
        lightColor: '#FFF7D7',
        gradiant: 'linear-gradient(180deg, #F6C604 0%, #F5CD0D 25%, #F3E630 100%)',
        cover: `${Images.matrice.loisirs}`,
        labels: [
          {
            slug: 'formations-2',
            label: 'APP.EXPERTISE.TRAINING',
            color: '#FED638',
            icon: <FormationIcon style={{ color: '#FED638' }} />,
          },
          {
            slug: 'ateliers-de-prevention',
            label: 'APP.EXPERTISE.PREVENTION',
            color: '#FED638',
            icon: <PreventionIcon style={{ color: '#FED638' }} />,
          },
          {
            slug: 'ateliers-numeriques',
            label: 'APP.EXPERTISE.DIGITAL',
            color: '#FED638',
            icon: <NumeriqueIcon style={{ color: '#FED638' }} />,
          },
          {
            slug: 'informations',
            label: 'APP.EXPERTISE.INFORMATION',
            color: '#FED638',
            icon: <InformationIcon style={{ color: '#FED638' }} />,
          },
          {
            slug: 'demarches-administratives',
            label: 'APP.EXPERTISE.ADMINIDTRATIVE',
            color: '#FED638',
            icon: <AdministrativesIcon />,
            rank: 2,
          },
          {
            slug: 'aide-juridique',
            label: 'APP.EXPERTISE.JURIDICAL',
            color: '#FED638',
            icon: <AidesJuridiquesIcon />,
            rank: 3,
          },
          {
            slug: 'formations',
            label: 'APP.EXPERTISE.TRAINING',
            color: '#FED638',
            icon: <FormationIcon />,
            rank: 1,
          },
          {
            slug: 'mediation-familiale',
            label: 'APP.EXPERTISE.MEDIATION',
            color: '#FED638',
            icon: <MediationFamilialeIcon />,
            rank: 4,
          },
        ],
      };
    }
    case LEVEL_NAMES[1]: {
      return {
        color: '#FFA70B',
        lightColor: '#FEEDCE',
        gradiant: 'linear-gradient(180deg, #FFA709 0%, #FFAF0F 44%, #FFE239 100%)',
        cover: `${Images.matrice.loisirs}`,
        labels: [
          {
            slug: 'activites-sportives',
            label: 'APP.EXPERTISE.SPORT',
            color: '#FFA70B',
            icon: <ActivitesSportivesIcon style={{ color: '#FFA70B' }} />,
          },
          {
            slug: 'maintien-en-forme',
            label: 'APP.EXPERTISE.SHAPE',
            color: '#FFA70B',
            icon: <MaintienFormeIcon style={{ color: '#FFA70B' }} />,
          },
          {
            slug: 'sorties-culturelles',
            label: 'APP.EXPERTISE.CULTURAL',
            color: '#FFA70B',
            icon: <SortiesCulturellesIcon style={{ color: '#FFA70B' }} />,
          },
          {
            slug: 'jeux',
            label: 'APP.EXPERTISE.GAME',
            color: '#FFA70B',
            icon: <JeuxIcon style={{ color: '#FFA70B' }} />,
            cover: `${Images.matrice.loisirs}`,
          },
          {
            slug: 'rencontres-conviviales',
            label: 'APP.EXPERTISE.MEETING',
            color: '#FFA70B',
            icon: <RencontresIcon style={{ color: '#FFA70B' }} />,
          },
          {
            slug: 'activites-creatives',
            label: 'APP.EXPERTISE.CREATIVE',
            color: '#FFA70B',
            icon: <ArtIcon style={{ color: '#FFA70B' }} />,
            keywords: ['Peinture', 'Tricot', 'Dessin', 'Couture'],
          },
          {
            slug: 'activites-musicales',
            label: 'APP.EXPERTISE.MUSIC',
            color: '#FFA70B',
            icon: <MusiqueIcon style={{ color: '#FFA70B' }} />,
          },
          {
            slug: 'activites-de-loisirs',
            label: 'APP.EXPERTISE.SPARE_TIME',
            color: '#FFA70B',
            icon: <CarteIcon />,
            rank: 1,
          },
          {
            slug: 'activites-detente-bien-etre',
            label: 'APP.EXPERTISE.RELAX',
            color: '#FFA70B',
            icon: <BienEtreIcon />,
            rank: 2,
          },
          {
            slug: 'education-specialisee-enfants',
            color: '#FFA70B',
            icon: <EducationSpecialiseeIcon />,
          },
          {
            slug: 'education-specialisee-adolescents',
            color: '#FFA70B',
            icon: <EducationSpecialiseeIcon />,
          },
          {
            slug: 'insertion-professionnelle',
            color: '#FFA70B',
            icon: <InsertionProIcon />,
          },
          {
            slug: 'esat',
            color: '#FFA70B',
            icon: <InsertionProIcon />,
          },
          {
            slug: 'se-promener',
            label: 'Se promener',
            color: '#FFA70B',
            icon: <AccompagnementIcon style={{ color: '#FFA70B' }} />,
          },
          {
            slug: 'activite-exterieure',
            label: 'Activité extérieure',
            color: '#FFA70B',
            icon: <OutdoorIcon style={{ color: '#FFA70B' }} />,
          },
          {
            slug: 'activite-sportive',
            label: 'Activités sportives',
            color: '#FFA70B',
            icon: <ActivitesSportivesIcon style={{ color: '#FFA70B' }} />,
          },
          {
            slug: 'activite-detente',
            label: 'Activités détente',
            color: '#FFA70B',
            icon: <BienEtreIcon style={{ color: '#FFA70B' }} />,
          },
          {
            slug: 'sortie-culturelle',
            label: 'Sortie culturelle',
            color: '#FFA70B',
            icon: <SortiesCulturellesIcon style={{ color: '#FFA70B' }} />,
          },
          {
            slug: 'activite-creative',
            label: 'Activité créative',
            color: '#FFA70B',
            icon: <ArtIcon style={{ color: '#FFA70B' }} />,
          },
          {
            slug: 'jeux-2',
            label: 'Jeux',
            color: '#FFA70B',
            icon: <JeuxIcon style={{ color: '#FFA70B' }} />,
          },
          {
            slug: 'activite-musicale',
            label: 'Activité musicale',
            color: '#FFA70B',
            icon: <MusiqueIcon style={{ color: '#FFA70B' }} />,
          },
          {
            slug: 'avoir-une-visite',
            label: 'Reçevoir une visite',
            color: '#FFA70B',
            icon: <VisiteIcon style={{ color: '#FFA70B' }} />,
          },
        ],
      };
    }
    case LEVEL_NAMES[2]: {
      return {
        color: '#FF6B17',
        lightColor: '#FFE1D0',
        gradiant: 'linear-gradient(179deg, #FF6B17 0%, #FFD32A 100%, #F7D8FF 100%)',
        cover: `${Images.matrice.loisirs}`,
        labels: [
          {
            slug: 'repit',
            label: 'APP.EXPERTISE.RESPITE',
            color: '#FF6B17',
            icon: <RepitIcon style={{ color: '#FF6B17' }} />,
          },
          {
            slug: 'sejours-de-repit-2',
            label: 'APP.EXPERTISE.HOLIDAYS',
            color: '#FF6B17',
            icon: <SejourRepitIcon style={{ color: '#FF6B17' }} />,
          },
          {
            slug: 'accueil-de-jour',
            label: 'APP.EXPERTISE.DAY_CARE_RESPITE',
            color: '#FF6B17',
            icon: <AccueilJourIcon />,
            rank: 1,
          },
          {
            slug: 'halte-repit',
            label: 'APP.EXPERTISE.RESPITE_CARE',
            color: '#FF6B17',
            icon: <HalteRepitIcon />,
            rank: 2,
          },
          {
            slug: 'hebergement-temporaire',
            label: 'APP.EXPERTISE.SHORT_STAY_RESPITE',
            color: '#FF6B17',
            icon: <HebergementTemporaireIcon />,
            rank: 3,
          },
          {
            slug: 'accueillants-familiaux',
            label: 'APP.EXPERTISE.SITTING_RESPITE',
            color: '#FF6B17',
            icon: <AccueillantsFamiliauxIcon />,
            rank: 4,
          },
          {
            slug: 'relayage-a-domicile',
            label: 'APP.EXPERTISE.HOME_RESPITE',
            color: '#FF6B17',
            icon: <RelayageIcon />,
            rank: 5,
          },
          {
            slug: 'sejours-de-repit',
            label: 'APP.EXPERTISE.HOLIDAYS',
            color: '#FF6B17',
            icon: <SejourRepitIcon />,
            rank: 6,
          },
        ],
      };
    }
    case LEVEL_NAMES[3]: {
      return {
        color: '#53C264',
        lightColor: '#DDF3E0',
        gradiant: 'linear-gradient(180deg, #53C263 0%, #5DC564 24%, #B1DD6C 100%)',
        cover: `${Images.matrice.quotidien}`,
        labels: [
          {
            slug: 'sortir-les-poubelles',
            color: '#53C264',
            icon: <PoubelleIcon />,
          },
          {
            slug: 'promener-le-chien',
            color: '#53C264',
            icon: <ChienIcon />,
          },
          {
            slug: 'fermer-les-volets',
            color: '#53C264',
            icon: <VoletIcon />,
          },
          {
            slug: 'arroser-les-plantes',
            color: '#53C264',
            icon: <PlanteIcon />,
          },
          {
            slug: 'petit-service',
            label: 'APP.EXPERTISE.SERVICE',
            color: '#53C264',
            icon: <ServiceIcon />,
            rank: 1,
          },
          {
            slug: 'visite-a-domicile',
            label: 'APP.EXPERTISE.VISIT',
            color: '#53C264',
            icon: <CafeIcon />,
            rank: 2,
          },
          {
            slug: 'se-promener-a-l-exterieur',
            label: 'APP.EXPERTISE.WALKS',
            color: '#53C264',
            icon: <AccompagnementIcon />,
            rank: 4,
          },
          {
            slug: 'soutien-telephonique',
            label: 'APP.EXPERTISE.PHONE',
            color: '#53C264',
            icon: <TelephoneIcon />,
            rank: 5,
          },
          {
            slug: 'aide-au-numerique',
            label: 'APP.EXPERTISE.DIGITAL_HELP',
            color: '#53C264',
            icon: <NumeriqueIcon />,
            rank: 6,
          },
          {
            slug: 'transport-solidaire',
            label: 'APP.EXPERTISE.TRANSPORT',
            color: '#53C264',
            icon: <TransportIcon />,
            rank: 7,
          },
          {
            slug: 'aide-aux-courses',
            label: 'APP.EXPERTISE.SHOPPING',
            color: '#53C264',
            icon: <CourseIcon />,
            rank: 8,
          },
          {
            slug: 'visite-de-courtoisie',
            color: '#53C264',
            icon: <VisiteIcon />,
          },
          {
            slug: 'adapter-son-habitat',
            color: '#53C264',
            icon: <HabitatIcon />,
          },
          {
            slug: 'aides-techniques',
            color: '#53C264',
            icon: <AidesTechniquesIcon />,
          },
          {
            slug: 'teleassistance',
            color: '#53C264',
            icon: <TelephoneIcon />,
          },
        ],
      };
    }
    case LEVEL_NAMES[4]: {
      return {
        color: '#4069DB',
        lightColor: '#D9E1F8',
        gradiant: 'linear-gradient(180deg, #4069DB 0%, #4078DB 41%, #40A7DB 100%)',
        cover: `${Images.matrice.aideadomicile}`,
        labels: [
          {
            slug: 'apporter-le-pain',
            color: '#4069DB',
            icon: <PainIcon />,
          },
          {
            slug: 'deposer-le-courrier',
            color: '#4069DB',
            icon: <CourrierIcon />,
          },
          {
            slug: 'etre-vigilant',
            color: '#4069DB',
            icon: <VigilantIcon />,
          },
          {
            slug: 'preparation-des-repas',
            color: '#4069DB',
            icon: <PortageRepasIcon />,
          },
          {
            slug: 'aide-autonomie',
            label: 'APP.EXPERTISE.AUTONOMY',
            color: '#4069DB',
            icon: <PersonneAgeeIcon />,
            rank: 1,
          },
          {
            slug: 'accompagnement-sortie',
            label: 'APP.EXPERTISE.OUTINGS',
            color: '#4069DB',
            icon: <AccompagnementIcon />,
            rank: 2,
          },
          {
            slug: 'aide-au-deplacement',
            label: 'APP.EXPERTISE.TRAVEL',
            color: '#4069DB',
            icon: <TransportIcon />,
            rank: 3,
          },
          {
            slug: 'services-du-quotidien',
            label: 'APP.EXPERTISE.DAILY_SERVICES',
            color: '#4069DB',
            icon: <ServiceIcon />,
            rank: 4,
          },
          {
            slug: 'entretien-du-logement',
            label: 'APP.EXPERTISE.HOUSEWORK',
            color: '#4069DB',
            icon: <MenageIcon />,
            rank: 5,
          },
          {
            slug: 'portage-de-repas',
            label: 'APP.EXPERTISE.MEAL',
            color: '#4069DB',
            icon: <PortageRepasIcon />,
            rank: 6,
          },
          { slug: 'courses', color: '#4069DB', icon: <CourseIcon /> },
          {
            slug: 'aide-au-lever-aide-au-coucher',
            color: '#4069DB',
            icon: <LitIcon />,
          },
          {
            slug: 'aide-a-la-toilette-a-l-habillage',
            color: '#4069DB',
            icon: <ToiletteIcon />,
          },
          {
            slug: 'aide-a-la-prise-des-repas',
            color: '#4069DB',
            icon: <RepasIcon />,
          },
          {
            slug: 'garde-de-nuit',
            color: '#4069DB',
            icon: <NuitIcon />,
          },
        ],
      };
    }
    case LEVEL_NAMES[5]: {
      return {
        color: '#962DC6',
        lightColor: '#EAD5F4',
        gradiant: 'linear-gradient(180deg, #962DC6 0%, #A02FC7 48%, #C535CD 100%)',
        cover: `${Images.matrice.bien_etre}`,
        labels: [
          {
            slug: 'detente-bien-etre',
            label: 'APP.EXPERTISE.WELL_BEING',
            color: '#962DC6',
            icon: <BienEtreIcon style={{ color: '#962DC6' }} />,
            keywords: ['Yoga', 'Aromathérapie', 'Méditation', 'Qi gong'],
            cover: `${Images.matrice.bien_etre}`,
          },
          {
            slug: 'groupes-de-parole-2',
            label: 'APP.EXPERTISE.SUPPORT_GROUPS',
            color: '#962DC6',
            icon: <GroupeParoleIcon style={{ color: '#962DC6' }} />,
          },
          {
            slug: 'pair-aidance-2',
            label: 'APP.EXPERTISE.PEER',
            color: '#962DC6',
            icon: <PairAidanceIcon style={{ color: '#962DC6' }} />,
          },
          {
            slug: 'prendre-ses-medicaments',
            color: '#962DC6',
            icon: <MedicamentIcon />,
          },
          {
            slug: 'suivre-ses-constantes',
            color: '#962DC6',
            icon: <ConstantesIcon />,
          },
          {
            slug: 'prendre-ses-rdv-medicaux',
            color: '#962DC6',
            icon: <RdvMedicauxIcon />,
          },
          {
            slug: 'informations-pathologies',
            color: '#962DC6',
            icon: <InfoSanteIcon />,
          },
          {
            slug: 'bilan-de-sante',
            color: '#962DC6',
            icon: <ConstantesIcon />,
          },
          {
            slug: 'soutien-psychologique-individuel',
            label: 'APP.EXPERTISE.PSY',
            color: '#962DC6',
            icon: <SoutienPsyIcon />,
            rank: 1,
          },
          {
            slug: 'groupes-de-parole',
            label: 'APP.EXPERTISE.SUPPORT_GROUPS',
            color: '#962DC6',
            icon: <GroupeParoleIcon />,
            rank: 2,
          },
          {
            slug: 'communaute-d-experts',
            label: 'APP.EXPERTISE.EXPERT',
            color: '#962DC6',
            icon: <CommunauteIcon />,
            rank: 3,
          },
          {
            slug: 'pair-aidance',
            label: 'APP.EXPERTISE.PEER',
            color: '#962DC6',
            icon: <PairAidanceIcon />,
            rank: 4,
          },
          {
            slug: 'counseling',
            label: 'APP.EXPERTISE.COUNSELING',
            color: '#962DC6',
            icon: <CounselingIcon />,
            rank: 5,
          },
          {
            slug: 'socio-esthetique',
            label: 'APP.EXPERTISE.AESTHETIC',
            color: '#962DC6',
            icon: <SocioEsthetiqueIcon />,
            rank: 6,
          },
        ],
      };
    }
    case LEVEL_NAMES[6]: {
      return {
        color: '#6200D5',
        lightColor: '#E0CCF7',
        gradiant: 'linear-gradient(180deg, #6200D5 0%, #5E03CF 59%, #482CD6 100%)',
        cover: `${Images.matrice.santé}`,
        labels: [
          {
            slug: 'soins-infirmiers',
            label: 'APP.EXPERTISE.MEDICAL',
            color: '#6200D5',
            icon: <SoinIcon />,
            rank: 1,
          },
          {
            slug: 'kinesitherapie',
            label: 'APP.EXPERTISE.PHYSIOTHERAPY',
            color: '#6200D5',
            icon: <KineIcon />,
            rank: 2,
          },
          {
            slug: 'ergotherapie',
            label: 'APP.EXPERTISE.OCCUPATIONAL_THERAPY',
            color: '#6200D5',
            icon: <ErgoIcon />,
            rank: 3,
          },
          {
            slug: 'hospitalisation-a-domicile',
            color: '#6200D5',
            icon: <HopitalDomicileIcon />,
          },
        ],
      };
    }
    default: {
      return {
        color: 'grey',
        labels: [],
      };
    }
  }
}
export function getExpertiseStyle(expertise) {
  let defaultExpertise = { slug: '', color: grey[500], icon: <WelloIcon />, rank: 0 };
  if (!expertise) return defaultExpertise;
  LEVEL_NAMES.forEach(levelName => {
    const foundLabel = getExpertiseLevelStyle(levelName).labels.find(
      l => l.slug === `${expertise.slug}`,
    );
    if (foundLabel) defaultExpertise = { ...foundLabel, level: levelName };
  });
  return defaultExpertise;
}

export function orderExpertisesByLabelRank(expertises) {
  if (!expertises) return null;

  const rankedExpertises = expertises.map(e => ({
    ...e,
    rank: getExpertiseStyle(e).rank,
  }));

  return orderBy(rankedExpertises, 'rank', 'asc');
}
