import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import orderBy from 'lodash/orderBy';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';
import NotificationsIcon from '@material-ui/icons/Notifications';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuList from '@material-ui/core/MenuList';
import Toolbar from '@material-ui/core/Toolbar';
import Badge from '@material-ui/core/Badge';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import LockIcon from '@material-ui/icons/Lock';
import Tooltip from '@material-ui/core/Tooltip';
import { grey } from '@material-ui/core/colors';
import Hidden from '@material-ui/core/Hidden';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import MenuIcon from '@material-ui/icons/Menu';
import { Metrics } from '../../Themes';
import LayoutStyles from './Styles/LayoutStyles';
import OrganisationsMenuList from '../Organisations/Shared/UserMenuList';
import AuthActions from '../../Redux/AuthRedux';
import Routes from '../../Routing/Routes';
import WelloAvatar from '../Shared/WelloAvatar';
import ResponsiveDrawer from '../Shared/ResponsiveDrawer';
import NavBarMenu from './NavBarMenu';
import { isMyFamily, isProd } from '../../Acl/Controls';
import { getFamilyName } from '../../Services/FamilyHelper';
import { hasUnread } from '../../Services/SubscriptionHelper';
import NotificationList from '../Organisations/Shared/NotificationList';
import hasUnreadNotification from '../../Services/NotificationHelper';

type Props = {
  logo: String,
  leftTree: Array,
  rightTree: Array,
  logoLink: String,
  cta: Boolean,
  mailto: String,
};

const useStyles = makeStyles(theme => ({
  appBar: {
    backgroundColor: 'rgba(255,255,255,0.95)',
    boxShadow:
      '0px 2px 3px -1px rgba(0, 0, 0, 0.12), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 1px 0px 0px rgba(0, 0, 0, 0.12)',
  },
  layout: LayoutStyles.fullWidthLayout,
  toolbar: {
    minHeight: Metrics.navBarHeight,
    [`${theme.breakpoints.up('xs')} and (orientation: landscape)`]: {
      minHeight: Metrics.navBarHeight - theme.spacing(1),
    },
    [theme.breakpoints.up('sm')]: {
      minHeight: Metrics.navBarHeight + theme.spacing(1),
    },
  },
  account: {
    borderTop: 'outset',
    backgroundColor: '#FFC552',
    textAlign: 'center',
    minHeight: 30,
    [`${theme.breakpoints.up('xs')} and (orientation: landscape)`]: {
      minHeight: 30 - theme.spacing(1),
    },
    [theme.breakpoints.up('sm')]: {
      minHeight: 30,
    },
  },
  fabIconButton: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      textAlign: 'center',
    },
    [theme.breakpoints.up('sm')]: {
      margin: 'auto',
      left: 0,
      right: 0,
      paddingRight: theme.spacing(1),
    },
  },
  grow: {
    flexGrow: 1,
  },
  button: {
    marginLeft: theme.spacing(2),
    backgroundColor: 'transparent',
    height: '60px',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  buttonContained: {
    marginLeft: theme.spacing(2),
    backgroundColor: 'transparent',
  },
  notebookIcon: {
    color: grey[600],
  },
  avatar: {
    marginRight: '-25px',
    height: '25px',
    width: '25px',
  },
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    padding: 0,
  },
  badge: {
    height: '10px',
    width: '10px',
    minWidth: '10px',
    borderRadius: '15px',
  },
  iconButton: {
    color: 'black',
    backgroundColor: grey[200],
    padding: 9,
    marginLeft: theme.spacing(1),
  },
  notifBlock: {
    flexShrink: 0,
    flexGrow: 1,
  },
  cta: {
    marginLeft: theme.spacing(2),
  },
}));

const NavBar = ({ logo, leftTree, rightTree, logoLink, cta, mailto }: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();

  const [userAnchorEl, setUserAnchorEl] = React.useState();
  const [anchorEl, setAnchorEl] = React.useState();
  const [notifAnchorEl, setNotifAnchorEl] = React.useState();
  const [anchorTitle, setAnchorTitle] = React.useState();
  const [swiperOpen, setSwiperOpen] = React.useState(false);
  const currentUser = useSelector(state => state.auth.currentUser);
  const isLoggedIn = useSelector(state => state.auth.isLoggedIn);

  const handleNotifIconClick = event => {
    const date = new Date();
    if (hasUnreadNotification(currentUser))
      dispatch(
        AuthActions.updateHelperRequest(
          currentUser.id,
          {
            last_notif_reading_date: date.toJSON(),
          },
          true,
        ),
        null,
      );
    setNotifAnchorEl(event.currentTarget);
  };
  const [connectedModeTree, reverseConnectedModeTree] = React.useMemo(() => {
    if (!currentUser || !currentUser.family) return [];
    const { family } = currentUser;
    const hasFamilies = currentUser.families?.filter(f => !isMyFamily(f)).length > 0;
    const hasStructures =
      currentUser.organisations?.length > 0 ||
      currentUser.pending_organisations?.length > 0 ||
      currentUser.leagues?.length > 0 ||
      currentUser.holdings?.length > 0;
    const hasPrimaries = currentUser.primaries_subscriptions?.length > 0;

    const menus = [];
    menus.push({
      title: `${
        currentUser.first_name && currentUser.first_name !== 'null' ? currentUser.first_name : ''
      }`,
      subtitle: 'Mon espace',
      link: `/families/${currentUser.family_slug}`,
      avatar: (
        <Badge
          color="error"
          overlap="circular"
          variant="dot"
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          invisible={!hasUnread(family, false)}
          classes={{ badge: classes.badge }}
        >
          <WelloAvatar
            avatarUrl={currentUser.avatar_url}
            firstName={currentUser.first_name}
            lastName={currentUser.last_name}
            backgroundColor={currentUser.avatar_background_color}
            avatarStyle={{ height: 36, width: 36 }}
            size={36}
            onClick={() => history.push(`/families/${currentUser.family_slug}`)}
          />
        </Badge>
      ),
    });

    if (hasFamilies) {
      menus.push({
        title: t('MY_FAMILY', { context: currentUser.families?.length > 2 && 'MANY' }),
        items: [
          {
            component: <OrganisationsMenuList currentUser={currentUser} withFamilies />,
          },
        ],
        avatar: currentUser.families
          .filter(fa => !isMyFamily(fa))
          .slice(0, Math.min(currentUser.families?.length, 2))
          .map(fa => (
            <Tooltip title={getFamilyName(fa)} placement="bottom" key={fa?.name}>
              <div style={{ marginLeft: '3px' }}>
                <Badge
                  color="error"
                  overlap="circular"
                  variant="dot"
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  invisible={!hasUnread(fa, false)}
                  classes={{ badge: classes.badge }}
                >
                  <WelloAvatar
                    avatarUrl={fa?.admin?.avatar_url}
                    firstName={fa?.admin?.first_name}
                    lastName={fa?.admin?.last_name}
                    backgroundColor={fa?.admin?.avatar_background_color}
                    avatarStyle={{ height: 36, width: 36 }}
                    size={36}
                    onClick={() => history.push(Routes.organisationHomePath(fa))}
                  />
                </Badge>
              </div>
            </Tooltip>
          )),
      });
    }

    if (hasStructures) {
      menus.push({
        title: t('APP.USER.STRUCTURES', { context: 'MINE' }),
        items: [
          {
            component: <OrganisationsMenuList currentUser={currentUser} withOrganisations />,
          },
        ],
        avatar: currentUser.organisations
          ?.slice(0, Math.min(currentUser.organisations?.length, 4))
          .map(or => (
            <Tooltip
              title={`${or.holding_name} - ${or.name}`}
              placement="bottom"
              key={`${or.holding_name}-${or.name}`}
            >
              <div style={{ marginLeft: '3px' }}>
                <Badge
                  color="error"
                  overlap="circular"
                  variant="dot"
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  invisible={!hasUnread(or)}
                  classes={{ badge: classes.badge }}
                >
                  <WelloAvatar
                    onClick={() => history.push(Routes.organisationHomePath(or))}
                    avatarUrl={or.holding_cover_url}
                    avatarStyle={{ height: 36, width: 36 }}
                    size={36}
                  />
                </Badge>
              </div>
            </Tooltip>
          )),
      });
    }

    if (hasPrimaries) {
      const subscriptionsToShow = orderBy(
        currentUser.primaries_subscriptions,
        'unread_count',
        'desc',
      )
        .filter(
          (item, i, ar) => ar.indexOf(ar.find(s => s.subscrible_id === item.subscrible_id)) === i,
        )
        .filter(sub => sub.subscrible);
      menus.push({
        title: 'MY_PRIMARIES',
        items: [
          {
            component: (
              <MenuList style={{ width: '100%' }}>
                {subscriptionsToShow
                  .filter(sub => sub.subscrible)
                  .map(sub => (
                    <MenuItem
                      key={sub.id}
                      onClick={() => history.push(`/families/${sub.subscrible?.slug}`)}
                    >
                      <ListItemIcon style={{ paddingRight: 16 }}>
                        <Badge
                          color="error"
                          overlap="circular"
                          variant="dot"
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                          }}
                          invisible={sub.unread_count === 0}
                          classes={{ badge: classes.badge }}
                        >
                          <WelloAvatar
                            avatarUrl={sub?.subscrible?.admin?.avatar_url}
                            firstName={sub?.subscrible?.admin?.first_name}
                            lastName={sub?.subscrible?.admin?.last_name}
                            backgroundColor={sub?.subscrible?.admin?.avatar_background_color}
                            avatarStyle={{ height: 36, width: 36 }}
                            size={36}
                          />
                        </Badge>
                      </ListItemIcon>

                      <ListItemText primary={getFamilyName(sub?.subscrible)} component="span" />
                    </MenuItem>
                  ))}
              </MenuList>
            ),
          },
        ],
        avatar: subscriptionsToShow
          .filter(sub => sub.subscrible)
          .slice(0, Math.min(subscriptionsToShow?.length, 3))
          .map(sub => (
            <Tooltip title={sub?.subscrible?.name} placement="bottom" key={sub.id}>
              <div style={{ marginLeft: '3px' }}>
                <Badge
                  color="error"
                  overlap="circular"
                  variant="dot"
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  invisible={sub.unread_count === 0}
                  classes={{ badge: classes.badge }}
                >
                  <WelloAvatar
                    avatarUrl={sub?.subscrible?.admin?.avatar_url}
                    firstName={sub?.subscrible?.admin?.first_name}
                    lastName={sub?.subscrible?.admin?.last_name}
                    backgroundColor={sub?.subscrible?.admin?.avatar_background_color}
                    avatarStyle={{ height: 36, width: 36 }}
                    size={36}
                    onClick={() => history.push(`/families/${sub.subscrible?.slug}`)}
                  />
                </Badge>
              </div>
            </Tooltip>
          )),
      });
    }
    const reverse = menus.slice().reverse();
    return [menus, reverse];
  }, [currentUser, history]);

  const notConnectedModeTree = React.useMemo(() => {
    return (leftTree || []).concat(rightTree || []);
  }, [leftTree, rightTree]);

  const onSignOut = () => {
    dispatch(AuthActions.resetOnLogout());
  };

  const renderDrawer = tree => (
    <>
      <Button
        onClick={() => setSwiperOpen(true)}
        style={{
          visibility: tree && tree.length > 0 ? 'auto' : 'hidden',
        }}
      >
        <MenuIcon />
      </Button>
      <ResponsiveDrawer
        open={swiperOpen}
        onClose={() => setSwiperOpen(false)}
        logo={logo}
        items={tree}
      />
    </>
  );

  const renderRightSideWhenUserIsLoggedIn = () => (
    <div className={classes.notifBlock}>
      <Hidden only={['xs']}>
        <IconButton className={classes.iconButton} onClick={event => handleNotifIconClick(event)}>
          <Badge
            color="error"
            overlap="circular"
            variant="dot"
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            invisible={!hasUnreadNotification(currentUser)}
            classes={{ badge: classes.badge }}
          >
            <NotificationsIcon />
          </Badge>
        </IconButton>
      </Hidden>
      <Menu
        id="long-menu"
        anchorEl={notifAnchorEl}
        open={Boolean(notifAnchorEl)}
        onClick={() => setNotifAnchorEl(null)}
        PaperProps={{
          style: {
            marginTop: 48,
            width: '370px',
            maxWidth: '370px',
            paddingTop: 0,
            paddingBottom: 0,
          },
        }}
        MenuListProps={{
          style: {
            paddingTop: 0,
            paddingBottom: 0,
            maxWidth: '370px',
          },
        }}
        autoFocus={false}
      >
        <NotificationList notifications={currentUser?.notifications} hasShowMoreButton />
      </Menu>
      <IconButton
        className={classes.iconButton}
        onClick={event => setUserAnchorEl(event.currentTarget)}
      >
        <KeyboardArrowDownIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={userAnchorEl}
        open={Boolean(userAnchorEl)}
        onClick={() => setUserAnchorEl(null)}
        PaperProps={{
          style: {
            marginTop: 44,
            width: '150px',
            paddingTop: 0,
            paddingBottom: 0,
          },
        }}
        MenuListProps={{
          style: {
            paddingTop: 0,
            paddingBottom: 0,
          },
        }}
        autoFocus={false}
      >
        <MenuList className={classes.root}>
          {currentUser?.leagues &&
            currentUser.leagues[0] &&
            (!isProd || Routes.currentLeague().namespace === 'WELLO') && (
              <div key="stats">
                <MenuItem component={Link} to="/statistics">
                  {t('DASHBOARD')}
                </MenuItem>
                <Divider light />
              </div>
            )}
          <MenuItem component={Link} to="/settings/security" key="settings">
            {t('SETTINGS')}
          </MenuItem>
          <Divider light />
          <MenuItem onClick={onSignOut} component={Link} to="/signin" key="signout">
            {t('LOGOUT')}
          </MenuItem>
        </MenuList>
      </Menu>
    </div>
  );

  const renderRightSideWhenUserIsNotLoggedIn = () => (
    <>
      <Hidden only={['xs']}>
        {cta && <Grid className={classes.cta}>{cta}</Grid>}
        {mailto && (
          <Hidden smDown>
            <Button href={`mailto:${mailto}`} className={classes.button} disableRipple>
              <Typography>{t('CONTACT_US')}</Typography>
            </Button>
          </Hidden>
        )}
        <Button
          variant="contained"
          className={classes.buttonContained}
          component={Link}
          to="/signin"
        >
          {t('LOGIN')}
        </Button>
      </Hidden>
      <Hidden smUp>
        <Button component={Link} to="/signin">
          <LockIcon />
        </Button>
      </Hidden>
    </>
  );

  const renderButtons = tree => (
    <>
      {tree?.map(item => (
        <div key={item.title}>
          {item.items && (
            <>
              <Button
                onClick={event => {
                  setAnchorEl(event.currentTarget);
                  setAnchorTitle(item.title);
                }}
                className={classes.button}
                disableRipple
              >
                <Typography>{t(item.title)}</Typography>
                <ExpandMoreIcon
                  style={{
                    fontSize: '1.1rem',
                    marginLeft: '5px',
                    marginBottom: '-2px',
                    height: 'auto',
                  }}
                />
              </Button>
              <Menu
                id="long-structures"
                anchorEl={anchorEl}
                open={anchorTitle === item.title}
                onClick={() => {
                  setAnchorEl(null);
                  setAnchorTitle(null);
                }}
                PaperProps={{
                  style: {
                    marginTop: 44,
                    maxWidth: 800,
                  },
                }}
                MenuListProps={{
                  style: {
                    paddingTop: 0,
                    paddingBottom: 0,
                  },
                }}
              >
                {item.items.map((subItem, i) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <div key={`${subItem.title}-${i}`}>
                    <Grid style={{ margin: 24 }}>
                      <Typography variant="h5">{t(subItem.title)}</Typography>
                      <Typography variant="body1">{t(subItem.subtitle)}</Typography>
                      {subItem.offers && (
                        <Grid container style={{ marginTop: 24 }}>
                          {subItem.offers.map(offer => (
                            <Grid item xs="6" key={offer.link}>
                              <MenuItem
                                component={Link}
                                to={offer.link}
                                style={{ whiteSpace: 'normal' }}
                              >
                                {offer.icon}
                                <Grid>
                                  <Typography variant="h6" style={{ color: offer.color }}>
                                    {t(offer.title)}
                                  </Typography>
                                  <Typography variant="body2">{t(offer.subtitle)}</Typography>
                                </Grid>
                              </MenuItem>
                            </Grid>
                          ))}
                        </Grid>
                      )}
                    </Grid>
                    <Divider light />
                  </div>
                ))}
              </Menu>
            </>
          )}
          {item.link && (
            <Button to={item.link} component={Link} className={classes.button} disableRipple>
              <Typography>{t(item.title)}</Typography>
            </Button>
          )}
          {item.href && (
            <Button
              className={classes.button}
              target={item.targetBlank ? '_blank' : ''}
              component="a"
              href={item.href}
              disableRipple
            >
              <Typography>{t(item.title)}</Typography>
            </Button>
          )}
        </div>
      ))}
    </>
  );

  return (
    <AppBar position="fixed" color="default" className={classes.appBar}>
      <Toolbar className={classes.layout} disableGutters>
        <Hidden smUp>
          {!isLoggedIn && renderDrawer(notConnectedModeTree)}
          {isLoggedIn && renderDrawer(connectedModeTree)}
        </Hidden>
        <a href={logoLink ? logoLink : '/'} className={classes.fabIconButton}>
          <img src={logo} height="40" alt="Wello" />
        </a>
        {!isLoggedIn && leftTree && <Hidden only={['xs']}>{renderButtons(leftTree)}</Hidden>}
        <Typography variant="h6" component="span" color="inherit" className={classes.grow} />

        <div style={{ display: 'flex', alignItems: 'center' }}>
          {isLoggedIn && (
            <Hidden only={['xs']}>
              <NavBarMenu tree={reverseConnectedModeTree} />
            </Hidden>
          )}
          {isLoggedIn && renderRightSideWhenUserIsLoggedIn()}
          {!isLoggedIn && <Hidden only={['xs']}>{renderButtons(rightTree)}</Hidden>}
          {!isLoggedIn && renderRightSideWhenUserIsNotLoggedIn()}
        </div>
      </Toolbar>
      {currentUser?.email && currentUser?.validation_status === 'waiting_for_email_validation' && (
        <Toolbar className={classes.account} disableGutters>
          <Typography variant="h6" component="span" color="inherit" className={classes.grow}>
            {t('APP.NAV_BAR.ACCOUNT_NOT_CONFIRMED')}
          </Typography>
        </Toolbar>
      )}
    </AppBar>
  );
};

export default NavBar;
