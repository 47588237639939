import { call, put } from 'redux-saga/effects';
import LeaguesActions from '../Redux/LeaguesRedux';
import OrganisationsLeagueActions from '../Redux/OrganisationsLeagueRedux';
import ToasterActions from '../Redux/ToasterRedux';
import history from '../Routing/CurrentHistory';

export function* getLeagueOrganisations(api, { leagueId, citySlug }) {
  let response;
  if (citySlug) {
    response = yield call(api.getOrganisationsLeagueByCitySlug, leagueId, citySlug);
  } else if (leagueId) {
    response = yield call(api.getOrganisationsByLeague, leagueId);
  } else {
    response = yield call(api.getOrganisations);
  }

  if (response.ok) {
    yield put(
      OrganisationsLeagueActions.getLeagueOrganisationsSuccess(response.data.organisations),
    );
  } else {
    yield put(OrganisationsLeagueActions.getLeagueOrganisationsFailure());
  }
}

export function* getOrganisations(api, { leagueId }) {
  const response = yield call(api.getOrganisationsByLeague, leagueId);

  if (response.ok) {
    yield put(OrganisationsLeagueActions.getOrganisationsSuccess(response.data.organisations));
  } else {
    yield put(OrganisationsLeagueActions.getOrganisationsFailure(response.data));
  }
}

export function* addHoldingLeague(api, { leagueId, holdingSlug }) {
  const response = yield call(api.addHoldingLeague, leagueId, holdingSlug);
  if (response.ok) {
    yield put(OrganisationsLeagueActions.addHoldingLeagueSuccess(response.data.league));
    yield put(LeaguesActions.getLeagueSuccess(response.data.league));
    yield call(history.push, `/leagues/${leagueId}/holdings`);
    yield put(
      ToasterActions.showToaster({ id: 'APP.HOLDINGS_LEAGUES.ADD.SUCCESS' }, 'success', 5000),
    );
  } else {
    yield put(OrganisationsLeagueActions.addHoldingLeagueFailure(response.data));
    yield put(
      ToasterActions.showToaster({ id: 'APP.HOLDINGS_LEAGUES.ADD.FAILURE' }, 'error', 5000),
    );
  }
}

export function* removeHoldingLeague(api, { leagueId, holdingSlug }) {
  const response = yield call(api.removeHoldingLeague, leagueId, holdingSlug);

  if (response.ok) {
    yield put(OrganisationsLeagueActions.removeHoldingLeagueSuccess(response.data.league));
    yield put(LeaguesActions.getLeagueSuccess(response.data.league));
    yield put(
      ToasterActions.showToaster({ id: 'APP.HOLDINGS_LEAGUES.REMOVE.SUCCESS' }, 'success', 5000),
    );
  } else {
    yield put(OrganisationsLeagueActions.removeHoldingLeagueFailure(response.data));
    yield put(
      ToasterActions.showToaster({ id: 'APP.HOLDINGS_LEAGUES.REMOVE.FAILURE' }, 'error', 5000),
    );
  }
}
