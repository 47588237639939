import React from 'react';
import { connect } from 'react-redux';
import loadable from '@loadable/component';
import _ from 'lodash';
import ClubsActions from '../Redux/ClubsRedux';
const FullScreenLoader = loadable(() => import('../Components/Layout/FullScreenLoader'));

type Props = {
  match: Object,
  club: Object,
  getClub: Function,
  organisation: Object,
};

const mapStateToProps = state => ({
  club: state.clubs.club,
  organisation: state.organisation.organisation,
});

const mapDispatchToProps = dispatch => ({
  getClub: (organisation, clubId) =>
    dispatch(
      ClubsActions.getOrganisationClubRequest(organisation.holding_slug, organisation.slug, clubId),
    ),
});

const withClub = WrappedComponent => {
  function fetchClub(props: Props) {
    const { club, match, getClub, organisation } = props;
    const { club_id: clubId, organisation_slug: organisationSlug } = match.params;
    const clubIsMissing = !club;
    const clubHasChanged = _.get(club, 'id') !== parseInt(clubId, 10);

    if (_.some([clubIsMissing, clubHasChanged])) {
      getClub(organisation, clubId);
      return <FullScreenLoader />;
    }
    return (
      <WrappedComponent
        {...props}
        club={club}
        organisation={organisation || { id: club.organisation_id, slug: organisationSlug }}
      />
    );
  }

  return connect(mapStateToProps, mapDispatchToProps)(fetchClub);
};

export default withClub;
