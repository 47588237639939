import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  getCitiesRequest: ['term', 'allowedZipCodes'],
  getCitiesSuccess: ['cities'],
  getCitiesFailure: null,

  resetCities: [],
});

export const CitiesTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  isFetching: false,
  cities: [],
});

/* ------------- Reducers ------------- */
const getCitiesRequest = state => ({
  ...state,
  isFetching: true,
});

const getCitiesSuccess = (state, { cities }) => ({
  ...state,
  isFetching: false,
  cities,
});

const getCitiesFailure = state => ({
  ...state,
  isFetching: false,
});

const resetCities = () => INITIAL_STATE;

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_CITIES_REQUEST]: getCitiesRequest,
  [Types.GET_CITIES_SUCCESS]: getCitiesSuccess,
  [Types.GET_CITIES_FAILURE]: getCitiesFailure,

  [Types.RESET_CITIES]: resetCities,
});
