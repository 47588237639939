import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';

import { muiTheme } from '../../Themes';
import LeadsActions from '../../Redux/LeadsRedux';
import OrganisationContactSuccess from '../Organisations/Shared/ContactSuccess';
import { isAttributeDefined } from '../../Services/FormHelpers';
import { checkEmailValidity, checkPostCodeIsValid } from '../../Services/DataHelper';

import PhoneControl from './PhoneControl';
import Routes from '../../Routing/Routes';
import SLACallMeBackControls from './SLACallMeBackControls';
import { HELPER_TYPES } from '../../Services/ServiceHelper';

const useStyles = makeStyles(theme => ({
  linkButton: {
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    textDecoration: 'none',
    display: 'inline',
    margin: 0,
    padding: 0,
    color: theme.palette.primary.main,
    '&:hover': {
      textDecoration: 'underline',
    },
    '&:focus': {
      textDecoration: 'none',
    },
  },
  dialog: {
    padding: theme.spacing(3),
  },
  navBarButton: {
    display: 'grid',
    color: theme.current.primary.main,
  },
  caption: {
    textTransform: 'none',
    lineHeight: 0.8,
    color: theme.current.primary.main,
  },
  btnColor: {
    backgroundColor: theme.current.primary.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.current.primary.dark,
    },
  },
  description: { padding: theme.spacing(1, 0) },
}));

type Props = {
  organisation: Object,
  withContainedButton: Boolean,
  withPhone: Boolean,
  withEmail: Boolean,
  withPostCode: Boolean,
  title: String,
  description: String,
  buttonLabel: String,
  descriptionPlaceHolder: String,
  descriptionLabel: String,
  isNavBar: Boolean,
  secondLabel: String,
  backgroundColor: String,
  defaultCountryCode: String,
  gtmOrigin: String,
  defaultDescription: String,
  requiredDescription: Boolean,
  requiredPhone: Boolean,
};

const ButtonCallMeBackInModal = ({
  organisation,
  withContainedButton,
  withPhone,
  withEmail,
  withPostCode,
  title,
  description,
  buttonLabel,
  descriptionPlaceHolder,
  descriptionLabel,
  isNavBar,
  secondLabel,
  backgroundColor,
  defaultCountryCode,
  gtmOrigin,
  defaultDescription,
  requiredDescription,
  requiredPhone,
}: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const currentUser = useSelector(state => state.auth.currentUser);
  const createdLead = useSelector(state => state.leads.lead);
  const isCreatingOnOrga = useSelector(state => state.leads.isFetching.createNonQualifiedLead);
  const isCreatingOnLeague = useSelector(state => state.leads.isFetching.createLeagueLead);
  const isCreating = isCreatingOnOrga || isCreatingOnLeague;
  const leadOnOrga = !!organisation;
  const league = Routes.currentLeague();
  const isSLA = league.namespace === 'SLA';

  const [formData, setFormData] = React.useState({
    firstName: currentUser?.first_name || '',
    lastName: currentUser?.last_name || '',
    phone: currentUser?.phone || '',
    countryCode: currentUser?.country_code || defaultCountryCode || 'fr',
    email: currentUser?.email || '',
    helperType: HELPER_TYPES[0].slug,
    situation: '',
    age: '',
    need: '',
    postCode: '',
    description: defaultDescription || '',
    phoneError: null,
    emailError: null,
    postCodeError: null,
  });
  const [openDialog, setOpenDialog] = React.useState(false);

  const isUnvalid =
    !formData.firstName?.trim() ||
    !formData.lastName?.trim() ||
    !formData.email?.trim() ||
    (withPostCode && !formData.postCode?.trim()) ||
    formData.phoneError !== null ||
    formData.emailError !== null ||
    (isSLA &&
      formData.helperType !== 'other' &&
      (!formData.situation || !formData.age || !formData.need || !formData.helperType)) ||
    formData.postCodeError !== null ||
    (requiredDescription && !formData.description) ||
    (requiredPhone && !formData.phone);

  const handleClickOpen = event => {
    dispatch(LeadsActions.resetLead());
    setFormData({ ...formData, description: defaultDescription || '' });
    setOpenDialog(true);

    event.preventDefault();
  };

  const checkEmail = () => {
    if (!withEmail) return true;

    let validated = checkEmailValidity(formData.email);
    const newFormData = { ...formData, emailError: validated ? null : 'Email incorrect' };
    setFormData(newFormData);
    return validated;
  };

  const onSubmit = () => {
    if (!formData.phoneError && !formData.postCodeError && checkEmail()) {
      let leadAttributes = {
        helper_first_name: formData.firstName,
        helper_last_name: formData.lastName,
        phone: formData.phone,
        country_code: formData.countryCode,
        email: formData.email.toLowerCase()?.trim(),
        meta: { departement: formData.postCode },
        description: formData.description,
        organisation_id: organisation?.id,
      };

      if (isSLA && formData.helperType !== 'other') {
        leadAttributes.meta = {
          ...leadAttributes.meta,
          helper_type: formData.helperType,
          helper_situation: formData.situation,
          helper_age: formData.age,
          helper_needs: formData.need,
        };
      }

      if (!currentUser) {
        window.dataLayer.push({
          event: 'gtm_create_account',
          origin: 'contact',
          orga_id: leadOnOrga ? organisation?.id : null,
        });
      }

      if (leadOnOrga) {
        dispatch(
          LeadsActions.createNonQualifiedLeadRequest(
            organisation.holding.slug,
            organisation.slug,
            leadAttributes,
          ),
        );
        window.dataLayer.push({
          event: 'gtm_new_nonqualified_lead',
          receiver_type: 'orga',
          receiver_id: organisation.id,
          origin: gtmOrigin || 'other',
          role: organisation.role,
        });
      } else if (league) {
        dispatch(LeadsActions.createLeagueLeadRequest(league.id, leadAttributes));
        window.dataLayer.push({
          event: 'gtm_new_nonqualified_lead',
          receiver_type: 'league',
          receiver_id: league.id,
          origin: gtmOrigin || 'other',
          role: currentUser ? 'connected' : 'visitor',
        });
      }
    }
  };

  const handleCloseSuccess = () => {
    dispatch(LeadsActions.resetLead());
    setFormData({
      firstName: currentUser?.first_name || '',
      lastName: currentUser?.last_name || '',
      phone: currentUser?.phone || '',
      countryCode: currentUser?.country_code || defaultCountryCode || 'fr',
      email: currentUser?.email || '',
      helperType: HELPER_TYPES[0].slug,
      situation: '',
      age: '',
      need: '',
      postCode: '',
      description: '',
      phoneError: null,
      emailError: null,
      postCodeError: null,
    });
    setOpenDialog(false);
  };

  const renderSuccess = () => (
    <Grid className={classes.dialog}>
      <OrganisationContactSuccess
        organisation={organisation}
        title={t('APP.QUOTATION.STEP_3.TITLE')}
        subtitle={leadOnOrga ? t('APP.QUOTATION.STEP_3.SUBTITLE') : ''}
      />

      <DialogActions style={{ justifyContent: 'center' }}>
        <Button color="primary" onClick={handleCloseSuccess}>
          {t('CLOSE')}
        </Button>
      </DialogActions>
    </Grid>
  );

  const renderForm = () => (
    <form onSubmit={onSubmit}>
      <DialogTitle>
        <Typography variant="h5" component="div" align="center">
          {t(title || buttonLabel || 'APP.ASK_CALL.CONTACT_FORM.TITLE')}
        </Typography>
        {description && (
          <Typography
            variant="subtitle2"
            component="div"
            align="center"
            className={classes.description}
          >
            {t(description)}
          </Typography>
        )}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              name="firstName"
              value={formData.firstName}
              type="text"
              label={t('APP.ASK_CALL.TEXT_FIELD.LABEL.FIRST_NAME')}
              onChange={event => setFormData({ ...formData, firstName: event.target.value })}
              disabled={isAttributeDefined('first_name')(currentUser)}
              fullWidth
              autoFocus
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              name="lastName"
              value={formData.lastName}
              type="text"
              label={t('APP.ASK_CALL.TEXT_FIELD.LABEL.LAST_NAME')}
              onChange={event => setFormData({ ...formData, lastName: event.target.value })}
              disabled={isAttributeDefined('last_name')(currentUser)}
              fullWidth
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          {withPostCode && (
            <Grid item xs={withPhone ? 6 : 12}>
              <TextField
                required
                name="postCode"
                value={formData.postCode}
                type="text"
                label={t('APP.ASK_CALL.TEXT_FIELD.LABEL.DEPT')}
                onChange={event => setFormData({ ...formData, postCode: event.target.value })}
                onBlur={() =>
                  setFormData({
                    ...formData,
                    postCodeError: checkPostCodeIsValid(formData.postCode),
                  })
                }
                helperText={t(formData.postCodeError)}
                error={formData.postCodeError !== null}
                // disabled={isAttributeDefined('last_name')(currentUser)}
                fullWidth
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          )}

          {withPhone && (
            <Grid item xs={withPostCode ? 6 : 12}>
              <PhoneControl
                phone={formData.phone}
                phoneError={formData.phoneError}
                noMargin
                countryCode={formData.countryCode}
                setPhone={p => setFormData({ ...formData, phone: p })}
                setPhoneError={error => setFormData({ ...formData, phoneError: error })}
                setCountryCode={c => setFormData({ ...formData, countryCode: c })}
                readOnly={isAttributeDefined('phone')(currentUser)}
                variant="standard"
                required={requiredPhone}
              />
            </Grid>
          )}
          {withEmail && (
            <Grid item xs={12}>
              <TextField
                required
                name="email"
                value={formData.email}
                type="text"
                label={t('APP.ASK_CONTACT.TEXT_FIELD.LABEL.EMAIL.SUFFIX')}
                onChange={event => setFormData({ ...formData, email: event.target.value?.trim() })}
                onBlur={checkEmail}
                helperText={formData.emailError}
                error={formData.emailError !== null}
                disabled={isAttributeDefined('email')(currentUser)}
                fullWidth
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          )}

          {isSLA && <SLACallMeBackControls formData={formData} setFormData={setFormData} />}

          {(!isSLA || formData.helperType === 'other') && (
            <Grid item xs={12}>
              <TextField
                required={requiredDescription}
                name="description"
                value={formData.description}
                type="text"
                placeholder={t(
                  descriptionPlaceHolder || 'APP.ASK_CALL.TEXT_FIELD.LABEL.NEEDS.PLACEHOLDER',
                )}
                label={t(descriptionLabel || 'APP.ASK_CALL.TEXT_FIELD.LABEL.NEEDS.LABEL')}
                variant="outlined"
                fullWidth
                multiline
                minRows={4}
                onChange={event => setFormData({ ...formData, description: event.target.value })}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'center', padding: 16 }}>
        <Button
          // color="primary"
          variant="contained"
          onClick={onSubmit}
          disabled={isUnvalid || isCreating}
          className={classes.btnColor}
        >
          {isCreating ? <CircularProgress size={24} /> : t('APP.ASK_CALL.ACTION.SEND')}
        </Button>
      </DialogActions>
    </form>
  );

  return (
    <>
      {isNavBar && (
        <Button className={classes.navBarButton} onClick={handleClickOpen}>
          {t(buttonLabel)}
          <Typography variant="caption" className={classes.caption}>
            {t(secondLabel)}
          </Typography>
        </Button>
      )}
      {withContainedButton && !isNavBar && (
        <Button
          variant="contained"
          style={{
            backgroundColor: backgroundColor || muiTheme.current.primary.main,
            color: backgroundColor === '#fff' ? 'initial' : '#fff',
          }}
          onClick={handleClickOpen}
        >
          {t(buttonLabel || 'APP.ASK_CALL.NAME')}
        </Button>
      )}
      {!withContainedButton && !isNavBar && (
        <button type="button" className={classes.linkButton} onClick={handleClickOpen}>
          {t(buttonLabel || 'APP.ASK_CALL.NAME')}
        </button>
      )}
      <Dialog open={openDialog} onClose={handleCloseSuccess}>
        {createdLead ? renderSuccess() : renderForm()}
      </Dialog>
    </>
  );
};

export default ButtonCallMeBackInModal;
