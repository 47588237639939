import React from 'react';
import { isClientSide } from '../Config/ServerConfig';
import { isProd } from '../Acl/Controls';

export const HOSTS_CONFIG = [
  {
    hostname: 'www.wello.fr',
    league: {
      id: isProd ? 6 : 2,
      slug: 'wello',
      namespace: 'WELLO',
      rootName: 'Wello',
    },
  },
  {
    hostname: 'www.besoinaideadomicile.fr',
    league: { id: 1, slug: 'una', namespace: 'UNA', rootName: 'UNA' },
  },
  {
    hostname: 'www.lascalaa.fr',
    league: { id: 5, slug: 'la-scalaa', namespace: 'SCALAA', rootName: 'La Scalaa' },
  },
  {
    hostname: 'www.deplacezvous.fr',
    league: {
      id: 7,
      slug: 'mormal',
      namespace: 'MORMAL',
      favicon: '/faviconccmormal.ico',
      notif: '/faviconccmormalnotif.ico',
      rootName: 'Déplacez-vous',
    },
  },
  {
    hostname: 'www.soutenirlesaidants.fr',
    league: {
      id: isProd ? 10 : 3,
      slug: 'soutenir-les-aidants',
      namespace: 'SLA',
      favicon: '/faviconsla.ico',
      notif: '/faviconslanotif.ico',
      rootName: 'Soutenir les aidants',
    },
  },
  {
    hostname: 'www.mormaletvous.fr',
    league: {
      id: 12,
      slug: 'mormaletvous',
      namespace: 'CCMORMAL',
      favicon: '/faviconccmormal.ico',
      notif: '/faviconccmormalnotif.ico',
      rootName: 'Mormal et vous',
    },
  },
  {
    hostname: 'www.diapason92.fr',
    league: {
      id: 11,
      slug: 'diapason92',
      namespace: 'DIAPASON',
      favicon: '/favicondiapason.ico',
      notif: '/favicondiapasonnotif.ico',
      rootName: 'Diapason 92',
    },
  },
  {
    hostname: 'www.reprise-envies.fr',
    league: {
      id: 13,
      slug: 'reprise',
      namespace: 'REPRISE',
      favicon: '/faviconreprise.ico',
      notif: '/faviconreprisenotif.ico',
      rootName: 'Reprise Envies',
    },
  },
  {
    hostname: 'www.aidants-vendee.fr',
    league: {
      id: 14,
      slug: 'aidants-vendee',
      namespace: 'CD85',
      favicon: '/faviconvendee.ico',
      notif: '/faviconvendeenotif.ico',
      rootName: 'Aidants Vendée',
    },
  },
  {
    hostname: 'app.hapicoop.fr',
    league: {
      id: 200,
      slug: 'hapicoop',
      namespace: 'HAPICOOP',
      favicon: '/faviconhapicoop.ico',
      notif: '/faviconhapicoopnotif.ico',
      rootName: 'Hapicoop',
    },
  },
  {
    hostname: 'www.aidant-ma-vie.fr',
    league: {
      id: 201,
      slug: 'centre-helene-borel',
      namespace: 'CHB',
      favicon: '/faviconchb.ico',
      notif: '/faviconchbnotif.ico',
      rootName: 'Aidant ma vie',
    },
  },
  {
    hostname: 'www.solution-alzheimer.fr',
    league: {
      id: 202,
      slug: 'global',
      namespace: 'GLOBAL',
      favicon: '/faviconglobal.ico',
      notif: '/faviconglobalnotif.ico',
      rootName: 'Solution Alzheimer',
    },
  },
  {
    hostname: 'www.bienvivrenantes.fr',
    league: {
      id: 203,
      slug: 'nantes-erdre',
      namespace: 'NE',
      favicon: '/faviconNe.ico',
      notif: '/faviconNenotif.ico',
      rootName: 'Bien vivre Nantes',
    },
  },
  {
    hostname: 'www.bienvivreluxembourg.lu',
    league: {
      id: 208,
      slug: 'bien-vivre-au-luxembourg',
      namespace: 'SOSS',
      favicon: '/faviconsoss.ico',
      notif: '/faviconsossnotif.ico',
      rootName: 'Bien vivre au Luxembourg',
    },
  },
];

type Props = {
  host: String,
  hosts: Array,
  children: Function,
};

const HostConstraint = (props: Props) => {
  const { hosts, children } = props;
  const currentHost = isClientSide() ? window.location.hostname : global.host;
  React.useEffect(() => {
    const fav = document.getElementById('favicon');
    if (fav) {
      const hostConfig = HOSTS_CONFIG.find(
        h =>
          h.hostname ===
          currentHost.replace('-dev', '').replace('-staging', '').replace('staging.', ''),
      );
      fav.href = hostConfig?.league?.favicon ? hostConfig.league.favicon : '/favicon.ico';
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (hosts.includes(currentHost)) {
    return children;
  }
  return null;
};

export default HostConstraint;

export const checkHostConstraint = hosts => {
  const currentHost = isClientSide() ? window.location.hostname : global.host;
  return hosts.includes(currentHost);
};

export const HOSTS_NAMES = {
  wello: [
    'wello.kiosc.net',
    'wello.fr',
    'www.wello.fr',
    'www.wello-dev.fr',
    'v2.wello.fr',
    'v2.wello-dev.fr',
    'v2.wello.be',
    'staging.wello.fr',
  ],
  una: [
    'www.besoinaideadomicile.fr',
    'www.besoinaideadomicile-staging.fr',
    'staging.besoinaideadomicile.fr',
    'www.besoinaideadomicile-dev.fr',
  ],
  lascalaa: [
    'lascalaa.fr',
    'www.lascalaa.fr',
    'www.lascalaa-staging.fr',
    'staging.lascalaa.fr',
    'www.lascalaa-dev.fr',
  ],
  sla: [
    'www.soutenirlesaidants.fr',
    'staging.soutenirlesaidants.fr',
    'www.soutenirlesaidants-dev.fr',
    'localhost',
  ],
  mormal: ['www.deplacezvous.fr', 'staging.deplacezvous.fr', 'www.deplacezvous-dev.fr'],
  ccmormal: ['www.mormaletvous.fr', 'staging.mormaletvous.fr', 'www.mormaletvous-dev.fr'],
  diapason: ['www.diapason92.fr', 'www.diapason92-dev.fr'],
  reprise: ['www.reprise-envies.fr', 'www.reprise-envies-dev.fr', 'staging.reprise-envies.fr'],
  cd85: ['www.aidants-vendee.fr', 'www.aidants-vendee-dev.fr'],
  hapicoop: ['app.hapicoop.fr', 'app.hapicoop-dev.fr', 'hapicoop-wello.kiosc.net'],
  chb: ['www.aidant-ma-vie.fr', 'staging.aidant-ma-vie.fr', 'www.aidant-ma-vie-dev.fr'],
  global: [
    'www.solution-alzheimer.fr',
    'staging.solution-alzheimer.fr',
    'www.solution-alzheimer-dev.fr',
  ],
  ne: ['www.bienvivrenantes.fr', 'staging.bienvivrenantes.fr', 'www.bienvivrenantes-dev.fr'],
  soss: [
    'www.bienvivreluxembourg.lu',
    'staging.bienvivreluxembourg.lu',
    'www.bienvivreluxembourg-dev.lu',
  ],
};
