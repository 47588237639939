import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import DoneIcon from '@material-ui/icons/Done';
import green from '@material-ui/core/colors/green';
import { withStyles } from '@material-ui/core/styles';
import CardOrganisation from './CardOrganisation';
import LayoutStyles from '../../Layout/Styles/LayoutStyles';

const styles = theme => ({
  container: {
    ...LayoutStyles.centered600Layout,
    padding: theme.spacing(2, 2),
    marginTop: 0,
  },
  successTitle: {
    color: green[500],
  },
  doneIcon: {
    margin: `${theme.spacing(1)}px 0 -5px ${theme.spacing(1)}px`,
    fontSize: 32,
  },
  cardContainer: {
    margin: `${theme.spacing(5)}px 0`,
  },
  marginDescription: {
    margin: theme.spacing(2, 0),
  },
});

type Props = {
  classes: Object,
  organisation: Object,
  title: String,
  description: String,
  subtitle: String,
  children: Object,
};

function ContactSuccess(props: Props) {
  const { organisation, title, description, subtitle, classes, children } = props;

  return (
    <Grid className={classes.container}>
      <Typography variant="h5" align="center" className={classes.successTitle} gutterBottom>
        {title}
        <DoneIcon className={classes.doneIcon} />
      </Typography>
      {description && (
        <Typography
          variant="subtitle1"
          align="center"
          gutterBottom
          className={classes.marginDescription}
        >
          {description}
        </Typography>
      )}
      <Typography variant="subtitle1" align="center" gutterBottom>
        {subtitle}
      </Typography>
      {organisation && (
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          className={classes.cardContainer}
        >
          <Grid item sm={6} xs={12}>
            <CardOrganisation
              key={`organisation-${organisation?.id}`}
              organisation={organisation}
              bannerUrl={organisation?.banner_url}
              avatarUrl={organisation?.holding?.cover_url || organisation?.banner_url}
              name={organisation?.name}
              holdingName={organisation?.holding?.name || ''}
              description={organisation?.holding?.short_description?.replace(/<[^>]*>/g, '') || ''}
            />
          </Grid>
        </Grid>
      )}
      {children}
    </Grid>
  );
}

export default withStyles(styles)(ContactSuccess);
