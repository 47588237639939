import _isArray from 'lodash/isArray';

export const SERVICE_SLUGS = {
  TRANSPORT: 'transport',
  AIDE_A_DOMICILE: 'aide-a-domicile',
  REPIT: 'repit',
};
export const SERVICE_TYPES = [
  {
    slug: 'visite',
    label: 'Visite',
    value: SERVICE_SLUGS.AIDE_A_DOMICILE,
    description: 'Partager un café, un temps de lecture, une partie de cartes...',
  },
  {
    slug: 'aide-numerique',
    label: 'Aide numérique',
    value: SERVICE_SLUGS.AIDE_A_DOMICILE,
    description: "Aider à se servir d'internet, d'une tablette, d'un réseau social...",
  },
  {
    slug: 'service',
    label: 'Service',
    value: SERVICE_SLUGS.AIDE_A_DOMICILE,
    description: 'Rendre un petit service, bricolage et améliorer le quotidien...',
  },
  {
    slug: 'accompagnement-sortie',
    label: 'Accompagnement sortie',
    searchLabel: "l'accompagnement en sortie",
    value: SERVICE_SLUGS.AIDE_A_DOMICILE,
    description: 'Accompagner une sortie, pour une promenade, un rendez-vous, un loisir...',
  },
  {
    slug: 'aide-autonomie',
    label: 'Aide autonomie',
    searchLabel: "l'aide à l'autonomie",
    value: SERVICE_SLUGS.AIDE_A_DOMICILE,
    description: 'Aide au lever, au coucher, à toilette, et à la préparation des repas...',
  },
  {
    slug: 'aide-au-menage',
    label: 'Aide au ménage',
    searchLabel: "l'aide au ménage",
    value: SERVICE_SLUGS.AIDE_A_DOMICILE,
    description: "Aide à l'entretien du logement, ménage, repassage...",
  },
  {
    label: 'Transport',
    slug: 'transport',
    value: SERVICE_SLUGS.TRANSPORT,
    description:
      'Pour vous accompagner pour les courses, des rendez-vous médicaux, ou rendre visite à vos proches',
  },
  {
    label: 'Repit',
    slug: 'repit',
    value: SERVICE_SLUGS.REPIT,
    description: 'Pour accompagner votre proche dans la vie quotidienne comme si vous étiez là.',
  },
  {
    slug: 'telephone',
    label: 'Soutien téléphonique',
    value: SERVICE_SLUGS.AIDE_A_DOMICILE,
    description: "Un p'tit coup de téléphone pour prendre des nouvelles et passer un bon moment.",
  },
  {
    slug: 'course',
    label: 'Aide aux courses',
    value: SERVICE_SLUGS.AIDE_A_DOMICILE,
    description: 'Se faire déposer les courses par nos bénévoles. ',
  },
];

export const SITUATION_TYPES = [
  {
    slug: 'chronic',
    label: 'APP.ASK_CONTACT.TEXT_FIELD.LABEL.HELPER_SITUATION.CHRONIC',
  },
  {
    slug: 'evolutive',
    label: 'APP.ASK_CONTACT.TEXT_FIELD.LABEL.HELPER_SITUATION.EVOLUTIVE',
  },
  {
    slug: 'handicap',
    label: 'APP.ASK_CONTACT.TEXT_FIELD.LABEL.HELPER_SITUATION.HANDICAP',
  },
  {
    slug: 'other',
    label: 'APP.ASK_CONTACT.TEXT_FIELD.LABEL.HELPER_SITUATION.OTHER',
  },
];

export const HELPER_TYPES = [
  {
    slug: 'primary',
    label: 'APP.ASK_CONTACT.TEXT_FIELD.LABEL.HELPER_PROFILE.PRIMARY',
  },
  {
    slug: 'helped',
    label: 'APP.ASK_CONTACT.TEXT_FIELD.LABEL.HELPER_PROFILE.HELPED',
  },
  {
    slug: 'other',
    label: 'APP.ASK_CONTACT.TEXT_FIELD.LABEL.HELPER_PROFILE.OTHER',
  },
];

export const SITUATION_AGES = [
  {
    slug: 'less60',
    label: 'APP.ASK_CONTACT.TEXT_FIELD.LABEL.HELPER_AGE.LESS60',
  },
  {
    slug: 'more60',
    label: 'APP.ASK_CONTACT.TEXT_FIELD.LABEL.HELPER_AGE.MORE60',
  },
];

export const SITUATION_NEEDS = [
  {
    slug: 'informations',
    label: 'APP.ASK_CONTACT.TEXT_FIELD.LABEL.HELPER_NEEDS.INFORMATIONS',
  },
  {
    slug: 'respite',
    label: 'APP.ASK_CONTACT.TEXT_FIELD.LABEL.HELPER_NEEDS.RESPITE',
  },
  {
    slug: 'soutien',
    label: 'APP.ASK_CONTACT.TEXT_FIELD.LABEL.HELPER_NEEDS.SOUTIEN',
  },
  {
    slug: 'activity',
    label: 'APP.ASK_CONTACT.TEXT_FIELD.LABEL.HELPER_NEEDS.ACTIVITY',
  },
  {
    slug: 'accompaniement',
    label: 'APP.ASK_CONTACT.TEXT_FIELD.LABEL.HELPER_NEEDS.ACCOMPANIEMENT',
  },
];

export const getHoursState = openedAt => {
  if (_isArray(openedAt)) {
    return {
      monday: openedAt[0] || [],
      tuesday: openedAt[1] || [],
      wednesday: openedAt[2] || [],
      thursday: openedAt[3] || [],
      friday: openedAt[4] || [],
      saturday: openedAt[5] || [],
      sunday: openedAt[6] || [],
    };
  } else return {};
};
