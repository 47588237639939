import { createMigrate } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { createBlacklistFilter } from 'redux-persist-transform-filter';

const REDUX_VERSION = 10;

const migrations = {
  [REDUX_VERSION]: () => ({}),
};

const REDUX_PERSIST = {
  storeConfig: {
    key: 'primary',
    version: REDUX_VERSION,
    storage,
    migrate: createMigrate(migrations),
    transforms: [
      createBlacklistFilter('auth', ['isFetching']),
      createBlacklistFilter('cities', ['isFetching']),
      createBlacklistFilter('leads', ['isFetching']),
      createBlacklistFilter('organisationsLeague', ['isFetching']),
      createBlacklistFilter('organisation', ['isFetching', 'organisation', 'family', 'errors']),
      createBlacklistFilter('organisationMembership', ['isFetching']),
      createBlacklistFilter('leagues', ['isFetching']),
      createBlacklistFilter('users', ['isFetching']),
      createBlacklistFilter('email', ['isFetching', 'emailToken']),
      createBlacklistFilter('tasks', ['isFetching']),
      createBlacklistFilter('careNotebooks', ['isFetching']),
      createBlacklistFilter('deals', ['isFetching']),
      createBlacklistFilter('requestResponseDecorator', ['requesting']),
      createBlacklistFilter('courses', ['isFetching']),
      createBlacklistFilter('clubs', ['isFetching']),
      createBlacklistFilter('transmissions', ['isFetching']),
    ],
  },
};

export default REDUX_PERSIST;
