import 'react-app-polyfill/ie9';
import 'core-js/es/string';

// Support for...of (a commonly used syntax feature that requires Symbols)
import 'core-js/features/symbol';
// Support iterable spread (...Set, ...Map)
import 'core-js/features/array/from';
import 'core-js/features/array/includes';
import 'core-js/features/array/entries';
import 'core-js/features/array/values';
import 'core-js/features/string/pad-start';
import 'core-js/features/string/includes';
import 'core-js/features/string/repeat';
import 'core-js/features/string/ends-with';
import 'core-js/features/object/entries';
import 'core-js/features/object/values';

// React 16+ relies on Map, Set, and requestAnimationFrame
import 'core-js/features/map';
import 'core-js/features/set';
import 'core-js/web/url-search-params';

import 'filepond-polyfill';
import 'unorm';
