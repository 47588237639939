import React from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';
import Badge from '@material-ui/core/Badge';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import GroupIcon from '@material-ui/icons/Group';
import Divider from '@material-ui/core/Divider';
import WelloAvatar from '../../Shared/WelloAvatar';
import { getUserHoldingsAndOrgasTree } from '../../../Services/UserHelper';
import {
  isHealthcare,
  isSap,
  isAssociation,
  isPfr,
  isEstablishment,
  isFamily,
  isOther,
  isMyFamily,
} from '../../../Acl/Controls';
import { CheckRoleRule } from '../../../Acl/Rules';
import Routes from '../../../Routing/Routes';
import { getFamilyAvatar, getFamilyName } from '../../../Services/FamilyHelper';
import { hasUnread } from '../../../Services/SubscriptionHelper';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    padding: 0,
  },
  avatar: {
    marginRight: '-25px',
    height: '25px',
    width: '25px',
  },
  holding: {
    '& > *': {
      fontWeight: '700',
    },
  },
  badge: {
    height: '9px',
    width: '9px',
    minWidth: '9px',
    borderRadius: '15px',
  },
}));

type Props = {
  currentUser: Object,
  withFamilies: Boolean,
  withOrganisations: Boolean,
};

const OrganisationsMenuList = ({
  currentUser,
  currentUser: { leagues },
  withFamilies,
  withOrganisations,
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  let orgs = [];
  if (withOrganisations)
    orgs = orgs.concat(currentUser?.organisations).concat(currentUser?.pending_organisations);

  const families = withFamilies ? currentUser.families?.filter(fa => !isMyFamily(fa)) : [];
  const holdingAndOrgasTree =
    getUserHoldingsAndOrgasTree(
      currentUser?.holdings,
      orgs,
      orga => CheckRoleRule(orga?.role, 'organisation:read', { organisation: orga }),
      t,
    ) || [];

  const renderOrgaMenuItem = (organisation, icon) => (
    <span key={organisation.id}>
      <MenuItem
        component={Link}
        key={`orga-${organisation.id}`}
        to={Routes.organisationHomePath(organisation)}
      >
        <Badge
          color="error"
          overlap="circular"
          variant="dot"
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          invisible={!hasUnread(organisation)}
          classes={{ badge: classes.badge }}
        >
          <ListItemIcon className={classes.avatar}>{icon}</ListItemIcon>{' '}
        </Badge>
        <ListItemText
          primary={organisation.name}
          component="span"
          style={{
            paddingLeft: '10px',
          }}
          secondary={
            currentUser?.pending_organisations.find(o => o.id === organisation.id)
              ? t('APP.USER.ORGANISATIONS.WAITING')
              : ''
          }
        />
      </MenuItem>
    </span>
  );

  const renderOrgas = (holding, filter, icon) => (
    <>
      {holding.organisations
        .filter(filter)
        .map(organisation => renderOrgaMenuItem(organisation, icon))}
    </>
  );

  const renderFamilies = () => (
    <span>
      {withOrganisations && holdingAndOrgasTree.length > 0 && <Divider light />}
      {withOrganisations && (
        <MenuItem key="Familles" disabled>
          <ListItemIcon className={classes.avatar}>
            <WelloAvatar avatarClass={classes.avatar} size={36} />
          </ListItemIcon>
          <ListItemText primary="Familles" component="span" className={classes.holding} />
        </MenuItem>
      )}
      {families.map(family => (
        <span key={family.id}>
          <MenuItem
            component={Link}
            key={`orga-${family.id}`}
            to={Routes.organisationHomePath(family)}
          >
            <ListItemIcon>
              {getFamilyAvatar(family, { height: 28, width: 28, fontSize: '1rem' })}
            </ListItemIcon>
            <ListItemText primary={getFamilyName(family)} component="span" />

            <Badge
              color="error"
              overlap="circular"
              variant="dot"
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              invisible={!hasUnread(family)}
              classes={{ badge: classes.badge }}
            />
          </MenuItem>
        </span>
      ))}
    </span>
  );

  return (
    <MenuList className={classes.root}>
      {withOrganisations &&
        leagues &&
        leagues.map(league => (
          <MenuItem component={Link} key={`league-${league.id}`} to={`/leagues/${league.id}`}>
            <ListItemIcon className={classes.avatar}>
              <LocationCityIcon />
            </ListItemIcon>
            <ListItemText primary={_.upperFirst(league.name)} component="span" />
          </MenuItem>
        ))}
      {withOrganisations &&
        holdingAndOrgasTree.map(holding => (
          <span key={holding.id}>
            {/* {(i > 0 || leagues?.length > 0) && <Divider light />} */}
            <MenuItem
              component={Link}
              key={`holding-${holding.id}`}
              to={
                holding.organisations.length === 1
                  ? `/${holding.slug}/${holding.organisations[0].slug}`
                  : `/${holding.slug}/leads`
              }
              disabled={holding.organisations.length > 1 && !holding.admin_id}
            >
              <ListItemIcon className={classes.avatar}>
                <WelloAvatar avatarUrl={holding.cover_url} avatarClass={classes.avatar} size={36} />
              </ListItemIcon>
              <ListItemText
                primary={
                  holding.organisations.length === 1
                    ? `${holding.name} - ${holding.organisations[0].name} `
                    : holding.name
                }
                component="span"
                className={classes.holding}
              />
            </MenuItem>
            {holding.organisations.length > 1 && (
              <>
                {holding.organisations && renderOrgas(holding, isFamily, <GroupIcon />)}
                {holding.organisations && renderOrgas(holding, isAssociation, <GroupIcon />)}
                {holding.organisations && renderOrgas(holding, isPfr, <GroupIcon />)}
                {holding.organisations && renderOrgas(holding, isEstablishment, <GroupIcon />)}
                {holding.organisations && renderOrgas(holding, isSap, <GroupIcon />)}
                {holding.organisations && renderOrgas(holding, isHealthcare, <GroupIcon />)}
                {holding.organisations && renderOrgas(holding, isOther, <GroupIcon />)}
              </>
            )}
          </span>
        ))}
      {withFamilies && families?.length > 0 && renderFamilies()}
    </MenuList>
  );
};

export default OrganisationsMenuList;
