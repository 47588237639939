import Roles from './Roles';

const getAclRole = role => {
  switch (role) {
    case Roles.ADMIN: {
      return 'admin';
    }
    case Roles.MODERATOR: {
      return 'moderator';
    }
    case Roles.PRO:
    case Roles.DOCTOR:
    case Roles.NURSE:
    case Roles.KINE:
    case Roles.PSYCHOLOGIST:
    case Roles.ERGO:
    case Roles.CAREGIVER:
    case Roles.PERSONAL_CARER: {
      return 'pro';
    }
    case Roles.EXTERNAL: {
      return 'external';
    }
    case Roles.COORDINATOR: {
      return 'coordinator';
    }
    case Roles.VOLUNTEER:
    case Roles.MEMBER: {
      return 'volunteer';
    }
    case Roles.EXTERIOR:
    case Roles.PRIMARY: {
      return 'primary';
    }
    case Roles.NEIGHBOR:
    case Roles.FRIEND: {
      return 'friend';
    }

    case Roles.MAIN_HELPER: {
      return 'main_helper';
    }

    case Roles.FAMILY: {
      return 'family';
    }

    case Roles.FAMILY_VISITOR: {
      return 'family-orga-visitor';
    }

    case Roles.FAMILY_PRO: {
      return 'family-orga-pro';
    }

    case Roles.FAMILY_COORDINATOR: {
      return 'family-orga-coordinator';
    }

    default: {
      return 'visitor';
    }
  }
};

const RoleRules = {
  admin: {
    static: [
      'organisation:read',
      'organisation:configure',
      'tasks:read',
      'tasks:create',
      'tasks:register',
      'tasks:manage',
      'tasks:editdelete',
      'articles:read',
      'articles:create',
      'articles:editdelete',
      'deals:read',
      'deals:create',
      'deals:editdelete',
      'deals-comments:delete',
      'courses:read',
      'courses:create',
      'courses:editdelete',
      'leads:read',
      'leads:archive',
      'clubs:create',
      'clubs:editdelete',
      'clubs:read',
      'family:read',
      'carenotebook-dailylife:read',
      'carenotebook-healthcare:read',
      'carenotebooks-posts:editdelete',
      'carenotebooks-posts-comments:delete',
      'members:read',
      'members:update',
      'members:validate',
      'primaries:read',
      'transmissions-comments:editdelete',
      'pin:update',
      'pin:be-updated',
    ],
    dynamic: {
      'tasks:editdelete': ({ taskOrgaKey, pageOrgaKey }) => {
        if (taskOrgaKey && pageOrgaKey && taskOrgaKey !== pageOrgaKey) return false;
        return true;
      },
    },
  },
  moderator: {
    static: [
      'organisation:read',
      'organisation:configure',
      'articles:read',
      'articles:create',
      'articles:editdelete',
      'deals:read',
      'deals:create',
      'deals:editdelete',
      'deals-comments:delete',
      'clubs:create',
      'clubs:editdelete',
      'clubs:read',
      'members:read',
      'members:validate',
      'organisation:quit',
    ],
  },
  pro: {
    static: [
      'organisation:configure',
      'tasks:read',
      'tasks:create',
      'tasks:register',
      'tasks:manage',
      'tasks:editdelete',
      'articles:read',
      'deals:read',
      'deals:create',
      'courses:read',
      'courses:create',
      'leads:read',
      'clubs:read',
      'family:read',
      'carenotebook-dailylife:read',
      'carenotebook-healthcare:read',
      'members:read',
      'primaries:read',
      'articles:create',
      'pin:be-updated',
    ],
    dynamic: {
      'tasks:editdelete': ({ userId, taskOwnerId, taskOrgaKey, pageOrgaKey }) => {
        if (!userId || !taskOwnerId) return false;
        if (taskOrgaKey && pageOrgaKey && taskOrgaKey !== pageOrgaKey) return false;
        return userId === taskOwnerId;
      },
      'deals:editdelete': ({ userId, dealOwnerId }) => {
        if (!userId || !dealOwnerId) return false;
        return userId === dealOwnerId;
      },
      'deals-comments:delete': ({ userId, commentOwnerId }) => {
        if (!userId || !commentOwnerId) return false;
        return userId === commentOwnerId;
      },
      'organisation:read': ({ organisation }) => {
        if (!organisation || !organisation.type) return false;
        if (organisation.type !== 'Organisations::Family') return true;

        return false;
      },
      'carenotebooks-posts:editdelete': ({ userId, postOwnerId }) => {
        if (!userId || !postOwnerId) return false;
        return userId === postOwnerId;
      },
      'carenotebooks-posts-comments:delete': ({ userId, commentOwnerId }) => {
        if (!userId || !commentOwnerId) return false;
        return userId === commentOwnerId;
      },
      'transmissions-comments:editdelete': ({ userId, commentOwnerId }) => {
        if (!userId || !commentOwnerId) return false;
        return userId === commentOwnerId;
      },
    },
  },
  external: {
    static: [
      'tasks:read',
      'tasks:create',
      'tasks:register',
      'tasks:manage',
      'articles:read',
      'deals:read',
      'deals:create',
      'courses:read',
      'clubs:read',
      'members:read',
      'articles:create',
      'pin:be-updated',
    ],
    dynamic: {
      'tasks:editdelete': ({ userId, taskOwnerId, taskOrgaKey, pageOrgaKey }) => {
        if (!userId || !taskOwnerId) return false;
        if (taskOrgaKey && pageOrgaKey && taskOrgaKey !== pageOrgaKey) return false;
        return userId === taskOwnerId;
      },
      'deals:editdelete': ({ userId, dealOwnerId }) => {
        if (!userId || !dealOwnerId) return false;
        return userId === dealOwnerId;
      },
      'deals-comments:delete': ({ userId, commentOwnerId }) => {
        if (!userId || !commentOwnerId) return false;
        return userId === commentOwnerId;
      },
      'organisation:read': ({ organisation }) => {
        if (!organisation || !organisation.type) return false;
        if (organisation.type !== 'Organisations::Family') return true;

        return false;
      },
    },
  },
  coordinator: {
    static: [
      'organisation:configure',
      'tasks:read',
      'tasks:create',
      'tasks:register',
      'tasks:manage',
      'tasks:editdelete',
      'articles:read',
      'deals:read',
      'deals:create',
      'courses:read',
      'courses:create',
      'leads:read',
      'clubs:read',
      'family:read',
      'carenotebook-dailylife:read',
      'carenotebook-healthcare:read',
      'members:read',
      'primaries:read',
      'pin:update',
      'articles:create',
      'pin:be-updated',
    ],
    dynamic: {
      'tasks:editdelete': ({ userId, taskOwnerId, taskOrgaKey, pageOrgaKey }) => {
        if (!userId || !taskOwnerId) return false;
        if (taskOrgaKey && pageOrgaKey && taskOrgaKey !== pageOrgaKey) return false;
        return userId === taskOwnerId;
      },
      'deals:editdelete': ({ userId, dealOwnerId }) => {
        if (!userId || !dealOwnerId) return false;
        return userId === dealOwnerId;
      },
      'deals-comments:delete': ({ userId, commentOwnerId }) => {
        if (!userId || !commentOwnerId) return false;
        return userId === commentOwnerId;
      },
      'organisation:read': ({ organisation }) => {
        if (!organisation || !organisation.type) return false;
        if (organisation.type !== 'Organisations::Family') return true;

        return false;
      },
      'carenotebooks-posts:editdelete': ({ userId, postOwnerId }) => {
        if (!userId || !postOwnerId) return false;
        return userId === postOwnerId;
      },
      'carenotebooks-posts-comments:delete': ({ userId, commentOwnerId }) => {
        if (!userId || !commentOwnerId) return false;
        return userId === commentOwnerId;
      },
      'transmissions-comments:editdelete': ({ userId, commentOwnerId }) => {
        if (!userId || !commentOwnerId) return false;
        return userId === commentOwnerId;
      },
    },
  },
  volunteer: {
    static: [
      'tasks:read',
      'tasks:register',
      'articles:read',
      'deals:read',
      'deals:create',
      'courses:read',
      'leads:read',
      'clubs:read',
      'organisation:quit',
    ],
    dynamic: {
      'deals:editdelete': ({ userId, dealOwnerId }) => {
        if (!userId || !dealOwnerId) return false;
        return userId === dealOwnerId;
      },
      'deals-comments:delete': ({ userId, commentOwnerId }) => {
        if (!userId || !commentOwnerId) return false;
        return userId === commentOwnerId;
      },
      'organisation:read': ({ organisation }) => {
        if (!organisation || !organisation.type) return false;
        if (organisation.type !== 'Organisations::Family') return true;

        return false;
      },
      'articles:editdelete': ({ userId, articleOwnerId }) => {
        if (!userId || !articleOwnerId) return false;
        return userId === articleOwnerId;
      },
    },
  },
  primary: {
    static: [
      'tasks:read',
      'tasks:register',
      'articles:read',
      'deals:read',
      'deals:create',
      'courses:read',
      'clubs:read',
    ],
    dynamic: {
      'organisation:read': ({ organisation }) => {
        if (!organisation || !organisation.type) return false;
        if (organisation.type !== 'Organisations::Family') return true;

        return false;
      },
      'deals:editdelete': ({ userId, dealOwnerId }) => {
        if (!userId || !dealOwnerId) return false;
        return userId === dealOwnerId;
      },
      'deals-comments:delete': ({ userId, commentOwnerId }) => {
        if (!userId || !commentOwnerId) return false;
        return userId === commentOwnerId;
      },
    },
  },
  family: {
    static: [
      'organisation:read',
      'tasks:read',
      'tasks:create',
      'tasks:register',
      'carenotebook-dailylife:read',
      'carenotebook-healthcare:read',
    ],
    dynamic: {
      'tasks:editdelete': ({ userId, taskOwnerId, taskOrgaKey, pageOrgaKey }) => {
        if (!userId || !taskOwnerId) return false;
        if (taskOrgaKey && pageOrgaKey && taskOrgaKey !== pageOrgaKey) return false;
        return userId === taskOwnerId;
      },
      'carenotebooks-posts:editdelete': ({ userId, postOwnerId }) => {
        if (!userId || !postOwnerId) return false;
        return userId === postOwnerId;
      },
      'carenotebooks-posts-comments:delete': ({ userId, commentOwnerId }) => {
        if (!userId || !commentOwnerId) return false;
        return userId === commentOwnerId;
      },
    },
  },
  main_helper: {
    static: [
      'organisation:read',
      'tasks:read',
      'tasks:create',
      'tasks:register',
      'tasks:manage',
      'leads:read',
      'leads:archive',
      'carenotebook-dailylife:read',
      'carenotebook-healthcare:read',
      'carenotebooks-posts:editdelete',
      'carenotebooks-posts-comments:delete',
      'members:read',
      'pin:update',
    ],
    dynamic: {
      'tasks:editdelete': ({ taskOrgaKey, pageOrgaKey }) => {
        if (taskOrgaKey && pageOrgaKey && taskOrgaKey !== pageOrgaKey) return false;
        return true;
      },
      'organisation:configure': ({ organisation }) => {
        if (!organisation || !organisation.type) return false;
        return true;
      },
    },
  },
  friend: {
    static: [
      'organisation:read',
      'tasks:read',
      'tasks:create',
      'tasks:register',
      'carenotebook-dailylife:read',
    ],
    dynamic: {
      'tasks:editdelete': ({ userId, taskOwnerId, taskOrgaKey, pageOrgaKey }) => {
        if (!userId || !taskOwnerId) return false;
        if (taskOrgaKey && pageOrgaKey && taskOrgaKey !== pageOrgaKey) return false;
        return userId === taskOwnerId;
      },
      'carenotebooks-posts:editdelete': ({ userId, postOwnerId }) => {
        if (!userId || !postOwnerId) return false;
        return userId === postOwnerId;
      },
      'carenotebooks-posts-comments:delete': ({ userId, commentOwnerId }) => {
        if (!userId || !commentOwnerId) return false;
        return userId === commentOwnerId;
      },
    },
  },
  'family-orga-visitor': {
    static: ['tasks:read', 'tasks:create', 'tasks:register', 'carenotebook-dailylife:read'],
    dynamic: {
      'tasks:editdelete': ({ userId, taskOwnerId, taskOrgaKey, pageOrgaKey }) => {
        if (!userId || !taskOwnerId) return false;
        if (taskOrgaKey && pageOrgaKey && taskOrgaKey !== pageOrgaKey) return false;
        return userId === taskOwnerId;
      },
      'carenotebooks-posts:editdelete': ({ userId, postOwnerId }) => {
        if (!userId || !postOwnerId) return false;
        return userId === postOwnerId;
      },
      'carenotebooks-posts-comments:delete': ({ userId, commentOwnerId }) => {
        if (!userId || !commentOwnerId) return false;
        return userId === commentOwnerId;
      },
    },
  },
  'family-orga-pro': {
    static: [
      'family:read',
      'organisation:configure',
      'tasks:read',
      'tasks:create',
      'tasks:register',
      'tasks:manage',
      'carenotebook-dailylife:read',
      'carenotebook-healthcare:read',
    ],
    dynamic: {
      'tasks:editdelete': ({ userId, taskOwnerId, taskOrgaKey, pageOrgaKey }) => {
        if (!userId || !taskOwnerId) return false;
        if (taskOrgaKey && pageOrgaKey && taskOrgaKey !== pageOrgaKey) return false;
        return userId === taskOwnerId;
      },
      'carenotebooks-posts:editdelete': ({ userId, postOwnerId }) => {
        if (!userId || !postOwnerId) return false;
        return userId === postOwnerId;
      },
      'carenotebooks-posts-comments:delete': ({ userId, commentOwnerId }) => {
        if (!userId || !commentOwnerId) return false;
        return userId === commentOwnerId;
      },
    },
  },
  'family-orga-coordinator': {
    static: [
      'family:read',
      'organisation:configure',
      'tasks:read',
      'tasks:create',
      'tasks:register',
      'tasks:manage',
      'carenotebook-dailylife:read',
      'carenotebook-healthcare:read',
      'pin:update',
    ],
    dynamic: {
      'tasks:editdelete': ({ userId, taskOwnerId, taskOrgaKey, pageOrgaKey }) => {
        if (!userId || !taskOwnerId) return false;
        if (taskOrgaKey && pageOrgaKey && taskOrgaKey !== pageOrgaKey) return false;
        return userId === taskOwnerId;
      },
      'carenotebooks-posts:editdelete': ({ userId, postOwnerId }) => {
        if (!userId || !postOwnerId) return false;
        return userId === postOwnerId;
      },
      'carenotebooks-posts-comments:delete': ({ userId, commentOwnerId }) => {
        if (!userId || !commentOwnerId) return false;
        return userId === commentOwnerId;
      },
    },
  },
};

const OrgaRules = {
  'Organisations::Association': {
    static: [
      'leads',
      'tasks',
      'courses',
      'clubs',
      'carenotebook-dailylife',
      'schedule',
      'open-carenotebook',
      'articles',
    ],
    dynamic: {},
  },
  'Organisations::Pfr': {
    static: [
      'leads',
      'tasks',
      'courses',
      'clubs',
      'carenotebook-dailylife',
      'articles',
      'schedule',
      'open-carenotebook',
    ],
    dynamic: {},
  },
  'Organisations::Establishment': {
    static: [
      'leads',
      'tasks',
      'courses',
      'clubs',
      'carenotebook-dailylife',
      'schedule',
      'open-carenotebook',
      'articles',
    ],
    dynamic: {},
  },
  'Organisations::Sap': {
    static: [
      'leads',
      'tasks',
      'courses',
      'clubs',
      'carenotebook-dailylife',
      'carenotebook-healthcare',
      'schedule',
      'open-carenotebook',
      'articles',
    ],
    dynamic: {},
  },
  'Organisations::Healthcare': {
    static: [
      'leads',
      'courses',
      'clubs',
      'carenotebook-dailylife',
      'carenotebook-healthcare',
      'schedule',
      'open-carenotebook',
    ],
    dynamic: {},
  },
  'Organisations::Other': {
    static: ['leads', 'open-carenotebook'],
    dynamic: {},
  },
  'Organisations::Family': {
    static: ['leads', 'tasks', 'schedule', 'open-carenotebook'],
    dynamic: {
      carenotebooks: ({ family }) => {
        if (!family || !family.organisations_circle || family.organisations_circle.length === 0)
          return false;
        return true;
      },
    },
  },
};

const CheckRule = (rules, status, action, data) => {
  if (action === true) return true;

  const permissions = rules[status];
  if (!permissions) {
    // role is not present in the rules
    return false;
  }

  const staticPermissions = permissions.static;

  if (staticPermissions && staticPermissions.includes(action)) {
    // static rule not provided for action
    return true;
  }

  const dynamicPermissions = permissions.dynamic;

  if (dynamicPermissions) {
    const permissionCondition = dynamicPermissions[action];
    if (!permissionCondition) {
      // dynamic rule not provided for action
      return false;
    }

    return permissionCondition(data);
  }
  return false;
};

export const CheckRoleRule = (role, action, data) => {
  return CheckRule(RoleRules, getAclRole(role), action, data);
};

export const CheckOrgaRule = (type, action, data) => {
  return CheckRule(OrgaRules, type, action, data);
};
