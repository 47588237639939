import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import List from '@material-ui/core/List';
import Phone from '@material-ui/icons/Phone';
import Email from '@material-ui/icons/Email';
import PersonPin from '@material-ui/icons/PersonPin';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom';
import { red } from '@material-ui/core/colors';
import { isCommunity, isFamily } from '../../Acl/Controls';
import UserActions from '../../Redux/UsersRedux';
import { getOrgaName } from '../../Services/OrganisationHelper';
import Routes from '../../Routing/Routes';
import WelloAvatar from './WelloAvatar';
import { getStatus } from '../../Services/UserHelper';

const useStyles = makeStyles(theme => ({
  bigAvatar: {
    margin: 0,
    width: 45,
    height: 45,
    fontSize: '1.3rem',
  },
  chipContent: {
    paddingTop: '0px',
    paddingBottom: '0px',
    '&>*': {
      paddingTop: '0px',
      paddingBottom: '0px',
    },
    '&:last-child': {
      paddingTop: '0px',
      paddingBottom: '0px',
    },
  },
  list: {
    paddingTop: '3px',
    paddingBottom: '3px',
    paddingLeft: '0px',
  },
  listBock: {
    paddingTop: '3px',
    paddingBottom: '8px',
    paddingLeft: '0px',
  },
  listOrga: {
    paddingLeft: '0px',
    paddingTop: '0',
    paddingBottom: '0',
    marginTop: '-4px',
    marginBottom: '-4px',
  },
  listItem: {
    marginRight: '3px',
    paddingLeft: '2px',
    minWidth: '32px',
  },
  description: {
    marginBottom: theme.spacing(3),
  },
  redColor: {
    color: red[600],
  },
  chip: {
    marginRight: 4,
    marginBottom: 4,
  },
  loader: {
    marginLeft: 4,
    marginTop: 8,
    color: 'grey',
  },
  buttonLink: {
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'transparent',
      textDecoration: 'underline',
    },
    padding: 0,
  },
  overflow: {
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: '1',
    overflow: 'hidden',
    textAlign: 'left',
  },
}));

type Props = {
  helper: Object,
  actions: Object,
  validated: Boolean,
  elevation: Number,
};

const HelperDetails = ({ helper, actions, validated, elevation }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const usersOrganisations = useSelector(state => state.users.usersOrganisations);
  const currentUser = useSelector(state => state.auth.currentUser);
  const isFetchingUserOrganisations = useSelector(
    state => state.users.isFetching.getUserOrganisations,
  );
  const userOrganisations = usersOrganisations ? usersOrganisations[helper?.id] : null;

  React.useEffect(() => {
    if (helper && currentUser) dispatch(UserActions.getUserOrganisationsRequest(helper.id));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderUserOrgaSubblock = (orgaList, iconComponent, type) => (
    <List className={classes.listBlock}>
      {orgaList.map(orga => (
        <ListItem className={classes.listOrga} key={orga.id}>
          <ListItemIcon className={classes.listItem}>{iconComponent}</ListItemIcon>
          <ListItemText>
            {!isFamily(orga) && (
              <Button
                disableRipple
                className={classes.buttonLink}
                onClick={() => history.push(Routes.organisationHomePath(orga))}
              >
                <Typography className={classes.overflow}>{`${t(
                  orga.role
                    ? `APP.ROLE.TOOLTIP.${type}.${orga.role.toUpperCase()}`
                    : 'APP.ROLE.TOOLTIP.FAMILY',
                )} ${t('OF')} ${getOrgaName(orga)}`}</Typography>
              </Button>
            )}
            {isFamily(orga) && (
              <Typography>{`${t('APP.ROLE.TOOLTIP.FAMILY')} ${t('OF')} ${getOrgaName(
                orga,
              )}`}</Typography>
            )}
          </ListItemText>
        </ListItem>
      ))}
    </List>
  );

  const renderUserOrganisations = () => {
    const familyOrgas = userOrganisations?.filter(orga => isFamily(orga));
    const commuOrgas = userOrganisations?.filter(orga => isCommunity(orga));
    const otherOrgas = userOrganisations?.filter(orga => !isCommunity(orga) && !isFamily(orga));
    const showProgress =
      isFetchingUserOrganisations && (!userOrganisations || userOrganisations.length === 0);

    return (
      <>
        {showProgress && <CircularProgress className={classes.loader} size={20} />}
        {userOrganisations && userOrganisations.length > 0 && (
          <Divider
            light
            style={{ marginTop: 10, marginBottom: 10, marginRight: -30, marginLeft: -30 }}
          />
        )}
        {otherOrgas &&
          otherOrgas.length > 0 &&
          renderUserOrgaSubblock(otherOrgas, <LocationCityIcon />, 'ORGA')}
        {commuOrgas &&
          commuOrgas.length > 0 &&
          renderUserOrgaSubblock(commuOrgas, <Diversity3Icon />, 'COMMUNITY')}
        {familyOrgas &&
          familyOrgas.length > 0 &&
          renderUserOrgaSubblock(familyOrgas, <FamilyRestroomIcon />)}
      </>
    );
  };

  return (
    <>
      <Card style={{ marginTop: 0, padding: 16, minWidth: 500 }} elevation={elevation || 0}>
        <CardHeader
          avatar={
            <WelloAvatar
              avatarUrl={helper.avatar_url}
              firstName={helper.first_name}
              lastName={helper.last_name}
              backgroundColor={helper.avatar_background_color}
              avatarClass={classes.bigAvatar}
              size={45}
            />
          }
          title={
            <>
              <Typography variant="h5" style={{ textTransform: 'capitalize' }}>
                {helper.first_name} {helper.last_name?.toUpperCase()}
              </Typography>
              {validated === undefined || validated ? (
                <Typography variant="subtitle1">{helper.pseudo}</Typography>
              ) : (
                <Typography variant="subtitle1" component="p" className={classes.redColor}>
                  {t('APP.ORGANISATION.SETTINGS.MEMBERS.MEMBER_TO_VALIDATE')}
                </Typography>
              )}
            </>
          }
          action={actions}
        />
        <CardContent className={classes.chipContent}>
          <List dense>
            {/* <Typography variant="subtitle1" className={classes.description}>
              {helper.description || ''}
            </Typography> */}
            {helper.phone && (
              <ListItem className={classes.list}>
                <ListItemIcon className={classes.listItem}>
                  <Phone />
                </ListItemIcon>
                <ListItemText>
                  <Typography>{helper.phone}</Typography>
                </ListItemText>
              </ListItem>
            )}
            {helper.email && (
              <ListItem className={classes.list}>
                <ListItemIcon className={classes.listItem}>
                  <Email />
                </ListItemIcon>
                <Typography>{helper.email}</Typography>
                <Typography style={{ paddingLeft: 8, paddingRight: 8 }}>{'   -   '}</Typography>
                <ListItemText>
                  <Typography style={{ fontStyle: 'italic', fontSize: '0.85rem' }}>
                    {t(getStatus({ validated: true, helper: helper }).fullTitle)}
                  </Typography>
                </ListItemText>
              </ListItem>
            )}
            {helper.formatted_address && helper.formatted_address !== 'null' && (
              <ListItem className={classes.list}>
                <ListItemIcon className={classes.listItem}>
                  <PersonPin />
                </ListItemIcon>
                <ListItemText>
                  <Typography>
                    {helper.formatted_address && helper.formatted_address !== 'null'
                      ? helper.formatted_address
                      : ''}
                  </Typography>
                </ListItemText>
              </ListItem>
            )}
            {renderUserOrganisations()}
          </List>
        </CardContent>
      </Card>
    </>
  );
};

export default HelperDetails;
