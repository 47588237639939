import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';
import fp from 'lodash/fp';
import { createMultiAttributesTree } from '../Services/DataHelper';
import findAndReplace from './ReduxHelpers';

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  getHoldingRequest: ['holdingSlug'],
  getHoldingSuccess: ['holding'],
  getHoldingFailure: ['errors'],

  getHoldingsRequest: [''],
  getHoldingsSuccess: ['holdings'],
  getHoldingsFailure: ['errors'],

  updateHoldingRequest: [
    'holding',
    'updateAttributes',
    'cover',
    'charter',
    'currentUserId',
    'email',
  ],
  updateHoldingSuccess: ['holding'],
  updateHoldingFailure: ['errors'],

  createHoldingRequest: ['holding', 'cover', 'charter', 'currentUserId', 'email'],
  createHoldingSuccess: ['holding'],
  createHoldingFailure: ['errors'],

  addHoldingOrganisationRequest: ['holding', 'organisationId', 'currentUser'],
  addHoldingOrganisationSuccess: ['holding'],
  addHoldingOrganisationFailure: ['errors'],

  removeHoldingOrganisationRequest: ['holdingSlug', 'organisation', 'currentUser'],
  removeHoldingOrganisationSuccess: ['holding'],
  removeHoldingOrganisationFailure: ['errors'],

  createOrganisationHoldingRequest: ['holdingSlug', 'organisationAttributes', 'currentUser'],
  createOrganisationHoldingSuccess: ['holding'],
  createOrganisationHoldingFailure: ['errors'],

  getHoldingServicesRequest: ['holdingSlug'],
  getHoldingServicesSuccess: ['services'],
  getHoldingServicesFailure: ['errors'],

  getHoldingServiceRequest: ['holdingSlug', 'serviceSlug'],
  getHoldingServiceSuccess: ['service'],
  getHoldingServiceFailure: ['errors'],

  addHoldingServiceRequest: ['holdingSlug', 'service', 'image'],
  addHoldingServiceSuccess: ['service'],
  addHoldingServiceFailure: ['errors'],

  updateHoldingServiceRequest: ['holdingSlug', 'service', 'image'],
  updateHoldingServiceSuccess: ['service'],
  updateHoldingServiceFailure: ['errors'],

  removeHoldingServiceRequest: ['holdingSlug', 'serviceId'],
  removeHoldingServiceSuccess: ['serviceId'],
  removeHoldingServiceFailure: ['errors'],

  getAllPathologiesRequest: [],
  getAllPathologiesSuccess: ['pathologies'],
  getAllPathologiesFailure: ['errors'],

  getAllExpertisesRequest: [],
  getAllExpertisesSuccess: ['expertises'],
  getAllExpertisesFailure: ['errors'],

  getLevelsRequest: [],
  getLevelsSuccess: ['levels'],
  getLevelsFailure: ['errors'],

  getAllEstablishmentTypesRequest: [],
  getAllEstablishmentTypesSuccess: ['establishmentTypes'],
  getAllEstablishmentTypesFailure: ['errors'],

  addHoldingRubricRequest: ['holdingSlug', 'rubric'],
  addHoldingRubricSuccess: ['rubric'],
  addHoldingRubricFailure: ['errors'],

  updateHoldingRubricRequest: ['holdingSlug', 'rubric'],
  updateHoldingRubricSuccess: ['rubric'],
  updateHoldingRubricFailure: ['errors'],

  removeHoldingRubricRequest: ['holdingSlug', 'rubricId'],
  removeHoldingRubricSuccess: ['rubricId'],
  removeHoldingRubricFailure: ['errors'],

  resetHoldings: [],
});

export const HoldingTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
  holding: null,
  holdings: [],
  holdingServices: null,
  holdingService: null,
  isFetching: {
    getHolding: null,
    getHoldings: null,
    updateHolding: null,
    addHoldingOrganisation: null,
    getAllEstablishmentTypes: null,
  },
  errors: {
    getHolding: null,
    getHoldings: null,
    updateHolding: null,
    addHoldingOrganisation: null,
    getAllEstablishmentTypes: null,
  },
});

/* ------------- Reducers ------------- */
// get holding
const getHoldingRequest = state => ({
  ...state,
  holding: INITIAL_STATE.holding,
  isFetching: {
    ...state.isFetching,
    getHolding: true,
  },
});

const getHoldingSuccess = (state, { holding }) => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    getHolding: false,
  },
  holding,
});

const getHoldingFailure = (state, { errors }) => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    getHolding: false,
  },
  errors: {
    ...state.errors,
    getHolding: errors,
  },
});

// get holdings
const getHoldingsRequest = state => ({
  ...state,
  isFetching: {
    ...state.isFetching,
    getHoldings: true,
  },
});

const getHoldingsSuccess = (state, { holdings }) => ({
  ...state,
  holdings,
  isFetching: {
    ...state.isFetching,
    getHoldings: false,
  },
});

const getHoldingsFailure = (state, { errors }) => ({
  ...state,
  errors: {
    ...state.errors,
    getHoldings: errors,
  },
  isFetching: {
    ...state.isFetching,
    getHoldings: false,
  },
});

// -
// createHolding
//
const createHoldingRequest = state => ({
  ...state,
  isFetching: { ...state.isFetching, createHolding: true },
});

const createHoldingSuccess = (state, { holding }) => ({
  ...state,
  isFetching: { ...state.isFetching, createHolding: false },
  holding,
  holdings: [...state.holdings, holding],
  errors: INITIAL_STATE.errors,
});

const createHoldingFailure = (state, { errors }) => ({
  ...state,
  isFetching: { ...state.isFetching, createHolding: false },
  errors: {
    ...state.errors,
    createHoldings: errors,
  },
});

// -
// updateHolding
//
const updateHoldingRequest = state => ({
  ...state,
  isFetching: { ...state.isFetching, updateHolding: true },
});

const updateHoldingSuccess = (state, { holding: updatedHolding }) => ({
  ...state,
  isFetching: { ...state.isFetching, updateHolding: false },
  holding: updatedHolding,
  holdings: findAndReplace(h => h.id === updatedHolding.id, updatedHolding)(state.holdings),
  errors: INITIAL_STATE.errors,
});

const updateHoldingFailure = (state, { errors }) => ({
  ...state,
  isFetching: { ...state.isFetching, updateHolding: false },
  errors: {
    ...state.errors,
    updateHolding: errors,
  },
});

// -
// addHoldingOrganisation
//
const addHoldingOrganisationRequest = state => ({
  ...state,
  isFetching: { ...state.isFetching, addHoldingOrganisation: true },
});

const addHoldingOrganisationSuccess = (state, { holding }) => ({
  ...state,
  isFetching: { ...state.isFetching, addHoldingOrganisation: false },
  holding,
  errors: INITIAL_STATE.errors,
});

const addHoldingOrganisationFailure = (state, { errors }) => ({
  ...state,
  isFetching: { ...state.isFetching, addHoldingOrganisation: false },
  errors: {
    ...state.errors,
    addHoldingOrganisation: errors,
  },
});

// -
// removeHoldingOrganisation
//
const removeHoldingOrganisationRequest = state => ({
  ...state,
  isFetching: { ...state.isFetching, removeHoldingOrganisation: true },
});

const removeHoldingOrganisationSuccess = (state, { holding }) => ({
  ...state,
  isFetching: { ...state.isFetching, removeHoldingOrganisation: false },
  holding,
  errors: INITIAL_STATE.errors,
});

const removeHoldingOrganisationFailure = (state, { errors }) => ({
  ...state,
  isFetching: { ...state.isFetching, removeHoldingOrganisation: false },
  errors: {
    ...state.errors,
    removeHoldingOrganisation: errors,
  },
});

// -
// createOrganisationHolding
//
const createOrganisationHoldingRequest = state => ({
  ...state,
  isFetching: { ...state.isFetching, createOrganisationHolding: true },
});

const createOrganisationHoldingSuccess = (state, { holding }) => {
  const newState = {
    ...state,
    holding,
    isFetching: { ...state.isFetching, createOrganisationHolding: false },
    errors: INITIAL_STATE.errors,
  };
  return newState;
};

const createOrganisationHoldingFailure = (state, { errors }) => ({
  ...state,
  isFetching: { ...state.isFetching, createOrganisationHolding: false },
  errors: {
    ...state.errors,
    createOrganisationHolding: errors,
  },
});

// getHoldingServices
const getHoldingServicesRequest = state => ({
  ...state,
  isFetching: { ...state.isFetching, getHoldingServices: true },
  holdingServices: INITIAL_STATE.holdingServices,
  errors: INITIAL_STATE.errors,
});
const getHoldingServicesSuccess = (state, { services }) => ({
  ...state,
  isFetching: { ...state.isFetching, getHoldingServices: false },
  holdingServices: services,
});

const getHoldingServicesFailure = (state, { errors }) => ({
  ...state,
  isFetching: { ...state.isFetching, getHoldingServices: false },
  errors: {
    ...state.errors,
    getHoldingServices: errors,
  },
});

// getHoldingService
const getHoldingServiceRequest = state => ({
  ...state,
  isFetching: { ...state.isFetching, getHoldingService: true },
  holdingService: INITIAL_STATE.holdingService,
  errors: INITIAL_STATE.errors,
});
const getHoldingServiceSuccess = (state, { service: holdingService }) => ({
  ...state,
  isFetching: { ...state.isFetching, getHoldingService: false },
  holdingService,
});
const getHoldingServiceFailure = (state, { errors }) => ({
  ...state,
  isFetching: { ...state.isFetching, getHoldingService: false },
  errors: {
    ...state.errors,
    getHoldingService: errors,
  },
});

// addHoldingService
const addHoldingServiceRequest = state => ({
  ...state,
  isFetching: { ...state.isFetching, addHoldingService: true },
});
const addHoldingServiceSuccess = (state, { service }) => {
  let { holdingServices: newHoldingServices } = state;
  if (newHoldingServices) newHoldingServices.push(service);
  else newHoldingServices = [service];

  return {
    ...state,
    isFetching: { ...state.isFetching, addHoldingService: false },
    holdingServices: newHoldingServices,
    errors: INITIAL_STATE.errors,
  };
};
const addHoldingServiceFailure = (state, { errors }) => ({
  ...state,
  isFetching: { ...state.isFetching, addHoldingService: false },
  errors: {
    ...state.errors,
    addHoldingService: errors,
  },
});

// updateHoldingService
const updateHoldingServiceRequest = state => ({
  ...state,
  isFetching: { ...state.isFetching, updateHoldingService: true },
});
const updateHoldingServiceSuccess = (state, { service: updatedService }) => {
  const updatedList = fp.map(service => {
    if (service.id === updatedService.id) {
      return { ...service, ...updatedService };
    }
    return service;
  })(state.holdingServices);
  let { holdingService } = state;
  if (state.holdingService && holdingService.id === updatedService.id) {
    holdingService = updatedService;
  }
  return {
    ...state,
    isFetching: { ...state.isFetching, updateHoldingService: false },
    holdingServices: updatedList,
    holdingService,
    errors: INITIAL_STATE.errors,
  };
};
const updateHoldingServiceFailure = (state, { errors }) => ({
  ...state,
  isFetching: { ...state.isFetching, updateHoldingService: false },
  errors: {
    ...state.errors,
    updateHoldingService: errors,
  },
});

// removeHoldingService
const removeHoldingServiceRequest = state => ({
  ...state,
  isFetching: { ...state.isFetching, removeHoldingService: true },
});

const removeHoldingServiceSuccess = (state, { serviceId }) => ({
  ...state,
  isFetching: { ...state.isFetching, removeHoldingService: false },
  holdingServices: state.holdingServices.filter(service => service.id !== serviceId),
  errors: INITIAL_STATE.errors,
});

const removeHoldingServiceFailure = (state, { errors }) => ({
  ...state,
  isFetching: { ...state.isFetching, removeHoldingService: false },
  errors: {
    ...state.errors,
    removeHoldingService: errors,
  },
});

// get all pathologies
const getAllPathologiesRequest = state => ({
  ...state,
  isFetching: { ...state.isFetching, getAllPathologies: true },
  errors: INITIAL_STATE.errors,
});
const getAllPathologiesSuccess = (state, { pathologies }) => {
  const pathologiesTree = createMultiAttributesTree(pathologies, ['pathology_type']);
  return {
    ...state,
    isFetching: { ...state.isFetching, getAllPathologies: false },
    pathologiesTree,
  };
};

const getAllPathologiesFailure = (state, { errors }) => ({
  ...state,
  isFetching: { ...state.isFetching, getAllPathologies: false },
  errors: {
    ...state.errors,
    getAllPathologies: errors,
  },
});

// get all expertises
const getAllExpertisesRequest = state => ({
  ...state,
  isFetching: { ...state.isFetching, getAllExpertises: true },
  errors: INITIAL_STATE.errors,
});
const getAllExpertisesSuccess = (state, { expertises }) => {
  return {
    ...state,
    isFetching: { ...state.isFetching, getAllExpertises: false },
    allExpertises: expertises,
  };
};

const getAllExpertisesFailure = (state, { errors }) => ({
  ...state,
  isFetching: { ...state.isFetching, getAllExpertises: false },
  errors: {
    ...state.errors,
    getAllExpertises: errors,
  },
});

// get levels
const getLevelsRequest = state => ({
  ...state,
  isFetching: { ...state.isFetching, getLevels: true },
  errors: INITIAL_STATE.errors,
});

const getLevelsSuccess = (state, { levels }) => {
  return {
    ...state,
    isFetching: { ...state.isFetching, getLevels: false },
    levels,
  };
};

const getLevelsFailure = (state, { errors }) => ({
  ...state,
  isFetching: { ...state.isFetching, getLevels: false },
  errors: {
    ...state.errors,
    getLevels: errors,
  },
});

// get all establishment types
const getAllEstablishmentTypesRequest = state => ({
  ...state,
  isFetching: { ...state.isFetching, getAllEstablishmentTypes: true },
  errors: INITIAL_STATE.errors,
});
const getAllEstablishmentTypesSuccess = (state, { establishmentTypes }) => {
  return {
    ...state,
    isFetching: { ...state.isFetching, getAllEstablishmentTypes: false },
    establishmentTypes,
  };
};
const getAllEstablishmentTypesFailure = (state, { errors }) => ({
  ...state,
  isFetching: { ...state.isFetching, getAllEstablishmentTypes: false },
  errors: {
    ...state.errors,
    getAllEstablishmentTypes: errors,
  },
});

// addHoldingRubric
const addHoldingRubricRequest = state => ({
  ...state,
  isFetching: { ...state.isFetching, addHoldingRubric: true },
});
const addHoldingRubricSuccess = (state, { rubric }) => {
  let { holdingRubrics: newHoldingRubrics } = state;
  if (newHoldingRubrics) newHoldingRubrics.push(rubric);
  else newHoldingRubrics = [rubric];

  return {
    ...state,
    isFetching: { ...state.isFetching, addHoldingRubric: false },
    holdingRubrics: newHoldingRubrics,
    errors: INITIAL_STATE.errors,
  };
};
const addHoldingRubricFailure = (state, { errors }) => ({
  ...state,
  isFetching: { ...state.isFetching, addHoldingRubric: false },
  errors: {
    ...state.errors,
    addHoldingRubric: errors,
  },
});

// updateHoldingRubric
const updateHoldingRubricRequest = state => ({
  ...state,
  isFetching: { ...state.isFetching, updateHoldingRubric: true },
});
const updateHoldingRubricSuccess = (state, { rubric: updatedRubric }) => {
  const updatedList = fp.map(rubric => {
    if (rubric.id === updatedRubric.id) {
      return { ...rubric, ...updatedRubric };
    }
    return rubric;
  })(state.holdingRubrics);
  let { holdingRubric } = state;
  if (state.holdingRubric && holdingRubric.id === updatedRubric.id) {
    holdingRubric = updatedRubric;
  }
  return {
    ...state,
    isFetching: { ...state.isFetching, updateHoldingRubric: false },
    holdingRubrics: updatedList,
    holdingRubric,
    errors: INITIAL_STATE.errors,
  };
};
const updateHoldingRubricFailure = (state, { errors }) => ({
  ...state,
  isFetching: { ...state.isFetching, updateHoldingRubric: false },
  errors: {
    ...state.errors,
    updateHoldingRubric: errors,
  },
});

// removeHoldingRubric
const removeHoldingRubricRequest = state => ({
  ...state,
  isFetching: { ...state.isFetching, removeHoldingRubric: true },
});

const removeHoldingRubricSuccess = (state, { rubricId }) => ({
  ...state,
  isFetching: { ...state.isFetching, removeHoldingRubric: false },
  holdingRubrics: state.holdingRubrics.filter(rubric => rubric.id !== rubricId),
  errors: INITIAL_STATE.errors,
});

const removeHoldingRubricFailure = (state, { errors }) => ({
  ...state,
  isFetching: { ...state.isFetching, removeHoldingRubric: false },
  errors: {
    ...state.errors,
    removeHoldingRubric: errors,
  },
});

const resetHoldings = () => INITIAL_STATE;

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_HOLDING_REQUEST]: getHoldingRequest,
  [Types.GET_HOLDING_SUCCESS]: getHoldingSuccess,
  [Types.GET_HOLDING_FAILURE]: getHoldingFailure,

  [Types.GET_HOLDINGS_REQUEST]: getHoldingsRequest,
  [Types.GET_HOLDINGS_SUCCESS]: getHoldingsSuccess,
  [Types.GET_HOLDINGS_FAILURE]: getHoldingsFailure,

  [Types.CREATE_HOLDING_REQUEST]: createHoldingRequest,
  [Types.CREATE_HOLDING_SUCCESS]: createHoldingSuccess,
  [Types.CREATE_HOLDING_FAILURE]: createHoldingFailure,

  [Types.UPDATE_HOLDING_REQUEST]: updateHoldingRequest,
  [Types.UPDATE_HOLDING_SUCCESS]: updateHoldingSuccess,
  [Types.UPDATE_HOLDING_FAILURE]: updateHoldingFailure,

  [Types.ADD_HOLDING_ORGANISATION_REQUEST]: addHoldingOrganisationRequest,
  [Types.ADD_HOLDING_ORGANISATION_SUCCESS]: addHoldingOrganisationSuccess,
  [Types.ADD_HOLDING_ORGANISATION_FAILURE]: addHoldingOrganisationFailure,

  [Types.REMOVE_HOLDING_ORGANISATION_REQUEST]: removeHoldingOrganisationRequest,
  [Types.REMOVE_HOLDING_ORGANISATION_SUCCESS]: removeHoldingOrganisationSuccess,
  [Types.REMOVE_HOLDING_ORGANISATION_FAILURE]: removeHoldingOrganisationFailure,

  [Types.CREATE_ORGANISATION_HOLDING_REQUEST]: createOrganisationHoldingRequest,
  [Types.CREATE_ORGANISATION_HOLDING_SUCCESS]: createOrganisationHoldingSuccess,
  [Types.CREATE_ORGANISATION_HOLDING_FAILURE]: createOrganisationHoldingFailure,

  [Types.GET_HOLDING_SERVICES_REQUEST]: getHoldingServicesRequest,
  [Types.GET_HOLDING_SERVICES_SUCCESS]: getHoldingServicesSuccess,
  [Types.GET_HOLDING_SERVICES_FAILURE]: getHoldingServicesFailure,

  [Types.GET_HOLDING_SERVICE_REQUEST]: getHoldingServiceRequest,
  [Types.GET_HOLDING_SERVICE_SUCCESS]: getHoldingServiceSuccess,
  [Types.GET_HOLDING_SERVICE_FAILURE]: getHoldingServiceFailure,

  [Types.ADD_HOLDING_SERVICE_REQUEST]: addHoldingServiceRequest,
  [Types.ADD_HOLDING_SERVICE_SUCCESS]: addHoldingServiceSuccess,
  [Types.ADD_HOLDING_SERVICE_FAILURE]: addHoldingServiceFailure,

  [Types.UPDATE_HOLDING_SERVICE_REQUEST]: updateHoldingServiceRequest,
  [Types.UPDATE_HOLDING_SERVICE_SUCCESS]: updateHoldingServiceSuccess,
  [Types.UPDATE_HOLDING_SERVICE_FAILURE]: updateHoldingServiceFailure,

  [Types.REMOVE_HOLDING_SERVICE_REQUEST]: removeHoldingServiceRequest,
  [Types.REMOVE_HOLDING_SERVICE_SUCCESS]: removeHoldingServiceSuccess,
  [Types.REMOVE_HOLDING_SERVICE_FAILURE]: removeHoldingServiceFailure,

  [Types.GET_ALL_PATHOLOGIES_REQUEST]: getAllPathologiesRequest,
  [Types.GET_ALL_PATHOLOGIES_SUCCESS]: getAllPathologiesSuccess,
  [Types.GET_ALL_PATHOLOGIES_FAILURE]: getAllPathologiesFailure,

  [Types.GET_ALL_EXPERTISES_REQUEST]: getAllExpertisesRequest,
  [Types.GET_ALL_EXPERTISES_SUCCESS]: getAllExpertisesSuccess,
  [Types.GET_ALL_EXPERTISES_FAILURE]: getAllExpertisesFailure,

  [Types.GET_LEVELS_REQUEST]: getLevelsRequest,
  [Types.GET_LEVELS_SUCCESS]: getLevelsSuccess,
  [Types.GET_LEVELS_FAILURE]: getLevelsFailure,

  [Types.GET_ALL_ESTABLISHMENT_TYPES_REQUEST]: getAllEstablishmentTypesRequest,
  [Types.GET_ALL_ESTABLISHMENT_TYPES_SUCCESS]: getAllEstablishmentTypesSuccess,
  [Types.GET_ALL_ESTABLISHMENT_TYPES_FAILURE]: getAllEstablishmentTypesFailure,

  [Types.ADD_HOLDING_RUBRIC_REQUEST]: addHoldingRubricRequest,
  [Types.ADD_HOLDING_RUBRIC_SUCCESS]: addHoldingRubricSuccess,
  [Types.ADD_HOLDING_RUBRIC_FAILURE]: addHoldingRubricFailure,

  [Types.UPDATE_HOLDING_RUBRIC_REQUEST]: updateHoldingRubricRequest,
  [Types.UPDATE_HOLDING_RUBRIC_SUCCESS]: updateHoldingRubricSuccess,
  [Types.UPDATE_HOLDING_RUBRIC_FAILURE]: updateHoldingRubricFailure,

  [Types.REMOVE_HOLDING_RUBRIC_REQUEST]: removeHoldingRubricRequest,
  [Types.REMOVE_HOLDING_RUBRIC_SUCCESS]: removeHoldingRubricSuccess,
  [Types.REMOVE_HOLDING_RUBRIC_FAILURE]: removeHoldingRubricFailure,

  [Types.RESET_HOLDINGS]: resetHoldings,
});
