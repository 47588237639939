import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Typography from '@material-ui/core/Typography';

import ToasterActions from '../Redux/ToasterRedux';

type Props = {
  isLoggedIn: Boolean,
  currentUser: Object,
  showAuthRequiredToaster: Function,
};

const Authentication = WrappedComponent => {
  function WithAuthentication(props: Props) {
    const { isLoggedIn, currentUser, showAuthRequiredToaster } = props;
    const { t } = useTranslation();

    if (isLoggedIn) {
      return <WrappedComponent currentUser={currentUser} {...props} />;
    }

    showAuthRequiredToaster({ id: 'APP.ACL.AUTH_REQUIRED' });

    return (
      <>
        <Typography align="center" style={{ paddingTop: '20px' }}>
          <Link to="/signin">{t('APP.ACL.AUTH_REQUIRED')}</Link>
        </Typography>
        <Redirect to="/signin" />
      </>
    );
  }

  const mapStateToProps = state => ({
    isLoggedIn: state.auth.isLoggedIn,
    currentUser: state.auth.currentUser,
  });

  const mapDispatchToProps = dispatch => ({
    showAuthRequiredToaster: message =>
      dispatch(ToasterActions.showToaster(message, 'error', 5000)),
  });

  return connect(mapStateToProps, mapDispatchToProps)(WithAuthentication);
};

export default Authentication;
