import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { formatType, getLeagueSearchResultTabs } from '../../../Services/AlgoliaHelper';
import Routes from '../../../Routing/Routes';
import NoMatch404 from '../../../Pages/NoMatch404';

const CheckTabValue = WrappedComponent => {
  function Checked(props) {
    const match = useRouteMatch();
    const {
      params: { type_slug: typeSlug },
    } = match;
    const type = formatType(typeSlug);

    if (!getLeagueSearchResultTabs(Routes.currentNamespace()).includes(type)) {
      return <NoMatch404 />;
    }

    return <WrappedComponent {...props} />;
  }
  return Checked;
};

export default CheckTabValue;
