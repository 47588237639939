import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import loadable from '@loadable/component';
import TasksActions from '../Redux/TasksRedux';
const FullScreenLoader = loadable(() => import('../Components/Layout/FullScreenLoader'));

type Props = {
  match: Object,
  task: Object,
  getTask: Function,
  organisation: Object,
};

const mapStateToProps = state => ({
  task: state.tasks.task,
  organisation: state.organisation.organisation,
});

const mapDispatchToProps = dispatch => ({
  getTask: (holdingSlug, organisationSlug, familySlug, taskSlug, eventDate) =>
    dispatch(
      TasksActions.getTaskRequest(holdingSlug, organisationSlug, familySlug, taskSlug, eventDate),
    ),
});

const withCopiedTask = WrappedComponent => {
  function fetchTask(props: Props) {
    const { task, match, getTask, organisation } = props;
    const {
      task_slug: taskSlug,
      event_date: eventDate,
      holding_slug: holdingSlug,
      organisation_slug: organisationSlug,
      family_slug: familySlug,
    } = match.params;
    const taskIsMissing = !task;
    const taskHasChanged = _.get(task, 'slug') !== taskSlug;

    if (_.some([taskIsMissing, taskHasChanged])) {
      getTask(holdingSlug, organisationSlug, familySlug, taskSlug, eventDate);
      return <FullScreenLoader />;
    }
    return (
      <WrappedComponent
        {...props}
        task={{ ...task, id: null, slug: null }}
        organisation={organisation || { id: task.organisation_id, slug: organisationSlug }}
      />
    );
  }

  return connect(mapStateToProps, mapDispatchToProps)(fetchTask);
};

export default withCopiedTask;
