import React from 'react';
import { isServerSide } from '../../Config/ServerConfig';

type Props = {
  src: String,
  successRenderer: React.Component,
  errorRenderer: React.Component,
  loadRenderer: React.Component,
};

const IMAGE_STATE = {
  LOAD: null,
  SUCCESS: true,
  ERROR: false,
};
const ONE_SECOND_IN_MILLISECONDS = 1000;
const LOAD_TIMEOUT = 20 * ONE_SECOND_IN_MILLISECONDS;

class ImageWithFallback extends React.Component<Props> {
  state = {
    srcAvailable: IMAGE_STATE.LOAD,
    render: false,
  };

  //
  // react lifecycle
  //
  componentDidMount() {
    const { src } = this.props;
    if (src) {
      this.preloadSrc();
    } else {
      this.onLoadError();
    }
    this.timer = setTimeout(() => {
      // To prevent flash with initials
      this.setState({ render: true });
    }, 200);
  }

  componentDidUpdate(prevProps) {
    const { src: prevSrc } = prevProps;
    const { src: currentSrc } = this.props;

    if (prevSrc !== currentSrc) {
      this.freePreloadDependencies();
      this.preloadSrc();
    }
  }

  componentWillUnmount() {
    this.freePreloadDependencies();
    clearTimeout(this.timer);
  }

  //
  // preloading event handlers
  //
  onLoadSuccess = () => {
    this.setState({ srcAvailable: IMAGE_STATE.SUCCESS });
    this.freePreloadDependencies();
  };

  onLoadError = () => {
    this.setState({ srcAvailable: IMAGE_STATE.ERROR });
    this.freePreloadDependencies();
  };

  //
  // preloading internals
  //
  preloadSrc = () => {
    const { src } = this.props;
    this.loader = new Image();

    this.loader.onload = this.onLoadSuccess;
    this.loader.onerror = this.onLoadError;
    this.loader.src = src;

    this.loaderTimeout = setTimeout(this.abortPreloadSrc, LOAD_TIMEOUT);
  };

  abortPreloadSrc = () => {
    this.freePreloadDependencies();
    this.setState({ srcAvailable: IMAGE_STATE.ERROR });
  };

  // beware this one is important
  // using timer on component, requires careful cleanup
  // plus we are using image & events to manipulate state, requires careful cleanup
  freePreloadDependencies = () => {
    if (this.loaderTimeout) {
      clearTimeout(this.loaderTimeout); // kill timeout on unmounted component
    }
    if (this.loader) {
      this.loader.onload = null;
      this.loader.onerror = null;
      this.loader = null; // kill loader[& it's attached events] on unmounted component
    }
  };

  //
  // real rendering
  //
  render() {
    const { srcAvailable, render } = this.state;
    const { successRenderer, errorRenderer, loadRenderer } = this.props;

    if (isServerSide()) {
      return successRenderer;
    }
    switch (srcAvailable) {
      case IMAGE_STATE.SUCCESS:
        return successRenderer;
      case IMAGE_STATE.ERROR:
        return errorRenderer;
      case IMAGE_STATE.LOAD:
      default:
        return !render ? successRenderer : loadRenderer || errorRenderer;
    }
  }
}
export default React.memo(ImageWithFallback);
