import { green, orange, red, grey } from '@material-ui/core/colors';

export const getUserHoldingsAndOrgasTree = (holdings, organisations, isLinkable, t) => {
  const holdingsOrgasTree = [];

  // Add all user holdings to tree.
  // Add user organisations belonging to these holdings.

  if (holdings) {
    holdings.forEach(h => {
      const holdingUpdated = { ...h };
      holdingUpdated.organisations = organisations
        ? organisations.filter(orga => orga.holding_id === h.id && isLinkable(orga))
        : [];

      holdingsOrgasTree.push(holdingUpdated);
    });
  }
  // console.log(holdingsOrgasTree);

  // Get all other user orgas (meaning organisations whose holding doesn't belong to user holdings
  // and organisations that do not belong to any holding (like families)) and separate them in fake holdings.

  const otherUserOrgas = organisations
    ? organisations.filter(
        orga =>
          !holdingsOrgasTree.find(h => h.organisations.find(o => o.id === orga.id)) &&
          isLinkable(orga),
      )
    : [];

  const orgasWithoutHolding = [];
  const nonAdminHoldings = [];
  otherUserOrgas.forEach(orga => {
    if (!orga.holding_slug) {
      orgasWithoutHolding.push(orga);
    } else if (nonAdminHoldings.find(holding => holding.slug === orga.holding_slug)) {
      nonAdminHoldings.find(holding => holding.slug === orga.holding_slug).organisations.push(orga);
    } else {
      const type = orga.type?.split('Organisations')[1] || null;
      const nonAdminHolding = {
        name: orga.holding_name,
        slug: orga.holding_slug,
        id: orga.holding_id,
        admin_id: orga.holding_admin_id,
        admin_status: orga.holding_admin_status,
        cover_url: orga.holding_cover_url,
        organisations: [orga],
        type: type ? `Holdings${type}` : null,
      };
      nonAdminHoldings.push(nonAdminHolding);
    }
  });

  // Add non user holdings to tree (their admin_id is null so not clickable)
  if (nonAdminHoldings.length > 0) {
    nonAdminHoldings.forEach(holding => {
      holdingsOrgasTree.push(holding);
    });
  }

  // Add family holding to tree (admin_id is null so not clickable)

  if (orgasWithoutHolding.filter(o => o.role !== 'admin').length > 0) {
    const familyHolding = {
      name: t('APP.USER.MENU.FAMILIES'),
      id: -1,
      admin_id: null,
      organisations: orgasWithoutHolding.filter(o => o.role !== 'admin'),
    };
    holdingsOrgasTree.push(familyHolding);
  }
  return holdingsOrgasTree;
};

export const getUserOrganisation = (user, holdingSlug, orgaSlug) => {
  return user?.organisations?.find(
    orga => orga.slug === orgaSlug && orga.holding_slug === holdingSlug,
  );
};

export const getPrimaryStatus = membership => {
  let icon = {};
  if (membership?.validated && membership?.helper?.last_login_date) {
    icon = {
      color: green[600],
      title: 'APP.ORGANISATION.SETTINGS.MEMBERS.ACCOUNT_CREATED',
    };
  } else if (!membership?.validated) {
    icon = {
      color: red[600],
      title: 'APP.ORGANISATION.SETTINGS.MEMBERS.MEMBER_TO_VALIDATE',
    };
  } else if (
    membership?.helper?.email &&
    membership?.helper?.validation_status === 'waiting_for_email_validation'
  ) {
    icon = {
      color: orange[600],
      title: 'APP.ORGANISATION.SETTINGS.MEMBERS.ACCOUNT_NOT_CREATED',
    };
  } else if (
    membership?.helper?.validation_status === 'not_invited' &&
    !membership?.helper?.last_login_date
  ) {
    icon = {
      color: grey[500],
      title: 'APP.ORGANISATION.SETTINGS.MEMBERS.CONTACT',
    };
  } else
    icon = {
      color: '#fff',
      title: '',
    };
  return icon;
};

export const getStatus = membership => {
  let icon = {};
  if (
    membership?.validated &&
    (membership?.helper?.last_login_date ||
      membership?.helper?.validation_status === 'email_validated')
  ) {
    icon = {
      color: green[600],
      title: 'APP.ORGANISATION.SETTINGS.MEMBERS.ACCOUNT_CREATED',
      fullTitle: 'APP.ORGANISATION.SETTINGS.MEMBERS.ACCOUNT_CREATED',
    };
  } else if (!membership?.validated) {
    icon = {
      color: red[600],
      title: 'APP.ORGANISATION.SETTINGS.MEMBERS.MEMBER_TO_VALIDATE',
      fullTitle: 'APP.ORGANISATION.SETTINGS.MEMBERS.MEMBER_TO_VALIDATE',
    };
  } else if (membership?.helper.validation_status === 'waiting_for_email_validation') {
    icon = {
      color: orange[600],
      title: 'APP.ORGANISATION.SETTINGS.MEMBERS.ACCOUNT_NOT_CREATED',
      fullTitle: 'APP.ORGANISATION.SETTINGS.MEMBERS.ACCOUNT_NOT_CREATED.FULL',
    };
  } else {
    icon = {
      color: grey[500],
      title: 'APP.ORGANISATION.SETTINGS.MEMBERS.CONTACT',
      fullTitle: 'APP.ORGANISATION.SETTINGS.MEMBERS.CONTACT',
    };
  }
  return icon;
};

export const getUserFamily = (user, familySlug) => {
  return user?.families?.find(family => family.slug === familySlug);
};
